import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {mapVenueSectionGroup, VenueSectionGroup} from '../../models/venue-section-group.class';
import {mapVenueSectionSeat, VenueSectionSeat} from '../../models/venue-section-seat.class';
import {mapVenueSection, VenueSection} from '../../models/venue-section.class';
import {mapVenue, Venue} from '../../models/venue.class';

@Injectable()
export class VenueService {

    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient) {
    }

    getVenue(venueUuid: string, params?: HttpParams): Observable<Venue> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Venue>(this.baseUrl + `/v1.0/venue/${venueUuid}`, config).pipe(
            map(data => {
                return (new Venue()).mapModel(data);
            })
        );

    }

    getVenuesResponse(params?: HttpParams): Observable<HttpResponse<Venue[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Venue[]>(this.baseUrl + `/v1.0/venue`, {
            ...config,
            observe: 'response'
        });
    }

    getVenues(params?: HttpParams): Observable<Venue[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Venue[]>(this.baseUrl + `/v1.0/venue`, config);
    }

    getVenueSection(venueUuid: string, venueSectionUuid: string, params?: HttpParams): Observable<VenueSection> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<VenueSection>(this.baseUrl + `/v1.0/venue/${venueUuid}/section/${venueSectionUuid}`, config)
            .pipe(
                map((item: VenueSection) => {
                    return mapVenueSection(item);
                })
            );
    }

    getVenueSectionsGroups(venueUuid: string, params?: HttpParams): Observable<VenueSectionGroup[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<VenueSectionGroup[]>(this.baseUrl + `/v1.0/venue/${venueUuid}/group`, config);
    }

    postVenueSectionGroup(venueId: string, body: object): Observable<VenueSectionGroup> {
        return this.http.post<VenueSectionGroup>(this.baseUrl + `/v1.0/venue/${venueId}/group`, body, this.config)
            .pipe(
                map(item => {
                    return mapVenueSectionGroup(item);
                })
            );
    }

    deleteVenueSectionGroup(venueId: string, venueSectionGroupId: string): Observable<VenueSectionGroup> {
        const config = Object.assign({}, this.config);

        return this.http.delete<VenueSectionGroup>(this.baseUrl + `/v1.0/venue/${venueId}/group/${venueSectionGroupId}`, config);
    }

    postVenue(venue: Venue): Observable<Venue> {
        venue.sections = null;

        return this.http.post<Venue>(this.baseUrl + `/v1.0/venue`, venue, this.config)
            .pipe(
                map(item => {
                    return mapVenue(item);
                })
            );
    }

    updateVenue(venue: Venue, params?: HttpParams): Observable<Venue> {
        const config = Object.assign({}, this.config);
        config.params = params;
        venue.sections = null;

        return this.http.patch<Venue>(this.baseUrl + `/v1.0/venue/${venue.id}`, venue, config)
            .pipe(
                map(item => {
                    return mapVenue(item);
                })
            );
    }

    deleteVenue(venue: Venue): Observable<Venue> {
        return this.http.delete<Venue>(this.baseUrl + `/v1.0/venue/${venue.id}`, this.config);
    }

    postVenueSection(venueId: string, body: object): Observable<VenueSection> {
        return this.http.post<VenueSection>(this.baseUrl + `/v1.0/venue/${venueId}/section`, body, this.config)
            .pipe(
                map(item => {
                    return mapVenueSection(item);
                })
            );
    }

    updateVenueSection(venueId: string, venueSection: any, params?: HttpParams): Observable<VenueSection> {
        const config = Object.assign({}, this.config);
        config.params = params;
        venueSection.seats = null;
        // venueSection.resources = null;

        return this.http.patch<VenueSection>(this.baseUrl + `/v1.0/venue/${venueId}/section/${venueSection.id}`, venueSection, config)
            .pipe(
                map(item => {
                    return mapVenueSection(item);
                })
            );
    }

    deleteVenueSection(venueId: string, venueSectionId: string, params?: HttpParams): Observable<VenueSection> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.delete<VenueSection>(this.baseUrl + `/v1.0/venue/${venueId}/section/${venueSectionId}`, config);
    }

    postVenueSectionSeat(venueId: string, venueSectionId: string, body: object, params?: HttpParams): Observable<VenueSectionSeat> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<VenueSectionSeat>(this.baseUrl + `/v1.0/venue/${venueId}/section/${venueSectionId}/seat`, body, config)
            .pipe(
                map(item => {
                    return mapVenueSectionSeat(item);
                })
            );
    }

    updateVenueSectionSeat(venueId: string, venueSectionId: any, venueSectionSeatId: any, body: object, params?: HttpParams): Observable<VenueSectionSeat> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.put<VenueSectionSeat>(this.baseUrl + `/v1.0/venue/${venueId}/section/${venueSectionId}/seat/${venueSectionSeatId}`, body, config)
            .pipe(
                map(item => {
                    return mapVenueSectionSeat(item);
                })
            );
    }

    getVenueSectionSeat(venueId: string, venueSectionId: string, venueSectionSeatId: string, params?: HttpParams): Observable<VenueSectionSeat> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<VenueSectionSeat>(this.baseUrl + `/v1.0/venue/${venueId}/section/${venueSectionId}/seat/${venueSectionSeatId}`, config)
            .pipe(
                map(item => {
                    return mapVenueSectionSeat(item);
                })
            );
    }

    postVenueSectionMap(venueId: string, body: object): Observable<Venue> {
        return this.http.post<Venue>(this.baseUrl + `/v1.0/venue/${venueId}/map`, body, this.config)
            .pipe(
                map(data => {
                    return (new Venue()).mapModel(data);
                })
            );
    }
}
