import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapPaymentMethod, PaymentMethod} from '../../models/payment-method.class';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodService {

    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getPaymentMethods(params?: HttpParams): Observable<PaymentMethod[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<PaymentMethod[]>(this.baseUrl + `/v1.0/paymentmethod`, config)
            .pipe(
                map(items => {
                    return items.map(item => mapPaymentMethod(item));
                })
            );
    }

    patchPaymentMethodsEnabled(paymentMethods: PaymentMethod[], params?: HttpParams): Observable<PaymentMethod[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<PaymentMethod[]>(this.baseUrl + `/v1.0/paymentmethod`, paymentMethods, config)
            .pipe(
                map(items => {
                    return items.map(item => mapPaymentMethod(item));
                })
            );
    }
}
