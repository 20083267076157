<!-- Filter email  -->
<cm-filter #cmfilter attr.data-title="{{('Ticket_Template_Editor.Settings_Title' | trans) | async}}"
           (cm-filter-closed)="backToOverview()">
    <ng-container *ngIf="mobileTemplate$ | async as mobileTemplate">
        <!-- Name -->
        <div class="form-group m-t-16">
            <div class="form-row">
                <div class="form-input">
                    <input id="task-title" class="form-control" type="text"
                           placeholder="Title" [(ngModel)]="mobileTemplate.name" (change)="nameChanged($event)">
                    <label class="label-styling" for="task-title">Name</label>
                </div>
            </div>
        </div>
        <!-- Showing event date -->
        <div class="m-b-24 m-t-24">
            <div class="form-row">
                <div class="form-check">
                    <label>
                        <input type="checkbox" [checked]="mobileTemplate.is_showing_event_date"
                               (change)="switchEventDate($event)"/>
                        <span class="label">{{('Ticket_Template_List.Showing_Event_Date' | trans) | async}}</span>
                    </label>
                </div>
            </div>
        </div>
        <!-- Showing event date -->
        <div class="m-b-24 m-t-24">
            <div class="form-row">
                <div class="form-check">
                    <label>
                        <input type="checkbox" [checked]="mobileTemplate.is_showing_expiry_date"
                               (change)="switchExpiryDate($event)"/>
                        <span class="label">{{('Ticket_Template_List.Showing_Expiry_Date' | trans) | async}}</span>
                    </label>
                </div>
            </div>
        </div>
        <!-- Showing event date -->
        <div class="m-b-24 m-t-24">
            <div class="form-row">
                <div class="form-check">
                    <label>
                        <input type="checkbox" [checked]="mobileTemplate.is_showing_price"
                               (change)="switchPrice($event)"/>
                        <span class="label">{{('Ticket_Template_List.Showing_Price' | trans) | async}}</span>
                    </label>
                </div>
            </div>
        </div>
        <!-- Showing event date -->
        <div class="m-b-24 m-t-24">
            <div class="form-row">
                <div class="form-check">
                    <label>
                        <input type="checkbox" [checked]="mobileTemplate.is_showing_purchase_date"
                               (change)="switchPurchaseDate($event)"/>
                        <span class="label">{{('Ticket_Template_List.Showing_Purchase_Date' | trans) | async}}</span>
                    </label>
                </div>
            </div>
        </div>
        <!-- Google wallet color -->
        <div class="m-b-24 m-t-24">
            <div class="form-row">
                <div class="form-input">
                    <cm-color-picker data-color="{{mobileTemplate.primary_background_color}}" (change)="googleWalletColorChanged($event)" data-hide-alpha="true" data-label="{{('Ticket_Template_List.Google_Wallet_Color' | trans) | async}}"></cm-color-picker>
                </div>
            </div>
        </div>
        <!-- Import Image -->
        <div class="row m-t-16">
            <span [innerText]="('Mobile_Template_Editor.Banner.Size' | trans) | async"></span>
        </div>
        <div class="m-t-16">
            <cm-file-upload data-max-file-size="1048576" data-display="Banner/Logo" name="image"
                            (change)="uploadBannerImage($event)">
            </cm-file-upload>
        </div>
        <!-- Import Image -->
        <div class="row m-t-16">
            <span [innerText]="('Mobile_Template_Editor.Background.Size' | trans) | async"></span>
        </div>
        <div class="m-t-16">
            <cm-file-upload data-max-file-size="1048576" data-display="Background" name="imagev2"
                            (change)="uploadBackgroundImage($event)">
            </cm-file-upload>
        </div>
        <button class="btn btn-primary m-t-16"
                (click)="onSaveClick()">{{('Generic.Forms.SaveButton' | trans) | async}}</button>
    </ng-container>
</cm-filter>

<ng-container *ngIf="mobileTemplate">
    <div class="col-12 text-right m-b-16">
        <cm-tooltip [attr.data-tooltip-text]="('Editor.Toggle_Settings' | trans) | async">
            <button class="btn btn-primary-icon" (click)="toggleFilter()">
                <span class="cm-icon cm-icon-settings"></span>
            </button>
        </cm-tooltip>
    </div>
    <div class="mobile-template-example" [ngClass]="{'hidden': loadingEditor}" id="mobile-template-example"></div>
</ng-container>
<ng-container *ngIf="loadingEditor">
    <div class="load-indicator">
        <cm-indicator></cm-indicator>
    </div>
</ng-container>
