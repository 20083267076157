import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {Customer} from '../../../models/customer.class';
import {OrderInvoice} from '../../../models/order-invoice.class';

@Component({
    selector: 'app-dialog-create-invoice',
    templateUrl: './dialog-create-invoice.component.html',
    styleUrls: ['./dialog-create-invoice.component.css']
})
export class DialogCreateInvoiceComponent extends DialogBase<IInput, IOutput> {
    public extraData: any;

    public customer: Customer;
    public orderInvoice: OrderInvoice = new OrderInvoice();

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.extraData = data.extraData;

        this.customer = this.extraData.customer;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: {
                orderInvoice: this.orderInvoice
            }
        });
    }

    public cancel() {
        super.cancel();
    }

    useCustomerData(): void {
        this.orderInvoice.companyName = this.customer.companyName;
        this.orderInvoice.initials = this.customer.initials;
        this.orderInvoice.firstName = this.customer.firstName;
        this.orderInvoice.insertion = this.customer.insertion;
        this.orderInvoice.lastName = this.customer.lastName;
        this.orderInvoice.street = this.customer.street;
        this.orderInvoice.houseNumber = this.customer.houseNumber;
        this.orderInvoice.postalCode = this.customer.postalCode;
        this.orderInvoice.city = this.customer.city;
        this.orderInvoice.province = this.customer.province;
        this.orderInvoice.country = this.customer.country;
    }

}
