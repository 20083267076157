import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
               selector:    'app-form-text-switch',
               templateUrl: './text-switch.component.html',
               styleUrls:   ['./text-switch.component.css']
           })
export class TextSwitchComponent implements OnInit, OnDestroy {
    @Input() formId;
    @Input() id: string;
    @Input() label;
    @Input() hint;
    @Input() disabled: boolean;
    @Input() readonly;
    @Input() required;
    @Input() value: string;
    @Input() option1: any;
    @Input() option2: any;
    @Input() optionLabelTranslate            = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    isValid                                  = true;
    errorMessage: string;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);

        this.validate();
    }

    validate() {
        this.isValid      = true;
        this.errorMessage = '';

        if (this.required && !this.value) {
            this.errorMessage = 'General.Required';
            this.isValid      = false;
        }

        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }
}
