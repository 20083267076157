import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Country, mapCountry} from '../../models/country.class';
import {map} from 'rxjs/operators';
import {LocaleService} from '../../../core/services/locale.service';
import {Language} from '../../models/language.class';

@Injectable({
    providedIn: 'root'
})
export class GlobalizationService {
    private baseUrl = environment.CM_API_PROXY_URL + '/globalization';
    private defaultConfig = {
        withCredentials: true,
        params: {languageID: 'NL'}
    };

    locale: string;

    constructor(private http: HttpClient, private localeService: LocaleService) {
        const locale = this.localeService.getLanguage();
        let languageID;

        switch (locale) {
            case 'en-gb':
            case 'en-us': {
                languageID = 'EN';
                break;
            }
            case 'nl-nl': {
                languageID = 'NL';
                break;
            }
            default: {
                languageID = 'EN';
                break;
            }
        }

        this.defaultConfig.params.languageID = languageID;
    }

    getCountries(): Observable<Country[]> {
        return this.http.get<Country>(this.baseUrl + `/v1.0/countries`, this.defaultConfig)
            .pipe(
                map((items: any) => {
                    return items.map((item: Country) => {
                        return mapCountry(item);
                    });
                })
            );
    }

    getLanguages(): Observable<Language[]> {
        return this.http.get<Language>(this.baseUrl + `/v1.0/language`, this.defaultConfig)
            .pipe(
                map((items: any) => {
                    return items.map((item: Country) => {
                        return new Language().mapModel(item);
                    });
                })
            );
    }
}
