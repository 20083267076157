export const getTechnicalLink = (): string => {
    let technicalLink;
    if (typeof (window as any).getCmAccount === 'function' && (window as any).getCmAccount()) {
        if ((window as any).getCmAccount().TechnicalLinks) {
            for (const link of (window as any).getCmAccount().TechnicalLinks) {
                if (link.TechnicalLinkTypeID === 'TICKETING') {
                    technicalLink = link.ID;
                }
            }
        }
    }

    return technicalLink ? technicalLink : null;
};
export const getLogicalAccount = (): string => {
    let logicalAccount = '';
    if (typeof (window as any).getCmAccount === 'function' && (window as any).getCmAccount()) {
        for (const link of (window as any).getCmAccount().TechnicalLinks) {
            if (link.TechnicalLinkTypeID === 'TICKETING') {
                logicalAccount = link.AccountID;
            }
        }
    }

    return logicalAccount;
};
