import {ProductGroupType} from '../../enums/order/product-group-type';
import {PriceItem} from '../price-item.class';

export class OrderItemType {
    constructor(
        public id: string,
        public groupedOrderItemId: string,
        public name: string,
        public type: ProductGroupType,
        public prices: PriceItem[]) {
    }
}
