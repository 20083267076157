import {Pipe, PipeTransform} from '@angular/core';
import {ScanService} from '../../shared/models/scanservice';
import {DatePipe} from '@angular/common';
import {TranslationPipe} from './translation.pipe';
import {generateLoadingRows} from '../helpers/table-helper';

@Pipe({
    name: 'scanServiceTableRow',
    pure: true
})
export class ScanServiceTableRowPipe implements PipeTransform {

    constructor(private translationPipe: TranslationPipe, private datePipe: DatePipe) {
    }

    transform(scanServices: ScanService[], ...args: any[]): any[] {
        const isLoading = args[0] || false;

        if (isLoading) {
            return generateLoadingRows(6);
        }

        return scanServices.map((scanService, i) => {
            const containsScanUser = scanService.scanUsers.length > 0;
            const scanServiceVenueSectionNames = scanService.venueSections.map(section => section.name).join('<br>');

            const actionOptions = [
                {
                    dataDisplay: this.translationPipe.transform('Scan_Service_List.Actions.View'),
                    dataIcon: 'show',
                    id: 'VIEW',
                    disabled: 'true'
                },
                {
                    dataDisplay: this.translationPipe.transform('Scan_Service_List.Actions.Duplicate'),
                    dataIcon: 'duplicate',
                    id: 'DUPLICATE',
                    disabled: 'true'
                },
                {
                    dataDisplay: this.translationPipe.transform('Scan_Service_List.Actions.QR_Login'),
                    dataIcon: 'qr-code',
                    id: 'QR_LOGIN',
                    disabled: !containsScanUser
                },
                {
                    dataDisplay: this.translationPipe.transform('Scan_Service_List.Actions.Delete_Scan_User'),
                    dataIcon: 'group-delete',
                    id: 'DELETE_SCAN_USER',
                    disabled: !containsScanUser
                },
                {
                    dataDisplay: this.translationPipe.transform('Scan_Service_List.Actions.Delete'),
                    dataIcon: 'delete',
                    id: 'DELETE'
                }
            ];

            return {
                id: i,
                rowData: [
                    {
                        value: scanService.name,
                        options: []
                    },
                    {
                        value: scanService.events[0]?.name,
                        options: []
                    },
                    {
                        value: `
                            <div class="tag cursor-pointer">
                                <cm-tooltip data-tooltip-text="${scanServiceVenueSectionNames}">
                                    ${scanService.venueSections.length} sections
                                </cm-tooltip>
                            </div>
                        `,
                        options: [{name: 'data-tag', value: ''}]
                    },
                    {
                        value: scanService.scanUsers[0] ? `<span class="cm-icon cm-icon-user-open" aria-hidden="true"></span><span>${scanService.scanUsers[0]?.username}</span>` : null,
                        options: [{name: 'data-icon', value: ''}]
                    },
                    {
                        value: this.datePipe.transform(scanService.createdAt, 'MM/dd/yy HH:mm'),
                        options: []
                    },
                    {
                        value: actionOptions,
                        options: [{name: 'data-action', value: true}]
                    }
                ]
            };
        });
    }

}
