<cm-dialog [id]="id" data-max-width="640px">
    <h3 slot="header" class="dialog-title">
        {{('Order_Download_Dialog.Title' | trans)}}
    </h3>

    <div slot="body">
        <div class="form-group">
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onCustomFieldsChanged($event.target.checked)">
                    <span class="label">{{('Customer_Export.Custom_Field' | trans)}}</span>
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onPersonalisationLinkChanged($event.target.checked)">
                    <span class="label">{{('Order_Download_Dialog.Personalisation_Link' | trans)}}</span>
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onDirectCheckoutLinkChanged($event.target.checked)">
                    <span class="label">{{('Order_Download_Dialog.Direct_Checkout_Link' | trans)}}</span>
                </label>
            </div>
        </div>

        <ng-container *ngIf="directCheckoutLink">
            <div class="form-group">
                <app-form-select [label]="('General.Shops' | trans)"
                                 [(value)]="directCheckoutLinkShopId"
                                 [options]="shops$ | async"
                                 optionLabel="name"
                                 optionValue="id">
                </app-form-select>
            </div>
            <div class="warning-message">
                <div class="cm-icon cm-icon-warning-open" aria-hidden="true"></div>
                <div>{{('Order_Download_Dialog.Direct_Checkout_Link_Warning' | trans)}}</div>
            </div>
        </ng-container>
    </div>

    <div slot="footer">
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-primary" (click)="confirm()">{{('General.Download' | trans)}}</button>
            </div>
        </div>
    </div>
</cm-dialog>
