import {VenueSectionGroup} from './venue-section-group.class';

export abstract class OrderDistributionSetting {

    activeRule: any;
    venueId: string;
    venueSectionGroups: VenueSectionGroup[];

    constructor(public id: string = null,
                public externalId: string = null,
                public type: string = null,
                public filterEventIds: string[] = [],
                public filterTicketTypeIds: string[] = [],
                public filterSubscriptionTypeIds: string[] = [],
                public filterSubscriptionTypePriceIds: string[] = [],
                public filterProductTypeIds: string[] = [],
                public filterProductTypePriceIds: string[] = [],
                public filterVenueSectionIds: string[] = [],
                public filterVenueSectionGroupIds: string[] = [],
                public filterShopIds: string[] = [],
                public filterCustomerTagIds: string[] = [],
                public filterCustomerIds: string[] = []
    ) {
    }
}

export function mapOrderDistributionSetting(item: any): OrderDistributionSetting {

    let orderDistributionSetting = null;

    if (item.type === 'EMAIL_TEMPLATE_GROUP') {
        orderDistributionSetting = new OrderDistributionSettingEmailTemplateGroup();
        orderDistributionSetting.emailTemplateGroupId = item.emailTemplateGroupId;
        orderDistributionSetting.isDownloadable = item.isDownloadable ?? true;
        orderDistributionSetting.isMobileEnabled = item.isMobileEnabled ?? true;
    }

    if (item.type === 'MOBILE_TEMPLATE') {
        orderDistributionSetting = new OrderDistributionSettingMobileTemplate();
        orderDistributionSetting.mobileTemplateId = item.mobileTemplateId;
    }

    if (item.type === 'TICKET_TEMPLATE') {
        orderDistributionSetting = new OrderDistributionSettingTicketTemplate();
        orderDistributionSetting.ticketTemplateId = item.ticketTemplateId;
        orderDistributionSetting.barcodeType = item.barcodeType ?? 'QRCODE';
        orderDistributionSetting.ticketTemplateType = item.ticketTemplateType ?? 'A4';
    }

    orderDistributionSetting.id = item.id;
    orderDistributionSetting.externalId = item.externalId;
    orderDistributionSetting.type = item.type;
    orderDistributionSetting.filterEventIds = item.filterEventIds || [];
    orderDistributionSetting.filterTicketTypeIds = item.filterTicketTypeIds || [];
    orderDistributionSetting.filterSubscriptionTypeIds = item.filterSubscriptionTypeIds || [];
    orderDistributionSetting.filterSubscriptionTypePriceIds = item.filterSubscriptionTypePriceIds || [];
    orderDistributionSetting.filterProductTypeIds = item.filterProductTypeIds || [];
    orderDistributionSetting.filterProductTypePriceIds = item.filterProductTypePriceIds || [];
    orderDistributionSetting.filterVenueSectionIds = item.filterVenueSectionIds || [];
    orderDistributionSetting.filterVenueSectionGroupIds = item.filterVenueSectionGroupIds || [];
    orderDistributionSetting.filterShopIds = item.filterShopIds || [];
    orderDistributionSetting.filterCustomerTagIds = item.filterCustomerTagIds || [];
    orderDistributionSetting.filterCustomerIds = item.filterCustomerIds || [];

    return orderDistributionSetting;
}

export class OrderDistributionSettingEmailTemplateGroup extends OrderDistributionSetting {
    public emailTemplateGroupId: string = null;
    public isDownloadable = true;

    constructor() {
        super();
    }
}

export class OrderDistributionSettingMobileTemplate extends OrderDistributionSetting {
    mobileTemplateId: string = null;
    isMobileEnabled = true;

    constructor() {
        super();
    }
}

export class OrderDistributionSettingTicketTemplate extends OrderDistributionSetting {
    ticketTemplateId = 'A4';
    barcodeType = 'QRCODE';
    ticketTemplateType: string = null;

    constructor() {
        super();
    }
}
