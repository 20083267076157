import {SeatMapTool} from './seat-map-tool.interface';
import {VenueSectionSeat} from '../venue-section-seat.class';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {ServiceInjector} from '../../services/service-injector.service';
import { HttpParams } from '@angular/common/http';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {Renderer2} from '@angular/core';
import {OrderItemRequestHelper} from '../../helpers/request/order-item-request-helper';
import {ProductGroupType} from '../../enums/order/product-group-type';
import {SeatStatus} from '../../enums/seatmap/seat-status';

export class SelectTool extends SeatMapTool {

    private orderManagementService: OrderManagementService;
    private venueManagementService: VenueManagementService;
    private sidebarService: SidebarService;
    private renderer: Renderer2;

    private isSelecting = false;
    private selectRectStartPosition: any;
    private selectedSectionSeats = [];
    private selectRect: any;
    private selectRectStartPositionUntransformed: any;
    private transformedSeatPositions;
    private selectedEventTicket: any;
    private selectedVenueSectionSeat: any;
    private svg: any;

    constructor(options: any = {}) {
        super(options);

        this.orderManagementService = ServiceInjector.injector.get(OrderManagementService);
        this.venueManagementService = ServiceInjector.injector.get(VenueManagementService);
        this.sidebarService = ServiceInjector.injector.get(SidebarService);

        this.renderer = options.renderer;
    }

    seatMouseDown(venueSectionSeat: VenueSectionSeat) {
        if (venueSectionSeat._isProcessing) {
            return;
        }

        const orderItem = this.isSeatInCurrentOrder(venueSectionSeat);

        if (orderItem) {
            this.handleRemoveSeatFromOrder(orderItem, venueSectionSeat);
        } else {
            this.handleAddSeatToOrder(venueSectionSeat);
        }
    }

    seatMouseMove(e: any, venueSectionSeat: VenueSectionSeat) {
        //
    }

    seatMouseEnter(e: any, venueSectionSeat: VenueSectionSeat) {
        //
    }

    seatMouseOut(e: any, venueSectionSeat: VenueSectionSeat): void {
        //
    }

    svgMouseDown(e: any) {
        if (this.isSelecting) {
            this.svgMouseUp(e);
        }

        if (!e.target) {
            return;
        }

        this.selectedSectionSeats = [];
        const matrixTransform = this.getMatrixTransform(e);
        this.isSelecting = true;

        const svg = e.target.closest('svg');

        const svgClientBoundingRect = svg.getBoundingClientRect();

        this.selectRectStartPosition = {
            x: matrixTransform.x - svgClientBoundingRect.x,
            y: matrixTransform.y - svgClientBoundingRect.y
        };

        this.selectRectStartPositionUntransformed = {
            x: e.clientX,
            y: e.clientY
        };

        this.selectRect = this.renderer.createElement('rect', 'svg');
        this.renderer.addClass(this.selectRect, 'select-rect');
        this.renderer.setAttribute(this.selectRect, 'x', `${matrixTransform.x}`);
        this.renderer.setAttribute(this.selectRect, 'y', `${matrixTransform.y}`);
        this.renderer.setAttribute(this.selectRect, 'vector-effect', 'non-scaling-stroke');
        this.renderer.appendChild(svg, this.selectRect);

        this.transformedSeatPositions = [];

        document.querySelectorAll('.section-path').forEach(path => {
            const pathPosition = path.getBoundingClientRect();
            this.transformedSeatPositions.push({
                x: pathPosition.left + (pathPosition.width / 2),
                y: pathPosition.top + (pathPosition.height / 2)
            });
        });
    }

    svgMouseMove(e: any) {
        if (!e.target) {
            return;
        }

        this.svg = e.target.closest('svg');

        const matrixTransform = this.getMatrixTransform(e);
        const svgBoundingClientRect = this.svg.getBoundingClientRect();

        if (!this.isSelecting) {
            return;
        }

        const selectRectBoundingClientRect = this.selectRect.getBoundingClientRect();
        const selectedSeats: VenueSectionSeat[] = [];
        const size = {
            width: Math.abs(matrixTransform.x - this.selectRectStartPosition.x - svgBoundingClientRect.x),
            height: Math.abs(matrixTransform.y - this.selectRectStartPosition.y - svgBoundingClientRect.y)
        };

        this.renderer.setAttribute(this.selectRect, 'width', `${size.width}`);
        this.renderer.setAttribute(this.selectRect, 'height', `${size.height}`);

        if (matrixTransform.x - svgBoundingClientRect.left < this.selectRectStartPosition.x) {
            this.renderer.setAttribute(this.selectRect, 'x', matrixTransform.x);
        }

        if (matrixTransform.y - svgBoundingClientRect.top < this.selectRectStartPosition.y) {
            this.renderer.setAttribute(this.selectRect, 'y', matrixTransform.y);
        }

        this.transformedSeatPositions.forEach((seatPosition, i) => {
            if (
                seatPosition.x > selectRectBoundingClientRect.left &&
                seatPosition.x < selectRectBoundingClientRect.left + selectRectBoundingClientRect.width &&
                seatPosition.y > selectRectBoundingClientRect.top &&
                seatPosition.y < selectRectBoundingClientRect.top + selectRectBoundingClientRect.height
            ) {
                selectedSeats.push(this.options.venueSection.seats[i]);
            }
        });

        this.selectedSectionSeats = selectedSeats;
    }

    svgMouseUp(e: any): void {
        this.dragSelectSeats(this.selectedSectionSeats);
        this.selectedSectionSeats = [];

        this.removeSelectRectElement(e);
        this.isSelecting = false;
    }

    svgMouseLeave(e: any): void {
        if (!e.relatedTarget || e.relatedTarget.tagName.toLowerCase() === 'app-seat-map-tooltip') {
            return;
        }

        this.selectedSectionSeats = [];
        this.removeSelectRectElement(e);
        this.isSelecting = false;
    }

    private isSeatInCurrentOrder(venueSectionSeat: VenueSectionSeat) {
        if (!this.orderManagementService.order) {
            return false;
        }

        for (const groupedItem of this.orderManagementService.order.groupedOrderItems) {
            if ((this.options.event && this.options.event.id === groupedItem.id)
                || (this.options.subscriptionType && this.options.subscriptionType.id === groupedItem.id)
                || (this.options.subscriptionTypes && this.options.subscriptionTypes.filter(subscriptionType => subscriptionType.id === groupedItem.id))) {
                for (const orderItem of groupedItem.items) {
                    if (orderItem.ticket && orderItem.ticket.seat.id === venueSectionSeat.id) {
                        return orderItem;
                    }

                    if (orderItem.subscription && orderItem.subscription.seat.id === venueSectionSeat.id) {
                        return orderItem;
                    }
                }
            }
        }
    }

    private handleAddSeatToOrder(venueSectionSeat: VenueSectionSeat): void {
        if (venueSectionSeat.eventTickets[0]) {
            this.sidebarService.openSidebar(`orders/${venueSectionSeat.eventTickets[0].order.id}`);
            return;
        }

        venueSectionSeat._isProcessing = true;

        const request = [];
        let params = new HttpParams()
            .set('venueSectionSeat[available]', 'true')
            .set('venueSectionSeat[details]', 'true')
            .set('eventTicket[customer]', 'true');

        if (this.options.event) {
            params = params.set('scope[eventId]', this.options.event.id);
        } else if (this.options.subscriptionType) {
            params = params.set('scope[subscriptionTypeId]', this.options.subscriptionType.id);
        } else if (this.options.eventCategory) {
            params = params.set('scope[eventCategoryId]', this.options.eventCategory.id);
        }

        if (this.options.event) {
            this.venueManagementService.addSelectedSeat(venueSectionSeat);
            request.push(
                OrderItemRequestHelper.getRequestBody(
                    ProductGroupType.EVENT_TICKET,
                    this.options.event.id,
                    venueSectionSeat.id,
                    null
                )
            );
        } else if (this.options.subscriptionType) {
            this.venueManagementService.addSelectedSeat(venueSectionSeat);
            request.push(
                OrderItemRequestHelper.getRequestBody(
                    ProductGroupType.SUBSCRIPTION,
                    this.options.subscriptionType.id,
                    venueSectionSeat.id
                )
            );
        }

        this.orderManagementService.addOrderItems(request, true, () => {
            venueSectionSeat.available = 0;
            venueSectionSeat._isProcessing = false;
        });
    }

    private makeSeatAvailable(venueSectionSeat: VenueSectionSeat): void {
        venueSectionSeat.available = 1;
        venueSectionSeat.status = SeatStatus.AVAILABLE;
    }

    private handleRemoveSeatFromOrder(orderItem: any, venueSectionSeat: VenueSectionSeat): void {
        this.orderManagementService.deleteOrderItem(orderItem.id, true, () => {
            this.makeSeatAvailable(venueSectionSeat);
        });
    }

    private getMatrixTransform(e) {
        if (!e.target) {
            return;
        }

        const svg = e.target.closest('svg');

        const screenCTM = svg.getScreenCTM();
        const svgPoint = svg.createSVGPoint();

        svgPoint.x = e.clientX;
        svgPoint.y = e.clientY;

        return svgPoint.matrixTransform(screenCTM.inverse());
    }

    private dragSelectSeats(venueSectionSeats: Array<any>) {
        const request = [];
        let params = new HttpParams()
            .set('venueSectionSeat[available]', 'true')
            .set('venueSectionSeat[details]', 'true')
            .set('eventTicket[customer]', 'true')
            .set('eventTicket[order]', 'true')
            .set('depth', '3');

        if (this.options.eventCategory) {
            params = params.set('scope[eventCategoryId]', this.options.eventCategory.id);
        } else if (this.options.subscriptionType) {
            params = params.set('scope[subscriptionTypeId]', this.options.subscriptionType.id);
        } else if (this.options.event) {
            params = params.set('scope[eventId]', this.options.event.id);
        }

        // Fix drag to select bug
        for (const venueSectionSeat of venueSectionSeats) {
            if (venueSectionSeat.capacity === 0) {
                return false;
            }

            const orderItem = this.isSeatInCurrentOrder(venueSectionSeat);

            if (orderItem) {
                // Delete order item
                // TODO: Add fail check
                this.makeSeatAvailable(venueSectionSeat);
                this.orderManagementService.deleteOrderItem(orderItem.id, true, () => {
                    this.venueManagementService.fetchVenueSectionSeats([orderItem]);
                });
            } else if (venueSectionSeat.available > 0 && venueSectionSeat.status !== SeatStatus.UNAVAILABLE) {
                // Move Seat
                if (this.orderManagementService.moveOrderItem) {
                    let body;
                    if (this.orderManagementService.moveOrderItem.ticket || this.orderManagementService.moveOrderItem.subscription) {
                        body = {
                            move: {
                                venueSectionSeatId: venueSectionSeat.id,
                            }
                        };
                    }

                    this.orderManagementService.updateOrderItem(this.orderManagementService.moveOrderItem.id, body, true, () => {
                        this.venueManagementService.fetchVenueSectionSeats();
                    });
                    break;
                }

                // Add order item
                if (this.options.event) {
                    // TODO: Add fail check
                    venueSectionSeat.available = 0;
                    venueSectionSeat.status = SeatStatus.UNAVAILABLE;
                    this.venueManagementService.addSelectedSeat(venueSectionSeat);
                    request.push(
                        OrderItemRequestHelper.getRequestBody(
                            ProductGroupType.EVENT_TICKET,
                            this.options.event.id,
                            venueSectionSeat.id,
                            null
                        )
                    );
                } else if (this.options.subscriptionType) {
                    // TODO: Add fail check
                    venueSectionSeat.available = 0;
                    venueSectionSeat.status = SeatStatus.UNAVAILABLE;
                    this.venueManagementService.addSelectedSeat(venueSectionSeat);
                    request.push(
                        OrderItemRequestHelper.getRequestBody(
                            ProductGroupType.SUBSCRIPTION,
                            this.options.subscriptionType.id,
                            venueSectionSeat.id
                        )
                    );
                }
            } else if (venueSectionSeat.status !== SeatStatus.UNAVAILABLE) {
                // Get seat info
                this.selectedSectionSeats = [];
                this.selectedEventTicket = null;
                this.selectedVenueSectionSeat = venueSectionSeat;

                for (const seat of this.options.venueSection.seats) {
                    if (seat.details.customerId === venueSectionSeat.details.customerId && venueSectionSeat.details.customerId !== null) {
                        this.selectedSectionSeats.push(seat);
                    }
                }

                if (venueSectionSeat.eventTickets && venueSectionSeat.eventTickets.length > 0) {
                    this.selectVenueSectionSeat(venueSectionSeat);
                } else {
                    params = params.set('venueSectionSeat[eventTicket]', 'true').set('eventTicket[customer]', 'true');
                    this.venueManagementService.getVenueSectionSeat(venueSectionSeat.id, params, (resultVenueSectionSeat) => {
                        this.selectVenueSectionSeat(resultVenueSectionSeat);
                    });
                }
            }
        }

        if (request.length > 0) {
            this.orderManagementService.addOrderItems(request, true);
        }
    }

    private removeSelectRectElement(e) {
        if (!this.isSelecting || !this.svg) {
            return;
        }

        this.renderer.removeChild(this.svg, this.selectRect);
        this.isSelecting = false;
    }

    private selectVenueSectionSeat(venueSectionSeat: VenueSectionSeat) {
        if (venueSectionSeat.eventTickets.length > 0) {
            return;
        }

        if (venueSectionSeat.details.status !== 'RESERVED') {
            return;
        }

        if (this.selectedEventTicket.orderItemId) {
            this.orderManagementService.getOrderByOrderItemId(this.selectedEventTicket.orderItemId, () => this.orderManagementService.expandDialog(true));
        } else if (this.selectedEventTicket.subscriptionId) {
            this.orderManagementService.getOrderBySubscriptionId(this.selectedEventTicket.subscriptionId, () => this.orderManagementService.expandDialog(true));
        }
    }

    getSelectedSeats(): VenueSectionSeat[] {
        return this.selectedSectionSeats;
    }
}
