import {Customer, mapCustomer} from './customer.class';
import {ModelBase} from './abstract/model-base';
import {TableRow} from './abstract/table-row';

export class OrderListItem extends ModelBase implements TableRow {
    public id: string;
    public batchId: string;
    public status: string;
    public customer: Customer;
    public orderItemAmount: number;
    public totalPrice: string;
    public expireAt: string;
    public createdAt: string;
    public isSelected = false;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/dashboard/order'
        });
    }

    mapModel(data: any) {
        const model = new OrderListItem();

        model.id = data.id;
        model.batchId = data.batchId;
        model.status = data.status;
        model.customer = data.customer ? mapCustomer(data.customer) : null;
        model.orderItemAmount = data.orderItemAmount;
        model.totalPrice = data.totalPrice;
        model.expireAt = data.expireAt;
        model.createdAt = data.createdAt;

        return model;
    }
}
