<ng-container *ngIf="!isLoading; else loading">
    <div class="form-group">
        <div class="table-responsive full-width" style="max-height: 640px">
            <table class="table w-100">
                <thead>
                <tr class="white">
                    <th scope="col" class="cross-cell"></th>
                    <ng-container *ngFor="let venueSectionGroup of venueSectionGroups">
                        <th scope="col">{{venueSectionGroup.name}}</th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="venueSectionGroups.length > 0">
                    <ng-container *ngFor="let ticketType of ticketTypes">
                        <tr>
                            <td>{{ ticketType.name }}</td>
                            <ng-container *ngFor="let venueSectionGroup of venueSectionGroups">
                                <td *ngIf="ticketTypePriceItems[ticketType.id][venueSectionGroup.id]; let currentPriceItem">
                                    <div class="form-input">
                                        <app-form-input-currency [(value)]="currentPriceItem.price"
                                                                 [(selectedVat)]="currentPriceItem.vat"
                                                                 [countryId]="venueCountryId"
                                                                 (valueChange)="inputChanged()">
                                        </app-form-input-currency>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>

                    <ng-container *ngFor="let subscriptionTypePrice of subscriptionTypePrices">
                        <tr>
                            <td class="fixed-column">{{subscriptionTypePrice.name}}</td>
                            <ng-container *ngFor="let venueSectionGroup of venueSectionGroups">
                                <td *ngIf="subscriptionTypePriceItems[subscriptionTypePrice.id][venueSectionGroup.id]; let currentPriceItem">
                                    <div class="form-input">
                                        <app-form-input-currency
                                            [(value)]="currentPriceItem.price"
                                            [countryId]="venueCountryId"
                                            (valueChange)="inputChanged()">
                                        </app-form-input-currency>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="venueSectionGroups.length === 0">
                    <tr>
                        <td colspan="7" class="no-results-title text-center">
                            {{('Price_Plan_Table.No_Results_Title' | trans)}}<br>
                            {{('Price_Plan_Table.No_Results_Subtitle' | trans)}}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <div class="page-section" [class.open]="showTicketTypeForm || showOverridePricePlanForm">
        <div class="row">
            <div class="col-6 col-md-3 text-left">
                <button class="btn btn-secondary" *ngIf="showEditButtons && event"
                        (click)="showOverridePricePlanForm = !showOverridePricePlanForm; showTicketTypeForm = false">
                    {{('Price_Plan.Overwrite_Price_Plan' | trans)}}
                </button>
            </div>
            <div class="col-6 col-md-9 text-right">
                <button class="btn btn-primary" *ngIf="showEditButtons && subscriptionType && subscriptionType.id"
                        (click)="showSubscriptionTypePriceForm = !showSubscriptionTypePriceForm;">
                    {{('Price_Plan.New_Ticket_Type' | trans)}}
                </button>
                <button class="btn btn-primary m-l-8" *ngIf="showEditButtons && !subscriptionType && !event"
                        (click)="showTicketTypeForm = !showTicketTypeForm; showOverridePricePlanForm = false">
                    {{('Price_Plan.New_Ticket_Type' | trans)}}
                </button>
                <button class="btn btn-cta m-l-8" [disabled]="!valueChanged" (click)="savePricePlan()">
                    {{('Price_Plan_Table.Save' | trans)}}
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showTicketTypeForm">
        <div class="card-footer-action" [class.open]="showTicketTypeForm">
            <div class="row form-group">
                <div class="col-12 col-md-5">
                        <div class="form-input">
                                <app-input-translation
                                    [(value)]="ticketType.name"
                                    [type]="'text'"
                                    [translation]="ticketType.nameTranslation"
                                    [label]="('Price_Plan.Ticket_Type_Name' | trans)"
                                >
                                </app-input-translation>
                        </div>
                </div>
                <div class="col-6 col-md-2 offset-md-1 text-center">
                    <div class="form-check m-t-32">
                        <label>
                            <input type="checkbox" value="true" [(ngModel)]="ticketType.secondary">
                            <span class="label">
                                    {{('Price_Plan.Ticket_Secondary' | trans)}}
                                </span>
                        </label>
                    </div>
                </div>
                <div class="col-6 col-md-2 text-center">
                    <div class="form-check m-t-32">
                        <label>
                            <input type="checkbox" value="true" [(ngModel)]="ticketType.c3vEnabled">
                            <span class="label">{{('Price_Plan.C3V_Check' | trans)}}</span>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-2 text-right">
                    <button class="btn btn-primary m-t-24"
                            (click)="saveTicketType(ticketType)">{{('General.Add' | trans)}}</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showSubscriptionTypePriceForm">
        <div class="card-footer-action" [class.open]="showSubscriptionTypePriceForm">
            <div class="row form-group">
                <div class="col-12 col-md-4">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="text" class="form-control"
                                   placeholder="{{('Price_Plan.Ticket_Type_Name' | trans)}}"
                                   [(ngModel)]="subscriptionTypePrice.name">
                            <label>{{('Price_Plan.Ticket_Type_Name' | trans)}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="number" value="true" min="0" class="form-control"
                                   [(ngModel)]="subscriptionTypePrice.maxAmount">
                            <label>{{('Price_Plan.Maximum_Tickets_Per_Person' | trans)}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-2">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="number" value="true" class="form-control"
                                   [(ngModel)]="subscriptionTypePrice.minAge">
                            <label>{{('Price_Plan.Minimum_Age' | trans)}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-2">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="number" value="true" class="form-control"
                                   [(ngModel)]="subscriptionTypePrice.maxAge">
                            <label>{{('Price_Plan.Maximum_Age' | trans)}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2 text-right">
                    <button class="btn btn-primary m-t-24"
                            (click)="saveSubscriptionTypePrice(subscriptionTypePrice)">{{('General.Add' | trans)}}</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showOverridePricePlanForm">
        <div class="card-footer-action" [class.open]="showOverridePricePlanForm">
            <div class="row form-group">
                <div class="col-12 col-md-10">
                    <div class="form-input">
                        <cm-select data-label="{{('Price_Plan.Select_Basic_Price_Plan' | trans)}}">
                            <cm-option *ngFor="let pricePlanTemplate of pricePlanTemplates" slot="option"
                                       data-display="{{pricePlanTemplate.name}}"
                                       value="{{pricePlanTemplate.id}}"
                                       (cm-option-clicked)="setPricePlanTemplate($event)">
                            </cm-option>
                        </cm-select>
                    </div>
                </div>
                <div class="col-12 col-md-2 text-right">
                    <button class="btn btn-primary m-t-24"
                            (click)="overridePricePlan()">{{('General.Save' | trans)}}</button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
