<span class="cm-icon cm-icon-" aria-hidden="true"></span>
<ng-content></ng-content>

<ng-container *ngIf="isDismissible">
    <cm-button
        data-button-type="text"
        data-button-style="cta"
        [attr.data-label]="'General.Dismiss' | trans"
        (click)="onDismissClick()"
    ></cm-button>
</ng-container>
