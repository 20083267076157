import {Injectable} from '@angular/core';
import {from, Observable, throwError, map} from 'rxjs';
import * as qz from 'qz-tray';
import * as semver from 'semver';
import {sha256} from 'js-sha256';
import {NotificationService} from '../notification-service/notification.service';
import {environment} from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {mapPrinter, Printer} from '../../models/printer.class';
import {OrderDeliveryService} from '../order-delivery-service/order-delivery.service';
import {mapPrintFormat, PrintFormat} from '../../models/printFormat.class';
import {DialogConfirmationComponent} from '../../components/dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogService} from '../dialog-service/dialog.service';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {getTechnicalLink} from '../../../core/helpers/platform-helper';

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    websocketConnection;
    driverConnected;
    printers: Printer[] = [];
    formats = [
        {name: 'A4', type: 'PRINT_A4'},
        {name: 'A4 Split 4x', type: 'PRINT_A4_SPLIT'},
        {name: 'BOCA', type: 'PRINT_BOCA'},
        {name: 'Card', type: 'PRINT_PVC_CARD'}
    ];

    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient,
                private orderDeliveryService: OrderDeliveryService,
                private dialogService: DialogService,
                private router: Router,
                private notificationService: NotificationService) {
    }

    initialize() {
        qz.api.setSha256Type(data => sha256(data));
        qz.api.setPromiseType(resolver => new Promise(resolver));

        this.getSettings().subscribe(data => {
            // tslint:disable-next-line:no-shadowed-variable
            qz.security.setCertificatePromise((resolve) => resolve(atob(data.certificate)));
            qz.security.setSignaturePromise((toSign) => {
                // tslint:disable-next-line:no-shadowed-variable
                return (resolve, reject) => {
                    this.signMessage(toSign).subscribe({
                        next: (response) => {
                            resolve(response.data);
                        }, error: (error) => {
                            reject(error);
                        }
                    });
                };
            });

            this.connect(data.host);
        });
    }

    async connect(host) {
        try {
            this.websocketConnection = qz.websocket.connect({host: host, usingSecure: true}).then(() => {
                this.driverConnected = true;
                this.getPrinters().subscribe();
                this.checkForUpdate(environment.NEWEST_PRINTER_DRIVER_VERSION);
            });
        } catch (error) {
            console.log('Unable to connect to printer(s)', error);
            this.driverConnected = false;
        }
    }

    checkForUpdate(newestVersion) {
        try {
            this.getVersion().subscribe(installedVersion => {
                if (semver.gt(newestVersion, installedVersion)) {
                    this.dialogService.createDialogComponent(DialogConfirmationComponent, {
                        titleText: {key: 'Dialog.Update_Printer_Driver_Title'},
                        bodyText: {key: 'Dialog.Update_Printer_Driver_Body'},
                        cancelText: 'Dialog.Cancel',
                        confirmText: 'General.Download'
                    }, 'update-printer-driver');

                    this.dialogService.emitDataSubject.subscribe(response => {
                        if (!response.cancelled) {
                            return this.router.navigate(['settings/printers']);
                        }
                    });
                } else if (newestVersion !== installedVersion) {
                    console.warn('The currently installed printer driver is maybe not fully supported. Download and install the printer driver again from the seated dashboard.');
                }
            });
        } catch (error) {
            console.log('Unable validate driver version', error);
        }
    }

    getSettings(): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/v1.0/print/setting`, this.config);
    }

    signMessage(message: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + `/v1.0/print/sign`, {data: message}, this.config);
    }

    isDriverConnected() {
        return this.driverConnected;
    }

    errorHandler(error: any): Observable<any> {
        this.notificationService.showTranslatedError('Error_With_Printer');
        return throwError(error);
    }

    // Get list of printers connected
    getPrinters(): Observable<string[]> {
        return from(this.websocketConnection.then(() => qz.printers.find()))
            .pipe(
                map((printers: string[]) => printers.map((printer) => mapPrinter(printer))),
                tap(printers => this.printers = printers),
                catchError(error => this.errorHandler(error))
            );
    }

    setDefaultPrinter(printer) {
        if (printer && printer.id) {
            localStorage.setItem(`seatedDashboard.${getTechnicalLink()}.printers.default`, JSON.stringify(printer));
        }
    }

    deleteDefaultPrinter() {
        localStorage.removeItem(`seatedDashboard.${getTechnicalLink()}.printers.default`);
    }

    getDefaultPrinter() {
        if (localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.default`)) {
            return mapPrinter(JSON.parse(localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.default`)));
        }

        return null;
    }

    getPrintFormats() {
        const printFormats = [];
        for (const format of this.formats) {
            if (localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.${format.type}`)) {
                printFormats.push(mapPrintFormat(JSON.parse(localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.${format.type}`))));
            } else {
                printFormats.push(new PrintFormat(format.type, format.name, []));
            }
        }

        return printFormats;
    }

    getVersion(): Observable<string> {
        return from(this.websocketConnection.then(() => qz.api.getVersion()))
            .pipe(
                catchError(error => this.errorHandler(error))
            );
    }

    print(type: string, orderDeliveryBody: object, notification = true) {
        let printer = this.getDefaultPrinter();

        if (printer && printer.type !== type) {
            if (localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.${type}`)) {
                printer = mapPrinter(JSON.parse(localStorage.getItem(`seatedDashboard.${getTechnicalLink()}.printers.${type}`)).printers[0]);
            } else {
                printer = null;
            }
        }

        if (printer) {
            this.orderDeliveryService.postOrderDelivery(orderDeliveryBody).subscribe(orderDelivery => {
                let orderDeliveryUrl = orderDelivery.url;

                if (printer.type === 'PRINT_A4') {
                    orderDeliveryUrl = orderDeliveryUrl + '&a=pdf&s=A4';
                }

                if (printer.type === 'PRINT_A4_SPLIT') {
                    orderDeliveryUrl = orderDeliveryUrl + '&a=pdfsplit&s=A4';
                }

                if (printer.type === 'PRINT_BOCA') {
                    orderDeliveryUrl = orderDeliveryUrl + '&a=pdf&s=BOCA';
                }

                if (printer.type === 'PRINT_PVC_CARD') {
                    orderDeliveryUrl = orderDeliveryUrl + '&a=pdf&s=PVCCARD';
                }

                const pages = Math.ceil(orderDelivery.amount / 100);
                for (let page = 1; page <= pages; page++) {
                    setTimeout(() => {
                        this.printBatch(printer, [{
                            type: 'pdf',
                            data: orderDeliveryUrl + `&p=${page}`
                        }
                        ]).catch((error) => {
                            notification = false;
                            console.error(error);
                            return this.errorHandler(error);
                        }).finally(() => {
                            if (notification) {
                                this.notificationService.showTranslatedNotification('success', 'ticket', 'printed');
                            }
                        });
                        // Add timeout between calls to keep ticket batches in order
                    }, (page - 1) * 10000);
                }

            });
        } else {
            this.notificationService.showTranslatedNotification('error', 'no_printer', 'selected');
        }
    }

    // Print data to chosen printer
    printBatch(printer: Printer, data: any, name = 'Tickets'): Promise<(null | Error)> {
        if (!printer.size || printer.size.width === 0 || printer.size.height === 0) {
            printer.size = null;
        }

        // Create a default config for the found printer
        const config = qz.configs.create(printer.id, {
            jobName: name,
            scaleContent: printer.scaleContent,
            orientation: printer.orientation,
            rotation: printer.rotation,
            colorType: printer.color,
            size: printer.size
        });

        return qz.print(config, data);
    }
}
