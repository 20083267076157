<h3>{{('Order_Delivery.Delivery' | trans)}}</h3>

<div class="list-group">
    <ng-container *ngIf="orderDeliveries$ | async as orderDeliveries; else loading">
        <div class="container-group">
            <ng-container *ngFor="let orderDelivery of orderDeliveries;">
                <div class="container-group-row row m-b-32">
                    <div class="container-group-item row">
                        <div class="container-content">
                            <div class="container-group-col header row">
                                <div class="meta-avatar col">
                                        <span *ngIf="orderDelivery.method === 'DOWNLOAD_PDF'"
                                              class="cm-icon cm-icon-pdf" aria-hidden="true">
                                        </span>
                                    <span *ngIf="orderDelivery.method === 'EMAIL'" class="cm-icon cm-icon-email"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderDelivery.method === 'SMS'" class="cm-icon cm-icon-comment"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderDelivery.method === 'GUEST_MANAGER'"
                                          class="cm-icon cm-icon-user-open" aria-hidden="true"></span>
                                    <span *ngIf="orderDelivery.method === 'POST'" class="cm-icon cm-icon-telegram"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderDelivery.method === 'PICKUP'" class="cm-icon cm-icon-android"
                                          aria-hidden="true"></span>
                                    <span
                                        *ngIf="['PRINT_A4', 'PRINT_BOCA', 'PRINT_PVC_CARD'].includes(orderDelivery.method)"
                                        class="cm-icon cm-icon-print"
                                        aria-hidden="true"></span>
                                </div>
                                <div class="col">
                                    <div>{{('Order_Delivery.Event_' + orderDelivery.method | trans)}}</div>
                                    <div class="row container-sub-header">
                                        <div>{{ orderDelivery.sendAt | date:'EE d MMM y H:mm' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-group-col footer p-t-8">
                                <!--                                        <ng-container *ngIf="activityLog.actor">-->
                                <!--                                            <span class="m-r-16">-->
                                <!--                                        <span class="cm-icon m-r-4" [class.cm-icon-user-fill]="activityLog.actor.name"-->
                                <!--                                              [class.cm-icon-bot]="!activityLog.actor.name" aria-hidden="true"></span>-->
                                <!--                                                <span>{{activityLog.actor.name ? activityLog.actor.name : ('Activity_Log.Unknown' | trans)}}</span>-->
                                <!--                                            </span>-->
                                <!--                                        </ng-container>-->

                                <span class="cm-icon cm-icon-user-open m-r-4" aria-hidden="true"></span>
                                <span class="link">{{orderDelivery.firstName}} {{orderDelivery.lastName}}</span>

                                <ng-container *ngIf="orderDelivery.method === 'EMAIL'">
                                    <span class="cm-icon cm-icon-right m-r-4" aria-hidden="true"></span>
                                    <span class="link">{{orderDelivery.email}}</span>
                                </ng-container>

                                <ng-container *ngIf="orderDelivery.method === 'SMS'">
                                    <span class="cm-icon cm-icon-right m-r-4" aria-hidden="true"></span>
                                    <span class="link">{{orderDelivery.phoneNumber}}</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>


                <!--                    <div class="list-group-item multi-line row">-->
                <!--                        <div class="meta-icon">-->
                <!--                            <span *ngIf="orderDelivery.method === 'DOWNLOAD_PDF'" class="cm-icon cm-icon-archive"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                            <span *ngIf="orderDelivery.method === 'EMAIL'" class="cm-icon cm-icon-email"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                            <span *ngIf="orderDelivery.method === 'SMS'" class="cm-icon cm-icon-comment"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                            <span *ngIf="orderDelivery.method === 'GUEST_MANAGER'" class="cm-icon cm-icon-user-open"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                            <span *ngIf="orderDelivery.method === 'POST'" class="cm-icon cm-icon-telegram"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                            <span *ngIf="orderDelivery.method === 'PICKUP'" class="cm-icon cm-icon-android"-->
                <!--                                aria-hidden="true"></span>-->
                <!--                        </div>-->
                <!--                        <div class="meta-info w-100">-->
                <!--                            <div class="meta-info-desc">-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'DOWNLOAD_PDF'">-->
                <!--                                    {{('Order_Delivery.Downloaded_PDF_With' | trans)}} <a-->
                <!--                                        [href]="orderDelivery.url"-->
                <!--                                        target="_blank">{{('Order_Delivery.Tickets' | trans)}}</a>-->
                <!--                                </ng-container>-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'EMAIL'">-->
                <!--                                    {{('Order_Delivery.Email_Sent_To' | trans)}}-->
                <!--                                    <span>{{orderDelivery.email}}</span>-->
                <!--                                    {{('Order_Delivery.With' | trans)}} <a [href]="orderDelivery.url"-->
                <!--                                        target="_blank">{{('Order_Delivery.Tickets' | trans)}}</a>-->
                <!--                                    <div class="cl-black-50">Aan {{orderDelivery.firstName}} {{orderDelivery.lastName}}-->
                <!--                                        van-->
                <!--                                        {{orderDelivery.companyName}}</div>-->
                <!--                                </ng-container>-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'SMS'">-->
                <!--                                    {{('Order_Delivery.SMS_Sent_To' | trans)}}-->
                <!--                                    <span>{{orderDelivery.phoneNumber}}</span>-->
                <!--                                    {{('Order_Delivery.With' | trans)}} <a [href]="orderDelivery.url"-->
                <!--                                        target="_blank">{{('Order_Delivery.Tickets' | trans)}}</a>-->
                <!--                                    {{('Order_Delivery.To' | trans)}} {{orderDelivery.firstName}}-->
                <!--                                    {{orderDelivery.lastName}}-->
                <!--                                    <span *ngIf="orderDelivery.companyName">{{('Order_Delivery.From' | trans)}}-->
                <!--                                        {{orderDelivery.companyName}}</span>-->
                <!--                                    <div class="cl-black-50">{{('Order_Delivery.To' | trans)}}-->
                <!--                                        {{orderDelivery.firstName}} {{orderDelivery.lastName}}-->
                <!--                                        <span-->
                <!--                                            *ngIf="orderDelivery.companyName">{{('Order_Delivery.From' | trans)}}-->
                <!--                                            {{orderDelivery.companyName}}</span></div>-->
                <!--                                </ng-container>-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'GUEST_MANAGER'">-->
                <!--                                    <a [href]="orderDelivery.url"-->
                <!--                                        target="_blank">{{('Order_Delivery.Tickets' | trans)}}</a>-->
                <!--                                    {{('Order_Delivery.Assigned_To_Guest_Manager' | trans)}}-->
                <!--                                </ng-container>-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'POST'">-->
                <!--                                    {{('Order_Delivery.Post_Delivery' | trans)}}-->
                <!--                                </ng-container>-->
                <!--                                <ng-container *ngIf="orderDelivery.method === 'PICKUP'">-->
                <!--                                    {{('Order_Delivery.Pickup' | trans)}}-->
                <!--                                </ng-container>-->
                <!--                            </div>-->
                <!--                            <div class="meta-info-time">{{orderDelivery.sendAt | date:'H:mm EE d MMM y'}}</div>-->
                <!--                        </div>-->
                <!--                    </div>-->
            </ng-container>
        </div>
    </ng-container>
</div>
<mat-paginator [length]="orderDeliveriesTableTotal" [pageSize]="orderDeliveriesTablePageSize"
               [pageSizeOptions]="[5, 10, 25, 50, 100, 250]" (page)="orderDeliveriesTableUpdate($event)">
</mat-paginator>


<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
