import {Injectable} from '@angular/core';
import {forkJoin, merge, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Phrase, Translation} from './shared/models/translation.interface';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CmTranslationService} from './core/services/cm-translation.service';
import {ApiDiscoveryService, Endpoint} from './api-discovery.service';
import {SportsApiService} from './sports-api.service';
import {Vat} from './shared/models/vat.class';
import {mapOrganisation, Organisation} from './shared/models/guest-manager/organisation.class';
import {PlatformService} from './shared/services/platform-service/platform.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private isInitialized = false;

    private app: {
        vat: Array<Vat>,
        organisation: Organisation,
    } = {
        vat: [],
        organisation: undefined
    };

    constructor(
        private http: HttpClient,
        private platformService: PlatformService,
        private translationService: CmTranslationService,
        private apiDiscoveryService: ApiDiscoveryService,
        private sportsApi: SportsApiService
    ) {
    }

    public initializeApp(): Promise<void> {
        return new Promise((resolve) => {
            if (this.isInitialized) {
                resolve();
            }

            forkJoin([
                this.initializeOrganisation(),
                this.initializeTranslations(),
                this.initializeApiDiscovery(),
            ]).subscribe({
                complete: () => {
                    this.isInitialized = true;
                    resolve();
                }
            });
        });
    }

    public config(option: string): any {
        return this.app[option];
    }

    private initializeOrganisation(): Observable<Organisation> {
        const technicalLink = this.platformService.getTechnicalLinkId();

        return this.http.get<Organisation>(`${environment.CM_API_URL}/seatedapi/v1.0/organisation/${technicalLink}?organisation[organisationSettings]=true&depth=2`, {
            withCredentials: true
        }).pipe(
            tap((organisation: Organisation) => {
                this.app.organisation = mapOrganisation(organisation);
            })
        );
    }

    private initializeTranslations(): Observable<Translation[]> {
        return this.http.get<Translation[]>(environment.TRANSLATION_URL, {
            withCredentials: true
        }).pipe(
            tap((translations: Translation[]) => {
                const translationMap = translations.reduce((result: Map<string, Phrase[]>, translation: Translation) => {
                    return result.set(translation.ID.toLowerCase(), translation.Phrases);
                }, new Map<string, Phrase[]>());

                this.translationService.setTranslationMap(translationMap);

                (window as any).translations = translationMap;
            }),
            catchError(() => {
                console.error('Error loading translations');
                return of(null);
            })
        );
    }

    private initializeApiDiscovery(): Observable<Array<Endpoint>> {
        return this.apiDiscoveryService.getEndpoints().pipe(
            tap(() => this.initializeAppData()),
            catchError(() => {
                console.error('[API DISCOVERY] Error during API Discovery');

                this.initializeAppData();

                return of(null);
            })
        );
    }

    private initializeAppData(): void {
        merge(
            this.sportsApi.getManyVat().pipe(tap((vats: Array<Vat>) => this.app.vat = vats))
        ).subscribe();
    }
}
