import {Pipe, PipeTransform} from '@angular/core';
import {ActivityLog} from '../../shared/models/activity-log.class';
import {activityLogBlacklist} from '../../../config/activity-log-blacklist';
import {pushIfUnique} from '../helpers/array-helper';

@Pipe({
    name: 'activityLogChangedValues'
})
export class ActivityLogChangedValuesPipe implements PipeTransform {

    transform(activityLog: ActivityLog): any {
        if (!activityLog?.previousValue && !activityLog?.value) {
            return {};
        }

        const keys = activityLog.previousValue ? Object.keys(activityLog.previousValue) : [];
        const valueKeys = activityLog.value ? Object.keys(activityLog.value) : [];

        valueKeys.forEach(key => {
            pushIfUnique(keys, key);
        });

        const result = {};

        keys.filter(key => !activityLogBlacklist.includes(key)).forEach(key => {
            const row = {
                previous: activityLog.previousValue ? activityLog.previousValue[key] : null,
                current: activityLog.value ? activityLog.value[key] : null,
                isChanged: false
            };

            row.isChanged = row.previous !== row.current;

            if (row.previous || row.current) {
                result[key] = row;
            }
        });

        return result;
    }

}
