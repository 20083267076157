import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-list-preview',
    templateUrl: './list-preview.component.html',
    styleUrls: ['./list-preview.component.css']
})
export class ListPreviewComponent implements OnChanges {

    @Input()
    items: string[];

    @Input()
    separator = ', ';

    @Input()
    previewLength = 3;

    @Input()
    empty = '-';

    list: string;
    preview: string;
    tooltip: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.list = this.items.join(this.separator);
        this.preview = [...this.items].splice(0, this.previewLength).join(this.separator);
    }

}
