import {Download} from './download.interface';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {ResourceService} from '../../resource-service/resource.service';
import {map} from 'rxjs/operators';
import {Resource} from '../../../models/event-resource.class';

export class ResourceReportDownload extends Download {
    type = 'file';
    filters: any[];
    total: number;
    batchSize: number;

    constructor(private resourceService: ResourceService) {
        super();
    }

    public getData(type: string, skip: number, total: number, filters) {
        let httpParams = filters ? filters : new HttpParams();
        httpParams = httpParams.set('skip', skip.toString());
        httpParams = httpParams.set('take', this.batchSize.toString());
        httpParams = httpParams.set('timezone', this.getTimezone());

        return this.resourceService.getResourcesAsHttpResponse(httpParams).pipe(
            map((httpResponse: HttpResponse<Resource[]>) => httpResponse.body.map((resource: Resource) => {
                return {
                    id: resource.uuid,
                    name: resource.name,
                    link: resource.link,
                    type: resource.type,
                    updated_at: resource.updatedAt
                };
            }))
        );
    }
}
