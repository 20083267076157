<!--<div class="form-input" [class.error]="!isValid">-->
<!--    <input class="form-control"-->
<!--           [type]="type"-->
<!--           (change)="inputChange()"-->
<!--           [(ngModel)]="value"-->
<!--           [autocomplete]="autocomplete"-->
<!--           [required]="required"-->
<!--           [disabled]="disabled"-->
<!--           [readOnly]="readOnly"-->
<!--           [attr.placeholder]="placeholder || null"-->
<!--           [class.has-error]="!isValid">-->
<!--    <label [class.has-error]="!isValid">{{label}}</label>-->
<!--    <ng-container *ngIf="!isValid && errorMessage">-->
<!--        <div class="form-input-validation">-->
<!--            <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--</div>-->

<cm-color-picker
    [attr.data-label]="label"
    [attr.data-color]="hex"
    [attr.data-alpha]="alpha"
    [attr.data-hide-alpha]="hideAlpha ? true : null"
    (change)="onColorChange($event.detail)"
></cm-color-picker>
