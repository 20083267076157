export class GuestManagerUser {
    constructor(
        public id: string             = null,
        public organisationId: string = null,
        public firstName: string      = null,
        public insertion: string      = null,
        public lastName: string       = null,
        public email: string          = null,
        public phoneNumber: string    = null) {
    }
}
