<ng-container *ngIf="venue">
    <div [class.card]="'NEW_EVENT'">
        <div class="row">
            <div class="col-12">

                <div class="card-content">

                    <div class="form-group">

                        <div class="row">
                            <div class="col-12">
                                <h3 class="f-w-400">
                                    {{('Venue_Form.Venue_Information' | trans)}}
                                </h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <div class="form-row">
                                    <app-input-translation
                                        formId="venueForm"
                                        [(value)]="venue.name"
                                        [type]="'text'"
                                        [required]="true"
                                        [translation]="venue.nameTranslation"
                                        [label]="('Venue_Form.Name' | trans)"></app-input-translation>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div class="form-row">
                                    <app-form-select
                                        formId="venueForm"
                                        [(value)]="venue.stageType"
                                        [options]="stageTypes"
                                        optionLabel="name"
                                        optionValue="id"
                                        [label]="('Venue_Form.Stage_Type' | trans)"
                                        [required]="true">
                                    </app-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row m-b-32">
                            <div class="col-12">
                                <app-file-picker
                                [label]="(('Venue_Detail.map' | trans))"
                                [file]="venue.resource"
                                [isRemoveAllowed]="true"
                                (fileSelected)="setResource($event)"
                                (fileRemoved)="setResource(null)">
                                </app-file-picker>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <h3 class="f-w-400">
                                    {{('Venue_Form.Address' | trans)}}
                                </h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-row col-12 col-sm-6">
                                <app-form-input
                                    formId="venueForm"
                                    [(value)]="venue.street"
                                    [required]="true"
                                    [label]="('Venue_Form.Street' | trans)"></app-form-input>
                            </div>
                            <div class="form-row col-12 col-sm-2">
                                <app-form-input
                                    formId="venueForm"
                                    [(value)]="venue.houseNumber"
                                    [required]="true"
                                    [label]="('Venue_Form.House_Number' | trans)"></app-form-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-row col-12 col-sm-6">
                                <app-form-input
                                    formId="venueForm"
                                    [(value)]="venue.city"
                                    [required]="true"
                                    [label]="('Venue_Form.City' | trans)"></app-form-input>
                            </div>
                            <div class="form-row col-12 col-sm-2">
                                <app-form-input
                                    formId="venueForm"
                                    [(value)]="venue.postalCode"
                                    [required]="true"
                                    [label]="('Venue_Form.Postal_Code' | trans)"></app-form-input>
                            </div>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="venue.id">
                                <div class="form-row col-12 col-sm-4">
                                    <app-form-input
                                        formId="venueForm"
                                        [(value)]="venue.province"
                                        [disabled]="true"
                                        [label]="('Venue_Form.Province' | trans)"></app-form-input>
                                </div>
                                <div class="form-row col-12 col-sm-4">
                                    <div class="form-input">

                                        <ng-container *ngIf="countries$ | async  as countries; else loading">
                                            <app-form-select
                                                formId="venueForm"
                                                [(value)]="venue.country"
                                                [options]="countries"
                                                optionLabel="name"
                                                optionValue="id"
                                                [label]="('Venue_Form.Country' | trans)"
                                                [required]="true"></app-form-select>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!venue.id">
                                <div class="form-row col-12 col-sm-6">
                                    <div class="form-input">

                                        <ng-container *ngIf="countries$ | async  as countries; else loading">
                                            <app-form-select
                                                formId="venueForm"
                                                [(value)]="venue.country"
                                                [options]="countries"
                                                optionLabel="name"
                                                optionValue="id"
                                                [label]="('Venue_Form.Country' | trans)"
                                                [required]="true"></app-form-select>
                                        </ng-container>

                                        <!--                                        <cm-select-->
                                        <!--                                            data-label="{{('Venue_Form.Country' | trans)}}"-->
                                        <!--                                            [value]="venue.country"-->
                                        <!--                                            (cm-option-clicked)="venue.country = $event.detail.value;">-->

                                        <!--                                            <ng-container *ngIf="countries$ | async  as countries; else loading">-->
                                        <!--                                                <ng-container *ngFor="let country of countries">-->
                                        <!--                                                    <cm-option slot="option"-->
                                        <!--                                                                      value="{{country.id}}"-->
                                        <!--                                                                      data-display="{{country.name}}">-->
                                        <!--                                                    </cm-option>-->
                                        <!--                                                </ng-container>-->
                                        <!--                                            </ng-container>-->

                                        <!--                                        </cm-select>-->
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="card-footer border-top">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-secondary" (click)="cancel()" *ngIf="context == 'NEW_EVENT'">
                                        {{('Venue_Form.Cancel' | trans)}}
                                    </button>
                                    <button class="btn btn-cta"
                                            (click)="saveVenue(venue)">{{('Venue_Form.Save' | trans)}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
