import {SeatMapTool} from './seat-map-tool.interface';
import {VenueSectionSeat} from '../venue-section-seat.class';
import {ServiceInjector} from '../../services/service-injector.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {Renderer2} from '@angular/core';
import {LocalReservationService} from '../../services/local-reservation.service';
import {ProductGroupType} from '../../enums/order/product-group-type';
import {OrderItemReservation} from '../reservation/order-item-reservation.class';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {isNullOrUndefined} from '../../common';

export class ReservationTool extends SeatMapTool {

    private orderManagementService: OrderManagementService;
    private localReservationService: LocalReservationService;
    private sidebarService: SidebarService;
    private notificationService: NotificationService;
    private renderer: Renderer2;

    private isSelecting = false;
    private selectRectStartPosition: any;
    private selectedSectionSeats = [];
    private selectRect: any;
    private transformedSeatPositions;
    private svg: any;

    constructor(options: any = {}) {
        super(options);

        this.orderManagementService = ServiceInjector.injector.get(OrderManagementService);
        this.localReservationService = ServiceInjector.injector.get(LocalReservationService);
        this.notificationService = ServiceInjector.injector.get(NotificationService);
        this.sidebarService = ServiceInjector.injector.get(SidebarService);

        this.renderer = options.renderer;
    }

    seatMouseDown(venueSectionSeat: VenueSectionSeat) {
        if (this.handleRemoveSeatFromOrder(venueSectionSeat)) {
            this.notificationService.showTranslatedSuccessNotification('ticket', 'removed', {
                amount: 1,
                isPlural: false
            });
            return;
        }

        if (!this.handleAddSeatToOrder(venueSectionSeat)) {
            return;
        }

        this.notificationService.showTranslatedSuccessNotification('ticket', 'added', {
            amount: 1,
            isPlural: false
        });
    }

    seatMouseMove(e: any, venueSectionSeat: VenueSectionSeat) {
        //
    }

    seatMouseEnter(e: any, venueSectionSeat: VenueSectionSeat) {
        //
    }

    seatMouseOut(e: any, venueSectionSeat: VenueSectionSeat): void {
        //
    }

    svgMouseDown(e: any) {
        if (this.isSelecting) {
            this.svgMouseUp(e);
        }

        if (!e.target) {
            return;
        }

        this.selectedSectionSeats = [];
        const matrixTransform = this.getMatrixTransform(e);
        this.isSelecting = true;

        const svg = e.target.closest('svg');

        const svgClientBoundingRect = svg.getBoundingClientRect();

        this.selectRectStartPosition = {
            x: matrixTransform.x - svgClientBoundingRect.x,
            y: matrixTransform.y - svgClientBoundingRect.y
        };

        this.selectRect = this.renderer.createElement('rect', 'svg');
        this.renderer.addClass(this.selectRect, 'select-rect');
        this.renderer.setAttribute(this.selectRect, 'x', `${matrixTransform.x}`);
        this.renderer.setAttribute(this.selectRect, 'y', `${matrixTransform.y}`);
        this.renderer.setAttribute(this.selectRect, 'vector-effect', 'non-scaling-stroke');
        this.renderer.appendChild(svg, this.selectRect);

        this.transformedSeatPositions = [];

        document.querySelectorAll('.section-path').forEach(path => {
            const pathPosition = path.getBoundingClientRect();
            this.transformedSeatPositions.push({
                x: pathPosition.left + (pathPosition.width / 2),
                y: pathPosition.top + (pathPosition.height / 2)
            });
        });
    }

    svgMouseMove(e: any): void {
        if (!e.target) {
            return;
        }

        this.svg = e.target.closest('svg');

        const matrixTransform = this.getMatrixTransform(e);
        const svgBoundingClientRect = this.svg.getBoundingClientRect();

        if (!this.isSelecting) {
            return;
        }

        const selectRectBoundingClientRect = this.selectRect.getBoundingClientRect();
        const selectedSeats: VenueSectionSeat[] = [];
        const size = {
            width: Math.abs(matrixTransform.x - this.selectRectStartPosition.x - svgBoundingClientRect.x),
            height: Math.abs(matrixTransform.y - this.selectRectStartPosition.y - svgBoundingClientRect.y)
        };

        this.renderer.setAttribute(this.selectRect, 'width', `${size.width}`);
        this.renderer.setAttribute(this.selectRect, 'height', `${size.height}`);

        if (matrixTransform.x - svgBoundingClientRect.left < this.selectRectStartPosition.x) {
            this.renderer.setAttribute(this.selectRect, 'x', matrixTransform.x);
        }

        if (matrixTransform.y - svgBoundingClientRect.top < this.selectRectStartPosition.y) {
            this.renderer.setAttribute(this.selectRect, 'y', matrixTransform.y);
        }

        this.transformedSeatPositions.forEach((seatPosition, i) => {
            if (
                seatPosition.x > selectRectBoundingClientRect.left &&
                seatPosition.x < selectRectBoundingClientRect.left + selectRectBoundingClientRect.width &&
                seatPosition.y > selectRectBoundingClientRect.top &&
                seatPosition.y < selectRectBoundingClientRect.top + selectRectBoundingClientRect.height
            ) {
                selectedSeats.push(this.options.venueSection.seats[i]);
            }
        });

        this.selectedSectionSeats = selectedSeats;
    }

    svgMouseUp(e: any): void {
        if (this.selectedSectionSeats.length) {
            this.dragSelectSeats(this.selectedSectionSeats);
        }
        this.selectedSectionSeats = [];

        this.removeSelectRectElement(e);
        this.isSelecting = false;
    }

    svgMouseLeave(e: any): void {
        if (!e.relatedTarget || e.relatedTarget.tagName.toLowerCase() === 'app-seat-map-tooltip') {
            return;
        }

        this.selectedSectionSeats = [];
        this.removeSelectRectElement(e);
        this.isSelecting = false;
    }

    private getLocalReservationService(): LocalReservationService {
        return this.localReservationService;
    }

    private getOrderManagementService(): OrderManagementService {
        return this.orderManagementService;
    }

    /**
     * @returns boolean Whether the seat was removed from the order
     */
    private handleRemoveSeatFromOrder(venueSectionSeat: VenueSectionSeat): boolean {
        let type: ProductGroupType;
        let typeId: string;
        if (this.options.subscriptionType) {
            type = ProductGroupType.SUBSCRIPTION;
            typeId = this.options.subscriptionType.id;
        } else if (this.options.event) {
            type = ProductGroupType.EVENT_TICKET;
            typeId = this.options.event.id;
        }

        if (!type) {
            return false;
        }

        const localReservationService = this.getLocalReservationService();
        const orderItemResult = localReservationService.findOrderItem(type, typeId, venueSectionSeat.id);
        if (!orderItemResult) {
            return false;
        }

        if (!orderItemResult.item.orderItemId) {
            localReservationService.deleteOrderItemFromReservation(
                ProductGroupType[orderItemResult.groupedOrderItem.type],
                orderItemResult.groupedOrderItem.id,
                orderItemResult.item.venueSectionSeatId
            );
            this.makeSeatAvailable(venueSectionSeat);
            return true;
        }

        const orderManagementService = this.getOrderManagementService();
        orderManagementService.deleteOrderItems([orderItemResult.item.orderItemId]).subscribe({
            next: () => {
                orderManagementService.refreshSelectedOrder();
                localReservationService.deleteOrderItemsWithIdsFromReservation(
                    [orderItemResult.item.orderItemId]
                );
                this.makeSeatAvailable(venueSectionSeat);
            }
        });
        return true;
    }

    private handleAddSeatToOrder(venueSectionSeat: VenueSectionSeat): boolean {
        if (venueSectionSeat.eventTickets[0]) {
            this.sidebarService.openSidebar(`orders/${venueSectionSeat.eventTickets[0].order.id}`);
            return false;
        }

        if (!venueSectionSeat.isAvailable()) {
            return false;
        }

        const localReservationService = this.getLocalReservationService();
        if (this.options.event) {
            const event = this.options.event;
            if (event.ticketTypes.length === 0) {
                return false;
            }

            const defaultTicketType = event.getTicketTypeWithPrice(this.options.venueSection.venueSectionGroupId);

            if (isNullOrUndefined(defaultTicketType)) {
                this.notificationService.showTranslatedError('Price_Not_Set');
                return false;
            }

            localReservationService.addOrderItem(
                ProductGroupType.EVENT_TICKET,
                event.name,
                event.id,
                new OrderItemReservation(
                    defaultTicketType.id,
                    defaultTicketType.name,
                    event.venueId,
                    this.options.venueSection.name,
                    this.options.venueSection.venueSectionGroupId,
                    venueSectionSeat.id,
                    venueSectionSeat.row,
                    venueSectionSeat.seat
                ),
                [event.eventCategoryId],
                event.venueId
            );
        } else if (this.options.subscriptionType) {
            const subscriptionType = this.options.subscriptionType;
            if (subscriptionType.subscriptionTypePrices.length === 0) {
                return false;
            }

            const defaultSubscriptionTypePrice = subscriptionType.getSubscriptionTypePriceWithPrice(this.options.venueSection.venueSectionGroupId);

            if (isNullOrUndefined(defaultSubscriptionTypePrice)) {
                this.notificationService.showTranslatedError('Price_Not_Set');
                return false;
            }

            localReservationService.addOrderItem(
                ProductGroupType.SUBSCRIPTION,
                subscriptionType.name,
                subscriptionType.id,
                new OrderItemReservation(
                    defaultSubscriptionTypePrice.id,
                    defaultSubscriptionTypePrice.name,
                    subscriptionType.venueId,
                    this.options.venueSection.name,
                    this.options.venueSection.venueSectionGroupId,
                    venueSectionSeat.id,
                    venueSectionSeat.row,
                    venueSectionSeat.seat
                ),
                subscriptionType.eventCategories.map(category => category.id),
                subscriptionType.venueId
            );
        }

        venueSectionSeat.available = 0;

        return true;
    }

    private makeSeatAvailable(venueSectionSeat: VenueSectionSeat): void {
        venueSectionSeat.available = 1;
        venueSectionSeat.status = 'AVAILABLE';
        venueSectionSeat.details.status = null;
    }

    private getMatrixTransform(e) {
        if (!e.target) {
            return;
        }

        const svg = e.target.closest('svg');

        const screenCTM = svg.getScreenCTM();
        const svgPoint = svg.createSVGPoint();

        svgPoint.x = e.clientX;
        svgPoint.y = e.clientY;

        return svgPoint.matrixTransform(screenCTM.inverse());
    }

    private dragSelectSeats(venueSectionSeats: Array<any>) {
        let updateState: 'UPDATED' | 'ADDED' | 'DELETED' = null;
        for (const venueSectionSeat of venueSectionSeats) {
            if (venueSectionSeat.capacity === 0) {
                continue;
            }

            if (this.handleRemoveSeatFromOrder(venueSectionSeat)) {
                if (updateState === 'ADDED') {
                    updateState = 'UPDATED';
                } else {
                    updateState = 'DELETED';
                }
                continue;
            }

            if (this.handleAddSeatToOrder(venueSectionSeat)) {
                if (updateState === 'DELETED') {
                    updateState = 'UPDATED';
                } else {
                    updateState = 'ADDED';
                }
            }
        }

        if (!updateState) {
            return;
        }

        this.notificationService.showTranslatedSuccessNotification('ticket', updateState.toLowerCase(), {
            amount: venueSectionSeats.length,
            isPlural: venueSectionSeats.length > 1
        });
    }

    private removeSelectRectElement(e) {
        if (!this.isSelecting || !this.svg) {
            return;
        }

        this.renderer.removeChild(this.svg, this.selectRect);
        this.isSelecting = false;
    }

    getSelectedSeats(): VenueSectionSeat[] {
        return this.selectedSectionSeats;
    }
}
