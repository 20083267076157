<ng-container *ngIf="activityLog$ | async as activityLogs">

    <ng-container *ngFor="let activityLog of activityLogs">

        <div class="row">
            <div class="col-2">
                <ng-container *ngIf="['EVENT_ADD', 'EVENT_UPDATE', 'EVENT_DELETE'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-calendar" aria-hidden="true"></span>
                </ng-container>
                <ng-container
                    *ngIf="['CUSTOMER_ADD', 'CUSTOMER_UPDATE', 'CUSTOMER_DELETE'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-person" aria-hidden="true"></span>
                </ng-container>
                <ng-container
                    *ngIf="['ORDER_ADD', 'ORDER_UPDATE', '', 'ORDER_DELETE', 'ORDER_ALLOCATE', 'ORDER_ALLOCATE_ROLLBACK'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-clean" aria-hidden="true"></span>
                </ng-container>
                <ng-container
                    *ngIf="['PAYMENT_START', 'PAYMENT_SUCCESS', 'PAYMENT_CANCELLED', 'PAYMENT_FAILED', 'PAYMENT_REFUNDED'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-money" aria-hidden="true"></span>
                </ng-container>
                <ng-container
                    *ngIf="['EVENT_TICKET_ADD', 'EVENT_TICKET_UPDATE', 'EVENT_TICKET_CANCELED', 'EVENT_TICKET_DELETE'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-ticket" aria-hidden="true"></span>
                </ng-container>
                <ng-container
                    *ngIf="['SUBSCRIPTION_ADD', 'SUBSCRIPTION_UPDATE', 'SUBSCRIPTION_CANCELED', 'SUBSCRIPTION_DELETE', 'SUBSCRIPTION_RENEWED'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-creditcard" aria-hidden="true"></span>
                </ng-container>
                <ng-container *ngIf="['RESELL_AVAILABLE', 'RESELL_SOLD'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-refresh" aria-hidden="true"></span>
                </ng-container>
                <ng-container *ngIf="['MOVE_TO_VENUE_SECTION_SEAT'].includes(activityLog.event)">
                    <span class="cm-icon cm-icon-move" aria-hidden="true"></span>
                </ng-container>
            </div>
            <div class="col-10 activity-log-title">
                {{ ('Activity_Log_Event.' + activityLog.event | trans) }}
            </div>
        </div>

        <div class="row activity-log-description">
            <div class="col-10 margin-fix">
                <ng-container *ngIf="activityLog.actor">
                    <div class="row">
                        <div class="col-1">
                            <span class="cm-icon cm-icon-time-open small"></span>
                        </div>
                        <div class="col-11">
                            <span>{{ activityLog.createdAt | date:'medium':'UTC':locale }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <span class="cm-icon small"
                                  [class.cm-icon-user-fill]="activityLog.actor.name"
                                  [class.cm-icon-bot]="!activityLog.actor.name" aria-hidden="true"></span>
                        </div>
                        <div class="col-11">
                            <span>{{activityLog.actor.name ? activityLog.actor.name : ('Activity_Log.Unknown' | trans)}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="activityLog.orderId && activityLog.event !== 'ORDER_DELETE'">
                    <div class="row" [routerLink]="['/orders', activityLog.orderId]">
                        <div class="col-1">
                            <span class="cm-icon cm-icon-clean small" aria-hidden="true"></span>
                        </div>
                        <div class="col-11">
                            <span
                                class="link">{{activityLog.details?.order?.batchId || ('General.Order' | trans)}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="activityLog.customerId">
                    <div class="row" [routerLink]="['/customers', activityLog.customerId]">
                        <div class="col-1">
                            <span class="cm-icon cm-icon-user-open small" aria-hidden="true"></span>
                        </div>
                        <div class="col-11">
                            <span
                                class="link">{{('General.Customer' | trans)}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </ng-container>

</ng-container>
