<div class="row">
    <ng-container *ngIf="categories">
        <ng-container *ngFor="let category of categories">
            <div class="row list-row m-b-16">
                <div class="col-12 list-row-item" [class.col-md-4]="columns > 1">
                    <ng-container *ngIf="categoryNameTranslate">
                        <span class="f-s-16">{{category[categoryLabel] | trans}}</span>
                    </ng-container>
                    <ng-container *ngIf="!categoryNameTranslate">
                        <span class="f-s-16">{{category[categoryLabel]}}</span>
                    </ng-container>
                </div>
                <div class="col-12" [class.col-md-8]="columns > 1">
                    <div class="row">
                        <ng-container *ngFor="let option of category[optionsIndex]">
                            <div class="col-12" (click)="updateSelection(option)"
                                 [class.col-sm-12]="columns === 1"
                                 [class.col-sm-6]="columns === 2"
                                 [class.col-sm-4]="columns === 3">
                                <div class="form-radio m-b-8">
                                    <input class="form-check-input" type="radio" [value]="option[optionValue]" [(ngModel)]="selectedOption">
                                    <ng-container *ngIf="optionLabelTranslate">
                                        <label class="form-check-label">{{option[optionLabel] | trans}}</label>
                                    </ng-container>
                                    <ng-container *ngIf="!optionLabelTranslate">
                                        <label class="form-check-label">{{option[optionLabel]}}</label>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
