<cm-dialog [attr.data-show-top-divider]="showBorders ? true : null"
           [attr.data-show-bottom-divider]="showBorders ? true : null"
           [attr.data-max-width]="width + 'px'" #cmDialog>
    <h5 slot="header" class="dialog-title">
        {{ title }}
    </h5>

    <div slot="body" class="form-row">
        <p [innerHTML]="body"></p>
        <form #form="ngForm">
            <ng-content></ng-content>
        </form>
    </div>

    <ng-container *ngIf="showFooter">
        <div slot="footer" class="footer">
            <ng-container *ngIf="scope !== 'ACKNOWLEDGE'">
                <button class="btn btn-secondary"
                        (click)="onCancel()">{{ 'Dialog.' + scope + '.Cancel' | trans }}
                </button>
            </ng-container>

            <ng-container *ngIf="scope !== 'CONFIRM'">
                <span></span>
                <button class="btn btn-cta"
                        [disabled]="saveDisabled ? true : null"
                        (click)="onSave()">{{ ('Dialog.' + scope + '.Confirm' | trans) }}
                </button>
            </ng-container>

            <ng-container *ngIf="scope === 'CONFIRM'">
                <cm-button
                    [attr.id]="confirmButtonId"
                    data-icon="check"
                    [attr.data-label]="'Dialog.CONFIRM.Confirm' | trans"
                    data-button-style="cta"
                    data-button-type="icon-text"
                    data-button-size="medium"
                    (click)="onSave()">
                </cm-button>
            </ng-container>
        </div>
    </ng-container>
</cm-dialog>
