<cm-dialog [id]="id">
    <h3 slot="header" class="dialog-title" [innerHTML]="(titleText.key | trans) | tags: titleText.tags">
    </h3>
    <div slot="body" class="col-12" [innerHTML]="(bodyText.key | trans) | tags: bodyText.tags">
    </div>
    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{(cancelText | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{(confirmText | trans)}}</button>
    </div>
</cm-dialog>
