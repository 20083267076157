import {PriceItem} from '../models/price-item.class';
import {TicketType} from '../models/ticket-type.class';
import {VenueSectionGroup} from '../models/venue-section-group.class';
import {SubscriptionTypePrice} from '../models/subscription-type-price.class';

export class PricePlanHelper {

    /**
     * Generates an array with price items based on given ticket type and venue section groups
     * @param venueSectionGroups The corresponding venue section groups
     * @param ticketType The corresponding ticket type if applicable
     * @param subscriptionTypePrice The corresponding subscriptionTypePrice if applicable
     * @returns [] An array containing price items sorted by venue section group
     *
     * example value: priceItems['c15d953e-fbf6-4a7a-8712-8f89de1464d9'] => PriceItem
     */
    generatePriceItems(venueSectionGroups: VenueSectionGroup[], ticketType: TicketType, subscriptionTypePrice: SubscriptionTypePrice = null) {
        const priceItems = [];
        for (const venueSectionGroup of venueSectionGroups) {
            let priceItem = ticketType ?
                ticketType.prices.find(price => price.venueSectionGroupId === venueSectionGroup.id) :
                subscriptionTypePrice.priceItems.find(item => item.venueSectionGroupId === venueSectionGroup.id);

            if (!priceItem) {
                priceItem = new PriceItem();

                if (ticketType) {
                    priceItem.ticketTypeId = ticketType.id;
                } else if (subscriptionTypePrice) {
                    priceItem.subscriptionTypePriceId = subscriptionTypePrice.id;
                }

                priceItem.venueSectionGroupId = venueSectionGroup.id;
            }

            priceItems[venueSectionGroup.id] = priceItem;
        }

        return priceItems;
    }

    triggerValueChange(subscriptionTypePrice: SubscriptionTypePrice, ticketType, currencyPriceItems, afasProductId, changeEvent) {
        if (subscriptionTypePrice) {
            subscriptionTypePrice.priceItems = [];
        } else if (ticketType) {
            ticketType.prices = [];
        }

        for (const priceItem of Object.values(currencyPriceItems)) {
            if (subscriptionTypePrice) {
                subscriptionTypePrice.priceItems.push(priceItem as PriceItem);
            } else if (ticketType) {
                ticketType.prices.push(priceItem);
            }
        }

        if (subscriptionTypePrice && !subscriptionTypePrice.externalData.find(item => item.key === 'AFAS_ITEM_ID')) {
            subscriptionTypePrice.externalData.push({key: 'AFAS_ITEM_ID', value: afasProductId});
        } else if (ticketType && !ticketType.externalData.find(item => item.key === 'AFAS_ITEM_ID')) {
            ticketType.externalData.push({key: 'AFAS_ITEM_ID', value: afasProductId});
        }

        changeEvent.emit();
    }
}
