export class Refund {
    constructor(public id: string         = null,
                public status: string     = null,
                public amount: string     = null,
                public refundAmount: string     = null,
                public refundedAt: string = null,
                public createdAt: string  = null,
                public updatedAt: string  = null,
    ) {
    }

}

export function mapRefund(item: any): Refund {
    return new Refund(
        item.id,
        item.status,
        item.amount,
        item.refundAmount,
        item.refundedAt,
        item.createdAt,
        item.updatedAt
    );
}
