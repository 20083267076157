import {Injectable, Injector} from '@angular/core';
import {CustomerTag} from '../../models/customer-tag.class';
import {StateManagementService} from '../util/state-management-service/state-management.service';

@Injectable({
                providedIn: 'root'
            })
export class CustomerTagManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new CustomerTag();

        this.initialize();
    }
}
