import {Injectable} from '@angular/core';
import {BehaviorSubject, finalize, forkJoin, of, tap} from 'rxjs';
import {mapOrder, Order} from '../../models/order.class';
import {OrderService} from './order.service';
import {NotificationService} from '../notification-service/notification.service';
import { HttpParams } from '@angular/common/http';
import {OrderItem} from '../../models/order-item.class';
import {PlatformService} from '../platform-service/platform.service';
import {Observable} from 'rxjs/internal/Observable';
import {Reservation} from '../../models/reservation.class';
import {ReservationService} from '../reservation/reservation.service';
import {ReservationDifferenceHelper} from '../../models/reservation/reservation-difference-helper';
import {LocalReservationService} from '../local-reservation.service';
import {concatMap} from 'rxjs/operators';
import {GroupedOrderItem} from '../../models/reservation/grouped-order-item.class';

@Injectable()
export class OrderManagementService {
    public orderSubject: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
    public order$: Observable<Order> = this.orderSubject.asObservable();
    public conflictsSubject: BehaviorSubject<GroupedOrderItem[]> = new BehaviorSubject<GroupedOrderItem[]>(null);
    public conflicts$: Observable<GroupedOrderItem[]> = this.conflictsSubject.asObservable();
    public order: Order;
    public moveOrderItem: OrderItem;
    public orphanSeatSubject: BehaviorSubject<string[]>;
    public dialogExpanded = false;
    public dialogExpandedSubject: BehaviorSubject<boolean>;
    public orphanSeats: string[] = [];
    requestCount = 0;

    constructor(private orderService: OrderService,
                private notificationService: NotificationService,
                private platformService: PlatformService,
                private reservationService: ReservationService
    ) {
        this.orphanSeatSubject = new BehaviorSubject(this.orphanSeats);
        this.dialogExpandedSubject = new BehaviorSubject(this.dialogExpanded);
    }

    expandDialog(open: boolean) {
        this.dialogExpanded = open;
        this.dialogExpandedSubject.next(this.dialogExpanded);
    }

    refreshSelectedOrder(): boolean {
        if (this.order?.id) {
            this.getOrder(this.order.id);
            return true;
        }
        return false;
    }

    getOrder(orderId: string, callback?, ignoreRedirect = false) {
        let params = new HttpParams()
            .set('depth', 4)
            .set('venueSectionSeat[sectionName]', true)
            .set('venueSectionSeat[sectionId]', true)
            .set('order[customer]', true)
            .set('order[groupedOrderItems]', true)
            .set('order[invoice]', true)
            .set('order[orderTags]', true)
            .set('order[orderItems]', true)
            .set('orderItem[personalisationRequired]', true)
            .set('eventTicket[event]', true)
            .set('eventTicket[ticketType]', true)
            .set('eventTicket[venueSectionGroupId]', true)
            .set('subscription[subscriptionTypeName]', true)
            .set('subscription[venueSectionGroupId]', true)
            .set('product[productTypeName]', true)
            .set('product[productTypePrice]', true);

        if (ignoreRedirect) {
            params = params.set('ignoreRedirect', 'true');
        }

        if (this.order && this.order.id !== orderId) {
            this.moveOrderItem = null;
        }

        this.orderService.getOrder(orderId, params).subscribe((order: Order) => {
            this.order = order;
            localStorage.setItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`, order.id);

            this.orderSubject.next(this.order);

            if (callback) {
                callback();
            }
        }, error => {
            if (error.error.code === 4042) {
                // No error
                localStorage.removeItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`);
            }
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    getOrderByOrderItemId(orderItemId: string, callback?) {
        this.orderService.getOrders(new HttpParams()
            .set('depth', '4')
            .set('venueSectionSeat[sectionName]', 'true')
            .set('venueSectionSeat[sectionId]', 'true')
            .set('order[customer]', 'true')
            .set('order[orderItemId]', orderItemId)
            .set('order[groupedOrderItems]', 'true')
            .set('order[orderTags]', 'true')
        ).subscribe(response => {
            this.order = mapOrder(response.body[0]);
            localStorage.setItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`, this.order.id);
            this.orderSubject.next(this.order);

            if (callback) {
                callback();
            }
        }, error => {
            if (error.error.code === 4042) {
                this.addOrder();
            }
        });
    }

    getOrderBySubscriptionId(subscriptionId: string, callback?) {
        this.orderService.getOrders(new HttpParams()
            .set('depth', '4')
            .set('venueSectionSeat[sectionName]', 'true')
            .set('venueSectionSeat[sectionId]', 'true')
            .set('order[customer]', 'true')
            .set('order[subscriptionId]', subscriptionId)
            .set('order[groupedOrderItems]', 'true')
            .set('order[orderTags]', 'true'))
            .subscribe(response => {
                    this.order = mapOrder(response.body[0]);
                    localStorage.setItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`, this.order.id);
                    this.orderSubject.next(this.order);

                    if (callback) {
                        callback();
                    }
                },
                error => {
                    if (error.error.code === 4042) {
                        this.addOrder();
                    }
                });
    }

    addOrder(callback?: () => any, updateOrderSubject = true): void {
        this.reservationService.postReservation().subscribe({
            next: (reservation: Reservation) => {
                const order = new Order();
                order.id = reservation.id;
                order.batchId = reservation.batchId;
                order.expireAt = reservation.expireAt;
                order.orderItems = [];

                this.order = order;

                localStorage.setItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`, this.order.id);

                if (updateOrderSubject) {
                    this.orderSubject.next(order);
                }
            },
            error: () => {
            },
            complete: () => {
                if (callback) {
                    callback();
                }
            }
        });
    }

    updateOrder(body: any, callback?) {
        this.moveOrderItem = null;
        const httpParams: HttpParams = new HttpParams()
            .set('depth', 4)
            .set('venueSectionSeat[sectionName]', true)
            .set('venueSectionSeat[sectionId]', true)
            .set('order[customer]', true)
            .set('order[groupedOrderItems]', true)
            .set('order[invoice]', true)
            .set('order[orderTags]', true)
            .set('order[orderItems]', true)
            .set('orderItem[personalisationRequired]', true)
            .set('eventTicket[event]', true)
            .set('eventTicket[ticketType]', true)
            .set('eventTicket[venueSectionGroupId]', true)
            .set('subscription[subscriptionTypeName]', true)
            .set('subscription[venueSectionGroupId]', true)
            .set('product[productTypeName]', true)
            .set('product[productTypePrice]', true);

        this.orderService.updateOrder(this.order.id, body, httpParams)
            .pipe(
                tap(order => {
                    this.order = order;
                    this.orderSubject.next(this.order);
                }),
                finalize(() => {
                    if (callback) {
                        callback();
                    }
                })
            ).subscribe();
    }

    deleteOrderSimple(): Observable<any> {
        return this.orderService.deleteOrder(this.order.id).pipe(
            tap(() => {
                localStorage.removeItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`);
                this.order = undefined;
            })
        );
    }

    deleteOrder(orderId: string, callback?) {
        this.orderService.deleteOrder(orderId).subscribe(response => {
            this.notificationService.showTranslatedNotification('success', 'order', 'deleted');
        }, (error) => {
            this.notificationService.showTranslatedError('40012');
        }, () => {
            this.unsetOrder();

            if (callback) {
                callback();
            }
        });
    }

    unsetOrder() {
        localStorage.removeItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`);
        this.order = undefined;
        this.orderSubject.next(this.order = undefined);
    }

    patchOrderItem(orderId, itemId, body: any, callback?) {
        this.orderService.patchOrderItem(orderId, itemId, body).subscribe(result => {
            if (callback) {
                callback();
            }
        });
    }

    addOrderItem(body: any, update = true, httpParams?: HttpParams, callback?) {
        if (!(this.order instanceof Order)) {
            this.addOrder(() => this.addOrderItem(body, update, httpParams, callback));
            return;
        }

        this.requestCount++;
        this.orderService.postOrderItem(this.order.id, body, httpParams).subscribe(
            () => {
                this.requestCount--;
                if (this.requestCount > 0 || !update) {
                    return;
                }

                this.notificationService.showTranslatedNotification('success', 'ticket_for_section', 'added');
            },
            error => {
                this.requestCount--;
                if (this.requestCount <= 0) {
                    this.getOrder(this.order.id, callback);
                }

                throw error;
            },
            () => {
                if (this.requestCount <= 0 && update) {
                    this.getOrder(this.order.id, callback);
                }

                if (callback) {
                    callback();
                }
            }
        );
    }

    addOrderItemsSimple(body: any) {
        if (!(this.order instanceof Order)) {
            this.addOrder(() => this.addOrderItemsSimple(body), false);
            return;
        }

        return this.orderService.postOrderItems(this.order.id, body, new HttpParams().set('collectConflicts', 'true'));
    }

    setConflicts(conflicts: GroupedOrderItem[]) {
        this.conflictsSubject.next(conflicts);
    }

    addOrderItems(body: any, update = true, callback?) {
        if (!(this.order instanceof Order)) {
            this.addOrder(() => this.addOrderItems(body, update, callback));
            return;
        }

        const eventTickets = body.filter(item => item.hasOwnProperty('ticket'));

        if (this.organisationAllowedToUseCheckoutApi() && eventTickets.length) {
            this.reservationService.postEventTickets(this.order.id, eventTickets).subscribe({
                next: (response: any) => {
                    this.notificationService.showTranslatedNotification('success', 'ticket', 'added', {
                        amount: response.amount,
                        isPlural: (response.amount !== 1)
                    });
                },
                error: undefined,
                complete: () => {
                    this.getOrder(this.order.id, callback);
                }
            });

            return;
        }

        this.requestCount++;
        this.orderService
            .postOrderItems(this.order.id, body)
            .subscribe((responseItems: { id: string, orderId: string }[]) => {
                    this.requestCount--;
                    if (this.requestCount <= 0 && update && responseItems?.length > 0) {
                        this.notificationService.showTranslatedNotification('success', 'ticket', 'added', {
                            amount: responseItems.length,
                            isPlural: (responseItems.length !== 1)
                        });
                    }
                },
                error => {
                    this.requestCount--;
                    if (this.requestCount <= 0) {
                        this.getOrder(this.order.id, callback);
                    }

                    throw error;
                },
                () => {
                    if (this.requestCount <= 0 && update) {
                        this.getOrder(this.order.id, callback);
                    }

                    if (callback) {
                        callback();
                    }
                }
            );
    }

    processReservationDifferences(localReservationService: LocalReservationService): Observable<any> {
        const differences = localReservationService.getDifferences();
        if (!differences.isChanged) {
            return of(null);
        }

        const reservationDifferenceHelper = new ReservationDifferenceHelper();
        const updatedCustomerId = differences.customer?.id;
        const addedItems = reservationDifferenceHelper.getAddedItems(differences);
        const postOrderItemBody = localReservationService.collectAddOrderItemsRequestBody(addedItems);
        const updateBody = reservationDifferenceHelper.getUpdatesBody(differences);

        const addOrderItems$ = postOrderItemBody.length > 0 ?
            this.addOrderItemsSimple(postOrderItemBody) : of(null);
        const updateOrderItems$ = updateBody.length > 0 ?
            this.updateOrderItemsSimple(updateBody) : of(null);

        const updateCustomer$ = updatedCustomerId ?
            this.orderService.updateOrder(this.order.id, {customerId: updatedCustomerId}) : of(null);

        return updateCustomer$.pipe(
            concatMap(() => {
                return forkJoin([updateOrderItems$, addOrderItems$]);
            }),
            tap(() => {
                this.getOrder(this.order.id);
            })
        );
    }

    deleteOrderItems(orderItemIds: string[]): Observable<any> {
        return this.orderService.deleteOrderItems(this.order.id, orderItemIds);
    }

    deleteOrderItem(orderItemId: string, update = true, callback?) {
        this.moveOrderItem = null;
        this.requestCount++;

        this.orderService.deleteOrderItem(this.order.id, orderItemId).subscribe(
            () => {
                this.requestCount--;
                if (this.requestCount <= 0 && update) {
                    this.notificationService.showTranslatedNotification('success', 'ticket', 'deleted', {
                        isPlural: true
                    });
                }
            },
            error => {
                this.requestCount--;

                if (this.requestCount <= 0) {
                    this.getOrder(this.order.id, callback);
                }
            },
            () => {
                if (this.requestCount <= 0 && update) {
                    this.getOrder(this.order.id, callback);
                }

                if (callback) {
                    callback();
                }
            }
        );
    }

    updateOrderItem(orderItemId: string, body: any, update = true, callback?) {
        this.moveOrderItem = null;
        const httpParams = new HttpParams().set('venueSectionSeat[sectionName]', 'true');
        this.requestCount++;

        this.orderService.updateOrderItem(this.order.id, orderItemId, body, httpParams).subscribe(
            () => {
                this.requestCount--;
                if (this.requestCount <= 0 && update) {
                    this.notificationService.showTranslatedNotification('success', 'ticket', 'updated', {
                        amount: 1,
                        isPlural: (this.order.orderItemAmount !== 1)
                    });
                }
            },
            error => {
                this.requestCount--;
                if (this.requestCount <= 0) {
                    this.getOrder(this.order.id, callback);
                }

                throw error;
            },
            () => {
                if (this.requestCount <= 0 && update) {
                    this.getOrder(this.order.id, callback);
                    return;
                }

                if (this.requestCount <= 0) {
                    callback();
                }
            }
        );
    }

    updateOrderItemsSimple(body: any) {
        const httpParams = new HttpParams().set('venueSectionSeat[sectionName]', 'true');
        return this.orderService.updateOrderItems(this.order.id, body, httpParams);
    }

    updateOrderItems(body: any, update = true, callback?) {
        this.moveOrderItem = null;
        const httpParams = new HttpParams().set('venueSectionSeat[sectionName]', 'true');
        this.requestCount++;

        this.orderService.updateOrderItems(this.order.id, body, httpParams).subscribe(updatedOrderItems => {
            this.requestCount--;
            if (this.requestCount <= 0 && update) {
                this.notificationService.showTranslatedNotification('success', 'ticket', 'updated', {
                    amount: updatedOrderItems.length,
                    isPlural: (this.order.orderItemAmount !== 1)
                });
            }
        }, () => {
            this.requestCount--;
            if (this.requestCount <= 0) {
                this.getOrder(this.order.id, callback);
            }

            // throw error;
        }, () => {
            if (this.requestCount <= 0 && update) {
                this.getOrder(this.order.id, callback);
            } else if (callback) {
                callback();
            }
        });
    }

    private addOrderHttpParams(params: HttpParams): HttpParams {
        return params.set('order[groupedOrderItems]', 'true')
            .set('order[orderItems]', 'true')
            .set('depth', 4)
            .set('order[groupedOrderItems]', true)
            .set('order[invoice]', true)
            .set('order[orderTags]', true)
            .set('order[orderItems]', true)
            .set('orderItem[personalisationRequired]', true)
            .set('eventTicket[event]', true)
            .set('eventTicket[ticketType]', true)
            .set('subscription[subscriptionTypeName]', true)
            .set('product[productTypeName]', true)
            .set('product[productTypePrice]', true);
    }

    addOrderDiscountCampaign(orderDiscountCampaignId: string, callback?) {
        let params = new HttpParams()
            .set('venueSectionSeat[sectionName]', 'true')
            .set('venueSectionSeat[sectionId]', 'true')
            .set('order[customer]', 'true')
            .set('eventTicket[venueSectionGroupId]', 'true')
            .set('subscription[venueSectionGroupId]', 'true');
        params = this.addOrderHttpParams(params);

        this.orderService.postOrderDiscountCampaign(this.order.id, orderDiscountCampaignId, params).subscribe((order: Order) => {
            this.order = order;
            this.orderSubject.next(this.order);
        }, (error) => {
            throw error;
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    addOrderDiscount(orderDiscountCode: string, callback?) {
        let params = new HttpParams()
            .set('venueSectionSeat[sectionName]', 'true')
            .set('venueSectionSeat[sectionId]', 'true')
            .set('order[customer]', 'true')
            .set('eventTicket[venueSectionGroupId]', 'true')
            .set('subscription[venueSectionGroupId]', 'true');
        params = this.addOrderHttpParams(params);

        this.orderService.postOrderDiscount(this.order.id, orderDiscountCode, params).subscribe((order: Order) => {
            this.order = order;
            this.orderSubject.next(this.order);
        }, (error) => {
            // throw error;
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    deleteOrderDiscount(discountId, callback?) {
        let params = new HttpParams()
            .set('venueSectionSeat[sectionName]', 'true')
            .set('venueSectionSeat[sectionId]', 'true')
            .set('order[customer]', 'true')
            .set('eventTicket[venueSectionGroupId]', 'true')
            .set('subscription[venueSectionGroupId]', 'true');
        params = this.addOrderHttpParams(params);

        this.orderService.deleteOrderDiscount(this.order.id, discountId, params).subscribe((order: Order) => {
            this.order = order;
            this.orderSubject.next(this.order);
        }, (error) => {
            throw error;
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    private organisationAllowedToUseCheckoutApi(): boolean {
        return [
            '4021c277-1949-420a-a353-0aad5f59fee4'
        ].includes(this.platformService.getTechnicalLinkId());
    }
}
