export enum KonvaTypesEnum {
    GROUP = 'GROUP',
    RECT = 'RECT',
    STAGE = 'STAGE',
    GRIDLINE = 'GRIDLINE',
    BOUNDARYLINE = 'BOUNDARYLINE',
    LAYER = 'LAYER',
    BOCALINE = 'BOCALINE',
    TRANSFORMER = 'TRANSFORMER',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE'
}

