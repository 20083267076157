import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {mapSubscriptionTypePrice, SubscriptionTypePrice} from '../../models/subscription-type-price.class';

@Injectable()
export class SubscriptionTypePriceService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getSubscriptionTypePrices(subscriptionTypeId: string, params?: HttpParams): Observable<SubscriptionTypePrice[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<SubscriptionTypePrice[]>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}/price`, config)
            .pipe(
                map((items: SubscriptionTypePrice[]) => {
                    return items.map(item => {
                        return mapSubscriptionTypePrice(item);
                    });
                })
            );
    }

    postSubscriptionTypePrice(subscriptionTypeId: string, body: any, params?: HttpParams): Observable<SubscriptionTypePrice> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<SubscriptionTypePrice>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}/price`, body, config)
            .pipe(
                map((item) => {
                    return mapSubscriptionTypePrice(item);
                })
            );
    }

    patchSubscriptionTypePrice(subscriptionTypeId: string, subscriptionTypePrice: SubscriptionTypePrice, params?: HttpParams): Observable<SubscriptionTypePrice> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<SubscriptionTypePrice>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}/price/${subscriptionTypePrice.id}`, subscriptionTypePrice, config)
            .pipe(
                map((item) => {
                    return mapSubscriptionTypePrice(item);
                })
            );
    }

    deleteSubscriptionTypePrice(subscriptionTypeId: string, subscriptionTypePriceId: string): Observable<SubscriptionTypePrice> {
        return this.http.delete<SubscriptionTypePrice>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}/price/${subscriptionTypePriceId}`, this.config);
    }

}
