import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {mapOrderDistributionSetting, OrderDistributionSetting} from '../models/order-distribution-setting';

@Injectable({
    providedIn: 'root'
})
export class OrderDistributionSettingService {

    constructor(private http: HttpClient) {
    }

    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    private static getTypeParam(type: string): string {
        if (type === 'EMAIL_TEMPLATE_GROUP') {
            return 'emailtemplategroup';
        }

        if (type === 'MOBILE_TEMPLATE') {
            return 'mobiletemplate';
        }

        if (type === 'TICKET_TEMPLATE') {
            return 'tickettemplate';
        }
    }

    getOrderDistributionSettings(type: string, externalId: string): Observable<OrderDistributionSetting[]> {
        const typeParam = OrderDistributionSettingService.getTypeParam(type);

        return this.http.get<OrderDistributionSetting[]>(this.baseUrl + `/v1.0/orderdistribution/setting/${typeParam}/${externalId}`, this.config)
            .pipe(
                map(orderDistributionSettings => {
                    if (orderDistributionSettings) {
                        return orderDistributionSettings.map(orderDistributionSetting => mapOrderDistributionSetting(orderDistributionSetting));
                    }
                })
            );
    }

    saveOrderDistributionSetting(orderDistributionSetting: OrderDistributionSetting): Observable<OrderDistributionSetting> {
        const typeParam = OrderDistributionSettingService.getTypeParam(orderDistributionSetting.type);

        if (orderDistributionSetting.id) {
            return this.http.put<OrderDistributionSetting>(
                this.baseUrl + `/v1.0/orderdistribution/setting/${typeParam}/${orderDistributionSetting.externalId}/setting/${orderDistributionSetting.id}`,
                orderDistributionSetting, this.config
            );
        } else {
            return this.http.post<OrderDistributionSetting>(
                this.baseUrl + `/v1.0/orderdistribution/setting/${typeParam}/${orderDistributionSetting.externalId}/setting`,
                orderDistributionSetting, this.config
            );
        }
    }

    public deleteOrderDistributionSetting(orderDistributionSetting: OrderDistributionSetting): Observable<any> {
        const typeParam = OrderDistributionSettingService.getTypeParam(orderDistributionSetting.type);

        return this.http.delete<any>(
            this.baseUrl + `/v1.0/orderdistribution/setting/${typeParam}/${orderDistributionSetting.externalId}/setting/${orderDistributionSetting.id}`, this.config
        );
    }

    public deleteAllOrderDistributionSettingsForEmailTemplateGroup(emailTemplateGroupId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.baseUrl}/v1.0/orderdistribution/setting/emailtemplategroup/${emailTemplateGroupId}`, this.config
        );
    }
}
