import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Reservation} from '../../models/reservation.class';
import {PostReservationResponse} from './post-reservation-response.interface';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PostOrderItemResponse} from './post-order-item-response.interface';

@Injectable({
    providedIn: 'root'
})
export class ReservationService {

    private readonly baseUrl: string = environment.CM_API_URL;

    constructor(private http: HttpClient) {
    }

    public postReservation(): Observable<Reservation> {
        return this.http.post(`${this.baseUrl}/seatedapi/v1.0/checkout/reservation`, {}, {
            withCredentials: true
        }).pipe(
            map((response: PostReservationResponse) => {
                return new Reservation(response.id, response.batchId, response.expireAt);
            })
        );
    }

    public postEventTickets(orderId: string, body: any): Observable<PostOrderItemResponse> {
        // Backwards compatible with POST orderItem
        const items = body[0].ticket
            ? body.map(item => item.ticket)
            : body;

        return this.http.post(`${this.baseUrl}/seatedapi/v1.0/checkout/reservation/${orderId}/product`, items, {
            withCredentials: true
        }).pipe(
            map((response: PostOrderItemResponse) => {
                return { amount: response.amount };
            })
        );
    }
}
