export class OrderItemConflictResult {
    constructor(
        public productId: string,
        public eventCategoryIds: string[],
        public venueSectionSeatId: string) {
    }
}

export function mapOrderItemConflictResult(item: any): OrderItemConflictResult {
    return new OrderItemConflictResult(
        item.productId,
        item.eventCategoryIds,
        item.venueSectionSeatId
    );
}
