<cm-table #tableElement id="reporting-preview">
    <cm-table-head>
        <cm-table-row>
            <ng-container *ngFor="let column of table.columns">
                <cm-table-header data-not-resizable>
                    {{ column.name }}
                    <ng-container *ngIf="column.description">
                        <span [cm-tooltip]="column.description"
                              class="cm-icon cm-icon-information-open"
                              aria-hidden="true"
                        ></span>
                    </ng-container>
                </cm-table-header>
            </ng-container>
        </cm-table-row>
    </cm-table-head>
    <cm-table-body>
        <ng-container *ngFor="let row of table.rows">
            <cm-table-row>
                <ng-container *ngFor="let cell of row.cells">
                    <cm-table-data>{{ cell.value }}</cm-table-data>
                </ng-container>
            </cm-table-row>
        </ng-container>
    </cm-table-body>
</cm-table>
