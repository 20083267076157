import {VenueSection} from './venue-section.class';
import {TableRow} from './abstract/table-row';

export class VenueSectionGroup implements TableRow {
    public isSelected = false;

    constructor(public id: string = null,
                public name: string = null,
                public sections: VenueSection[] = null) {
    }
}

export function mapVenueSectionGroup(item: any): VenueSectionGroup {
    return new VenueSectionGroup(
        item.id,
        item.name,
        item.sections
    );
}
