import {Download} from './download.interface';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ExportService} from '../../export-service/export.service';

export class OrderReportDownload extends Download {
    type = 'order';
    filters: HttpParams;
    total: number;
    batchSize: number;
    formatting = {
        order_amount: {
            numFmt: '0.00'
        },
        order_discount_amount: {
            numFmt: '0.00'
        },
        payment_amount: {
            numFmt: '0.00'
        }
    };

    private extraFields: any;

    constructor(private exportService: ExportService) {
        super();
    }

    getData(type: string, skip: number, total: number, filters) {
        let httpParams = this.filters;

        if (this.extraFields?.includeCustomFields) {
            httpParams = httpParams.set('export[includeCustomFields]', 'true');
            this.isExcelDownload = false;
        }

        return this.exportService.getOrderReport(
            httpParams
                .set('skip', skip.toString())
                .set('take', this.batchSize.toString())
                .set('timezone', this.getTimezone())
                .set('order[orderTags]', 'true')
        ).pipe(
            map((response: HttpResponse<any[]>) => {
                const rows = response.body;
                rows.map(row => {
                    // Add each custom field as object property
                    if (row.custom_fields) {
                        for (const customFieldId of Object.keys(row.custom_fields)) {
                            row[customFieldId] = row.custom_fields[customFieldId];
                        }
                    }

                    delete row.custom_fields;
                });

                return rows;
            })
        );
    }

    setExtraFields(extraFields: any) {
        this.extraFields = extraFields;

        return this;
    }
}
