import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {mapSubscriptionType, SubscriptionType} from '../../models/subscription-type.class';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class SubscriptionTypeService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getSubscriptionTypes(params?: HttpParams): Observable<SubscriptionType[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<SubscriptionType[]>(this.baseUrl + `/v1.0/subscription/type`, config)
            .pipe(
                map((items) => {
                    return items.map(item => {
                        return mapSubscriptionType(item);
                    });
                })
            );
    }

    getSubscriptionTypesPaginated(params?: HttpParams): Observable<HttpResponse<SubscriptionType[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<SubscriptionType[]>(this.baseUrl + `/v1.0/subscription/type`, {
            ...config,
            observe: 'response'
        }).pipe(
            map(response => {
                const body = response.body.map(subscriptionType => {
                    return mapSubscriptionType(subscriptionType);
                });

                return response.clone({body});
            })
        );
    }

    getSubscriptionType(subscriptionTypeId: string, params?: HttpParams): Observable<SubscriptionType> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<SubscriptionType>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}`, config)
            .pipe(
                map((item) => {
                    return mapSubscriptionType(item);
                })
            );
    }

    postSubscriptionType(body: any, params?: HttpParams): Observable<SubscriptionType> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<SubscriptionType>(this.baseUrl + `/v1.0/subscription/type`, body, config)
            .pipe(
                map((item) => {
                    return mapSubscriptionType(item);
                })
            );
    }

    updateSubscriptionType(subscriptionTypeId: string, body: any, params?: HttpParams): Observable<SubscriptionType> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<SubscriptionType>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}`, body, config)
            .pipe(
                map((item) => {
                    return mapSubscriptionType(item);
                })
            );
    }

    deleteSubscriptionType(subscriptionTypeId: string): Observable<any> {
        return this.http.delete<any>(this.baseUrl + `/v1.0/subscription/type/${subscriptionTypeId}`, this.config);
    }
}
