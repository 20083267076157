import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivityLogService} from '../../services/activity-log-service/activity-log.service';
import { HttpParams } from '@angular/common/http';
import {ActivityLog} from '../../models/activity-log.class';
import {Observable, map} from 'rxjs';
import {LocaleService} from '../../../core/services/locale.service';

@Component({
    selector: 'app-activity-log-v2',
    templateUrl: './activity-log-v2.component.html',
    styleUrls: ['./activity-log-v2.component.css']
})
export class ActivityLogV2Component implements OnInit, OnChanges {
    @Input() orderIds: string[] = [];

    activityLog$: Observable<ActivityLog[]>;
    locale = 'EN';

    constructor(private activityLogService: ActivityLogService, private localeService: LocaleService) {
    }

    ngOnInit(): void {
        this.locale = this.localeService.getShortLanguage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const params = new HttpParams()
            .set('activityLog[details]', 'true')
            .set('activityLog[orderIds]', changes.orderIds.currentValue.join(','));

        this.activityLog$ = this.activityLogService.getActivityLogs(params).pipe(
            map(httpResponse => httpResponse.body)
        );
    }

}
