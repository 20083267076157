<div class="header">
    <div class="row">
        <div class="col-12">
            <h1>{{ ('Move_Request.Header' | trans) }}</h1>
        </div>
    </div>
</div>


<ng-container *ngIf="request$ | async as request">

    <div class="heading row m-t-32">
        <h4>{{ request.customer.getName() }} requests:</h4>
    </div>

    <div class="form-group">
        <ng-container *ngFor="let requestItemGroup of request._requestItemGroups">

            <div class="request-item">
                <div class="row m-b-8">
                    <div class="col-12">
                        <div class="title">{{ requestItemGroup.initialAmount }}x {{ requestItemGroup.name }}</div>
                        <div class="subtitle">{{ requestItemGroup.groupName }}</div>
                    </div>
                </div>
                <div class="form-row row">
                    <div class="col-2">
                        <cm-input type="number"
                                  [attr.value]="requestItemGroup.amount"
                                  [attr.max]="requestItemGroup.initialAmount"
                                  [attr.min]="0"
                        ></cm-input>
                    </div>
                    <div class="col-10">
                        <cm-select>
                            <cm-option slot="option" [attr.data-display]="requestItemGroup.venueSection.name"></cm-option>
                        </cm-select>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>

    <div class="row m-t-32">
        <div class="col-12 text-right">
            <cm-button data-button-style="primary" data-label="Deny"></cm-button>
            <cm-button data-button-style="cta" data-label="Accept" class="m-r-16"></cm-button>
        </div>
    </div>

</ng-container>
