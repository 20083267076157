import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {OrderItem} from '../../models/order-item.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import {OrderService} from '../order-service/order.service';
import {SidebarService} from '../sidebar/sidebar.service';
import {mapVenue, Venue} from '../../models/venue.class';
import {VenueService} from '../venue-service/venue.service';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {environment} from '../../../../environments/environment';
import {VenueSection} from '../../models/venue-section.class';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MoveOrderService {

    private movableStatusIds = ['ISSUED', 'RESERVED', 'RESELL_AVAILABLE'];

    private selectedOrderItemsSource: BehaviorSubject<OrderItem[]> = new BehaviorSubject<OrderItem[]>([]);
    selectedOrderItems$: Observable<OrderItem[]> = this.selectedOrderItemsSource.asObservable();

    private activeOrderItemsSource: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    activeOrderItems$: Observable<any[]> = this.activeOrderItemsSource.asObservable().pipe(
        tap(activeOrderItems => {
            let selectedOrderItems = this.selectedOrderItemsSource.getValue();
            selectedOrderItems = selectedOrderItems.map(orderItem => {
                orderItem.isSelected = false;
                orderItem.isDisabled = false;

                const orderItemGroupId = orderItem.ticket?.eventId || orderItem.subscription?.subscriptionTypeId;

                if (this.selectedOrderItemGroupId && orderItemGroupId !== this.selectedOrderItemGroupId) {
                    orderItem.isDisabled = true;
                }

                if (activeOrderItems.find(orderItemI => orderItemI.id === orderItem.id) && !orderItem.isDisabled) {
                    orderItem.isSelected = true;
                }

                return orderItem;
            });

            this.selectedOrderItemsSource.next([...selectedOrderItems]);
        })
    );

    private venueSource: BehaviorSubject<Venue> = new BehaviorSubject<Venue>(null);
    venue$: Observable<Venue> = this.venueSource.asObservable();

    private selectedVenueSection: VenueSection;
    private selectedOrderItemGroupId: string;

    private orderItemHttpParams = new HttpParams()
        .set('orderItem[batchId]', 'true')
        .set('eventTicket[eventName]', 'true')
        .set('eventTicket[ticketType]', 'true')
        .set('eventTicket[customer]', 'true')
        .set('subscription[subscriptionType]', 'true')
        .set('subscription[subscriptionTypePrice]', 'true')
        .set('subscription[customer]', 'true')
        .set('venueSectionSeat[sectionName]', 'true');

    private venueHttpParams = new HttpParams()
        .set('depth', '3')
        .set('venueSection[isSeated]', 'true')
        .set('venueSection[available]', 'true');

    private seatedApiBaseUrl = environment.CM_API_URL;
    private seatedApiDefaultConfig = {
        withCredentials: true,
        params: null
    };

    constructor(
        private orderService: OrderService,
        private venueService: VenueService,
        private sidebarService: SidebarService,
        private http: HttpClient) {
    }

    addOrderItem(orderId: string, orderItemId: string = null): void {
        this.sidebarService.openSidebar('move-order');

        if (this.isOrderItemSelected(orderItemId)) {
            return;
        }

        const selectedOrderItems = this.selectedOrderItemsSource.getValue();

        if (orderItemId) {
            this.orderService.getOrderItem(orderId, orderItemId, this.orderItemHttpParams).subscribe(orderItem => {
                this.selectedOrderItemsSource.next([...selectedOrderItems, orderItem]);
            });
        } else {
            this.orderService.getOrderItems(orderId, this.orderItemHttpParams).subscribe(orderItems => {
                orderItems = orderItems
                    .filter(this.isOrderItemStatusValid.bind(this))
                    .filter(orderItemI => !this.isOrderItemSelected(orderItemI.id));
                this.selectedOrderItemsSource.next([...selectedOrderItems, ...orderItems]);
            });
        }

        this.resetActiveOrderItems();

    }

    removeOrderItem(orderItemId: string): void {
        const orderItems = this.selectedOrderItemsSource.getValue();
        const orderItemIndex = orderItems.findIndex(orderItemI => orderItemI.id === orderItemId);

        if (orderItemIndex >= 0) {
            orderItems.splice(orderItemIndex, 1);
        }

        if (!orderItems.length) {
            this.sidebarService.closeSidebar();
        }

        this.selectedOrderItemsSource.next([...orderItems]);

        this.resetActiveOrderItems();
    }

    toggleActiveOrderItem(orderItemId: string): void {
        this.isOrderItemActive(orderItemId) ? this.removeActiveOrderItem(orderItemId) : this.addActiveOrderItem(orderItemId);
    }

    getBestSeatAvailable(orderItems: any): Observable<any> {
        const body = this.mapBestSeatAvailablePostBody(orderItems);

        const config = this.seatedApiDefaultConfig;
        return this.http.post<VenueSectionSeat[]>(`${this.seatedApiBaseUrl}/seatedapi/v1.0/venue/section/seat/bestseatavailable`, body, config).pipe(
            map(venueSectionSeats => {
                const isSeated = this.selectedVenueSection.isSeated;

                if (isSeated) {
                    return this.mapOrderItemsToBestSeatAvailable(orderItems, venueSectionSeats);
                } else {
                    return this.mapOrderItemsToGeneralAdmission(orderItems, venueSectionSeats);
                }
            })
        );

    }

    setVenueSection(venueSection: VenueSection): void {
        this.selectedVenueSection = venueSection;
    }

    moveOrderItems(orderItems: any[]): Observable<any> {
        const config = this.seatedApiDefaultConfig;
        return this.http.patch(`${this.seatedApiBaseUrl}/seatedapi/v1.0/order/item/move`, orderItems, config);
    }

    getActiveOrderItemEventsAndSubscriptionTypes(): any {
        const orderItems = this.activeOrderItemsSource.getValue();
        const eventIds = [];
        const subscriptionTypeIds = [];

        orderItems.forEach(orderItem => {
            if (orderItem.ticket) {
                if (eventIds.findIndex(eventId => eventId === orderItem.ticket.eventId) === -1) {
                    eventIds.push(orderItem.ticket.eventId);
                }
            }

            if (orderItem.subscription) {
                subscriptionTypeIds.push(orderItem.subscription.subscriptionTypeId);
            }
        });

        return {eventIds, subscriptionTypeIds};
    }

    assignSeatToFirstAvailableOrderItem(venueSectionSeat: VenueSectionSeat): void {
        const orderItems = this.activeOrderItemsSource.getValue();
        const orderItemsWithoutSeat = orderItems.filter(orderItemI => !orderItemI.toVenueSectionSeat);
        const isSeatAvailable = !orderItems.find(orderItemI => {
            return !!orderItemI.toVenueSectionSeat && orderItemI.toVenueSectionSeat.id === venueSectionSeat.id;
        });

        if (orderItemsWithoutSeat.length && isSeatAvailable) {
            orderItemsWithoutSeat[0].toVenueSection = this.selectedVenueSection;
            orderItemsWithoutSeat[0].toVenueSectionSeat = venueSectionSeat;

            this.activeOrderItemsSource.next([...orderItems]);
        }
    }

    resetOrderItemToVenueSectionSeat(orderItemId: string): void {
        const orderItems = this.activeOrderItemsSource.getValue();
        const orderItem = orderItems.find(orderItemI => orderItemI.id === orderItemId);

        if (orderItem) {
            orderItem.toVenueSection = null;
            orderItem.toVenueSectionSeat = null;

            this.activeOrderItemsSource.next([...orderItems]);
        }
    }

    reset(includeSelected = false, closeSidebar = false): void {
        if (includeSelected) {
            this.selectedOrderItemsSource.next([]);
        } else {
            const selectedOrderItems = this.selectedOrderItemsSource.getValue();
            const selectedOrderItemsWithSeatsRemoved = selectedOrderItems.map(orderItem => {
                return {...orderItem, toVenueSection: null, toVenueSectionSeat: null};
            });
            // @ts-ignore
            this.selectedOrderItemsSource.next([...selectedOrderItemsWithSeatsRemoved]);
        }

        if (closeSidebar) {
            const orderItems = this.activeOrderItemsSource.getValue();
            const orderId = orderItems[orderItems.length - 1].orderId;
            this.sidebarService.closeSidebar(`orders/${orderId}`);
        }

        this.selectedOrderItemGroupId = null;

        this.activeOrderItemsSource.next([]);
        this.venueSource.next(null);
        this.selectedVenueSection = null;
    }

    getActiveOrderItemsGroupedByOrder(): any[] {
        const orderItems = this.activeOrderItemsSource.getValue();
        return orderItems.reduce((r, a) => {
            r[a.orderId] = r[a.orderId] || [];
            r[a.orderId].push(a);
            return r;
        }, Object.create(null));
    }

    selectAllOrderItemsInOrderItemGroup(): void {
        const orderItems = this.selectedOrderItemsSource.getValue();

        const orderItemsInGroup = orderItems.filter(orderItem => {
            const orderItemGroupId = orderItem.ticket?.eventId || orderItem.subscription?.subscriptionTypeId;
            return orderItemGroupId === this.selectedOrderItemGroupId;
        });

        this.activeOrderItemsSource.next([...orderItemsInGroup]);
    }

    setOrderItemGroupId(orderItemGroupId: string): void {
        this.selectedOrderItemGroupId = orderItemGroupId;

        const orderItems = this.activeOrderItemsSource.getValue();
        this.activeOrderItemsSource.next([...orderItems]);
    }

    private addActiveOrderItem(orderItemId: string): void {
        const selectedOrderItems = this.selectedOrderItemsSource.getValue();
        const activeOrderItems = this.activeOrderItemsSource.getValue();
        const orderItem = selectedOrderItems.find(orderItemI => orderItemI.id === orderItemId);

        const currentVenueId = this.venueSource.getValue()?.id;
        const venueId = orderItem.ticket?.venueId || orderItem.subscription?.subscriptionType.venueId;
        const orderItemGroupId = orderItem.ticket?.eventId || orderItem.subscription?.subscriptionTypeId;

        if (!this.selectedOrderItemGroupId) {
            this.setOrderItemGroupId(orderItemGroupId);
        }

        if (!currentVenueId || currentVenueId !== venueId) {
            let venueHttpParams = new HttpParams()
                .set('depth', '3')
                .set('venueSection[isSeated]', 'true')
                .set('venueSection[available]', 'true');

            if (orderItem.ticket) {
                venueHttpParams = this.venueHttpParams.set('scope[eventId]', orderItem.ticket.eventId);
            }

            if (orderItem.subscription) {
                venueHttpParams = this.venueHttpParams.set('scope[subscriptionTypeId]', orderItem.subscription.subscriptionTypeId);
            }

            this.venueService.getVenue(venueId, venueHttpParams).subscribe(venue => {
                const mappedVenue = mapVenue(venue);
                this.venueSource.next(mappedVenue);
            });
        }

        this.activeOrderItemsSource.next([...activeOrderItems, orderItem]);
    }

    private removeActiveOrderItem(orderItemId: string): void {
        const orderItems = this.activeOrderItemsSource.getValue();
        const orderItemIndex = orderItems.findIndex(orderItemI => orderItemI.id === orderItemId);

        if (orderItemIndex >= 0) {
            orderItems.splice(orderItemIndex, 1);
        }

        this.activeOrderItemsSource.next([...orderItems]);

        const orderItemsAfterUpdate = this.activeOrderItemsSource.getValue();
        if (!orderItemsAfterUpdate.length) {
            this.setOrderItemGroupId(null);
            this.venueSource.next(null);
        }
    }

    private isOrderItemSelected(orderItemId: string): boolean {
        const orderItems = this.selectedOrderItemsSource.getValue();
        return orderItems.findIndex(orderItemI => orderItemI.id === orderItemId) >= 0;
    }

    private isOrderItemActive(orderItemId: string): boolean {
        const orderItems = this.activeOrderItemsSource.getValue();
        return orderItems.findIndex(orderItemI => orderItemI.id === orderItemId) >= 0;
    }

    private resetActiveOrderItems(): void {
        this.activeOrderItemsSource.next([]);
    }

    private mapBestSeatAvailablePostBody(orderItems: any): any {
        return {
            venueSectionIds: [this.selectedVenueSection.id],
            orderItems: orderItems.map(orderItem => {
                if (orderItem.ticket) {
                    return {
                        ticket: {
                            eventId: orderItem.ticket.eventId,
                            ticketTypeId: orderItem.ticket.ticketTypeId,
                        }
                    };
                } else if (orderItem.subscription) {
                    return {
                        subscription: {
                            subscriptionTypeId: orderItem.subscription.subscriptionTypeId,
                            subscriptionTypePriceId: orderItem.subscription.subscriptionTypePriceId,
                        }
                    };
                }

            })
        };
    }

    private mapOrderItemsToBestSeatAvailable(orderItems: any, venueSectionSeats: VenueSectionSeat[]) {
        return orderItems.map((orderItem, i) => {
            return {
                eventTicketId: orderItem.ticket?.id,
                subscriptionId: orderItem.subscription?.id,
                venueSectionSeatId: venueSectionSeats[i].id
            };
        });
    }

    private mapOrderItemsToGeneralAdmission(orderItems: any, venueSectionSeats: VenueSectionSeat[]) {
        return orderItems.map(orderItem => {
            return {
                eventTicketId: orderItem.ticket?.id,
                subscriptionId: orderItem.subscription?.id,
                venueSectionSeatId: venueSectionSeats[0].id
            };
        });
    }

    private isOrderItemStatusValid(orderItem: any): boolean {
        const item = orderItem.ticket || orderItem.subscription;

        if (!item) {
            return false;
        }

        return this.movableStatusIds.includes(item.status);
    }
}
