import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {DiscountCampaign, mapDiscountCampaign} from '../../models/discount-campaign';

@Injectable({
    providedIn: 'root'
})
export class DiscountCampaignService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getDiscountCampaign(discountCampaignId: string, params?: HttpParams): Observable<DiscountCampaign> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<DiscountCampaign>(this.baseUrl + `/v1.0/discountcampaign/${discountCampaignId}`, config).pipe(
            map(item => {
                return mapDiscountCampaign(item);
            })
        );
    }

    getDiscountCampaigns(params?: HttpParams): Observable<DiscountCampaign[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<DiscountCampaign[]>(this.baseUrl + `/v1.0/discountcampaign`, config).pipe(
            map(discountCampaigns => {
                return discountCampaigns.map(item => mapDiscountCampaign(item));
            })
        );
    }

    getDiscountCampaignsPaginated(params?: HttpParams): Observable<HttpResponse<DiscountCampaign[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<DiscountCampaign[]>(this.baseUrl + `/v1.0/discountcampaign`, {
                ...config,
                observe: 'response'
            }
        ).pipe(
            map((response: HttpResponse<DiscountCampaign[]>) => {
                const body = response.body.map((item: DiscountCampaign) => {
                    return mapDiscountCampaign(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }

    postDiscountCampaign(body: any, params?: HttpParams): Observable<DiscountCampaign> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<DiscountCampaign>(this.baseUrl + `/v1.0/discountcampaign`, body, config)
            .pipe(
                map(item => {
                    return mapDiscountCampaign(item);
                })
            );
    }

    putDiscountCampaign(discountCampaignId: string, body: any, params?: HttpParams): Observable<DiscountCampaign> {
        const config = Object.assign({}, this.config);
        config.params = params;

        if (body.discounts) {
            delete body.discounts;
        }

        return this.http.put<DiscountCampaign>(this.baseUrl + `/v1.0/discountcampaign/${discountCampaignId}`, body, config)
            .pipe(
                map(item => {
                    return mapDiscountCampaign(item);
                })
            );
    }

    deleteDiscountCampaign(discountCampaignId: string, params?: HttpParams): Observable<any> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.delete<DiscountCampaign>(this.baseUrl + `/v1.0/discountcampaign/${discountCampaignId}`, config);
    }

}
