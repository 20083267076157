import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NotificationObserver} from './observers/notification-observer';
import {Notification} from '../../models/notification.class';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private notificationServiceUrl = new URL(`${environment.NOTIFICATION_SERVICE_URL}`);
    private eventSource: EventSource;
    private readonly observers: NotificationObserver[];

    constructor() {
        this.observers = [];
    }

    public subscribeTo(topics: string[], jwt: string): void {
        for (const topic of topics) {
            this.notificationServiceUrl.searchParams.append('topic', topic);
        }

        this.notificationServiceUrl.searchParams.append('authorization', jwt);

        this.eventSource = new EventSource(this.notificationServiceUrl.toString(), {withCredentials: true});
        this.eventSource.onmessage = e => {
            const message = JSON.parse(e.data);
            this.notify(new Notification(
                message.topic,
                '',
                message.content
            ));
        };
    }

    public registerObserver(observer: NotificationObserver) {
        this.observers.push(observer);
    }

    private notify(notification: Notification) {
        for (const observer of this.observers) {
            observer.next(notification);
        }
    }
}
