import {Component, DEFAULT_CURRENCY_CODE, Inject, Input} from '@angular/core';
import {getCurrencySymbol} from '@angular/common';
import {CostItem} from '../../models/cost-item/cost-item.class';

@Component({
    selector: 'app-cost-items',
    templateUrl: './cost-items.component.html',
    styleUrl: './cost-items.component.scss'
})
export class CostItemsComponent {
    @Input() costItems: CostItem[];

    public tooltipText = '';
    public text = '';

    constructor(@Inject(DEFAULT_CURRENCY_CODE) private currencyId: string) {
    }

    ngOnInit(): void {
        this.initializeTexts();
    }

    private initializeTexts(): void {
        this.text = '';
        this.tooltipText = '';

        if (!this.costItems) {
            return;
        }

        let totalFees = 0;
        this.costItems.forEach((costItem): void => {
            if (costItem.amount <= 0) {
                return;
            }

            totalFees += costItem.amount;
            this.tooltipText += this.getCostItemTooltipText(costItem.label, costItem.amount, this.currencyId);
        });

        if (totalFees === 0) {
            return;
        }

        this.text = `+ ${this.getCostItemText(totalFees, this.currencyId)}`;
    }

    private getCostItemTooltipText(label: string, amount: number, currencyId: string): string {
        if (!label) {
            label = '';
        }

        return `${getCurrencySymbol(currencyId, 'narrow')}${amount.toFixed(2)} ${label}\n`;
    }

    private getCostItemText(amount: number, currencyId: string): string {
        return `${getCurrencySymbol(currencyId, 'narrow')}${amount.toFixed(2)}`;
    }
}
