import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tags'
})
export class TagPipe implements PipeTransform {

    transform(source: string, tags: { key: string, value: any }[]) {
        if (!source || !tags) {
            return source;
        }

        tags.forEach(tag => {
            source = String(source);
            const key = String(tag.key);
            const value = tag.value;

            source = source.replace(`{${key}}`, value);
        });

        return source;
    }
}
