import {AfterViewInit, Component} from '@angular/core';
import {Shop} from '../../../shared/models/shop.class';
import {DialogBase, DialogService, IInput, IOutput} from '../../../shared/services/dialog-service/dialog.service';
import {ShopManagementService} from '../../../shared/services/shop-management-service/shop-management.service';
import {Observable} from 'rxjs/internal/Observable';
import {delay, tap} from 'rxjs/operators';

@Component({
    selector: 'app-order-list-download-dialog',
    templateUrl: './order-list-download-dialog.component.html',
    styleUrls: ['./order-list-download-dialog.component.css']
})
export class OrderListDownloadDialogComponent extends DialogBase<IInput, IOutput> implements AfterViewInit {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    personalisationLink: boolean;
    directCheckoutLink: boolean;
    directCheckoutLinkShopId: string;
    includeCustomFields: boolean;

    shops$: Observable<Shop[]>;

    constructor(dialogService: DialogService, private shopManagementService: ShopManagementService) {
        super(dialogService);
    }

    ngAfterViewInit(): void {
        this.shops$ = this.shopManagementService.list$.pipe(
            delay(0),
            tap(shops => {
                if (shops[0]) {
                    this.directCheckoutLinkShopId = shops[0].id;
                }
            })
        ) as Observable<Shop[]>;
    }

    setModal(data: IInput) {
        this.titleText = data.titleText;
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: {
                personalisationLink: this.personalisationLink,
                directCheckoutLinkShopId: this.directCheckoutLinkShopId,
                includeCustomFields: this.includeCustomFields
            }
        });
    }

    public cancel() {
        super.cancel();
    }

    onCustomFieldsChanged(enabled: boolean) {
        this.includeCustomFields = enabled;
    }

    onPersonalisationLinkChanged(enabled: boolean) {
        this.personalisationLink = enabled;

        if (!enabled) {
            this.personalisationLink = null;
        }
    }

    onDirectCheckoutLinkChanged(enabled: boolean) {
        this.directCheckoutLink = enabled;

        if (!enabled) {
            this.directCheckoutLinkShopId = null;
        }
    }
}
