import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormManagementService {
    forms = [];

    constructor() {
    }

    subscribe(formId: string, component: FormField): void {
        this.forms.push({formId: formId, component: component});
    }

    unsubscribe(formId: string, component: FormField): void {
        const index = this.forms.findIndex(formElement => {
            return formElement?.formId === formId &&
                formElement?.component?.label === component?.label;
        });

        if (index === -1) {
            return;
        }
        this.forms.splice(index, 1);
    }

    validate(formId: string, formFieldIds?: string[], filledOutOnly = false): boolean {
        let formFields: any[];

        if (formFieldIds) {
            formFields = this.forms.filter(form => form.formId === formId && formFieldIds.includes(form.component.id));
        } else {
            formFields = this.forms.filter(form => form.formId === formId);
        }

        if (filledOutOnly) {
            formFields.filter(formField => !!formField.value);
        }

        for (const formField of formFields) {
            if (!formField.component.validate()) {
                return false;
            }
        }

        return true;
    }

    error(formId: string, formFieldIds?: string[]) {
        let formFields: any[];

        if (formFieldIds) {
            formFields = this.forms.filter(form => form.formId === formId && formFieldIds.includes(form.component.id));
        } else {
            formFields = this.forms.filter(form => form.formId === formId);
        }

        for (const formField of formFields) {
            formField.component.isValid = false;
        }

    }
}

export interface FormField {
    id: string;
    formId?: string;
    label?: string;

    validate(): boolean;
}
