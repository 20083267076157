import {Injectable} from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerOrder} from '../../models/customer-order';
import {SeatedApiService} from '../util/seated-api/seated-api.service';

@Injectable()
export class CustomerOrderService {
    constructor(private seatedApiService: SeatedApiService) {
    }

    getCustomerOrder(customerId: string, params): Observable<HttpResponse<CustomerOrder[]>> {
        return this.seatedApiService.getManyHttpResponse<CustomerOrder>(new CustomerOrder(), {customerId: customerId}, params)
            .pipe(
                map((response: HttpResponse<CustomerOrder[]>) => {
                    const body = response.body.map(item => {
                        return (new CustomerOrder()).mapModel(item);
                    });

                    return response.clone({body});
                })
            );
    }
}
