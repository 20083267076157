import {Inject, Injectable} from '@angular/core';
import {CM_TICKETING_LIBRARY_CONFIG} from '../core/cm-ticketing-library.config.token';
import {CmTicketingLibraryConfig} from '../core/cm-ticketing-library.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {EmailTemplateGroup} from '../models/email-template-group';
import {EmailTemplate} from '../models/email-template';
import {TicketTemplate} from '../models/ticket-template';
import {MobileTemplate} from '../models/mobile-template';
import {TicketTemplateComponentDatabase} from '../models/ticket-template-component-db';

@Injectable({
    providedIn: 'root'
})
export class OrderDistributionService {
    public accountGuid: string;
    private httpParams = {
        withCredentials: true,
        params: {},
        headers: {}
    };

    constructor(@Inject(CM_TICKETING_LIBRARY_CONFIG) private cmTicketingLibraryConfig: CmTicketingLibraryConfig, private http: HttpClient) {
        this.cmTicketingLibraryConfig = cmTicketingLibraryConfig;

        if (typeof (window as any).getCmSSO === 'function' && (window as any).getCmSSO()) {
            this.accountGuid = (window as any).getCmSSO().UserData.AccountGuid;
        } else {
            this.accountGuid = '92d855d3-cafe-4a1f-8418-227b43f1ba2b';
        }

        this.httpParams.headers = new HttpHeaders().set('X-CM-SSO-ACCOUNTGUID', this.accountGuid);
    }

    getTicketTemplateComponentTypes(): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatecomponenttypes/`, this.httpParams).pipe(share());
    }

    getTicketTemplateInformation(ticketTemplateUUID: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatecomponents/${ticketTemplateUUID}`, this.httpParams).pipe(share());
    }

    getTicketTemplateType(ticketTemplateType: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatetypes/${ticketTemplateType}`, this.httpParams).pipe(share());
    }

    getOrderExample(ticketTemplateUUID: string): string {
        return `${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_EXTERNAL_API_URL}/tickettemplates/${ticketTemplateUUID}`;
    }

    getTicketTemplateComponentsByType(ticketTemplateType: string, originTypeId: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatetypes/${ticketTemplateType}/tickettemplatescomponents/${originTypeId}`, this.httpParams).pipe(share());
    }

    getTicketTemplateComponentsConstrainsByType(ticketTemplateType: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplateconstrains/${ticketTemplateType}`, this.httpParams).pipe(share());
    }

    getTicketTemplatePresetsByType(ticketTemplateType: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatepresets/${ticketTemplateType}`, this.httpParams).pipe(share());
    }

    postTicketTemplate(ticketTemplateComponents: Array<TicketTemplateComponentDatabase>,
                       ticketTemplateName: string, ticketTemplateType: string, backgroundColor: string): Observable<any> {
        return this.http.post<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatecomponents/`, {
            ticket_template_type_id: ticketTemplateType,
            name: ticketTemplateName,
            info: ticketTemplateComponents,
            background_color: backgroundColor
        }, this.httpParams).pipe(share());
    }

    putTicketTemplate(ticketTemplateComponents: Array<TicketTemplateComponentDatabase>,
                      ticketTemplateName: string, ticketTemplateType: string, bgColor: string, uuid: string): Observable<any> {
        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplatecomponents/${uuid}`, {
            ticket_template_type_id: ticketTemplateType,
            name: ticketTemplateName,
            info: ticketTemplateComponents,
            background_color: bgColor
        }, this.httpParams).pipe(share());

    }

    getEmailTemplateGroups(isVisible = true): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups?is_visible=${isVisible}`, this.httpParams).pipe(share());
    }

    getEmailTemplates(emailTemplateGroupId: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroupId}/emailtemplates`,
            this.httpParams).pipe(share());
    }

    getEmailTemplateGroup(emailTemplateGroupId: string): Observable<any> {
        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroupId}`, this.httpParams).pipe(share());
    }

    getEmailTemplate(emailTemplateGroupId: string, emailTemplateId: string): Observable<any> {
        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroupId}/emailtemplates/${emailTemplateId}`, this.httpParams).pipe(share());
    }

    postEmailTemplateGroup(): Observable<any> {
        return this.http.post<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups`, {
            name: 'New Email Template Group'
        }, this.httpParams).pipe(share());
    }

    generateEmailTemplateGroup(): Observable<any> {
        return this.http.post<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/generate`, {
            name: 'New Email Template Group'
        }, this.httpParams).pipe(share());
    }

    deleteEmailTemplateGroup(emailTemplateGroup: EmailTemplateGroup): Observable<any> {
        emailTemplateGroup.is_visible = false;
        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroup.uuid}`, emailTemplateGroup, this.httpParams);
    }

    public restoreEmailTemplateGroup(emailTemplateGroup: EmailTemplateGroup): Observable<any> {
        emailTemplateGroup.is_visible = true;
        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroup.uuid}`, emailTemplateGroup, this.httpParams);
    }

    getEmailTemplateExample(emailTemplateGroupId: string, emailTemplateId: string, params?: any, timezone?: any): Observable<any> {
        if (params && timezone) {
            params['timezone_name'] = timezone.name;
            params['timezone_abbreviation'] = timezone.abbreviation;
            params['timezone_country_id'] = timezone.country_id;
        }

        const httpOptions = {
            headers: this.httpParams.headers,
            params,
            responseType: 'text' as const,
            withCredentials: true
        };

        return this.http.get(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroupId}/emailtemplates/${emailTemplateId}/example`, httpOptions);
    }

    editEmailTemplateGroup(emailTemplateGroup: EmailTemplateGroup): Observable<any> {
        return this.http.put<EmailTemplateGroup>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroup.uuid}`, emailTemplateGroup, this.httpParams);
    }

    editEmailTemplate(emailTemplateGroup: EmailTemplateGroup, emailTemplate: EmailTemplate): Observable<any> {
        return this.http.put<EmailTemplate>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/emailtemplategroups/${emailTemplateGroup.uuid}/emailtemplates/${emailTemplate.uuid}`, emailTemplate, this.httpParams);
    }

    getTicketTemplates(showVisible: boolean): Observable<any> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates?is_visible=${showVisible}`, this.httpParams).pipe(share());
    }

    getTicketTemplate(ticketTemplateId: string): Observable<any> {
        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates/${ticketTemplateId}`, this.httpParams).pipe(share());
    }

    saveTicketTemplate(ticketTemplate: TicketTemplate): Observable<any> {
        return this.http.post<TicketTemplate>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates`, ticketTemplate, this.httpParams);
    }

    editTicketTemplate(ticketTemplate: TicketTemplate): Observable<any> {
        return this.http.put<TicketTemplate>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates/${ticketTemplate.uuid}`, ticketTemplate, this.httpParams);
    }

    saveImageTicketTemplate(ticketTemplateId: string, content): Observable<any> {
        const formData = new FormData();
        formData.append('file', content, content.name);
        return this.http.post<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates/${ticketTemplateId}/content`, formData, this.httpParams);
    }

    deleteTicketTemplate(ticketTemplate: TicketTemplate): Observable<any> {
        ticketTemplate.is_visible = false;
        const ticketTemplatePut: any = ticketTemplate;
        delete ticketTemplatePut.example;
        delete ticketTemplatePut.background_color;
        delete ticketTemplatePut.url;

        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates/${ticketTemplate.uuid}/archiving`, ticketTemplatePut, this.httpParams);
    }

    enableTicketTemplate(ticketTemplate: TicketTemplate): Observable<any> {
        ticketTemplate.is_visible = true;
        const ticketTemplatePut: any = ticketTemplate;
        delete ticketTemplatePut.example;
        delete ticketTemplatePut.background_color;
        delete ticketTemplatePut.url;
        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/tickettemplates/${ticketTemplate.uuid}/archiving`, ticketTemplatePut, this.httpParams);
    }

    getMobileTemplates(): Observable<any> {
        return this.http.get<any[]>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates`, this.httpParams).pipe(share());
    }

    getMobileTemplate(mobileTemplateId: string): Observable<any> {
        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplateId}`, this.httpParams).pipe(share());
    }

    getMobileTemplateExample(mobileTemplateId: string): Observable<any> {
        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplateId}/example`, this.httpParams);
    }

    getMobileTemplateExampleEditor(mobileTemplate, bannerImageUrl?: string, backgroundImageUrl?: string, timezone?: any, currencyId?: string): Observable<any> {
        const config = Object.assign({}, this.httpParams);
        config.params = {
            ticket_type_name: mobileTemplate.name,
            is_showing_price: mobileTemplate.is_showing_price,
            is_showing_purchase_date: mobileTemplate.is_showing_purchase_date,
            is_showing_event_date: mobileTemplate.is_showing_event_date,
            is_showing_expiry_date: mobileTemplate.is_showing_expiry_date
        };
        if (bannerImageUrl) {
            config.params['example_resource_url_banner'] = bannerImageUrl;
        }
        if (backgroundImageUrl) {
            config.params['example_resource_url_background'] = backgroundImageUrl;
        }

        if (timezone) {
            config.params['timezone_name'] = timezone.name;
            config.params['timezone_abbreviation'] = timezone.abbreviation;
            config.params['timezone_country_id'] = timezone.country_id;
        }

        if (currencyId) {
            config.params['currency_id'] = currencyId;
        }

        return this.http.get<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplate.uuid}/example`, config);
    }

    deleteMobileTemplate(mobileTemplate: MobileTemplate): Observable<any> {
        mobileTemplate.is_visible = false;
        return this.http.put<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplate.uuid}`, mobileTemplate, this.httpParams);
    }

    saveMobileTemplate(mobileTemplate: MobileTemplate): Observable<any> {
        return this.http.post<MobileTemplate>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates`, mobileTemplate, this.httpParams);
    }

    editMobileTemplate(mobileTemplate: MobileTemplate): Observable<any> {
        return this.http.put<MobileTemplate>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplate.uuid}`, mobileTemplate, this.httpParams);
    }

    saveImageMobileTemplate(mobileTemplateId: string, content, contentType: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', content, content.name);
        return this.http.post<any>(`${this.cmTicketingLibraryConfig.ORDER_DISTRIBUTION_API_URL}/orderdistributionapi/v1.0/mobiletemplates/${mobileTemplateId}/mobiletemplateresourcetypes/${contentType}/content`, formData, this.httpParams);
    }
}
