import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import * as Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import {AnalyticsService} from '../../../services/analtics-service/analytics.service';
import {Chart} from 'highcharts';
import {AnalyticsDashboard} from '../../../models/analytics-dashboard.class';
import {AnalyticsDashboardItem} from '../../../models/analytics-dashboard-item.class';
import {AnalyticsDashboardEditorService} from '../../../services/analytics-dashboard-editor-service/analytics-dashboard-editor.service';
import {zip} from 'rxjs';

HighchartsExporting(Highcharts);

@Component({
               selector:    'app-analytics-chart',
               templateUrl: './analytics-chart.component.html',
               styleUrls:   ['./analytics-chart.component.scss']
           })
export class AnalyticsChartComponent implements OnInit, OnChanges, AfterContentInit {
    highcharts: typeof Highcharts           = Highcharts;
    @ViewChild('parent') parent;
    @Input() analyticsDashboard: AnalyticsDashboard;
    @Input() analyticsDashboardItem: AnalyticsDashboardItem;
    @Input() data                           = [];
    @Input() showDownload                   = false;
    @Input() showSettings                   = false;
    @Input() showConfigure                  = false;
    @Input() updateData;
    @Input() colors                         = [
        'rgba(0,127,255,1.0)',
        'rgba(102,16,242,1.0)',
        'rgba(4,228,244,1.0)',
        'rgba(61,220,151,1.0)',
        'rgba(255,170,0,1.0)',
        'rgba(27,252,213,1.0)',
        'rgba(253,202,64,1.0)',
        'rgba(255,73,92,1.0)',
        'rgba(6,88,165,1.0)',
        'rgba(68,21,157,1.0)',
        'rgb(14,140,118,1.0)',
        'rgba(43,144,102,1.0)',
        'rgba(159,110,11,1.0)',
        'rgba(12,148,158,1.0)',
        'rgba(157,133,49,1.0)',
        'rgba(159,55,67,1.0)',
    ];
    @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
    isLoading                               = false;
    options;
    height;
    chart: Chart;
    chartTypes                              = [
        {
            key:   'LINE',
            value: 'line'
        },
        {
            key:   'LINE_SMOOTH',
            value: 'spline'
        },
        {
            key:   'LINE_AREA',
            value: 'area'
        },
        {
            key:   'LINE_AREA_SMOOTH',
            value: 'areaspline'
        },
        {
            key:   'BAR_VERTICAL',
            value: 'column'
        },
        {
            key:   'BAR_HORIZONTAL',
            value: 'bar'
        },
        {
            key:   'PIE',
            value: 'pie'
        }
    ];

    constructor(private dialogService: DialogService,
                private analyticsService: AnalyticsService,
                private analyticsDashboardEditorService: AnalyticsDashboardEditorService) {
    }

    ngOnInit(): void {
        // this.updateOptions();
    }

    chartCallback(chart) {
        // Fix: bug unset chart after pdf download
        if (!this.chart) {
            this.chart = chart;
        }
    }

    ngAfterContentInit() {
        this.updateChartData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.chart && changes.updateData) {
            this.updateChartData();
        }

        this.update();
    }

    openSettings() {
        this.analyticsDashboardEditorService.setEditAnalyticsDashboardItem(this.analyticsDashboardItem);
    }

    deleteChart() {
        this.delete.emit(true);
    }

    updateOptions() {
        let floatingLegend = false;
        for (const analyticsDashboardItemSetting of this.analyticsDashboardItem.analyticsDashboardItemSettings) {
            if (analyticsDashboardItemSetting.type === 'PIE') {
                floatingLegend = true;
                break;
            }
        }

        this.options = {
            chart: {
                styledMode: false,
                zoomType:   'x'
            },
            title: {
                text: null,
                //     align:  'left',
                //     margin: 35,
                //     x:      0,
                //     style:  {
                //         'font-weight': 'bold'
                //     }
            },
            // subtitle:    {
            //     text:  this.chartSettings.subtitle,
            //     align: 'left'
            // },
            credits:     {
                enabled: false
            },
            colors:      this.colors,
            tooltip:     {
                backgroundColor: null,
                borderWidth:     0,
                shadow:          false,
                useHTML:         true,
                style:           {
                    padding: 0
                }
            },
            legend:      {
                enabled:       this.analyticsDashboardItem.isLegendEnabled,
                verticalAlign: 'bottom',
                align:         'left',
                width:         '100%',
                x:             -5,
                symbolRadius:  8,
                floating:      floatingLegend,
                layout:        floatingLegend ? 'vertical' : 'horizontal'
            },
            plotOptions: {
                series:     {
                    stacking: this.analyticsDashboardItem.isStacked ? 'normal' : undefined,
                    marker:   {
                        symbol:  'circle',
                        enabled: false,
                        states:  {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                },
                pie:        {
                    size:             '75%',
                    innerSize:        '75%',
                    allowPointSelect: true,
                    cursor:           'pointer',
                    dataLabels:       {
                        enabled: true,
                        format:  '{point.name}: {point.percentage:.1f} %'
                    },
                    showInLegend:     this.analyticsDashboardItem.isLegendEnabled,
                    center:           ['50%', '40%']
                },
                areaspline: {
                    fillOpacity: 0.25
                },
                column:     {
                    borderRadius: 1
                }
            },
            exporting:   {
                enabled: false
            },
            xAxis:       {
                title:     '',
                lineWidth: this.analyticsDashboardItem.isLabelHorizontalEnabled ? 1 : 0,
                tickWidth: 0,
                labels:    {
                    enabled: this.analyticsDashboardItem.isLabelHorizontalEnabled
                },
                type:      'datetime',
            },
            yAxis:       {
                title:  '',
                labels: {
                    enabled: this.analyticsDashboardItem.isLabelVerticalEnabled
                }
            },
            series:      this.data
        };

        // this.updateChart = Date.now().toString();
    }

    updateChartData() {
        if (this.analyticsDashboardItem.analyticsDashboardItemSettings.length === 0) {
            return;
        }

        this.isLoading = true;
        const requests = [];
        this.data      = [];

        for (const analyticsDashboardItemSetting of this.analyticsDashboardItem.analyticsDashboardItemSettings) {
            requests.push(
                this.analyticsService.post(
                    {
                        eventId:            analyticsDashboardItemSetting.eventId,
                        subscriptionTypeId: analyticsDashboardItemSetting.subscriptionTypeId,
                        range:              {
                            startAt:    analyticsDashboardItemSetting.startAt ? analyticsDashboardItemSetting.startAt : this.analyticsDashboard?.startAt,
                            endAt:      analyticsDashboardItemSetting.endAt ? analyticsDashboardItemSetting.endAt : this.analyticsDashboard?.endAt,
                            interval:   analyticsDashboardItemSetting.type === 'PIE' ? 'NONE' : null,
                            cumulative: analyticsDashboardItemSetting.isCumulative
                        },
                        filters:            {
                            venueSectionIds:          analyticsDashboardItemSetting.venueSectionIds,
                            ticketTypeIds:            analyticsDashboardItemSetting.ticketTypeIds,
                            subscriptionTypePriceIds: analyticsDashboardItemSetting.subscriptionTypePriceIds,
                            customerTagIds:           analyticsDashboardItemSetting.customerTagIds
                        },
                        groupBy:            analyticsDashboardItemSetting.groupBy
                    }
                )
            );
        }

        zip(...requests).subscribe((responseArray: any) => {
            let key = 0;
            for (const response of responseArray) {
                const analyticsDashboardItemSetting = this.analyticsDashboardItem.analyticsDashboardItemSettings[key++];

                if (analyticsDashboardItemSetting.type === 'PIE') {
                    const slices = [];
                    for (const dataset of response.data) {
                        slices.push({name: dataset.name ? dataset.name : 'Other/Unknown', y: dataset.data[0][1]});
                    }

                    this.data.push({
                                       type: this.getMappedChartType(analyticsDashboardItemSetting.type)?.value,
                                       data: slices
                                   });
                } else {
                    for (const dataset of response.data) {
                        this.data.push({
                                           name:  dataset.name ? dataset.name : 'Other/Unknown',
                                           type:  this.getMappedChartType(analyticsDashboardItemSetting.type)?.value,
                                           data:  dataset.data,
                                           stack: analyticsDashboardItemSetting.type
                                       });
                    }
                }
            }

            this.isLoading = false;
            this.update();
        });
    }

    update() {
        setTimeout(() => {
            if (this.parent && this.chart) {
                try {
                    const height = this.parent.nativeElement.clientHeight - 40;
                    this.chart.setSize(null, height);
                } catch (error) {
                    // Ignore undefined chart
                }
            }
        });
        this.updateOptions();
    }

    getMappedChartType(type: string) {
        return this.chartTypes.find(chartType => chartType.key === type);
    }

    download() {
        // @ts-ignore
        this.chart.exportChart({type: 'application/pdf'});
    }
}
