import {ModelBase} from './abstract/model-base';

export class CustomerOrder extends ModelBase {
    public customerId: string = null;
    public status: string = null;
    public orderId: string = null;
    public venueSections: string[] = [];
    public batchId = 0;
    public payedAt = 0;
    public expireAt = 0;
    public totalPrice: string;
    public amount: number;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/dashboard/customer/:customerId/order'
        });
    }

    mapModel(data: any): CustomerOrder {
        const model = new CustomerOrder();

        model.customerId = data.customerId;
        model.status = data.status;
        model.orderId = data.orderId;
        model.venueSections = data.venueSections;
        model.batchId = data.batchId;
        model.payedAt = data.payedAt;
        model.expireAt = data.expireAt;
        model.totalPrice = data.totalPrice;
        model.amount = data.amount;

        return model;
    }
}
