import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Customer} from '../../models/customer.class';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import {CustomerService} from '../customer-service/customer.service';
import {Router} from '@angular/router';
import {NotificationService} from '../notification-service/notification.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerManagementService {
    customer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
    customer$: Observable<Customer> = this.customer.asObservable();

    constructor(private customerService: CustomerService, private router: Router, private notificationService: NotificationService) {
    }

    setCustomer(customer: Customer): void {
        this.customer.next(customer);
    }

    saveCustomer(customer: Customer): void {
        if (customer.birthday) {
            customer.birthday = moment(customer.birthday, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD');
        }

        delete customer.guestManager;

        if (customer.id) {
            this.customerService.patchCustomer(customer.id, customer, new HttpParams()
                .set('customer[guestManager]', 'true')
                .set('customer[customerNotes]', 'true')
                .set('customer[customFieldAnswers]', 'true')
                .set('customFieldAnswer[customField]', 'true')
                .set('customer[acceptedConsents]', 'true')
                .set('customer[externalData]', 'true')
                .set('depth', '3')
            ).subscribe(customerData => {
                customerData.birthday = moment(customerData.birthday, 'DD-MM-YYYY').toISOString();
                this.setCustomer(customerData);
                this.notificationService.showTranslatedNotification('success', 'customer', 'updated');
            });
        } else {
            this.customerService.postCustomer(customer).subscribe(response => {
                this.router.navigate(['/customers', response.id]);
            });
        }
    }
}
