import {Component, Input, OnInit} from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {EventService} from '../../../services/event-service/event.service';
import {OrderDeliveryService} from '../../../services/order-delivery-service/order-delivery.service';
import {OrderDelivery} from '../../../models/order-delivery.class';

@Component({
    selector: 'app-order-delivery',
    templateUrl: './order-delivery.component.html',
    styleUrls: ['./order-delivery.component.scss']
})
export class OrderDeliveryComponent implements OnInit {
    @Input() orderId: string;

    // Table
    orderDeliveriesToggle = false;
    orderDeliveries$: Observable<OrderDelivery[]>;
    orderDeliveriesSubject: BehaviorSubject<any> = new BehaviorSubject([]);
    orderDeliveriesTableTotal = 0;
    orderDeliveriesTablePageSize = 5;
    orderDeliveriesTableLoading = true;

    constructor(private orderDeliveryService: OrderDeliveryService, private eventService: EventService) {
    }

    ngOnInit() {
        this.orderDeliveries$ = this.orderDeliveriesSubject.asObservable();
        this.orderDeliveriesTableUpdate();
    }

    orderDeliveriesTableUpdate(pageEvent?: PageEvent) {
        pageEvent = pageEvent ? pageEvent : {pageIndex: 0, pageSize: 5, length: 0};
        this.orderDeliveriesTablePageSize = pageEvent.pageSize;
        this.orderDeliveriesTableLoading = true;
        this.orderDeliveriesToggle = false;

        this.orderDeliveryService.getOrderDeliveries(this.orderId, new HttpParams()
            .set('skip', (pageEvent.pageIndex * pageEvent.pageSize).toString())
            .set('take', (pageEvent.pageSize).toString()))
            .subscribe((response: HttpResponse<OrderDelivery[]>) => {
                this.orderDeliveriesTableTotal = parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10);
                this.orderDeliveriesSubject.next(response.body);
                this.orderDeliveriesTableLoading = false;
            });
    }
}
