import {Injectable, Injector} from '@angular/core';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import {ProductType} from '../../models/product-type';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ProductTypeManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new ProductType();

        this.initialize(
            new HttpParams()
                .set('productType[productTypePrice]', 'true')
                .set('depth', '2')
        );
    }
}
