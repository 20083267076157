import {GroupedOrderItem} from './grouped-order-item.class';
import {OrderItemReservation} from './order-item-reservation.class';
import {LocalReservation} from './local-reservation.class';
import {Customer} from '../customer.class';
import {ProductGroupType} from '../../enums/order/product-group-type';

export interface ReservationDifferences {
    customer?: Customer | null;
    groupedOrderItems?: (Partial<GroupedOrderItem>)[];
    orderId?: string | null;
    isChanged?: boolean;
}

export class ReservationDifferenceHelper {
    public getAddedItems = (differences: ReservationDifferences): GroupedOrderItem[] => {
        if (!differences.groupedOrderItems) {
            return [];
        }

        const addedItems: any[] = [];
        for (const orderItem of differences.groupedOrderItems) {
            for (const item of orderItem.items) {
                if (!!item.orderItemId) {
                    continue;
                }

                // add the grouped order item if it doesn't exist yet in the array and the order item reservation to the items array of it
                const groupedOrderItem = addedItems.find(x => x.id === orderItem.id);
                if (!groupedOrderItem) {
                    addedItems.push({...orderItem, items: [item]});
                } else {
                    groupedOrderItem.items.push(item);
                }
            }
        }

        return addedItems;
    }

    public getUpdatesBody = (differences: ReservationDifferences) => {
        if (!differences.groupedOrderItems) {
            return [];
        }

        const updates: any = [];
        for (const orderItem of differences.groupedOrderItems) {
            for (const item of orderItem.items) {
                if (!item.orderItemId) {
                    continue;
                }

                let update: any = {id: item.orderItemId};
                if (item.typeId) {
                    switch (orderItem.type) {
                        case ProductGroupType.EVENT_TICKET:
                            update = {...update, ticket: {ticketTypeId: item.typeId}};
                            break;
                        case ProductGroupType.SUBSCRIPTION:
                            update = {...update, subscription: {subscriptionTypeId: item.typeId}};
                            break;
                        case ProductGroupType.PRODUCT:
                            update = {...update, product: {productTypePriceId: item.typeId}};
                            break;
                        default:
                            break;
                    }
                }

                if (item.price !== undefined) {
                    update = {...update, price: item.price};
                }

                if (item.venueSectionAccessId !== undefined) {
                    update = {...update, venueSectionAccess: [item.venueSectionAccessId]};
                }

                if (item.note !== undefined) {
                    update = {...update, note: item.note};
                }
                updates.push(update);
            }
        }

        return updates;
    }

    public getDifferences = (obj1: LocalReservation, obj2: LocalReservation): ReservationDifferences => {
        const differences: ReservationDifferences = {};
        differences.isChanged = false;

        if (!obj1.orderId) {
            return differences;
        }

        if (JSON.stringify(obj1.customer?.id) !== JSON.stringify(obj2.customer?.id)) {
            differences.customer = obj2.customer;
            differences.isChanged = true;
        }

        const groupedOrderItemsDifferences: (Partial<GroupedOrderItem>)[] = [];
        const obj1ItemsMap = new Map(obj1.groupedOrderItems.map(item => [item.id, item]));
        const obj2ItemsMap = new Map(obj2.groupedOrderItems.map(item => [item.id, item]));

        obj1.groupedOrderItems.forEach(item1 => {
            const item2 = obj2ItemsMap.get(item1.id);

            const itemDifferences: Partial<any> = {};

            const orderItemsDifferences: (Partial<OrderItemReservation>)[] = [];
            const item2Map = new Map(item2.items.map(subItem => [subItem.orderItemId, subItem]));

            item1.items.forEach(orderItem1 => {
                const orderItem2 = item2Map.get(orderItem1.orderItemId);
                const orderItemDifferences: Partial<OrderItemReservation> = {};

                if (orderItem2.orderItemId) {
                    // Updated order items
                    if (orderItem1.typeId !== orderItem2.typeId) {
                        orderItemDifferences.typeId = orderItem2.typeId;
                    }
                    orderItem2.venueSectionAccessId = (orderItem2.venueSectionAccessId || null);
                    if (orderItem1.venueSectionAccessId !== orderItem2.venueSectionAccessId) {
                        orderItemDifferences.venueSectionAccessId = orderItem2.venueSectionAccessId;
                    }
                    orderItem2.note = (orderItem2.note || null);
                    if (orderItem1.note !== orderItem2.note) {
                        orderItemDifferences.note = orderItem2.note;
                    }
                    if (orderItem1.price !== orderItem2.price) {
                        orderItemDifferences.price = orderItem2.price;
                    }
                }

                if (Object.keys(orderItemDifferences).length > 0) {
                    orderItemDifferences.orderItemId = orderItem1.orderItemId;
                    orderItemsDifferences.push(orderItemDifferences as OrderItemReservation);
                }
            });

            item2.items.forEach(orderItem2 => {
                if (!orderItem2.orderItemId) {
                    // Added order items
                    orderItemsDifferences.push(orderItem2);
                }
            });

            if (orderItemsDifferences.length > 0) {
                itemDifferences.items = orderItemsDifferences;
                itemDifferences.name = item2.name;
                itemDifferences.type = item2.type;
                itemDifferences.venueId = item2.venueId;
                itemDifferences.id = item2.id;
            }

            if (Object.keys(itemDifferences).length > 0) {
                groupedOrderItemsDifferences.push(itemDifferences as GroupedOrderItem);
            }
        });

        obj2.groupedOrderItems.forEach(item2 => {
            if (!obj1ItemsMap.has(item2.id)) {
                // Added order items
                groupedOrderItemsDifferences.push(item2);
            }
        });

        if (groupedOrderItemsDifferences.length > 0) {
            differences.groupedOrderItems = groupedOrderItemsDifferences;
            differences.isChanged = true;
        }

        return differences;
    }
}
