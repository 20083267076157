import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';

@Component({
    selector: 'app-form-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnDestroy, FormField {
    @Input() formId: string;
    @Input() id: string;
    @Input() label: string;
    @Input() hint: string;
    @Input() value: any;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() autocomplete: string;
    @Input() placeholder: string;
    @Input() match: string;
    @Input() errorMessage: string;
    @Input() type = 'text';
    @Input() minLength: number = null;
    @Input() maxLength: number = null;
    @Input() icon: string;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.validate();
    }

    validate(): boolean {
        const emailValidator = RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,25}$');
        this.errorMessage = '';

        if (this.required && !this.value) {
            this.errorMessage = 'General.Required';
            return false;
        } else if (this.type.toLowerCase() === 'email' && !emailValidator.test(this.value)) {
            this.errorMessage = 'General.Invalid_Email';
            return false;
        } else if (this.match && ((this.type.toLowerCase() === 'email' && this.match.toLowerCase() !== this.value.toLowerCase()) || this.match !== this.value)) {
            this.errorMessage = 'General.Not_Matching';
            return false;
        } else if (this.maxLength && this.value.length > this.maxLength) {
            this.errorMessage = 'Error.Max_Length';
            return false;
        } else if (this.minLength && this.value.length < this.minLength) {
            this.errorMessage = 'Error.Min_Length';
            return false;
        }

        return true;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

}
