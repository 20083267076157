import {Component, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {mapResource, Resource} from '../../../models/event-resource.class';
import {ResourceService} from '../../../services/resource-service/resource.service';
import {map, take} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-dialog-file-picker',
    templateUrl: './dialog-file-picker.component.html',
    styleUrls: ['./dialog-file-picker.component.css']
})
export class DialogFilePickerComponent extends DialogBase<IInput, IOutput> implements OnInit {
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    isLoading = true;

    type: string;

    files: Resource[] = [];
    selectedFile: Resource;

    uploadedFile: File;
    uploadedFileBlob: any;

    activeTab = 'UPLOADED_FILES';

    constructor(dialogService: DialogService,
                private resourceService: ResourceService) {
        super(dialogService);
    }

    ngOnInit() {
        this.isLoading = true;
    }

    setModal(data: IInput) {
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
        this.extraData = data.extraData;

        this.type = this.extraData.type;

        this.getFiles();
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: this.selectedFile as any
        });
    }

    public cancel() {
        super.cancel();
    }

    getFiles() {
        let httpParams = new HttpParams();

        if (this.type) {
            httpParams = httpParams.set('type', this.type);
        }

        this.resourceService.getResources(httpParams).pipe(
            map(resources => {
                this.files = resources.map(resource => {
                    return mapResource(resource);
                });
            }),
            take(1)
        ).subscribe(() => {
            this.isLoading = false;
        });
    }

    onTabClicked(tab: string) {
        this.activeTab = tab;
    }

    onFileClicked(file) {
        this.selectedFile = file;
    }

    onFileUploadSelected(uploadedFileEvent: any) {
        const uploadedFile = uploadedFileEvent.detail.length > 0 ? uploadedFileEvent.detail[0] : null;

        if (uploadedFile) {
            this.uploadedFile = uploadedFile;

            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                this.uploadedFileBlob = fileReader.result;
            };

            fileReader.readAsDataURL(uploadedFile);
        }
    }

    onFileUploadUnsetClicked() {
        this.uploadedFile = null;
        this.uploadedFileBlob = null;
    }

    confirmSelect() {
        this.confirm();
    }

    confirmUploadAndSelect() {
        this.resourceService.postResource(this.uploadedFile, this.uploadedFile.name).pipe(take(1)).subscribe(response => {
            this.selectedFile = mapResource(response);
            this.confirm();
        });
    }

    fileTrackBy(index, item) {
        return index;
    }

}
