import {VenueSectionSeat} from '../venue-section-seat.class';

export abstract class SeatMapTool {
    options: any = {};

    protected constructor(options: any = {}) {
        this.options = options;
    }

    abstract seatMouseDown(venueSectionSeat: VenueSectionSeat): void;

    abstract seatMouseMove(e: any, venueSectionSeat: VenueSectionSeat): void;

    abstract seatMouseEnter(e: any, venueSectionSeat: VenueSectionSeat): void;

    abstract seatMouseOut(e: any, venueSectionSeat: VenueSectionSeat): void;

    abstract svgMouseDown(e: any): void;

    abstract svgMouseMove(e: any): void;

    abstract svgMouseUp(e: any): void;

    abstract svgMouseLeave(e: any): void;

    abstract getSelectedSeats(): VenueSectionSeat[];

    setOptions(options: any): void {
        this.options = options;
    }
}
