import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {VenueService} from '../../services/venue-service/venue.service';
import {ActivatedRoute} from '@angular/router';
import {map, Observable} from 'rxjs';
import {VenueSection} from '../../models/venue-section.class';
import {MoveOrderService} from '../../services/move-order/move-order.service';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {Blockade} from '../../models/blockade.class';
import {SeatedApiService} from '../../services/util/seated-api/seated-api.service';
import {BlockadeService} from '../../services/blockade.service';
import {SeatMapOptions} from '../seatmap/seatmap.component';
import {SeatmapSeatInfo} from '../venue-section-master-detail/seatmap-seat-info';

@Component({
    selector: 'app-move-order-seatmap',
    templateUrl: './move-order-seatmap.component.html',
    styleUrls: ['./move-order-seatmap.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveOrderSeatmapComponent implements OnInit {

    venueSection$: Observable<VenueSection>;
    selectedVenueSectionSeatIds$: Observable<SeatmapSeatInfo[]> = this.moveOrderService.activeOrderItems$.pipe(
        map(orderItems => {
            const orderItemsWithToVenueSectionSeat = orderItems.filter(orderItem => orderItem.toVenueSectionSeat);
            return orderItemsWithToVenueSectionSeat.map(orderItem => {
                return new SeatmapSeatInfo(orderItem.toVenueSectionSeat.id);
            });
        })
    );

    viewBox: string;

    private blockades$: Observable<Blockade[]>;
    public blockades: Blockade[] = [];

    seatMapOptions: SeatMapOptions = {
        disableChosenSeats: true
    };

    constructor(
        private moveOrderService: MoveOrderService,
        private venueService: VenueService,
        private route: ActivatedRoute,
        private seatedApi: SeatedApiService,
        private blockadeService: BlockadeService) {
    }

    ngOnInit(): void {
        const venueId = this.route.snapshot.params.venueId;
        const venueSectionId = this.route.snapshot.params.venueSectionId;

        let httpParams = new HttpParams()
            .set('venueSection[seats]', 'true')
            .set('venueSectionSeat[available]', 'true')
            .set('venueSectionSeat[details]', 'true')
            .set('depth', '4');

        const scopeIds = this.moveOrderService.getActiveOrderItemEventsAndSubscriptionTypes();

        if (scopeIds.eventIds.length === 1) {
            httpParams = httpParams.set('scope[eventId]', scopeIds.eventIds[0]);
        }

        if (scopeIds.eventIds.length > 1) {
            httpParams = httpParams.set('scope[eventIds]', scopeIds.eventIds.join(','));
        }

        if (scopeIds.subscriptionTypeIds.length) {
            httpParams = httpParams.set('scope[subscriptionTypeId]', scopeIds.subscriptionTypeIds[0]);
        }

        this.venueSection$ = this.venueService.getVenueSection(venueId, venueSectionId, httpParams);

        this.getBlockades(scopeIds);
    }

    onVenueSectionSeatClick(venueSectionSeat: VenueSectionSeat): void {
        this.moveOrderService.assignSeatToFirstAvailableOrderItem(venueSectionSeat);
    }

    private getBlockades(scopeIds: any): void {
        let blockadeParams = new HttpParams()
            .set('blockade[venueSectionSeats]', true);

        if (scopeIds.eventIds.length === 1) {
            blockadeParams = blockadeParams.set('scope[eventId]', scopeIds.eventIds[0]);
        }

        if (scopeIds.subscriptionTypeIds.length === 1) {
            blockadeParams = blockadeParams.set('scope[subscriptionTypeId]', scopeIds.subscriptionTypeIds[0]);
        }

        this.blockades$ = this.seatedApi.getMany<Blockade>(new Blockade(), blockadeParams);
        this.blockades$.subscribe(blockades => this.blockades = blockades);

        this.blockadeService.reset();
    }

}
