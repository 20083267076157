import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {mapVenueSectionSeatSetting, VenueSectionSeatSetting} from '../../models/venue-section-seat-setting.class';

@Injectable({
    providedIn: 'root'
})
export class VenueSectionSeatSettingService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getVenueSectionSeatSettings(venueSectionSeatId: string, params?: HttpParams): Observable<VenueSectionSeatSetting[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<VenueSectionSeatSetting[]>(this.baseUrl + `/v1.0/venue/section/seat/${venueSectionSeatId}/setting`, config).pipe(
            map((items) => {
                return items.map(item => {
                    return mapVenueSectionSeatSetting(item);
                });
            })
        );
    }

    postVenueSectionSeatSetting(venueSectionSeatId: string, body: any, params?: HttpParams): Observable<VenueSectionSeatSetting> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<VenueSectionSeatSetting>(this.baseUrl + `/v1.0/venue/section/seat/${venueSectionSeatId}/setting`, body, config).pipe(
            map((item) => {
                return mapVenueSectionSeatSetting(item);
            })
        );
    }

    patchVenueSectionSeatSetting(venueSectionSeatId: string, venueSectionSeatSettingId: string, body: any, params?: HttpParams): Observable<VenueSectionSeatSetting> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<VenueSectionSeatSetting>(this.baseUrl + `/v1.0/venue/section/seat/${venueSectionSeatId}/setting/${venueSectionSeatSettingId}`, body, config).pipe(
            map((item) => {
                return mapVenueSectionSeatSetting(item);
            })
        );
    }

    deleteVenueSectionSeatSetting(venueSectionSeatId: string, venueSectionSeatSettingId: string): Observable<any> {
        return this.http.delete<any>(this.baseUrl + `/v1.0/venue/section/seat/${venueSectionSeatId}/setting/${venueSectionSeatSettingId}`, this.config);
    }
}
