import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NotificationService} from '../notification-service/notification.service';

@Injectable()
export class ExportService {
    private config = {
        withCredentials: true,
        params: {},
        responseType: 'json'
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient, private alertService: NotificationService) {
    }

    public getTicketReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/ticket`, {...config, observe: 'response'});
    }

    public getSubscriptionExport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/subscription`, {...config, observe: 'response'});
    }

    public getOrderReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/order`, {...config, observe: 'response'});
    }

    public getPaymentReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/payment`, {...config, observe: 'response'});
    }

    public getRequestReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/request`, {...config, observe: 'response'});
    }

    public getProductReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/product`, {...config, observe: 'response'});
    }

    public getFinanceReport(params?: HttpParams): Observable<HttpResponse<any>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        // @ts-ignore
        return this.http.get<any>(this.baseUrl + `/v2.0/export/finance`, {...config, observe: 'response'});
    }
}
