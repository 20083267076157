<div class="table-responsive">
    <table class="table table-narrow w-100">
        <thead>
        <tr>
            <th style="width: 50px"></th>
            <th style="width: 50px">{{ ('Order_Table.Section' | trans) }}</th>
            <th style="width: 40px">{{ ('Order_Table.Row' | trans) }}</th>
            <th style="width: 40px">{{ ('Order_Table.Seat' | trans) }}</th>
            <th>{{ ('Order_Table.Access_Group' | trans) }}</th>
            <th>{{ ('Order_Table.Type' | trans) }}</th>
            <th style="width: 110px">{{ ('Order_Table.Price' | trans) }}</th>
            <th style="width: 110px">{{ ('Order_Table.Actions' | trans) }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="order; else loading">
            <ng-container *ngFor="let orderItemGroup of order.groupedOrderItems;">
                <tr>
                    <td colspan="4">
                        <div>
                            <strong>{{ orderItemGroup.name }}</strong>
                        </div>
                        <div *ngIf="orderItemGroup">{{ orderItemGroup.event?.startAt | date:'mediumDate' }}</div>
                    </td>
                    <td colspan="1">
                        <div class="form-group">
                            <div class="form-input">
                                <select class="form-control"
                                        [disabled]="!editable"
                                        (change)="updateOrderVenueSectionAccess($event.target.value, orderItemGroup.items)"
                                        data-component="cm-select">
                                    <option disabled>{{ ('Order_Table.Access_Group' | trans) }}</option>
                                    <option value="">{{ ('Order_Table.No_Access_Group' | trans) }}</option>
                                    <ng-container *ngIf="getVenue(orderItemGroup.venueId) as venue;">
                                        <ng-container *ngFor="let section of venue.sections">
                                            <option disabled>{{ section.name }}</option>
                                            <option *ngFor="let venueSection of section.sections"
                                                    [value]="venueSection.id">
                                                {{ venueSection.name }}
                                            </option>
                                        </ng-container>
                                    </ng-container>
                                    <option *ngFor="let venueSection of venueSections" [value]="venueSection.id">
                                        {{ venueSection.name }}
                                    </option>
                                </select>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group" *ngIf="orderItemGroup.type === 'TICKET'">
                            <div class="form-input">
                                <select class="form-control" data-component="cm-select" [disabled]="!editable"
                                        (change)="updateOrderTicketType($event.target.value, orderItemGroup.items)">
                                    <option [value]="null">{{ ('General.Ticket_Type' | trans) }}</option>
                                    <option *ngFor="let ticketType of orderItemGroup.types" [value]="ticketType.id">
                                        {{ ticketType.name }}
                                    </option>
                                </select>
                                <label></label>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="orderItemGroup.type === 'SUBSCRIPTION'">
                            <div class="form-input">
                                <select class="form-control" data-component="cm-select" [disabled]="!editable"
                                        (change)="updateOrderSubscriptionTypePrice($event.target.value, orderItemGroup.items)">
                                    <option [value]="null">{{ ('Order_Table.Price_Type' | trans) }}</option>
                                    <ng-container *ngFor="let subscriptionType of orderItemGroup.types">
                                        <option [value]="subscriptionType.id">
                                            {{ subscriptionType.name }}
                                        </option>
                                    </ng-container>
                                </select>
                                <label></label>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="orderItemGroup.type === 'PRODUCT'">
                            <div class="form-input">
                                <select class="form-control" data-component="cm-select" [disabled]="!editable"
                                        (change)="updateOrderProductTypePrice($event.target.value, orderItemGroup.items)">
                                    <option [value]="null">{{ ('Order_Table.Price_Type' | trans) }}</option>
                                    <ng-container *ngFor="let productTypePrice of orderItemGroup.types">
                                        <option [value]="productTypePrice.id">
                                            {{ productTypePrice.name }}
                                        </option>
                                    </ng-container>
                                </select>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <div class="form-input">
                                <input placeholder="{{('Order_Table.Price' | trans)}}"
                                       [disabled]="!editable"
                                       (change)="updateOrderPrice($event.target.value, orderItemGroup.items)"
                                       class="form-control">
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td>
                    </td>
                </tr>
                <ng-container *ngFor="let orderItem of orderItemGroup.items; let i = index">
                    <ng-container *ngIf="i >= ((currentPage - 1) * itemsPerPage) && i < (currentPage * itemsPerPage)">
                        <tr [class.loading]="orderItem._loading"
                            [class.b-b-none]="orderItem.note || displayOrderNote === orderItem.id">
                            <ng-container *ngIf="orderItem.ticket">
                                <td [cm-tooltip]="orderItem.ticket.status">
                                    <span *ngIf="orderItem.ticket.status === 'CANCELED'"
                                          class="cm-icon cm-icon-error cl-error-color" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'BLOCKED'"
                                          class="cm-icon cm-icon-block cl-error-color" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'RESERVED'"
                                          class="cm-icon cm-icon-time-fill cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'ISSUED'"
                                          class="cm-icon cm-icon-verification cl-success-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'REFUNDED'"
                                          class="cm-icon cm-icon-refund cl-black-50" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'RESELL_AVAILABLE'"
                                          class="cm-icon cm-icon-duplicate cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'RESELL_RESERVED'"
                                          class="cm-icon cm-icon-duplicate cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.ticket.status === 'RESELL_SOLD'"
                                          class="cm-icon cm-icon-duplicate cl-success-color"
                                          aria-hidden="true"></span>
                                </td>
                                <td class="reset-line-height">
                                    <span class="parent-venue-section">
                                        {{ orderItem.ticket.seat.parentSectionName }}</span><br>
                                    {{ orderItem.ticket.seat.sectionName }}
                                </td>
                                <td>{{ orderItem.ticket.seat.row }}</td>
                                <td>{{ orderItem.ticket.seat.seat }}</td>
                                <td>
                                    <div class="form-group">
                                        <div class="form-input">
                                            <select class="form-control f-w-200"
                                                    [disabled]="!editable"
                                                    [(ngModel)]="orderItem.ticket.venueSectionAccessIds[0]"
                                                    (change)="updateOrderItemVenueSectionAccess($event.target.value, orderItem)"
                                                    data-component="cm-select">
                                                <option value="">{{ ('Order_Table.No_Access_Group' | trans) }}
                                                </option>
                                                <ng-container *ngIf="getVenue(orderItem.ticket.venueId) as venue;">
                                                    <ng-container *ngFor="let section of venue.sections">
                                                        <option disabled>{{ section.name }}</option>
                                                        <option *ngFor="let venueSection of section.sections"
                                                                [value]="venueSection.id">{{ venueSection.name }}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                                <option *ngFor="let venueSection of venueSections"
                                                        [value]="venueSection.id">{{ venueSection.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                                <td class="reset-line-height">
                                    <div class="form-group">
                                        <div class="form-input">
                                            <select class="form-control f-w-200"
                                                    data-component="cm-select"
                                                    [(ngModel)]="orderItem.ticket.ticketTypeId"
                                                    [disabled]="!editable"
                                                    (change)="updateOrderItemType($event.target.value, orderItem, orderItemGroup.id)">
                                                <ng-container *ngFor="let ticketType of orderItemGroup.types">
                                                    <option [value]="ticketType.id"
                                                            [disabled]="!getTicketTypePrice(ticketType, orderItem.ticket)">
                                                        {{ ticketType.name }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container *ngIf="orderItem.subscription">
                                <td [cm-tooltip]="orderItem.subscription.status">
                                    <span *ngIf="orderItem.subscription.status === 'CANCELED'"
                                          class="cm-icon cm-icon-error cl-error-color" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'BLOCKED'"
                                          class="cm-icon cm-icon-block cl-error-color" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'RESERVED'"
                                          class="cm-icon cm-icon-time-fill cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'ISSUED'"
                                          class="cm-icon cm-icon-verification cl-success-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'REFUNDED'"
                                          class="cm-icon cm-icon-refund cl-black-50" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'RESELL_AVAILABLE'"
                                          class="cm-icon cm-icon-duplicate cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'RESELL_RESERVED'"
                                          class="cm-icon cm-icon-duplicate cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.subscription.status === 'RESELL_SOLD'"
                                          class="cm-icon cm-icon-duplicate cl-success-color"
                                          aria-hidden="true"></span>
                                </td>
                                <td class="reset-line-height">
                                    <div class="parent-venue-section">
                                        <ng-container *ngIf="orderItem.subscription.seat">
                                            {{ orderItem.subscription.seat.parentSectionName }}
                                        </ng-container>
                                    </div>
                                    <ng-container *ngIf="orderItem.subscription.seat">
                                        {{ orderItem.subscription.seat.sectionName }}
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="orderItem.subscription.seat">
                                        {{ orderItem.subscription.seat.row }}
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="orderItem.subscription.seat">
                                        {{ orderItem.subscription.seat.seat }}
                                    </ng-container>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="form-input">
                                            <select class="form-control f-w-200" [disabled]="!editable"
                                                    [(ngModel)]="orderItem.subscription.venueSectionAccessIds[0]"
                                                    (change)="updateOrderItemVenueSectionAccess($event.target.value, orderItem)"
                                                    data-component="cm-select">
                                                <option value="">{{ ('Order_Table.No_Access_Group' | trans) }}
                                                </option>
                                                <ng-container *ngIf="getVenue(orderItemGroup.venueId) as venue;">
                                                    <ng-container *ngFor="let section of venue.sections">
                                                        <option disabled>{{ section.name }}</option>
                                                        <option *ngFor="let venueSection of section.sections"
                                                                [value]="venueSection.id">
                                                            {{ venueSection.name }}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="form-input">
                                            <select class="form-control f-w-200"
                                                    data-component="cm-select"
                                                    [(ngModel)]="orderItem.subscription.subscriptionTypePriceId"
                                                    [disabled]="!editable"
                                                    (change)="updateOrderItemSubscriptionTypePrice(orderItem, orderItem.subscription.subscriptionTypePriceId)">
                                                <ng-container
                                                    *ngFor="let subscriptionTypePrice of orderItemGroup.types">
                                                    <option [ngValue]="subscriptionTypePrice.id">
                                                        {{ subscriptionTypePrice.name }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container *ngIf="orderItem.product">
                                <td [cm-tooltip]="orderItem.product.status">
                                    <span *ngIf="orderItem.product.status === 'CANCELED'"
                                          class="cm-icon cm-icon-error cl-error-color" aria-hidden="true"></span>
                                    <span *ngIf="orderItem.product.status === 'RESERVED'"
                                          class="cm-icon cm-icon-time-fill cl-warning-color"
                                          aria-hidden="true"></span>
                                    <span *ngIf="orderItem.product.status === 'ISSUED'"
                                          class="cm-icon cm-icon-verification cl-success-color"
                                          aria-hidden="true"></span>
                                </td>
                                <td colspan="3">{{ orderItem.product.name }}</td>
                                <td>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="form-input">
                                            <select class="form-control f-w-200"
                                                    data-component="cm-select"
                                                    [(ngModel)]="orderItem.product.productTypePriceId"
                                                    [disabled]="!editable"
                                                    (change)="updateOrderItemType($event.target.value, orderItem, orderItemGroup.id)">
                                                <ng-container *ngFor="let productTypePrice of orderItemGroup.types">
                                                    <option [value]="productTypePrice.id">
                                                        {{ productTypePrice.name }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <td>
                                <div class="form-group">
                                    <div class="form-input">
                                        <input placeholder="{{('Order_Table.Price' | trans)}}"
                                               class="form-control f-w-200"
                                               [disabled]="!editable"
                                               (change)="updateOrderItemPrice(orderItem)"
                                               [(ngModel)]="orderItem.price">
                                    </div>
                                </div>

                                <ng-container *ngFor="let costItemAgreement of orderItem.additionalCosts">
                                    <ng-container *ngIf="costItemAgreement.amount > 0">
                                        <div class="order-item-cost-item-agreement">
                                            {{ costItemAgreement.label }}
                                            : {{ costItemAgreement.amount | currency:costItemAgreement.currencyId }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="text-right">
                                <div style="position: relative; top: 3px;">
                                    <ng-container *ngIf="!orderItem._loading; else loadingSmall">
                                        <button class="btn btn-secondary-icon m-r-16" *ngIf="editable"
                                                (click)="openOrderItemNote(orderItem.id)">
                                            <span class="cm-icon cm-icon-edit " aria-hidden="true"></span>
                                        </button>
                                        <button class="btn btn-secondary-icon" *ngIf="editable"
                                                (click)="deleteOrderItem(orderItem)">
                                            <span class="cm-icon cm-icon-delete" aria-hidden="true"></span>
                                        </button>
                                    </ng-container>
                                    <cm-button
                                        data-icon="block"
                                        data-button-style="secondary"
                                        data-button-type="icon"
                                        data-button-size="small"
                                        *ngIf="orderItem.ticket?.status === 'ISSUED' || orderItem.subscription?.status === 'ISSUED' ||
                                                orderItem.product?.status === 'ISSUED'"
                                        (click)="cancelOrderItem(orderItem)"
                                        [cm-tooltip]="('Order_Table.Cancel_Order_Item' | trans)">
                                    </cm-button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="orderItem.note || displayOrderNote === orderItem.id">
                            <td colspan="4"></td>
                            <td colspan="4">
                                <div class="form-group">
                                    <div class="form-input">
                                        <input [disabled]="!editable" type="text" name="orderItemNote"
                                               id="orderItemNote" class="form-control"
                                               (change)="updateOrderItemNote(orderItem.note, orderItem)"
                                               [(ngModel)]="orderItem.note">
                                        <label for="orderItemNote">{{ ('Order_Table.Ticket_Note' | trans) }}</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngFor="let additionalCost of order.additionalCosts">
                <tr>
                    <td></td>
                    <td class="text-right" colspan="5">{{ additionalCost.label }}</td>
                    <td class="text-right">
                        {{ additionalCost.amount | currency }}
                    </td>
                    <td class="text-right"></td>
                </tr>
            </ng-container>

            <ng-container *ngFor="let discount of order.discounts">
                <tr class="discount-table-row">
                    <td colspan="5"></td>
                    <td class="text-right">{{ discount.name }}</td>
                    <td class="text-right cl-success-color f-w-700">
                        -{{ discount.amount | currency }}
                    </td>
                    <td class="text-right">
                        <button class="btn btn-secondary-icon" *ngIf="editable"
                                (click)="deleteOrderDiscount(discount.id)">
                            <span class="cm-icon cm-icon-delete" aria-hidden="true"></span>
                        </button>
                    </td>
                </tr>
            </ng-container>

            <tr>
                <td colspan="5"></td>
                <td class="text-right">
                    <ng-container
                        *ngIf="order.totalDiscountAmount > 0">{{ ('Order_Table.Price_After_Discount' | trans) }}
                    </ng-container>
                    <ng-container
                        *ngIf="order.totalDiscountAmount === 0">{{ ('Order_Table.Price' | trans) }}
                    </ng-container>
                </td>
                <td class="text-right">
                    <strong>
                        {{ order.getPrice()| currency }}
                    </strong>
                </td>
                <td class="text-right">
                    <button *ngIf="editable"
                            class="btn btn-secondary-icon"
                            (click)="openDiscountDialog()"
                            [cm-tooltip]="('Order_Table.Add_Discount' | trans)">
                        <span class="cm-icon cm-icon-tag" aria-hidden="true"></span>
                    </button>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>
