import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PlatformService} from '../../services/platform-service/platform.service';
import {OrganisationService} from '../../services/organisation-service/organisation.service';
import {Organisation} from '../../models/guest-manager/organisation.class';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-dev-account-switch',
    templateUrl: './dev-account-switch.component.html',
    styleUrls: ['./dev-account-switch.component.scss']
})
export class DevAccountSwitchComponent implements OnInit, OnChanges {
    @Input() label: string;
    organisations: Organisation[] = [];
    filteredOrganisations: Organisation[];
    selectedOrganisation: Organisation;

    constructor(private platformService: PlatformService, private organisationService: OrganisationService) {
    }

    ngOnInit(): void {
        this.organisationService.getOrganisations(new HttpParams().set('take', -1)).subscribe(organisations => {
            this.organisations = organisations;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.eventId) {
            this.selectOrganisation(changes.eventId.currentValue);
        }
    }

    search(value) {
        if (value.trim() === '') {
            this.filteredOrganisations = this.organisations;
        } else {
            this.filteredOrganisations = this.organisations.filter(organisation => organisation.name.toLowerCase().includes(value.toLowerCase()) || organisation.technicalLinkId === value);
        }
    }

    selectOrganisation(technicalLinkId) {
        if (this.selectedOrganisation && this.selectedOrganisation.technicalLinkId === technicalLinkId) {
            return;
        }

        this.selectedOrganisation = this.organisations.find(item => item.technicalLinkId === technicalLinkId);

        localStorage.setItem('seatedDashboard.technicalLinkId', this.selectedOrganisation.technicalLinkId);
        window.location.reload();
    }

}
