import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Notification} from '../../../models/notification.class';
import {NotificationService} from '../notification.service';
import {NotificationObserver} from './notification-observer';
import {ReportingNotificationHandler} from '../../notifications/reporting-notification-handler';
import {NotificationHandler} from '../../notifications/notification-handler';
import {ProgressNotificationHandler} from '../../notifications/progress-notification-handler';
import {NullNotificationHandler} from '../../notifications/NullNotificationHandler';
import {NotificationTopic} from '../../../models/notification-topic.enum';
import {CopyVenueNotificationHandler} from '../../notifications/copy-venue-notification-handler';

@Injectable({
    providedIn: 'root'
})
export class AccountNotificationService implements NotificationObserver {

    private notificationStream: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
    public notifications$: Observable<Notification[]> = this.notificationStream.asObservable();

    public constructor(private notificationService: NotificationService, private injector: Injector) {
        this.notificationService.registerObserver(this);
    }

    public next(notification: Notification): void {
        const notificationHandler: NotificationHandler = this.createNotificationHandler(notification.topic);
        notificationHandler.handle(notification);
    }

    private createNotificationHandler(topic: string): NotificationHandler {
        let notificationHandler: NotificationHandler;

        switch (topic) {
            case NotificationTopic.PROGRESS:
                notificationHandler = new ProgressNotificationHandler(this.notificationStream);
                break;
            case NotificationTopic.REPORT_FINISHED:
            case NotificationTopic.REPORT_FAILED:
                notificationHandler = ReportingNotificationHandler.getInstance(this.injector, this.notificationStream);
                break;
            case NotificationTopic.COPY_VENUE_PROGRESS:
                notificationHandler = new CopyVenueNotificationHandler(this.notificationStream);
                break;
            default:
                notificationHandler = new NullNotificationHandler();
                break;
        }

        return notificationHandler;
    }
}
