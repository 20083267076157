import {Component, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {Customer} from '../../../models/customer.class';
import {CustomerManagementService} from '../../../services/customer-management/customer-management.service';

@Component({
               selector:    'app-dialog-edit-customer',
               templateUrl: './dialog-edit-customer.component.html',
               styleUrls:   ['./dialog-edit-customer.component.css']
           })
export class DialogEditCustomerComponent extends DialogBase<IInput, IOutput> implements OnInit {
    customer: Customer;

    constructor(dialogService: DialogService, private customerManagementService: CustomerManagementService) {
        super(dialogService);
    }

    ngOnInit(): void {
        this.customer = new Customer();
        this.customerManagementService.setCustomer(this.customer);
    }

    setModal(data: IInput) {
        if (data.extraData && data.extraData.customer) {
            this.customer = data.extraData.customer;
        }
    }

    public confirm() {
        this.customerManagementService.saveCustomer(this.customer);

        super.confirm({
                          successful: true,
                          // @ts-ignore
                          extraData:  this.customer
                      });
    }

    public cancel() {
        super.cancel();
    }

}
