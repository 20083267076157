import {ModelBase} from '../abstract/model-base';
import {OrganisationSetting} from '../organisation-setting.class';

export class Organisation extends ModelBase {
    public technicalLinkId: string;
    public organisationId: string;
    public name: string;
    public country: string;
    public timezoneId: string;
    public currencyId: string;
    public organisationSettings: OrganisationSetting[];
    public createdAt: string;
    public updatedAt: string;

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/organisation/:id',
            patch: 'v1.0/organisation/:id',
            post: 'v1.0/organisation'
        });
    }

    getSetting(settingId: string) {
        if (!this.organisationSettings) {
            return null;
        }

        const setting = this.organisationSettings.find(element => element.settingId === settingId);

        return setting ? setting.value : null;
    }

    mapModel(data: any): Organisation {
        const model = new Organisation();
        model.technicalLinkId = data.technicalLinkId;
        model.organisationId = data.organisationId;
        model.name = data.name;
        model.timezoneId = data.timezoneId;
        model.country = data.country;
        model.currencyId = data.currencyId;
        model.organisationSettings = data.organisationSettings?.map(setting => new OrganisationSetting().mapModel(setting));
        model.createdAt = data.createdAt;
        model.updatedAt = data.updatedAt;
        model.id = data.technicalLinkId;

        return model;
    }
}

export function mapOrganisation(item: any): Organisation {
    return new Organisation().mapModel(item);
}
