<div class="row form-group">
    <div class="col-6 col-sm-4 offset-sm-3 text-right">
        <div class="pagination-total">
            {{ currentPageStartAt }} - {{ currentPageEndAt }} {{('Pagination.Of' | trans)}} {{ total }}
        </div>
    </div>
    <div class="col-2 col-sm-1 text-right">
        <button class="btn btn-secondary-icon skip-button" (click)="onPreviousPageClick()">
            <span class="cm-icon cm-icon-previous" aria-hidden="true"></span>
        </button>
    </div>
    <div class="col-3 col-sm-1 text-center">
        <div class="form-input">
            <input type="number"
                   class="form-control skip-input"
                   [value]="(skip / take) + 1"
                   (input)="onPaginationChange({take: take, skip: $event.target.value})">
        </div>
    </div>
    <div class="col-1 col-sm-1">
        <button class="btn btn-secondary-icon skip-button" (click)="onNextPageClick()">
            <span class="cm-icon cm-icon-next" aria-hidden="true"></span>
        </button>
    </div>
    <div class="col-6 col-sm-2">
        <cm-select [attr.value]="take"
                   (cm-option-clicked)="onPaginationChange({take: $event.detail.value, skip: 0})">
            <cm-option slot="option" data-display="10" value="10"></cm-option>
            <cm-option slot="option" data-display="25" value="25"></cm-option>
            <cm-option slot="option" data-display="50" value="50"></cm-option>
            <cm-option slot="option" data-display="100" value="100"></cm-option>
            <cm-option slot="option" data-display="250" value="250"></cm-option>
        </cm-select>
    </div>
</div>




