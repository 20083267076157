<div class="row empty-state">
    <div class="col-12 col-sm-6">
        <div class="empty-state">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="col-12 col-sm-6">
        <cm-image data-type="empty" data-min-height="240"></cm-image>
    </div>
</div>
