import {Injectable, NgZone} from '@angular/core';
import {concatMap, mergeAll, mergeMap, tap, toArray} from 'rxjs/operators';
import {saveAs} from 'file-saver/dist/FileSaver';
import {NotificationService} from '../notification-service/notification.service';
import {Download} from './types/download.interface';
import {PrintService} from '../print-service/print.service';
import {Subscription} from '../../models/subscription.class';
import {from, Subject} from 'rxjs';
import {CmTranslationService} from '../../../core/services/cm-translation.service';
import * as ExcelJS from 'exceljs/dist/exceljs.js';
import {Worksheet} from 'exceljs/dist/exceljs.js';

@Injectable()
export class DownloadManagerService {
    downloadSubject = new Subject<Download>();
    download: Download = null;

    constructor(private notificationService: NotificationService,
                private printService: PrintService,
                private zone: NgZone,
                private cmTranslationService: CmTranslationService) {
    }

    downloadData(download: Download) {
        if (this.download) {
            this.notificationService.showTranslatedError('Already_Downloading_A_File');
            return;
        }

        let downloadItemCount = 0;
        this.download = download;
        this.downloadSubject.next(this.download);

        const requests = [];
        const requestLoops = Math.ceil((download.total / download.batchSize));

        for (let i = 0; i < requestLoops; i++) {
            requests.push(i * download.batchSize);
        }

        from(requests).pipe(
            mergeMap(skip => {
                return download.getData(download.type, skip, download.total, download.filters).pipe(
                    tap(() => {
                        downloadItemCount = downloadItemCount + download.batchSize;
                        this.download.progress = Math.round((downloadItemCount / download.total) * 100);
                        this.downloadSubject.next(this.download);
                    })
                );
            }, undefined, 5),
            mergeAll(),
            toArray()
        ).subscribe(rows => {
            if (rows.length === 0) {
                this.download = null;
                this.downloadSubject.next(this.download);
                this.notificationService.showTranslatedErrorNotification('report', 'no_result');
                return;
            }

            // specify how you want to handle null values here
            const fileName = download.fileName ? download.fileName : this.generateFileName(download.type);
            const replacer = (key, value) => value === null ? '' : value;
            const headers = Object.keys(rows[0]);

            if (this.download.isExcelDownload) {
                this.zone.run(async () => {
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Data', {
                        views: [{
                            state: 'frozen',
                            ySplit: 1
                        }]
                    });

                    this.setHeaders(worksheet, download, headers);
                    worksheet.addRows(rows);
                    this.setHeaderStyling(worksheet);
                    this.setAutoFilters(worksheet);
                    this.setAutoColumnWidth(worksheet);
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    saveAs(blob, fileName + '.xlsx');
                }).then(() => {
                    setTimeout(() => {
                        this.download = null;
                        this.downloadSubject.next(this.download);
                    }, 5000);
                });
            } else {
                const csv = rows.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
                csv.unshift(headers.join(';'));
                const csvArray = csv.join('\r\n');
                const BOM = '\uFEFF';
                const blob = new Blob([BOM + csvArray], {type: 'text/csv;charset=utf-8'});
                saveAs(blob, fileName + '.csv', {autoBom: true});

                setTimeout(() => {
                    this.download = null;
                    this.downloadSubject.next(this.download);
                }, 5000);
            }
        });
    }

    print(download: Download, printFormat) {
        if (this.download) {
            this.notificationService.showTranslatedError('Already_Downloading_A_File');
            return;
        }

        this.download = download;
        this.downloadSubject.next(this.download);

        const requests = [];
        const requestLoops = Math.ceil((download.total / download.batchSize));

        for (let i = 0; i < requestLoops; i++) {
            requests.push(i * download.batchSize);
        }

        from(requests).pipe(
            concatMap(skip => download.getData(download.type, skip, download.total, download.filters)),
            mergeAll(),
            toArray()
        ).subscribe(async (subscriptions: Subscription[]) => {
            if (subscriptions.length === 0) {
                this.download = null;
                this.downloadSubject.next(this.download);
                return;
            }

            let progress = 0;
            for (const subscription of subscriptions) {
                progress++;
                this.download.progress = Math.round((progress / download.total) * 100);
                this.downloadSubject.next(this.download);

                this.printService.print(printFormat, {
                    method: printFormat,
                    orderItemId: subscription.orderItemId,
                    subscriptionId: subscription.id,
                    barcodes: subscription.fixedBarcode
                }, false);

                // Add some sleep to not overload the printer driver
                await new Promise(r => setTimeout(r, 250));

                if (download.total === progress) {
                    setTimeout(() => {
                        this.download = null;
                        this.downloadSubject.next(this.download);
                    }, 5000);
                }
            }

        });
    }

    private generateFileName(type: string): string {
        const exportDate = new Date().toISOString().slice(0, 10);
        const exportName = this.cmTranslationService.getPhraseForLanguage(`Export.Name.${type}`);

        return `${exportDate} - ${exportName}`;
    }

    private setHeaders(worksheet: Worksheet, download: Download, headers: string[]) {
        const columns = [];

        for (const header of headers) {
            const column = {
                header: header,
                key: header.toLowerCase(),
                style: {
                    numFmt: '@'
                }
            };

            if (download.formatting[header]) {
                column.style.numFmt = download.formatting[header].numFmt;
            }

            columns.push(column);
        }

        worksheet.columns = columns;
    }

    private setHeaderStyling(worksheet: Worksheet) {
        worksheet.getRow(1).font = {
            bold: true,
            color: {
                argb: 'ffffff'
            }
        };

        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '0074e8'
            }
        };
    }

    private setAutoFilters(worksheet: Worksheet) {
        worksheet.autoFilter = {
            from: {
                row: 1,
                column: 1
            },
            to: {
                row: 1,
                column: worksheet.columnCount
            }
        };
    }

    private setAutoColumnWidth(worksheet: Worksheet) {
        worksheet.columns.forEach((column) => {
            let dataMax = 0;

            column.eachCell({includeEmpty: true}, (cell) => {
                // @ts-ignore
                const columnLength = cell.value?.length + 3;

                if (columnLength > dataMax) {
                    dataMax = columnLength;
                }
            });

            column.width = dataMax < 10 ? 10 : dataMax;
        });
    }
}
