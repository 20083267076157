import {Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Customer, mapCustomer} from '../../models/customer.class';
import {map, tap} from 'rxjs/operators';
import {CustomerFilters} from '../../models/customer-filters.class';
import {GuestManagerUser} from '../../models/guest-manager/guest-manager-user.class';
import {NotificationService} from '../notification-service/notification.service';
import {CustomerTag} from '../../models/customer-tag.class';
import {CustomerNote, mapCustomerNote} from '../../models/customer-note.class';

@Injectable()
export class CustomerService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {},
        responseType: null
    };

    constructor(private http: HttpClient, private notificationService: NotificationService) {
    }

    getCustomers(params?: HttpParams, filters?: CustomerFilters): Observable<HttpResponse<Customer[]>> {
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Customer[]>(this.baseUrl + `/v1.0/customer`, {...config, observe: 'response'}).pipe(
            map((response: HttpResponse<Customer[]>) => {
                const body = response.body.map((item: Customer) => {
                    return mapCustomer(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }

    getCustomer(customerId: string, params?: HttpParams): Observable<Customer> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Customer>(this.baseUrl + `/v1.0/customer/${customerId}`, config).pipe(
            map((item: Customer) => {
                return mapCustomer(item);
            })
        );
    }

    patchCustomer(customerid: string, body: any, params?: HttpParams): Observable<Customer> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Customer>(this.baseUrl + `/v1.0/customer/${customerid}`, body, config).pipe(
            map((item: Customer) => {
                return mapCustomer(item);
            })
        );
    }

    postCustomer(customer: Customer): Observable<Customer> {
        return this.http.post<Customer>(this.baseUrl + `/v1.0/customer`, customer, this.config).pipe(
            map((item: Customer) => {
                return mapCustomer(item);
            })
        );
    }

    resetPassword(customer: Customer): Observable<Customer> {
        return this.http.post<any>(this.baseUrl + `/v1.0/auth/login`, {
            username: btoa(customer.id),
            step: 'NEWPASSWORDMAIL'
        }, this.config);
    }

    deleteCustomer(customerId: string): Observable<any> {
        return this.http.delete<any>(this.baseUrl + `/v1.0/customer/${customerId}`, this.config);
    }

    remoteLoginShopCustomer(customerId: string, shopId: string): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/v1.0/customer/${customerId}/shop/${shopId}/login`, this.config);
    }

    addGuestManagerUser(customer: Customer, guestManagerUser: GuestManagerUser) {
        return this.http.post<GuestManagerUser>(this.baseUrl + `/v1.0/customer/${customer.id}/guestmanager/user`, guestManagerUser, this.config);
    }

    resetGuestManagerPassword(username: string, guestManagerOrganisationId: string) {
        const body = {
            username: btoa(username),
            organisationId: guestManagerOrganisationId
        };

        return this.http.post(`${environment.CM_API_URL}/guestmanagerapi/v1.0/auth/user/password-reset`, body, this.config).pipe(
            tap((result) => {
                this.notificationService.showTranslatedNotification('success', 'email', 'sent');
            })
        );
    }

    deleteGuestManagerUser(customerId: string, userId: string) {
        return this.http.delete<any>(this.baseUrl + `/v1.0/customer/${customerId}/guestmanager/user/${userId}`, this.config);
    }

    remoteLoginGuestManagerUser(customerId: string, userId: string, params?: HttpParams): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/v1.0/customer/${customerId}/guestmanager/user/login?userId=${userId}`, this.config);
    }

    postContactImport(file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', file.name);

        return this.http.post<Customer[]>(this.baseUrl + `/v1.0/import/customer/`, formData, this.config);
    }

    saveCustomerCustomerTags(customerCustomerTags: any, filters?: CustomerFilters, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<any>(this.baseUrl + `/v1.0/customer/tag/bulk`, customerCustomerTags, config);
    }

    removeCustomerCustomerTags(customerCustomerTags: any, filters?: CustomerFilters, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<any>(this.baseUrl + `/v1.0/customer/tag/bulk/remove`, customerCustomerTags, config);
    }

    postTag(tag: any) {
        const config = Object.assign({}, this.config);
        const customerTag = {
            name: tag
        };
        return this.http.post<CustomerTag>(this.baseUrl + `/v1.0/customer/tag`, customerTag, config);
    }

    archiveCustomers(customerIds: any, filters?: CustomerFilters, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<any>(this.baseUrl + `/v1.0/customer/archive/bulk`, {customerIds: customerIds}, config);
    }

    dearchiveCustomers(customerIds: any, filters?: CustomerFilters, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<any>(this.baseUrl + `/v1.0/customer/restore/bulk`, {customerIds: customerIds}, config);
    }

    exportToAddressBook(filters?: CustomerFilters, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<any>(this.baseUrl + `/v1.0/customer/export/addressbook`, config);
    }

    postCustomerNote(customerId: string, customerNote: CustomerNote, params?: HttpParams) {
        return this.http.post<CustomerNote>(this.baseUrl + `/v1.0/customer/${customerId}/note`, customerNote, this.config).pipe(
            map((item: CustomerNote) => {
                return mapCustomerNote(item);
            })
        );
    }

    deleteCustomerNote(customerId: string, customerNoteId: string) {
        return this.http.delete<CustomerNote>(this.baseUrl + `/v1.0/customer/${customerId}/note/${customerNoteId}`, this.config);
    }

}
