import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderBarService {
    private orderBarExpandedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public orderBarExpanded$: Observable<boolean>;

    constructor() {
        this.orderBarExpandedSource = new BehaviorSubject<boolean>(false);
        this.orderBarExpanded$ = this.orderBarExpandedSource.asObservable();
    }

    setOrderBarExpanded(expanded: boolean) {
        this.orderBarExpandedSource.next(expanded);
    }

    isOrderBarExpanded(): boolean {
        return this.orderBarExpandedSource.getValue();
    }
}
