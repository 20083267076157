import {GridsterItem} from 'angular-gridster2';
import {ChartSetting} from './chart-settings.class';
import {AnalyticsDashboardItemSetting} from './analytics-dashboard-item-setting.class';

export class AnalyticsDashboardItem {
    constructor(public id: string                                                      = null,
                public name: string                                                    = null,
                public positionX: number                                               = null,
                public positionY: number                                               = null,
                public width: number                                                   = 2,
                public height: number                                                  = 2,
                public isLegendEnabled: boolean                                        = true,
                public isGridHorizontalEnabled: boolean                                = true,
                public isGridVerticalEnabled: boolean                                  = false,
                public isLabelHorizontalEnabled: boolean                               = true,
                public isLabelVerticalEnabled: boolean                                 = true,
                public isStacked: boolean                                              = false,
                public analyticsDashboardItemSettings: AnalyticsDashboardItemSetting[] = [],
                public createdAt: string                                               = null,
                public updatedAt: string                                               = null,
                public chartSetting: ChartSetting                                      = new ChartSetting(),
                public gridster: GridsterItem                                          = {cols: width, rows: height, x: positionX, y: positionY, updatedAt: null}) {
    }
}

export function mapAnalyticsDashboardItem(item: any): AnalyticsDashboardItem {
    return new AnalyticsDashboardItem(
        item.id,
        item.name,
        item.positionX,
        item.positionY,
        item.width,
        item.height,
        item.isLegendEnabled,
        item.isGridHorizontalEnabled,
        item.isGridVerticalEnabled,
        item.isLabelHorizontalEnabled,
        item.isLabelVerticalEnabled,
        item.isStacked,
        item.analyticsDashboardItemSettings,
        item.createdAt,
        item.chartSetting,
        item.gridster
    );
}
