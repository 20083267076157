import {Injectable, Injector} from '@angular/core';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import {DiscountCampaign} from '../../models/discount-campaign';

@Injectable({
                providedIn: 'root'
            })
export class DiscountCampaignManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new DiscountCampaign();

        this.initialize();
    }

}
