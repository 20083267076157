<cm-dialog data-max-width="600px" [id]="id">
    <h3 slot="header" class="dialog-title">
        {{('Venue_Section_Seat_Setting.Edit_Capacity' | trans)}}
    </h3>

    <div slot="body" class="form-group">
        <h4 [cm-tooltip]="('Venue_Section_Seat_Setting.Exception_Help' | trans)">
            {{('Venue_Section_Seat_Setting.Exception' | trans)}} <span class="cm-icon cm-icon-help-open" aria-hidden="true"></span>
        </h4>

        <ng-container *ngFor="let venueSectionSeat of venueSectionSeats">
            <table class="table form-group w-100 mt-32">
                <thead>
                <tr>
                    <th>{{('Venue_Section_Seat_Setting.Original_Capacity' | trans)}}</th>
                    <th>{{('Venue_Section_Seat_Setting.New_Capacity' | trans)}}</th>
                    <th>{{('Venue_Section_Seat_Setting.Change' | trans)}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let venueSectionSeatSetting of venueSectionSeat.venueSectionSeatSettings">
                    <ng-container *ngIf="venueSectionSeatSetting.eventId && venueSectionSeatSetting.eventId === eventId">
                        <tr>
                            <td>{{venueSectionSeat.capacity}}</td>
                            <td>
                                <app-form-input type="number"
                                                [(value)]="venueSectionSeatSetting.capacity">
                                </app-form-input>
                            </td>
                            <td>
                                <app-form-input type="number"
                                                [value]="venueSectionSeatSetting.capacity - venueSectionSeat.capacity"
                                                (valueChange)="venueSectionSeatSetting.capacity = venueSectionSeat.capacity + +$event">
                                </app-form-input>
                            </td>
                            <td>
                                <button class="btn btn-secondary-icon" (click)="deleteVenueSectionSeatSetting(venueSectionSeat, venueSectionSeatSetting)">
                                    <span class="cm-icon cm-icon-delete" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
            <button class="btn btn-secondary-icon-text"
                    (click)="addVenueSectionSeatSetting(venueSectionSeat, true)"
                    [disabled]="isDisabled(venueSectionSeat, true)">
                <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                <span>{{('General.Add' | trans)}}</span>
            </button>

            <h4 class="m-t-32">{{('Venue_Section_Seat_Setting.Event_Category_Capacity' | trans)}}</h4>
            <table class="table form-group w-100 mt-32">
                <thead>
                <tr>
                    <th>{{('Venue_Section_Seat_Setting.Original_Capacity' | trans)}}</th>
                    <th>{{('Venue_Section_Seat_Setting.New_Capacity' | trans)}}</th>
                    <th>{{('Venue_Section_Seat_Setting.Change' | trans)}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let venueSectionSeatSetting of venueSectionSeat.venueSectionSeatSettings">
                    <ng-container *ngIf="!venueSectionSeatSetting.eventId">
                        <tr>
                            <td>{{venueSectionSeat.capacity}}</td>
                            <td>
                                <app-form-input type="number"
                                                [(value)]="venueSectionSeatSetting.capacity">
                                </app-form-input>
                            </td>
                            <td>
                                <app-form-input type="number"
                                                [value]="venueSectionSeatSetting.capacity - venueSectionSeat.capacity"
                                                (valueChange)="venueSectionSeatSetting.capacity = venueSectionSeat.capacity + +$event">
                                </app-form-input>
                            </td>
                            <td>
                                <button class="btn btn-secondary-icon" (click)="deleteVenueSectionSeatSetting(venueSectionSeat, venueSectionSeatSetting)">
                                    <span class="cm-icon cm-icon-delete" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
            <button class="btn btn-secondary-icon-text"
                    (click)="addVenueSectionSeatSetting(venueSectionSeat, false)"
                    [disabled]="isDisabled(venueSectionSeat, false)">
                <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                <span>{{('General.Add' | trans)}}</span>
            </button>
        </ng-container>
    </div>

    <div slot="footer" class="m-t-32">
        <button class="btn btn-secondary" (click)="cancel()">{{('Dialog.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('Dialog.Ok' | trans)}}</button>
    </div>

</cm-dialog>
