<cm-dialog [id]="id" data-max-width="640px">

    <h3 slot="header" class="dialog-title">
        {{('Dialog_Import_Customer_Tag.Title' | trans)}}
    </h3>

    <div slot="body" class="form-group">
        <ng-container *ngIf="!isCustomerTagImportFileUploadStarted && !isCustomerTagImportFileUploadDone">
            <div class="row">
                <div class="col-12">
                    <h3>{{('Dialog_Import_Customer_Tag.Select_Tag_Title' | trans)}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <cm-select data-label="Relation tag" data-action-button (cm-option-clicked)="onCustomerTagSelect($event)">
                        <ng-container *ngFor="let customerTag of (customerTags$ | async)">
                            <cm-option slot="option" [attr.value]="customerTag.id"
                                       [attr.data-display]="customerTag.name"></cm-option>
                        </ng-container>
                    </cm-select>
                </div>
            </div>

            <div class="row m-t-32">
                <div class="col-12">
                    <h3 class="has-description">{{('Dialog_Import_Customer_Tag.Select_File_Title' | trans)}}</h3>
                    {{('Dialog_Import_Customer_Tag.Select_File_Description' | trans)}}
                </div>
            </div>

            <div class="row m-t-16 m-b-16">
                <ng-container *ngIf="!customerTagImportFile">
                    <button class="btn btn-primary m-t-16" (click)="fileSelect.click()">
                        <span>{{('Dialog_Import_Customer_Tag.Import_Select_File' | trans)}}</span>
                    </button>
                </ng-container>

                <ng-container *ngIf="customerTagImportFile && !isCustomerTagImportFileUploadStarted">
                    <div class="row f-w-700">{{ customerTagImportFile.name }}</div>
                    <div class="row lowercase">{{ customerTagImportFileRowCount }} {{('General.Customers' | trans)}}</div>
                </ng-container>
            </div>

            <div class="row m-t-16 m-b-16">
                <ng-container *ngIf="!isCustomerTagImportFileUploadStarted">
                    <div class="form-group">
                        <div class="form-row">
                            <div class="form-check">
                                <label>
                                    <input type="checkbox" (change)="setRemoveTags($event.target.checked)">
                                    <span class="label">{{('Dialog_Import_Customer_Tag.Remove_Tags' | trans)}}</span>
                                </label>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="customerTagImportFile && isCustomerTagImportFileUploadStarted && !isCustomerTagImportFileUploadDone">
            <cm-indicator></cm-indicator>
        </ng-container>

        <ng-container *ngIf="isCustomerTagImportFileUploadDone">
            <div class="row">
                <div class="col-12">
                    <h4>{{('Dialog_Import_Customer_Tag.Import_Import_Done' | trans)}}</h4>
                </div>
            </div>
        </ng-container>
    </div>

    <div slot="footer">
        <ng-container *ngIf="customerTagImportFile && !isCustomerTagImportFileUploadDone">
            <button class="btn btn-secondary" (click)="closeCustomerTagImportDialog()">
                {{('General.Cancel' | trans)}}
            </button>
            <button class="btn btn-primary" (click)="confirmCustomerTagImportDialog()">
                {{('General.Import' | trans)}}
            </button>
        </ng-container>
        <ng-container *ngIf="customerTagImportFile && isCustomerTagImportFileUploadDone">
            <button class="btn btn-primary" (click)="closeCustomerTagImportDialog()">
                {{('General.Close' | trans)}}
            </button>
        </ng-container>
    </div>

    <input class="file-select" type="file" #fileSelect (change)="setCustomerTagImportFile($event)"
           accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">

</cm-dialog>
