<div class="card card-action" [class.closed]="!isExpanded" [draggable]="!isExpanded">
    <div class="card-menu-header" (click)="$event.preventDefault(); $event.stopPropagation(); isExpanded = !isExpanded">
        <div class="row">
            <div class="col-6 d-md-block vertical-middle consent-name">
                    <span class="edit-container">
                        <span>{{ consent.id ? consent.name : ('Consent.New_Consent' | trans) }}</span>
                    </span>
            </div>

            <div class="col-2 d-none d-md-block">
                <div class="f-s-12 f-w-200">{{('Consent.Required' | trans)}}</div>
                <div class="f-s-13 f-w-700">
                    <ng-container *ngIf="!consent.isRequired">
                        {{('General.No' | trans)}}
                    </ng-container>
                    <ng-container *ngIf="consent.isRequired">
                        {{('General.Yes' | trans)}}
                    </ng-container>
                </div>
            </div>

            <div class="col-4 d-none d-md-block">
                <div class="f-s-12 f-w-200">{{('General.Link' | trans)}}</div>
                <div class="f-s-13 f-w-700">
                    <ng-container *ngIf="consent.resource?.name && consent.resource?.type === 'PDF'">
                        {{ consent.resource.name }}
                    </ng-container>
                    <ng-container *ngIf="consent.resource?.link && consent.resource?.type === 'WEBSITE'">
                        {{ consent.resource.link }}
                    </ng-container>
                    <ng-container *ngIf="!consent.resource?.link">
                        -
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="card-menu">
        <ng-container *ngFor="let menuItem of menuItems">
            <div class="card-menu-item" [class.active]="activeMenuItem === menuItem.value"
                 (click)="activeMenuItem = menuItem.value">
                {{(menuItem.label | trans)}}
            </div>
        </ng-container>
    </div>

    <div class="card-menu-content">
        <div class="grid-content">
            <ng-container *ngIf="activeMenuItem === 'SETTINGS'">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-row">
                                    <app-input-translation
                                        formId="createEvent"
                                        [(value)]="consent.name"
                                        [type]="'text'"
                                        [translation]="consent.nameTranslation"
                                        [label]="('General.Name' | trans)"></app-input-translation>
                                </div>
                            </div>
                        </div>

                        <div class="row m-b-16">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="form-row">
                                        <div class="form-check">
                                            <label class="checkbox">
                                                <input type="checkbox" [checked]="consent.isRequired"
                                                       (change)="consent.isRequired = $event.target.checked"/>
                                                <span class="label">{{('Consent.Required' | trans)}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group p-t-16">
                            <div class="col-12">
                                <app-form-radio formId="customer"
                                                [(value)]="resourceType"
                                                [options]="resourceTypes"
                                                [optionLabelTranslate]="true"
                                                (valueChange)="onResourceSelected(null)"
                                                [listHorizontal]="true">
                                </app-form-radio>
                            </div>
                        </div>

                        <ng-container *ngIf="resourceType === 'WEBSITE'">
                            <div class="row m-b-16">
                                <div class="col-12">
                                    <div class="form-row">
                                        <app-form-input
                                            [value]="consent.resource?.link"
                                            [placeholder]="('General.Website' | trans)"
                                            (change)="onResourceSelected(getMockResource($event.target.value, 'WEBSITE'), 'WEBSITE')"></app-form-input>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="resourceType === 'PDF'">
                            <div class="row m-b-16">
                                <div class="col-12">
                                    <div class="form-row">
                                        <app-file-picker
                                            [label]="null"
                                            [file]="consent.resource"
                                            [isRemoveAllowed]="true"
                                            (fileSelected)="onResourceSelected($event, 'PDF')"
                                            (fileRemoved)="onResourceSelected(null)"></app-file-picker>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'CONDITIONS'">
                <p class="m-t-0">
                    {{('Consent.Conditions_Intro' | trans)}}
                </p>
                <div class="conditions">
                    <ng-container *ngIf="subscriptionTypes.length > 0">
                        <div class="row m-b-32">
                            <div class="col-12 col-md-4">
                            <span class="f-s-14">
                                {{('General.Subscription_Types' | trans)}}
                            </span><br>
                                <span class="header-link"
                                      (click)="updateArraySelection('filterSubscriptionTypeIds', subscriptionTypes)">
                                {{('General.Select_All' | trans)}}
                            </span> /
                                <span class="header-link"
                                      (click)="updateArraySelection('filterSubscriptionTypeIds', [])">
                                {{('General.Deselect_All' | trans)}}
                            </span>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="row">
                                    <ng-container *ngFor="let subscriptionType of subscriptionTypes">
                                        <div class="col-12 col-md-6">
                                            <div class="form-check">
                                                <label>
                                                    <input type="checkbox"
                                                           [checked]="inSelection('filterSubscriptionTypeIds', subscriptionType)"
                                                           (change)="updateSelection('filterSubscriptionTypeIds', subscriptionType)">
                                                    <span class="label">{{ subscriptionType.name }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="events.length > 0">
                        <div class="row">
                            <div class="col-12 col-md-4">
                            <span class="f-s-14">
                                {{('General.Events' | trans)}}
                            </span><br>
                                <span class="header-link"
                                      (click)="updateArraySelection('filterEventIds', events)">
                                {{('General.Select_All' | trans)}}
                            </span> /
                                <span class="header-link"
                                      (click)="updateArraySelection('filterEventIds', [])">
                                {{('General.Deselect_All' | trans)}}
                            </span>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="row">
                                    <ng-container *ngFor="let event of events">
                                        <div class="col-12 col-md-6">
                                            <div class="form-check">
                                                <label>
                                                    <input type="checkbox"
                                                           [checked]="inSelection('filterEventIds', event)"
                                                           (change)="updateSelection('filterEventIds', event)">
                                                    <span class="label">{{ event.name }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div class="row grid-footer">
            <div class="col-12 col-md-6">
                <button class="btn btn-secondary btn-error" (click)="onDeleteConsentClicked()"
                        *ngIf="consent.id">{{('General.Delete' | trans)}}
                </button>

                <button class="btn btn-secondary" [disabled]="!this.consent.consentSetting.filterSubscriptionTypeIds.length && !this.consent.consentSetting.filterEventIds.length"
                        *ngIf="consent.id" (click)="onClearConsentSettingClicked()">{{'Consent_Detail.Clear_Consent_Settings' | trans }}</button>
            </div>
            <div class="col-12 col-md-6 text-right">
                <button class="btn btn-primary m-l-8" (click)="onSaveConsentClicked()">
                    <span>{{('General.Save' | trans)}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
