<cm-dialog [id]="id" data-max-width="640px">

    <h3 slot="header" class="dialog-title">
        {{('Dialog_Create_Invoice.Title' | trans)}}
    </h3>

    <div slot="body" class="form-group">
        {{('Dialog_Create_Invoice.Body' | trans)}}

        <div class="row m-t-32 m-b-32">
            <div class="col-12">
                <button class="btn btn-primary" (click)="useCustomerData()">{{('Dialog_Create_Invoice.Use_Customer_Data' | trans)}}</button>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-6 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.companyName">
                <label>{{('Customer.Company_Name' | trans)}}</label>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-6 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.vatNumber">
                <label>{{('Order_Invoice.Vat_Number' | trans)}}</label>
            </div>
            <div class="col-12 col-sm-6 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.reference">
                <label>{{('Order_Invoice.Reference' | trans)}}</label>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-4 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.initials">
                <label>{{('Customer.Initials' | trans)}}</label>
            </div>
            <div class="col-12 col-sm-8 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.firstName">
                <label>{{('Customer.First_Name' | trans)}}</label>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-4 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.insertion">
                <label>{{('Customer.Insertion' | trans)}}</label>
            </div>
            <div class="col-12 col-sm-8 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.lastName">
                <label>{{('Customer.Last_Name' | trans)}}</label>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-8 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.street">
                <label>{{('Customer.Street' | trans)}}</label>
            </div>
            <div class="col-12 col-sm-4 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.houseNumber">
                <label>{{('Customer.House_Number' | trans)}}</label>
            </div>
        </div>

        <div class="row form-row">
            <div class="col-12 col-sm-4 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.postalCode">
                <label>{{('Customer.Postal_Code' | trans)}}</label>
            </div>
            <div class="col-12 col-sm-8 form-input">
                <input type="text" class="form-control" [(ngModel)]="orderInvoice.city">
                <label>{{('Customer.City' | trans)}}</label>
            </div>
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('General.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('General.Save' | trans)}}</button>
    </div>

</cm-dialog>
