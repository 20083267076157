import {Injectable} from '@angular/core';
import {Store} from '../../core/state-management/store';
import {Order} from '../../shared/models/order.class';
import {SeatedApiService} from '../../shared/services/util/seated-api/seated-api.service';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {Selected} from '../../shared/components/search-filter-container/selected.class';
import moment from 'moment';
import {
    OrderListDownloadDialogComponent
} from '../components/order-list-download-dialog/order-list-download-dialog.component';
import {OrderReportDownload} from '../../shared/services/download-manager-service/types/order-report-download';
import {DialogService} from '../../shared/services/dialog-service/dialog.service';
import {ExportService} from '../../shared/services/export-service/export.service';
import {DownloadManagerService} from '../../shared/services/download-manager-service/download-manager.service';
import {Customer} from '../../shared/models/customer.class';
import {OrderDelivery} from '../../shared/models/order-delivery.class';
import {NotificationService} from '../../shared/services/notification-service/notification.service';
import {OrderManagementService} from '../../shared/services/order-service/order-management.service';
import {BulkSelect} from './bulk-select';
import {AccountNotificationService} from '../../shared/services/sse/observers/account-notification.service';
import {OrderListItem} from '../../shared/models/order-list-item.class';
import {CsvDownload} from '../../shared/helpers/csv-download';

@Injectable({
    providedIn: 'root'
})
export class OrderListService {

    public store = new Store<{
        isLoading: boolean,
        orders: OrderListItem[],
        search: string,
        paginationSkip: number,
        paginationTake: number,
        paginationTotal: number,
        filters: Selected,
        bulkSelect: BulkSelect
    }>({
        isLoading: false,
        orders: [],
        search: undefined,
        paginationSkip: 0,
        paginationTake: 10,
        paginationTotal: undefined,
        filters: new Selected(),
        bulkSelect: new BulkSelect()
    });

    constructor(
        private seatedApi: SeatedApiService,
        private dialogService: DialogService,
        private exportService: ExportService,
        private downloadManager: DownloadManagerService,
        private orderManagementService: OrderManagementService,
        private notificationService: NotificationService,
        private accountNotificationService: AccountNotificationService) {
        this.initialize();
    }

    public downloadExport(): void {
        const filters = this.store.snapshot.filters;
        const search = this.store.snapshot.search;

        let httpParams = this.getFiltersAsHttpParams(filters);

        if (search) {
            httpParams = httpParams.set('order[search]', search);
        }

        this.dialogService.createDialogComponent(OrderListDownloadDialogComponent, {
            cancelText: 'Cancel',
            confirmText: 'Add'
        }, 'download-order');

        this.dialogService.emitDataSubject.subscribe(result => {
            if (!result.cancelled) {
                if (result.data.extraData.personalisationLink) {
                    httpParams = httpParams.set('export[personalisationLink]', result.data.extraData.personalisationLink);
                }

                if (result.data.extraData.directCheckoutLinkShopId) {
                    httpParams = httpParams.set('export[directCheckoutLinkShopId]', result.data.extraData.directCheckoutLinkShopId);
                }

                this.exportService.getOrderReport(
                    httpParams
                        .set('skip', '0')
                        .set('take', '0')
                ).subscribe(response => {
                    const startAt = httpParams.has('order[startAt]') ? httpParams.get('order[startAt]') : 'all';
                    const endAt = httpParams.has('order[endAt]') ? httpParams.get('order[endAt]') : 'all';

                    this.downloadManager.downloadData(
                        new OrderReportDownload(this.exportService)
                            .setFilters(httpParams)
                            .setTotal(parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10))
                            .setFileName(`Orders ${startAt} - ${endAt}`)
                            .setExtraFields(result.data.extraData)
                    );
                });
            }
        });
    }

    public postOrderDelivery(method: string, order: Order, customer: Customer): void {
        this.seatedApi.post<OrderDelivery>(new OrderDelivery(), {orderId: order.id}, {
            orderId: order.id,
            method: 'EMAIL',
            phoneNumber: order.customer.phoneNumber,
            email: order.customer.email,
            firstName: order.customer.firstName,
            lastName: order.customer.lastName
        }).subscribe(() => {
            this.notificationService.showTranslatedNotification('success', 'email', 'sent');
        });
    }

    public openOrderInBasket(orderId: string): void {
        this.orderManagementService.getOrder(orderId);
    }

    public bulkExpireDateOrdersPreview(bulkSelect: BulkSelect, options: object): Observable<any> {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds,
            preview: true,
            options
        };

        return this.seatedApi.postCustom(`v1.0/order/bulk/expire-date`, body, httpParams);
    }

    public bulkExpireDateOrders(bulkSelect: BulkSelect, options: object): void {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds,
            options
        };

        this.seatedApi.postCustom(`v1.0/order/bulk/expire-date`, body, httpParams).subscribe((bulkActionResult: any) => {
            this.store.setState({ bulkSelect: new BulkSelect() });
            this.store.refresh();

            this.notificationService.showTranslatedNotification('success', 'expire_date', 'updated');
        });
    }

    public bulkCancelOrders(bulkSelect: BulkSelect): void {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds
        };

        this.seatedApi.postCustom(`v1.0/order/bulk/cancel`, body, httpParams).subscribe((bulkActionResult: any) => {
            this.store.setState({ bulkSelect: new BulkSelect() });
            this.store.refresh();

            this.notificationService.showTranslatedNotification('success', 'cancel', 'started');
        });
    }

    public bulkDistributeOrdersOrderConfirmationPreview(bulkSelect: BulkSelect): Observable<any> {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds,
            preview: true
        };

        return this.seatedApi.postCustom(`v1.0/order/bulk/order-delivery/order-confirmation`, body, httpParams);
    }

    public bulkDistributeOrdersOrderConfirmation(bulkSelect: BulkSelect): void {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds
        };

        this.seatedApi.postCustom(`v1.0/order/bulk/order-delivery/order-confirmation`, body, httpParams).subscribe((bulkActionResult: any) => {
            this.store.setState({ bulkSelect: new BulkSelect() });
            this.store.refresh();

            this.notificationService.showTranslatedNotification('success', 'distribution', 'started');
        });
    }

    public bulkDistributeOrdersGuestManagerPreview(bulkSelect: BulkSelect): Observable<any> {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds,
            preview: true
        };

        return this.seatedApi.postCustom(`v1.0/order/bulk/order-delivery/guest-manager`, body, httpParams);
    }

    public bulkDistributeOrdersGuestManager(bulkSelect: BulkSelect): void {
        let httpParams = this.getFiltersAsHttpParams(this.store.snapshot.filters);

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('order[search]', this.store.snapshot.search);
        }

        const body = {
            method: bulkSelect.method,
            orderIds: bulkSelect.selectedIds
        };

        this.seatedApi.postCustom(`v1.0/order/bulk/order-delivery/guest-manager`, body, httpParams).subscribe((bulkActionResult: any) => {
            this.store.setState({ bulkSelect: new BulkSelect() });
            this.store.refresh();

            this.notificationService.showTranslatedNotification('success', 'distribution', 'started');
        });
    }

    private initialize(): void {
        combineLatest([
            this.store.select('search'),
            this.store.select('paginationSkip'),
            this.store.select('paginationTake'),
            this.store.select('filters'),
            this.store.refresh$
        ]).pipe(
            tap(() => this.store.setState({
                isLoading: true
            })),
            debounceTime(300),
            switchMap(([search, skip, take, filters]) => {
                let httpParams = this.getFiltersAsHttpParams(filters);
                httpParams = httpParams.set('skip', skip);
                httpParams = httpParams.set('take', take);
                httpParams = httpParams.set('depth', 2);

                if (search) {
                    httpParams = httpParams.set('order[search]', search);
                }

                return this.seatedApi.getManyHttpResponse<OrderListItem>(
                    new OrderListItem(),
                    {},
                    httpParams
                );
            })
        ).subscribe((response: HttpResponse<OrderListItem[]>) => {
            this.store.setState({
                orders: response.body,
                isLoading: false,
                paginationSkip: parseInt(response.headers.get('X-CM-PAGINATION-SKIP'), 10),
                paginationTake: parseInt(response.headers.get('X-CM-PAGINATION-TAKE'), 10),
                paginationTotal: parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10),
            });
        });
    }

    private getFiltersAsHttpParams(filters: Selected): HttpParams {
        let httpParams = new HttpParams();

        if (filters.status.length > 0) {
            httpParams = httpParams.set('order[status]', filters.status.join(','));
        }

        if (filters.eventIds.length > 0) {
            httpParams = httpParams.set('order[eventIds]', filters.eventIds.join(','));
        }

        if (filters.subscriptionTypeIds.length > 0) {
            httpParams = httpParams.set('order[subscriptionTypeIds]', filters.subscriptionTypeIds.join(','));
        }

        if (filters.venueSectionIds.length > 0) {
            httpParams = httpParams.set('order[venueSectionIds]', filters.venueSectionIds.join(','));
        }

        if (filters.shopIds.length > 0) {
            httpParams = httpParams.set('order[shopIds]', filters.shopIds.join(','));
        }

        if (filters.campaignIds.length > 0) {
            httpParams = httpParams.set('order[campaignIds]', filters.campaignIds.join(','));
        }

        if (filters.discountCampaignIds.length > 0) {
            httpParams = httpParams.set('order[discountCampaignIds]', filters.discountCampaignIds.join(','));
        }

        if (filters.customerTagIds.length > 0) {
            httpParams = httpParams.set('order[customerTagIds]', filters.customerTagIds.join(','));
        }

        if (filters.orderTagIds.length > 0) {
            httpParams = httpParams.set('order[orderTagIds]', filters.orderTagIds.join(','));
        }

        if (filters.paymentMethodIds.length > 0) {
            httpParams = httpParams.set('order[paymentMethodIds]', filters.paymentMethodIds.join(','));
        }

        const importIds = filters.importIds;
        if (importIds.length > 0) {
            httpParams = httpParams.set('order[importIds]', importIds.join(','));
        }

        if (filters.eventCategoryIds.length > 0) {
            httpParams = httpParams.set('order[eventCategoryIds]', filters.eventCategoryIds.join(','));
        }

        if (filters.dateRange.startAt) {
            httpParams = httpParams.set('order[startAt]', moment(filters.dateRange.startAt).toISOString());
        }

        if (filters.dateRange.endAt) {
            httpParams = httpParams.set('order[endAt]', moment(filters.dateRange.endAt).toISOString());
        }

        if (filters.expireDateRange.startAt) {
            httpParams = httpParams.set('order[expireAt][after]', moment(filters.expireDateRange.startAt).toISOString());
        }

        if (filters.expireDateRange.endAt) {
            httpParams = httpParams.set('order[expireAt][before]', moment(filters.expireDateRange.endAt).toISOString());
        }

        return httpParams;
    }

    public downloadInvalidBulkActionResult(invalidBulkActionResult: any[]): void {
        const csvDownloader = new CsvDownload();
        csvDownloader.downloadArrayAsCsv(invalidBulkActionResult);
    }
}
