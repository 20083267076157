import {Injectable} from '@angular/core';
import {AnalyticsDashboardItem} from '../../models/analytics-dashboard-item.class';
import {BehaviorSubject} from 'rxjs';

@Injectable({
                providedIn: 'root'
            })
export class AnalyticsDashboardEditorService {
    editAnalyticsDashboardItem: AnalyticsDashboardItem;
    editAnalyticsDashboardItemSubject = new BehaviorSubject<AnalyticsDashboardItem>(null);

    constructor() {
    }

    setEditAnalyticsDashboardItem(editAnalyticsDashboardItem: AnalyticsDashboardItem) {
        this.editAnalyticsDashboardItem = editAnalyticsDashboardItem;
        this.editAnalyticsDashboardItemSubject.next(this.editAnalyticsDashboardItem);
    }
}
