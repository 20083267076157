export const isOfType: (element: HTMLElement, tagNames: string[]) => boolean = (element: HTMLElement, tagNames: string[]): boolean => {
    return tagNames.indexOf(element.tagName.toLowerCase()) !== -1;
};

export const hasParentOfType: (element: HTMLElement, parentTagName: string[]) => boolean = (element: HTMLElement, parentTagNames: string[]): boolean => {
    let parent: any = element.parentNode;

    while (parent !== null) {
        if (parent.tagName && parentTagNames.indexOf(parent.tagName.toLowerCase()) !== -1) {
            return true;
        }

        parent = parent.parentNode;
    }

    return false;
};

export const hasIdOrParentWithId: (element: HTMLElement, searchId: string) => boolean = (element: HTMLElement, searchId: string): boolean => {
    if (element.id === searchId) {
        return true;
    }

    let parent: any = element.parentNode;
    while (parent !== null) {
        if (parent.id && searchId === parent.id) {
            return true;
        }

        parent = parent.parentNode;
    }

    return false;
};
