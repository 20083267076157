import {ModelBase} from '../abstract/model-base';

export class OrderDetailsRefund extends ModelBase {
    public amount: string;
    public status: string;
    public refundedAt: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsRefund {
        const model = new OrderDetailsRefund();

        model.amount = data.amount;
        model.status = data.status;
        model.refundedAt = data.refundedAt;

        return model;
    }
}
