<cm-dialog [id]="id">
    <h3 slot="header" class="dialog-title">
        {{printer.name}}
    </h3>

    <div slot="body">
        <div class="row form-group">
            <div class="col-12">
                <div class="form-row">
                    <div class="form-input">
                        <input type="text" class="form-control" [(ngModel)]="printer.name">
                        <label>{{('General.Name' | trans)}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-input">
                        <cm-select [attr.value]="printer.id" (cm-option-clicked)="printer.id = $event.detail.value" data-label="{{('Printer_List.Printer' | trans)}}">
                            <ng-container *ngFor="let printer of printers">
                                <cm-option slot="option" value="{{printer.id}}" data-display="{{printer.id}}"></cm-option>
                            </ng-container>
                        </cm-select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-check m-l-16">
                        <label>
                            <input type="checkbox" [(ngModel)]="printer.scaleContent">
                            <span class="label">{{('Printer_List.Scale' | trans)}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-row">
                    <div class="form-input">
                        <cm-select value="{{printer.type}}" (cm-option-clicked)="printer.type = $event.detail.value" data-label="{{('Printer_List.Type' | trans)}}">
                            <ng-container *ngFor="let printFormat of printFormats">
                                <cm-option slot="option" value="{{printFormat.type}}" data-display="{{printFormat.name}}"></cm-option>
                            </ng-container>
                        </cm-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-input">
                        <cm-select value="{{printer.orientation}}" (cm-option-clicked)="printer.orientation = $event.detail.value" data-label="{{('Printer_List.Orientation' | trans)}}">
                            <ng-container *ngFor="let orientation of orientations">
                                <cm-option slot="option" value="{{orientation}}" data-display="{{orientation}}"></cm-option>
                            </ng-container>
                        </cm-select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-row">
                    <div class="form-input">
                        <cm-select value="{{printer.rotation}}" (cm-option-clicked)="printer.rotation = $event.detail.value" data-label="{{('Printer_List.Tilt' | trans)}}">
                            <ng-container *ngFor="let rotation of rotations">
                                <cm-option slot="option" value="{{rotation}}" data-display="{{rotation}}"></cm-option>
                            </ng-container>
                        </cm-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-input">
                        <cm-select value="{{printer.color}}" (cm-option-clicked)="printer.color = $event.detail.value" data-label="{{('Printer_List.Color' | trans)}}">
                            <ng-container *ngFor="let color of colors">
                                <cm-option slot="option" value="{{color}}" data-display="{{color}}"></cm-option>
                            </ng-container>
                        </cm-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('General.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('General.Save' | trans)}}</button>
    </div>
</cm-dialog>
