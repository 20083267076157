import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EventCategory} from '../../models/event-category.class';
import {EventCategoryService} from './event-category.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class EventCategoryManagementService {
    eventCategoriesSubject: BehaviorSubject<EventCategory[]>;

    constructor(private eventCategoryService: EventCategoryService) {
        this.eventCategoriesSubject = new BehaviorSubject([]);
    }

    getEventCategories() {
        this.eventCategoryService.getEventCategories(new HttpParams().set('take', -1).set('depth', '1')).subscribe(response => {
            this.eventCategoriesSubject.next(response);
        });
    }
}
