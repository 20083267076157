<div class="page-header">
    <div class="row page-actions">
        <div class="col-12 col-sm-8">
            <h3 class="has-subtitle">{{analyticsDashboard?.name}}</h3>
            <ng-container *ngIf="analyticsDashboard?.startAt && analyticsDashboard?.endAt">
                {{analyticsDashboard?.startAt}} - {{analyticsDashboard?.endAt}}
            </ng-container>
            <ng-container *ngIf="!analyticsDashboard?.startAt && analyticsDashboard?.endAt">
                {{('General.All' | trans)}} -  {{analyticsDashboard?.endAt}}
            </ng-container>
            <ng-container *ngIf="analyticsDashboard?.startAt &&!analyticsDashboard?.endAt">
                {{analyticsDashboard?.startAt}} - {{('General.All' | trans)}}
            </ng-container>
            <ng-container *ngIf="!analyticsDashboard?.startAt && !analyticsDashboard?.endAt">
                {{('General.All' | trans)}}
            </ng-container>
        </div>
        <div class="col-12 col-sm-4 text-right">
            <button class="btn btn-primary-icon" (click)="toggleVenueSectionTable = !toggleVenueSectionTable">
                <span class="cm-icon cm-icon-align-justify" aria-hidden="true"></span>
            </button>
            <button class="btn btn-primary-icon" (click)="filter.open()">
                <span class="cm-icon cm-icon-filter" aria-hidden="true"></span>
            </button>
            <button class="btn btn-primary-icon" (click)="editMode = !editMode">
                <span class="cm-icon cm-icon-edit" aria-hidden="true"></span>
            </button>
        </div>
    </div>

    <ng-container *ngIf="toggleVenueSectionTable">
        <div class="row m-t-16">
            <div class="col-12">
                <app-event-analytics-venue-section-table></app-event-analytics-venue-section-table>
            </div>
        </div>
    </ng-container>

    <div class="row m-t-16" *ngIf="editMode">
        <div class="col-12 text-right toolbar">
            <button class="btn btn-secondary-icon-text" (click)="addItem()">
                <span aria-hidden="true" class="cm-icon cm-icon-add"></span>
                <span>{{('General.Add' | trans)}}</span>
            </button>
            <button class="btn btn-secondary-icon-text" (click)="save()">
                <span aria-hidden="true" class="cm-icon cm-icon-save"></span>
                <span>{{('General.Save' | trans)}}</span>
            </button>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <gridster [options]="dashboardOptions" class="grid">
            <ng-container *ngFor="let analyticsDashboardItem of analyticsDashboard?.dashboardItems">
                <app-analytics-dashboard-item
                    [analyticsDashboard]="analyticsDashboard"
                    [analyticsDashboardItem]="analyticsDashboardItem"
                    [updatedAt]="analyticsDashboardItem.updatedAt"
                    [editMode]="editMode">
                </app-analytics-dashboard-item>
            </ng-container>
        </gridster>
    </div>
</div>

<cm-filter data-title="Filters" #filter>
    <div class="form-group">
        <div class="row text-center">
            <div class="col-12">
                <button class="btn btn-secondary"
                        (click)="setRange('-1day', 'today')">{{('Analytics_Dashboard.Range_Today' | trans)}}</button>
            </div>
            <div class="col-12">
                <button class="btn btn-secondary"
                        (click)="setRange('-2day', '-1day')">{{('Analytics_Dashboard.Range_Yesterday' | trans)}}</button>
            </div>
            <div class="col-12">
                <button class="btn btn-secondary"
                        (click)="setRange('-7days', 'today')">{{('Analytics_Dashboard.Range_Week' | trans)}}</button>
            </div>
            <div class="col-12">
                <button class="btn btn-secondary"
                        (click)="setRange('-30days', 'today')">{{('Analytics_Dashboard.Range_Month' | trans)}}</button>
            </div>
            <div class="col-12">
                <button class="btn btn-secondary"
                        (click)="setRange(null, null)">{{('Analytics_Dashboard.Range_All' | trans)}}</button>
            </div>
        </div>
    </div>
</cm-filter>

<app-analytics-dashboard-item-editor
    [analyticsDashboard]="analyticsDashboard">
</app-analytics-dashboard-item-editor>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>
