import {Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {CustomField} from '../models/custom-field';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getCustomField(customFieldId: string, params?: HttpParams): Observable<HttpResponse<CustomField>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<CustomField>(this.baseUrl + `/v1.0/customfield/${customFieldId}`, {
            ...config,
            observe: 'response'
        });
    }

    getCustomFields(params?: HttpParams): Observable<HttpResponse<CustomField[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<CustomField[]>(this.baseUrl + `/v1.0/customfield`, {...config, observe: 'response'});
    }

    postCustomField(customField: CustomField, params?: HttpParams): Observable<CustomField> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<CustomField>(this.baseUrl + `/v1.0/customfield`, customField, config);
    }

    updateCustomField(customField: CustomField, params?: HttpParams): Observable<CustomField> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<CustomField>(this.baseUrl + `/v1.0/customfield/${customField.id}`, customField, config);
    }
}
