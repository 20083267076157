export enum OrderDeliveryMethod {
    NONE = 'NONE',
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    PRINT_A4 = 'PRINT_A4',
    PRINT_A4_SPLIT = 'PRINT_A4_SPLIT',
    PRINT_BOCA = 'PRINT_BOCA',
    PRINT_PVC_CARD = 'PRINT_PVC_CARD',
    DOWNLOAD_PDF = 'DOWNLOAD_PDF',
    GUEST_MANAGER = 'GUEST_MANAGER',
    POST = 'POST',
    PICKUP = 'PICKUP'
}
