import {ModelBase} from '../abstract/model-base';

export class OrderDetailsCustomerTag extends ModelBase {
    public id: string;
    public name: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsCustomerTag {
        const model = new OrderDetailsCustomerTag();

        model.id = data.id;
        model.name = data.name;

        return model;
    }
}
