import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cm-ticketing-pvccard-default-2',
    templateUrl: './ticket-template-pvccard-default-2.component.html',
    styleUrls: ['./ticket-template-pvccard-default-2.component.scss', '../../shared/shared-presets.scss']
})
export class TicketTemplatePvccardDefault2Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
