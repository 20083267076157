import {ModelBase} from '../abstract/model-base';

export class OrderDetailsDiscounts extends ModelBase {
    public name: string;
    public code: string;
    public amount: number;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsDiscounts {
        const model = new OrderDetailsDiscounts();

        model.name = data.name;
        model.code = data.code;
        model.amount = data.amount;

        return model;
    }
}
