import {NotificationHandler} from './notification-handler';
import {Notification} from '../../models/notification.class';
import {BehaviorSubject} from 'rxjs';

export class CopyVenueNotificationHandler implements NotificationHandler {

    private notificationStream: BehaviorSubject<Notification[]>;

    constructor(notificationStream: BehaviorSubject<Notification[]>) {
        this.notificationStream = notificationStream;
    }

    public handle(notification: Notification): void {
        this.notificationStream.next([notification]);
    }
}
