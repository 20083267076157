import {Component, Input} from '@angular/core';
import {UserManagementService} from '../services/user-management-service/user-management.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    selector: 'app-copy-button',
    templateUrl: './copy-button.component.html',
    styleUrl: './copy-button.component.scss'
})
export class CopyButtonComponent {
    @Input() copyText: string;
    @Input() employeeOnly = false;
    @Input() label: string;

    protected isCopyClicked = false;

    constructor(protected userManagementService: UserManagementService, private clipboard: Clipboard) {
    }

    copyToClipboard(): void {
        this.clipboard.copy(this.copyText);
        this.isCopyClicked = true;

        setTimeout(() => {
            this.isCopyClicked = false;
        }, 5000);
    }
}
