<cm-table [attr.data-name]="tableName" #cmTable>
    <cm-table-head>
        <cm-table-row>
            <ng-container *ngFor="let head of headers">
                <cm-ticketing-data-table-header-data [id]="head.id" [value]="head.value" [options]="head.options"
                                                     [checkBoxState]="headerCheckboxState"
                                                     (sortChangedEmitter)="sortChanged($event)" (stickColumnEmitter)="stickColumnCalled($event)"
                                                     (checkboxChangedEmitter)="selectAllClicked($event)"
                                                     (actionClickedEmitter)="headerActionClicked($event)">
                </cm-ticketing-data-table-header-data>
            </ng-container>
        </cm-table-row>
    </cm-table-head>
    <cm-table-body>
        <ng-container *ngFor="let row of rows">
            <cm-table-row (click)="rowClicked(row, $event)" [attr.data-selected]="row.selected ? row.selected : null">
                <ng-container *ngFor="let rowData of row.rowData">
                    <cm-ticketing-data-table-row-data (checkboxChangedEmitter)="rowCheckboxClicked($event, row.id)" (actionClickedEmitter)="rowActionClicked($event, row.id)"
                                                      [value]="rowData.value" [options]="rowData.options" [selected]="row.selected">
                    </cm-ticketing-data-table-row-data>
                </ng-container>
            </cm-table-row>
        </ng-container>
    </cm-table-body>
    <ng-container *ngIf="footer && footer.length > 1">
        <cm-table-foot>
            <cm-table-row>
                <ng-container *ngFor="let foot of footer">
                    <cm-ticketing-data-table-footer-data [value]="foot.value" [options]="foot.options" [selected]="selectedRowIds.length">
                    </cm-ticketing-data-table-footer-data>
                </ng-container>
            </cm-table-row>
        </cm-table-foot>
    </ng-container>
</cm-table>
