<ng-container *ngIf="customer$ | async as customer">
    <div class="page-content form-group">

        <div class="page-section">
            <div class="row space-between m-b-8">
                <div class="col-12 col-sm-3 d-flex align-items-center">
                    <span class="cm-icon cm-icon-filter m-l-8 m-r-8 m-b-8" aria-hidden="true"></span>
                    <app-search-filter-container [settings]="filterSettings"
                                                 [(selected)]="selectedFilters"
                                                 [filterOptions]="{includeNonePaymentMethod: true}"
                                                 (selectedChange)="onFilterChange($event)">
                    </app-search-filter-container>
                </div>
                <div class="col-12 col-sm-6 search-barcode">
                    <app-search [placeholderValue]="'General.Search_Barcode'" (requestUpdate)="onSearchChange($event)" [results]="eventTicketsTableTotal"></app-search>
                </div>
                <div class="col-12 col-sm-3 text-right">
                    <ng-container *ngIf="selectedEventTickets.length > 0">
                        <cm-context-menu>
                            <cm-option data-icon="reset"
                                       [attr.data-display]="('Relations.Generate_New_Barcode' | trans)"
                                       (cm-option-clicked)="renewTickets()">
                            </cm-option>

                            <cm-context-menu-line></cm-context-menu-line>

                            <cm-option data-icon="email"
                                       [attr.data-display]="('Relations.Email' | trans)"
                                       (cm-option-clicked)="toggle === 'EMAIL' ? toggle = 'NONE' : toggle = 'EMAIL'">
                            </cm-option>
                            <cm-option data-icon="message"
                                       [attr.data-display]="('Relations.SMS' | trans)"
                                       (cm-option-clicked)="toggle === 'SMS' ? toggle = 'NONE' : toggle = 'SMS'">
                            </cm-option>

                            <cm-context-menu-line></cm-context-menu-line>

                            <cm-option
                                class="cl-red"
                                data-icon="close"
                                [attr.data-display]="('Relations.Block' | trans)"
                                (cm-option-clicked)="blockTickets()">
                            </cm-option>
                            <cm-option
                                class="cl-red"
                                data-icon="close"
                                [attr.data-display]="('General.Cancel' | trans)"
                                (cm-option-clicked)="cancelTickets()">
                            </cm-option>
                        </cm-context-menu>
                    </ng-container>

                    <cm-context-menu>
                        <button class="btn btn-primary-icon" slot="icon" [disabled]="selectedEventTickets.length === 0">
                            <span class="cm-icon cm-icon-print" aria-hidden="true"></span>
                        </button>

                        <ng-container *ngFor="let printFormat of printFormats; let isLast = last">
                            <ng-container *ngIf="printFormat.printers.length > 0">
                                <cm-option
                                    [attr.data-display]="printFormat.name"
                                    disabled>
                                </cm-option>
                                <ng-container *ngFor="let printer of printFormat.printers">
                                    <cm-option
                                        [attr.data-display]="printer.name"
                                        (cm-option-clicked)="defaultPrinter = printer; setDefaultPrinter(); printTickets();">
                                    </cm-option>
                                </ng-container>
                                <ng-container *ngIf="!isLast">
                                    <cm-context-menu-line></cm-context-menu-line>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </cm-context-menu>

                    <button [disabled]="selectedEventTickets.length === 0"
                            [cm-tooltip]="('General.Download' | trans)"
                            (click)="sendTickets('DOWNLOAD_PDF')"
                            class="btn btn-primary-icon">
                        <span class="cm-icon cm-icon-download" aria-hidden="true"></span>
                    </button>
                </div>

                <ng-container *ngIf="toggle === 'PRINT'">
                    <div class="row">
                        <div class="col-12">
                            <div class="card m-t-16">
                                <div class="form-group">
                                    <div class="form-input">
                                        <select class="form-control country-picker"
                                                data-component="cm-select"
                                                (click)="$event.stopPropagation()"
                                                [(ngModel)]="defaultPrinter"
                                                [compareWith]="printerSelected"
                                                [selectedIndex]="1"
                                                (change)="setDefaultPrinter()">
                                            <ng-container *ngFor="let printFormat of printFormats">
                                                <ng-container *ngIf="printFormat.printers.length > 0">
                                                    <option value="null" disabled>{{printFormat.name}}</option>
                                                    <ng-container *ngFor="let printer of printFormat.printers">
                                                        <option [ngValue]="printer">
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{printer.name}}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                        <label>{{('Printer_List.Select_Printer' | trans)}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-2 text-right">
                                <button class="btn btn-primary m-t-24" [routerLink]="['/settings/printers']">
                                    {{('Printer_List.Settings' | trans)}}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="toggle === 'EMAIL' && selectedEventTickets.length > 0">
                    <div class="card m-t-16">
                        <div class="row form-group">
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="form-input m-r-16">
                                    <input type="text" class="form-control"
                                           placeholder="{{('Relations.First_Name' | trans)}}"
                                           [(ngModel)]="firstName">
                                    <label>{{('Relations.First_Name' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="form-input m-r-16">
                                    <input type="text" class="form-control"
                                           placeholder="{{('Relations.Last_Name' | trans)}}"
                                           [(ngModel)]="lastName">
                                    <label>{{('Relations.Last_Name' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="form-input m-r-16">
                                    <input type="email" class="form-control"
                                           placeholder="{{('Relations.Email' | trans)}}"
                                           [(ngModel)]="emailTo">
                                    <label>{{('Relations.Email' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <button class="btn btn-primary w-100 m-t-24 m-l-0"
                                        (click)="sendTickets('EMAIL')">{{('Relations.Send_Email' | trans)}}</button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="toggle === 'SMS' && selectedEventTickets.length > 0">
                    <div class="card m-t-16">
                        <div class="row form-group">
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="form-input m-r-16">
                                    <input type="text" class="form-control"
                                           placeholder="{{('Relations.First_Name' | trans)}}"
                                           [(ngModel)]="firstName">
                                    <label>{{('Relations.First_Name' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="form-input m-r-16">
                                    <input type="text" class="form-control"
                                           placeholder="{{('Relations.Last_Name' | trans)}}"
                                           [(ngModel)]="lastName">
                                    <label>{{('Relations.Last_Name' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="form-input m-r-16">
                                    <input class="form-control"
                                           placeholder="{{('Relations.Phone_Number' | trans)}}"
                                           [(ngModel)]="smsTo">
                                    <label>{{('Relations.Phone_Number' | trans)}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <button class="btn btn-primary w-100 m-t-24 m-l-0"
                                        (click)="sendTickets('SMS')">{{('Relations.Send_SMS' | trans)}}</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="form-group">
                <div class="datatable">
                    <div class="datatable-header">
                        <div class="datatable-header-item col-1">
                            <div class="form-check m-0">
                                <label>
                                    <input type="checkbox" (change)="updateEventTicketSelection()"
                                           [(ngModel)]="eventTicketsToggle">
                                    <span class="label"></span>
                                </label>
                            </div>
                        </div>
                        <div class="datatable-header-item col">{{('Relations.Ticket' | trans)}}</div>
                        <div class="datatable-header-item col-1"
                             *ngIf="showResell">{{('Relations.Resell' | trans)}}</div>
                        <div class="datatable-header-item col-2">{{('Relations.Barcode' | trans)}}</div>
                        <div class="datatable-header-item col-1 no-wrap">{{('Relations.Checked_In' | trans)}}</div>
                        <div class="datatable-header-item col-1"
                             *ngIf="showPersonalisation">{{('Relations.Personalisation' | trans)}}</div>
                        <div class="datatable-header-item col-1"
                             *ngIf="customer.guestManagerOrganisationId">{{('Relations.GM' | trans)}}</div>
                        <div class="datatable-header-item col-1"></div>
                    </div>

                    <ng-container *ngIf="eventTickets$ | async as eventTickets;">
                        <ng-container *ngFor="let eventTicket of eventTickets">
                            <div class="datatable-row" [class.fade-out]="eventTicketsTableLoading"
                                 [class.bg-error-color-light]="eventTicket.status === 'BLOCKED'"
                                 (click)="updateEventTicketSelection(eventTicket)">
                                <div class="datatable-row-item col-1">
                                    <div class="form-check m-0">
                                        <label>
                                            <input type="checkbox"
                                                   [disabled]="eventTicket.status === 'RESELL_SOLD'"
                                                   (change)="updateEventTicketSelection(eventTicket)"
                                                   [checked]="eventTicketInSelection(eventTicket)">
                                            <span class="label"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="datatable-row-item col reset-line-height">
                                    <div class="event-ticket-name">{{eventTicket.name}}</div>
                                    <ng-container *ngIf="getEventById(eventTicket.eventId) as event">
                                        <div class="event-name">{{ event.name }} ({{ event.startAt | date: 'mediumDate' }})</div>
                                    </ng-container>
                                </div>
                                <div class="datatable-row-item col-1" *ngIf="showResell">
                                    <ng-container *ngIf="eventTicket.status === 'RESELL_AVAILABLE'">
                                        <button class="btn btn-secondary-icon"
                                                [cm-tooltip]="'Resell.Available' | trans">
                                            <span class="cm-icon cm-icon-duplicate cl-warning-color"></span>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="eventTicket.status === 'RESELL_RESERVED'">
                                        <button class="btn btn-secondary-icon"
                                                [cm-tooltip]="'Resell.Reserved' | trans">
                                            <span class="cm-icon cm-icon-duplicate cl-warning-color"></span>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="eventTicket.status === 'RESELL_SOLD'">
                                        <button class="btn btn-secondary-icon"
                                                [cm-tooltip]="'Resell.Sold' | trans">
                                            <span class="cm-icon cm-icon-duplicate cl-success-color"></span>
                                        </button>
                                    </ng-container>
                                </div>
                                <div class="datatable-row-item col-2 cl-black-50 vertical-middle d-flex">
                                    <!-- Type icon -->
                                    <div *ngIf="eventTicket.subscriptionId && !eventTicket.isRenewed"
                                         class="cm-icon cm-icon-creditcard f-s-20 cl-selected-color barcode-icon"
                                         [cm-tooltip]="('Relations.Season_Ticket' | trans)">
                                    </div>
                                    <div *ngIf="!eventTicket.subscriptionId || eventTicket.isRenewed"
                                         class="cm-icon cm-icon-qr-code f-s-20 cl-selected-color barcode-icon"
                                         [cm-tooltip]="('Relations.Single_Ticket' | trans)">
                                    </div>

                                    <!-- State icon -->
                                    <div *ngIf="eventTicket.status === 'BLOCKED'"
                                         class="cm-icon cm-icon-lock-unlocked f-s-20 cl-selected-color barcode-icon"
                                         [cm-tooltip]="('General.Unblock' | trans)"
                                         (click)="unblockTicket(eventTicket)">
                                    </div>
                                    <div *ngIf="eventTicket.isRenewed"
                                         class="cm-icon cm-icon-reset f-s-20 cl-selected-color barcode-icon"
                                         [cm-tooltip]="('Relations.New_Barcode' | trans)"></div>
                                    <span class="no-wrap">{{eventTicket.barcode}}</span>
                                </div>
                                <div class="datatable-row-item col-1">
                                    <ng-container
                                        *ngIf="eventTicket.scanStatus && eventTicket.scanStatus.firstCheckInAt">
                                        <div [cm-tooltip]="getCheckInTooltip(eventTicket.scanStatus)">
                                        <span class="cm-icon cm-icon-check cl-success-color f-s-20"
                                              aria-hidden="true"></span>
                                            {{eventTicket.scanStatus.firstCheckInAt | date:'H:mm'}}
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="datatable-row-item col-1" *ngIf="showPersonalisation">
                                    <ng-container *ngIf="eventTicket.personalisationCustomerId">
                                <span class="cm-icon cm-icon-sync-true cl-black-50 vertical-middle m-r-8"
                                      aria-hidden="true"></span>
                                        <ng-container *ngIf="eventTicket.customerId === customer.id">
                                            <a class="vertical-top" target="_blank"
                                               [routerLink]="['/customers', eventTicket.personalisationCustomerId]">{{eventTicket.personalisationCustomer.getName()}}</a>
                                        </ng-container>
                                        <ng-container *ngIf="eventTicket.customerId !== customer.id">
                                            <a class="vertical-top" target="_blank"
                                               (click)="goToOrder(eventTicket)">Ontvanger</a>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="customer.guestManagerOrganisationId">
                                    <div class="datatable-row-item col-1">{{('Relations.' + (eventTicket.guestManager ? 'Yes' : 'No') | trans)}}</div>
                                </ng-container>
                                <div class="datatable-row-item col-1 d-flex">
                                    <div *ngIf="eventTicket.isExternalBarcode"
                                         class="cm-icon cm-icon-pop-out f-s-20 barcode-icon"
                                         [cm-tooltip]="('General.External' | trans)">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <mat-paginator [length]="eventTicketsTableTotal"
                               [pageIndex]="eventTicketsTableIndex"
                               [pageSize]="eventTicketsTablePageSize"
                               [pageSizeOptions]="[10, 25, 50, 100, 250]"
                               (page)="eventTicketsTableUpdate($event)">
                </mat-paginator>

            </div>
        </div>
    </div>

</ng-container>
