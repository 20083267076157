export class Printer {
    constructor(public id: string            = null,
                public name: string          = null,
                public type: string          = 'PRINT_A4',
                public size: any             = null,
                public orientation: string   = 'portrait',
                public scaleContent: boolean = false,
                public rotation: number      = 0,
                public color: string         = 'color') {
    }
}

export function mapPrinter(item: any): Printer {
    if (typeof item === 'string') {
        return new Printer(
            item,
            null,
            null,
            null,
            'portrait',
            false,
            0,
            'color'
        );
    }

    return new Printer(
        item.id,
        item.name,
        item.type,
        item.size === null ? {width: null, height: null} : item.size,
        item.orientation,
        item.scaleContent,
        item.rotation,
        item.color
    );
}
