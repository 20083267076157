<div class="header">
    <div class="row">
        <div class="col-12">
            <h1>{{ ('Move_Order.Header' | trans) }}</h1>
            <ng-container *ngIf="isSeatmap">
                <button class="btn btn-secondary-icon-text back-button" (click)="onBackToSelectVenueSection()">
                    <span class="cm-icon cm-icon-left" aria-hidden="true"></span>
                    <span>{{ ('Move_Order.Back' | trans) }}</span>
                </button>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <cm-inline-notification data-type="warning">
                        <span slot="icon"
                              class="cm-icon cm-icon-information-open"
                              aria-hidden="true">
                        </span>
            {{ 'Move_Seat_Tool.Barcode_Change_Warning' | trans }}
        </cm-inline-notification>
    </div>
</div>

<ng-container *ngIf="!isSeatmap">
    <div class="row heading">
        <div class="col-12">
            <h3 class="has-description">{{ ('Move_Order.Select_Tickets.Header' | trans) }}</h3>
            <div class="description">{{ ('Move_Order.Select_Tickets.Description' | trans) }}
            </div>
            <div class="buttons">
                <ng-container *ngIf="venueId">
                    <button class="btn btn-secondary m-r-8" (click)="selectAllOrderItemsInOrderItemGroup()">
                        {{ ('Move_Order.Select_Tickets.Select_All_In_Venue' | trans | tags: [
                        {key: 'venueName', value: 'group'}
                    ]) }}
                    </button>
                </ng-container>
                <button class="btn btn-secondary" (click)="onClearSelectedOrderItems()">
                    {{ ('Move_Order.Select_Tickets.Clear_All' | trans) }}
                </button>
            </div>
        </div>
    </div>

    <div class="row selected-order-items" [class.venue-selected]="!!venueId">
        <div class="col-12">
            <ng-container *ngFor="let orderItem of orderItems$ | async">
                <ng-container *ngTemplateOutlet="orderItemRowTemplate;context:{orderItem:orderItem}"></ng-container>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="venue$ | async as venue">
        <div class="row heading">
            <div class="col-12">
                <h3 class="has-description">{{ ('Move_Order.Select_Venue_Section.Header' | trans) }}</h3>
                <div class="description">{{ ('Move_Order.Select_Venue_Section.Description' | trans) }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-input-special">
                            <label for="autocomplete">{{ venue.name }}</label>
                            <input type="text" id="autocomplete" class="form-control"
                                   (input)="onVenueSectionFilterChange($event)">
                            <span class="input-left icon">
                                <span class="cm-icon cm-icon-search" aria-hidden="true"></span>
                            </span>

                            <cm-autocomplete data-linked-input="input#autocomplete"
                                             (cm-option-clicked)="onVenueSectionSelect($event.detail.value)"
                                             [value]="venueSection?.id">
                                <ng-container
                                    *ngFor="let ring of venue.sections | venueSectionFilter:venueSectionFilter">
                                    <cm-option [attr.data-display]="ring.name" slot="option" disabled></cm-option>
                                    <ng-container *ngFor="let section of ring.sections">
                                        <cm-option
                                            [attr.data-display]="section.name + ' (' + section.available + ' available)'"
                                            [attr.value]="section.id"
                                            slot="option"></cm-option>
                                    </ng-container>
                                </ng-container>
                            </cm-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="activeOrderItems$ | async as orderItems">
    <ng-container *ngIf="orderItems.length && venueSection && !isSeatmap">
        <ng-container *ngIf="venueSection.isSeated">
            <div class="footer">
                <div class="row heading">
                    <div class="col-12">
                        <h3 class="has-description">{{ ('Move_Order.Select_Seats.Header' | trans) }}</h3>
                        <div class="description">{{ ('Move_Order.Select_Seats.Description' | trans) }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 summary">
                        {{ ('Move_Order.Footer.Summary' | trans | tags: [
                        {key: 'orderItemAmount', value: orderItems.length},
                        {key: 'venueSectionName', value: venueSection.name}
                    ]) }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-secondary" (click)="onConfirmBestSeatAvailable()">
                            {{ ('Move_Order.Footer.Best_Seat_Available_Button' | trans) }}
                        </button>
                        <button class="btn btn-secondary" (click)="onSelectSeatmap()">
                            {{ ('Move_Order.Footer.Seatmap_Button' | trans) }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!venueSection.isSeated">
            <div class="footer">
                <div class="row">
                    <div class="col-12 summary">
                        {{ ('Move_Order.Footer.Summary' | trans | tags: [
                        {key: 'orderItemAmount', value: orderItems.length},
                        {key: 'venueSectionName', value: venueSection.name}
                    ]) }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="onConfirmGeneralAdmission()">
                            {{ ('Move_Order.Footer.General_Admission_Button' | trans) }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isSeatmap">
        <div class="row heading">
            <div class="col-12">
                <h3 class="has-description">{{ ('Move_Order.Seatmap.Header' | trans) }}</h3>
                <div class="description">{{ ('Move_Order.Seatmap.Description' | trans) }}</div>
            </div>
        </div>

        <div class="row selected-order-items">
            <div class="col-12">
                <ng-container *ngFor="let orderItem of orderItems">
                    <ng-container *ngTemplateOutlet="orderItemRowTemplate;context:{orderItem:orderItem}"></ng-container>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-l-16">
                <button class="btn btn-primary" (click)="onConfirmSeatmap(orderItems)">
                    {{('General.Confirm' | trans)}}
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #orderItemRowTemplate let-orderItem="orderItem">
    <div class="row order-item"
         [class.disabled]="orderItem.isDisabled"
         [class.done]="orderItem.toVenueSectionSeat"
         [class.summary]="isSeatmap"
         [class.selected]="orderItem.isSelected && !isSeatmap"
         (click)="(!orderItem.isDisabled && !isSeatmap) && setSelectedOrderItem(orderItem, orderItemRow)"
         #orderItemRow>
        <div class="col-12">
            <div class="row">
                <div class="col-1">
                    <span class="cm-icon cm-icon-checkbox-unchecked checkbox-unchecked" aria-hidden="true"
                          [cm-tooltip]="'Move_Order.Tooltip.Order_Item_Not_Selected' | trans"></span>
                    <span class="cm-icon cm-icon-checkbox-checked checkbox-checked" aria-hidden="true"
                          [cm-tooltip]="'Move_Order.Tooltip.Order_Item_Selected' | trans"></span>
                    <span class="cm-icon cm-icon-warning-open done-unchecked" aria-hidden="true"
                          [cm-tooltip]="'Move_Order.Tooltip.Order_Item_Not_Done' | trans"></span>
                    <span class="cm-icon cm-icon-verification-open done-checked" aria-hidden="true"
                          [cm-tooltip]="'Move_Order.Tooltip.Order_Item_Done' | trans"></span>
                </div>
                <div class="col-10">
                    <div class="title">{{ orderItem.name }} - {{ orderItem.ticketType }}</div>
                    <div class="subtitle">
                        <ng-container *ngIf="!orderItem.fromSectionIsSeated">
                            {{ orderItem.fromVenueSection }}
                        </ng-container>
                        <ng-container *ngIf="orderItem.fromSectionIsSeated">
                            {{ orderItem.fromVenueSection }}, {{ 'General.Row' | trans }} {{ orderItem.fromRow }},
                            {{ 'General.Seat' | trans }} {{ orderItem.fromSeat }}
                        </ng-container>
                    </div>
                    <div class="subtitle">
                        <div class="tag">
                            <span class="cm-icon cm-icon-user-open small" aria-hidden="true"></span>
                            {{ orderItem.customer }}
                        </div>
                        <div class="tag">
                            <span class="cm-icon cm-icon-clean small" aria-hidden="true"></span>
                            {{ orderItem.batchId }}
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <ng-container *ngIf="orderItem.toVenueSectionSeat">
                        <button class="btn btn-secondary-icon reset-order-item"
                                (click)="onResetToVenueSectionSeat(orderItem.id)">
                            <span class="cm-icon cm-icon-reset" aria-hidden="true"></span>
                        </button>
                    </ng-container>
                </div>
            </div>


            <ng-container *ngIf="orderItem.toVenueSectionSeat">
                <div class="row summary-row">
                    <div class="col-5 offset-1 summary-from">
                        <div class="row">
                            <div class="col-12 venue-section">{{ orderItem.fromVenueSection }}</div>
                        </div>
                        <ng-container *ngIf="orderItem.fromSeat">
                            <div class="row">
                                <div class="col-6">Row {{ orderItem.fromRow }}</div>
                                <div class="col-6">Seat {{ orderItem.fromSeat }}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-1">
                        <span class="cm-icon cm-icon-right" aria-hidden="true"></span>
                    </div>
                    <div class="col-5 summary-to">
                        <div class="row">
                            <div class="col-12 venue-section">{{ orderItem.toVenueSection.name }}</div>
                        </div>
                        <div class="row">
                            <div class="col-6">Row {{ orderItem.toVenueSectionSeat.row }}</div>
                            <div class="col-6">Seat {{ orderItem.toVenueSectionSeat.seat }}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
