import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapOrganisation, Organisation} from '../../models/guest-manager/organisation.class';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getOrganisation(organisationId: string, params?: HttpParams): Observable<Organisation> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Organisation>(this.baseUrl + `/v1.0/organisation/${organisationId}`, config).pipe(
            map(item => {
                return mapOrganisation(item);
            })
        );
    }

    getOrganisations(params?: HttpParams): Observable<Organisation[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Organisation[]>(this.baseUrl + `/v1.0/organisation`, config).pipe(
            map((items: Organisation[]) => {
                return items.map((item: Organisation) => {
                    return mapOrganisation(item);
                });
            })
        );
    }
}
