import {
    Component,
    EventEmitter,
    Output,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    Injectable,
    Inject,
    DEFAULT_CURRENCY_CODE
} from '@angular/core';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {TranslationService} from '../../services/translation.service';
import {TicketTemplate} from '../../models/ticket-template';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'cm-ticketing-ticket-template-list',
    templateUrl: './ticket-template-list.component.html',
    styleUrls: ['./ticket-template-list.component.css']
})

@Injectable({
    providedIn: 'root'
})

export class TicketTemplateListComponent implements OnInit {
    @Input() currentLang = 'nl';
    @Input() ticketTemplateTypes = [];
    @Output()
    ticketTemplateEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    ticketTemplateAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    ticketTemplateDelete: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('ticketTemplateTypeModal') ticketTemplateTypeModal: ElementRef;

    public ticketTemplates;
    public ticketTemplates$;
    public ticketTemplatePresets;
    public ticketTemplatePresets$;
    public ticketTemplatesSizedPerTwelve;
    public isFetchingRecords: boolean;
    public selectedTicketTemplateType;
    public availablePresetPerSelectedTicketTemplateType;

    private showVisible: boolean;
    private ticketTemplatePresetsSubscription: any;
    private keyWordToSearch: string = null;
    private temporarySearchedTemplates = null;
    private sliceCount = 1;

    constructor(private orderDistributionService: OrderDistributionService,
                private domSanitizer: DomSanitizer, private translationService: TranslationService,
                @Inject(DEFAULT_CURRENCY_CODE) public currencyId: string) {
    }

    ngOnInit(): void {
        this.translationService.setLanguage(this.currentLang);
        this.showVisible = true;
        this.isFetchingRecords = true;
        this.getTicketTemplates();
        if (this.ticketTemplateTypes?.length > 0) {
            this.selectedTicketTemplateType = this.ticketTemplateTypes[0];
        }

        this.getTicketTemplatePresets();
    }

    openTypeModal(): void {
        this.ticketTemplateTypeModal.nativeElement.open();
        this.availablePresetPerSelectedTicketTemplateType = this.ticketTemplates.filter(template => template.is_default === true
            && template.ticket_template_type_id === this.selectedTicketTemplateType);
    }

    onTicketTemplateEditClick(ticketTemplate): void {
        this.ticketTemplateEdit.emit(ticketTemplate);
    }

    onTicketTemplateAddClick(presetUuid): void {
        const type = this.selectedTicketTemplateType;
        this.ticketTemplateAdd.emit({type, presetUuid});
        this.ticketTemplateTypeModal.nativeElement.close();
    }

    onDeleteTicketTemplateClick(ticketTemplate): void {
        this.orderDistributionService.deleteTicketTemplate(ticketTemplate).subscribe(() => {
            this.ticketTemplateDelete.emit(ticketTemplate);
            this.getTicketTemplates();
            (window as any).AddSuccessNotification('Archived successful');
        });
    }

    onActivateTicketTemplateClick(ticketTemplate): void {
        this.orderDistributionService.enableTicketTemplate(ticketTemplate).subscribe(() => {
            this.getTicketTemplates();
            (window as any).AddSuccessNotification('Activation successful');
        });
    }

    getTicketTemplates(): void {
        this.isFetchingRecords = true;
        this.ticketTemplates$ = this.orderDistributionService.getTicketTemplates(this.showVisible);
        this.ticketTemplates$.subscribe((ticketTemplates: TicketTemplate[]) => {
            this.ticketTemplates = ticketTemplates.sort((templateA: TicketTemplate, templateB: TicketTemplate) => {
                if (templateB.is_default) {
                    return 1;
                } else {
                    return new Date(templateB.created_at).getTime() - new Date(templateA.created_at).getTime();
                }
            });

            this.ticketTemplates = this.ticketTemplates.filter(item => this.ticketTemplateTypes.includes(item.ticket_template_type_id));
            this.sliceTicketTemplates(this.ticketTemplates);

            this.ticketTemplates.forEach(template => {
                template.example =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(this.orderDistributionService.getOrderExample(template.uuid));
                template.url = this.orderDistributionService.getOrderExample(template.uuid);
            });

            window.onscroll = (ev) => {
                if ((window.innerHeight + window.scrollY + 32) >= document.body.offsetHeight) {
                    if (this.ticketTemplates.length < this.ticketTemplatesSizedPerTwelve.length + 12) {
                        this.ticketTemplatesSizedPerTwelve = this.ticketTemplates.slice(0, this.ticketTemplates.length);
                    } else {
                        this.ticketTemplatesSizedPerTwelve = this.ticketTemplates.slice(0, this.ticketTemplatesSizedPerTwelve.length + 12);
                    }

                    if (this.keyWordToSearch) {
                        this.searchTemplates(this.keyWordToSearch);
                    }
                }
            };
            this.isFetchingRecords = false;
        });
    }

    public loadedIframe($event: Event): void {
        const target = ($event.target as any);
        setTimeout(() => {
            target.parentElement.classList.remove('skeleton-loading');
            target.parentElement.classList.add('revert-skeleton-loading');
            target.classList.add('revert-skeleton-loading');
        }, 2000, target);
    }

    renderTemplate(url: string): void {
        const currencyUrl = new URL(url);
        currencyUrl.searchParams.set('currency_id', this.currencyId);
        window.open(currencyUrl, '_blank');
    }

    getNewTemplates($event: Event): void {

        const target = $event.target as HTMLInputElement;
        this.showVisible = !target.checked;
        this.getTicketTemplates();
    }

    searchTemplates(value: string): void {

        if (this.keyWordToSearch !== value.toLowerCase()) {
            this.keyWordToSearch = value.toLowerCase();
            this.temporarySearchedTemplates = this.ticketTemplates.filter((template) =>
                template.name.toLowerCase().indexOf(this.keyWordToSearch) > -1);
            this.sliceCount = 1;
        }

        this.sliceTicketTemplates(this.temporarySearchedTemplates);
    }

    sliceTicketTemplates(templates): void {
        if (templates.length < 12) {
            this.ticketTemplatesSizedPerTwelve = templates.slice(0, templates.length);
        } else {
            this.ticketTemplatesSizedPerTwelve = templates.slice(0, 12 * this.sliceCount);
            this.sliceCount++;
        }
    }

    selectNewTicketTemplatePresetType(templateType: string): void {
        if (templateType === this.selectedTicketTemplateType) {
            return;
        }

        this.selectedTicketTemplateType = templateType;
        this.availablePresetPerSelectedTicketTemplateType = this.ticketTemplates.filter(template => template.is_default === true
            && template.ticket_template_type_id === this.selectedTicketTemplateType);
        this.getTicketTemplatePresets();

    }

    getTicketTemplatePresets(): void {
        this.ticketTemplatePresets$ = this.orderDistributionService.getTicketTemplatePresetsByType(this.selectedTicketTemplateType);
        if (this.ticketTemplatePresetsSubscription) {
            this.ticketTemplatePresetsSubscription.unsubscribe();
        }

        this.ticketTemplatePresetsSubscription = this.ticketTemplatePresets$.subscribe(ticketTemplatePresets => {
            this.ticketTemplatePresets = ticketTemplatePresets;
            this.ticketTemplatePresets.sort((a, b) => a.display_order - b.display_order);
        });
    }
}
