import {ModelBase} from './abstract/model-base';

export class Payout extends ModelBase {
    id: string = null;
    externalId: string = null;
    externalReferenceId: string = null;
    name: string = null;
    collectedAmount: string = null;
    settledAmount: string = null;
    feeAmount: string = null;
    vatAmount: string = null;
    payoutAt: string = null;
    createdAt: string = null;

    constructor() {
        super();
        super.setRoutes({
            get: 'v1.0/payout/:productTypeId',
            getMany: 'v1.0/payout'
        });
    }

    mapModel(data: any): Payout {
        const model = new Payout();
        model.id = data.id;
        model.externalId = data.externalId;
        model.externalReferenceId = data.externalReferenceId;
        model.name = data.name;
        model.collectedAmount = data.collectedAmount;
        model.settledAmount = data.settledAmount;
        model.feeAmount = data.feeAmount;
        model.vatAmount = data.vatAmount;
        model.payoutAt = data.payoutAt;
        model.createdAt = data.createdAt;

        return model;
    }

}
