<h3>{{('Customer_Guest_Manager_List.Guest_Manager' | trans)}}</h3>

<ng-container *ngIf="customer.guestManager">
    <div class="page-section">
        <div class="datatable">
            <div class="datatable-header">
                <div class="datatable-header-item col-2">{{('Relations.First_Name' | trans)}}</div>
                <div class="datatable-header-item col-2">{{('Relations.Last_Name' | trans)}}</div>
                <div class="datatable-header-item col-4">{{('Relations.Email' | trans)}}</div>
                <div class="datatable-header-item col-4"></div>
            </div>
            <ng-container *ngFor="let user of customer.guestManager.users;">
                <div class="datatable-row">
                    <div class="datatable-row-item col-2">{{user.firstName}}</div>
                    <div
                        class="datatable-row-item col-2">{{user.lastName}} {{user.insertion ? ', ' + user.insertion : ''}}</div>
                    <div class="datatable-row-item col-4">{{user.email}}</div>
                    <div class="datatable-row-item col-4 d-flex">
                        <cm-tooltip [attr.data-tooltip-text]="('Relations.Send_Password_Reset' | trans)">
                            <button class="btn btn-secondary-icon m-r-16"
                                    (click)="sendPasswordForgotMail(user.email)">
                                <span class="cm-icon cm-icon-email"></span>
                            </button>
                        </cm-tooltip>
                        <cm-tooltip [attr.data-tooltip-text]="('Relations.Delete_Guest_Manager_User' | trans)">
                            <button class="btn btn-secondary-icon m-r-16"
                                    (click)="deleteGuestManagerUser(user.id)">
                                <span class="cm-icon cm-icon-delete"></span>
                            </button>
                        </cm-tooltip>
                        <cm-tooltip [attr.data-tooltip-text]="('Relations.Sign_In_As' | trans)">
                            <button class="btn btn-secondary-icon m-r-16"
                                    (click)="remoteLoginGuestManagerUser(user.id)">
                                <span class="cm-icon cm-icon-lock-locked"></span>
                            </button>
                        </cm-tooltip>
                        <cm-tooltip [attr.data-tooltip-text]="('Relations.Copy_Guest_Manager_Login_URL' | trans)">
                            <button class="btn btn-secondary-icon m-r-16"
                                    (click)="onCopyGuestManagerRemoteLoginClick(user.id)">
                                <span class="cm-icon cm-icon-link"></span>
                            </button>
                        </cm-tooltip>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<div class="page-section">
    <div class="row">
        <div class="col-12 text-right">
            <ng-container *ngIf="error">
            <span class="cl-error-color">
                {{('Error.' + error | trans)}}
            </span>
            </ng-container>
            <ng-container *ngIf="errorMessage">
            <span class="cl-error-color">
                {{errorMessage}}
            </span>
            </ng-container>
            <ng-container *ngIf="!customer.guestManager">
                <button class="btn btn-primary" (click)="enableGuestManager(customer)"
                        [disabled]="customer.guestManagerOrganisationId">
                    {{('Relations.Guest_Manager_' + (customer.guestManagerOrganisationId ? "Activated" : "Activate") | trans)}}
                </button>
            </ng-container>
            <ng-container *ngIf="customer.guestManager && !guestManagerForm">
                <button class="btn btn-primary" (click)="guestManagerForm = !guestManagerForm">
                    {{('Relations.Add_Account' | trans)}}
                </button>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="guestManagerForm">
        <div class="row form-group">
            <div class="col-12 col-md-4">
                <div class="form-row">
                    <div class="form-input">
                        <input name="gmFirstName" id="gmFirstName" class="form-control"
                               placeholder="{{('Relations.First_Name' | trans)}}"
                               [(ngModel)]="guestManagerUser.firstName">
                        <label for="gmFirstName">{{('Relations.First_Name' | trans)}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-row">
                    <div class="form-input">
                        <input name="gmFirstName" id="gmInsertion" class="form-control"
                               placeholder="{{('Relations.Insertion' | trans)}}"
                               [(ngModel)]="guestManagerUser.insertion">
                        <label for="gmFirstName">{{('Relations.Insertion' | trans)}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-row">
                    <div class="form-input">
                        <input name="gmFirstName" id="gmLastName" class="form-control"
                               placeholder="{{('Relations.Last_Name' | trans)}}"
                               [(ngModel)]="guestManagerUser.lastName">
                        <label for="gmFirstName">{{('Relations.Last_Name' | trans)}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="form-row">
                    <div class="form-input">
                        <input name="gmFirstName" id="gmEmail" class="form-control"
                               placeholder="{{('Relations.Email' | trans)}}"
                               [(ngModel)]="guestManagerUser.email">
                        <label for="gmFirstName">{{('Relations.Email' | trans)}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-input">
                    <input name="gmFirstName" id="gmPhonenumber" class="form-control"
                           placeholder="{{('Relations.Phone_Number' | trans)}}"
                           [(ngModel)]="guestManagerUser.phoneNumber">
                    <label for="gmFirstName">{{('Relations.Phone_Number' | trans)}}</label>
                </div>
            </div>
            <div class="col-12 text-right">
                <button class="btn btn-secondary"
                        (click)="guestManagerForm = false;">{{('General.Cancel' | trans)}}</button>
                <button class="btn btn-primary"
                        (click)="addGuestManagerUser()">{{('General.Save' | trans)}}</button>
            </div>
        </div>
    </ng-container>
</div>
