import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../../environments/environment';
import {
    AnalyticsDashboardItemSetting,
    mapAnalyticsDashboardItemSetting
} from '../../models/analytics-dashboard-item-setting.class';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsDashboardItemSettingService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    postAnalyticsDashboardItemSetting(
        analyticsDashboardId: string,
        analyticsDashboardItemId: string,
        analyticsDashboardItemSetting: AnalyticsDashboardItemSetting,
        params?: HttpParams
    ): Observable<AnalyticsDashboardItemSetting> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<AnalyticsDashboardItemSetting>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item/${analyticsDashboardItemId}/setting`, analyticsDashboardItemSetting, config).pipe(
            map(item => {
                return mapAnalyticsDashboardItemSetting(item);
            })
        );
    }

    patchAnalyticsDashboardItemSetting(
        analyticsDashboardId: string,
        analyticsDashboardItemId: string,
        analyticsDashboardItemSettingId: string,
        analyticsDashboardItemSetting: AnalyticsDashboardItemSetting,
        params?: HttpParams
    ): Observable<AnalyticsDashboardItemSetting> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<AnalyticsDashboardItemSetting>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item/${analyticsDashboardItemId}/setting/${analyticsDashboardItemSettingId}`, analyticsDashboardItemSetting, config).pipe(
            map(item => {
                return mapAnalyticsDashboardItemSetting(item);
            })
        );
    }

    deleteAnalyticsDashboardItemSetting(
        analyticsDashboardId: string,
        analyticsDashboardItemId: string,
        analyticsDashboardItemSettingId: string,
        params?: HttpParams
    ): Observable<any> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.delete<any>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item/${analyticsDashboardItemId}/setting/${analyticsDashboardItemSettingId}`, config);
    }
}
