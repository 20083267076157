import {ModelBase} from '../abstract/model-base';

export class OrderDetailsDeliveries extends ModelBase {
    public id: string;
    public method: string;
    public email: string;
    public phoneNumber: string;
    public firstName: string;
    public lastName: string;
    public companyName: string;
    public sendAt: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsDeliveries {
        const model = new OrderDetailsDeliveries();

        model.id = data.id;
        model.method = data.method;
        model.email = data.email;
        model.phoneNumber = data.phoneNumber;
        model.firstName = data.firstName;
        model.lastName = data.lastName;
        model.companyName = data.companyName;
        model.sendAt = data.sendAt;

        return model;
    }
}
