import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NotificationService} from '../../services/notification-service/notification.service';
import {tap} from 'rxjs/operators';
import {Customer} from '../../models/customer.class';
import {PlatformService} from '../../services/platform-service/platform.service';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {LocalReservationService} from '../../services/local-reservation.service';
import {DialogConfirmationComponent} from '../dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {OrderBarService} from '../../services/order-bar.service';
import {LocalReservation} from '../../models/reservation/local-reservation.class';
import {Order} from '../../models/order.class';

@Component({
    selector: 'app-action-footer',
    templateUrl: './action-footer.component.html',
    styleUrls: ['./action-footer.component.scss']
})
export class ActionFooterComponent implements OnInit, OnDestroy {
    public localReservation$: Observable<any>;
    customer: Customer;
    expanded = false;
    expandedFull = false;
    isExistingOrder = false;

    order: Order;
    orderItemAmount: number;

    private localReservation: LocalReservation;

    private localReservationSubscription: Subscription;
    private orderSubscription: Subscription;
    private orderBarExpandedSubscription: Subscription;

    constructor(private orderManagementService: OrderManagementService,
                private notificationService: NotificationService,
                private platformService: PlatformService,
                private dialogService: DialogService,
                private localReservationService: LocalReservationService,
                private orderBarService: OrderBarService) {
    }

    ngOnInit() {
        if (localStorage.getItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`)) {
            this.orderManagementService.getOrder(localStorage.getItem(`seatedDashboard.${this.platformService.getTechnicalLinkId()}.orderId`));
        }

        this.localReservation$ = this.localReservationService.localReservation$;
        this.localReservationSubscription = this.localReservation$.subscribe(localReservation => {
            this.localReservation = localReservation;

            setTimeout(() => {
                this.orderItemAmount = this.localReservationService.getAmountOfItems();
            });
            if (this.localReservationService.isActive()) {
                this.expanded = true;
                document.querySelector('body').classList.add('action-footer');
            } else if (!this.order) {
                this.expanded = false;
                document.querySelector('body').classList.remove('action-footer');
            }
        });

        this.orderSubscription = this.orderManagementService.order$.pipe(
            tap(order => {
                if (order && order.status !== 'COMPLETE') {
                    this.isExistingOrder = true;
                    this.customer = order.customer;
                    this.expanded = true;
                    this.order = order;

                    this.localReservation = LocalReservation.fromOrder(order);

                    setTimeout(() => {
                        this.localReservationService.setLocalReservation(this.localReservation, true);
                    });

                    document.querySelector('body').classList.add('action-footer');
                } else {
                    this.order = null;
                    this.isExistingOrder = false;
                    this.customer = null;
                    this.expanded = false;
                    this.expandedFull = false;

                    document.querySelector('body').classList.remove('action-footer');
                }
            })
        ).subscribe();

        this.orderBarExpandedSubscription = this.orderBarService.orderBarExpanded$.subscribe(expanded => {
            this.expandedFull = expanded;
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
        if (!this.localReservationService.isReservationActive()) {
            return;
        }

        if (!this.isExistingOrder || this.localReservationService.getDifferences().isChanged) {
            $event.returnValue = true;
        }
    }

    orderExpired(event) {
        if (event.action !== 'done') {
            return;
        }

        setTimeout(() => {
            this.notificationService.showTranslatedInfoNotification('order', 'expired');
            this.orderManagementService.unsetOrder();
            this.localReservationService.resetLocalReservation();
            this.expanded = false;
        }, 10);
    }

    addExpireTime() {
        if (this.localReservationService.getDifferences().isChanged) {
            this.notificationService.showTranslatedInfoNotification('order', 'contains_changes');
            return;
        }

        this.orderManagementService.updateOrder({expireAt: 60});
    }

    expandFooter(forceExpand: boolean = null) {
        this.expandedFull = forceExpand !== null ? forceExpand : !this.expandedFull;
        this.orderBarService.setOrderBarExpanded(this.expandedFull);
    }

    closeFooter() {
        if (this.isExistingOrder) {
            this.orderManagementService.unsetOrder();
            this.localReservationService.resetLocalReservation();
            this.expandFooter(false);
            return;
        }

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {
                key: 'Reservation.Delete'
            },
            bodyText: {key: 'Reservation.Delete.Confirm_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'order-delete');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (response.cancelled) {
                return;
            }

            this.expandFooter(false);
            this.localReservationService.resetLocalReservation();
            this.notificationService.showTranslatedSuccessNotification('order', 'deleted');
        });
    }

    getMoveOrderItemName() {
        // if (this.orderManagementService.moveOrderItem) {
        //     if (this.orderManagementService.moveOrderItem.ticket) {
        //         return this.orderManagementService.moveOrderItem.ticket.name;
        //     }
        //
        //     if (this.orderManagementService.moveOrderItem.subscription) {
        //         return this.orderManagementService.moveOrderItem.subscription.name;
        //     }
        // }

        return false;
    }

    ngOnDestroy(): void {
        this.localReservationSubscription.unsubscribe();
        this.orderSubscription.unsubscribe();
        this.orderBarExpandedSubscription.unsubscribe();
    }
}
