<div class="row m-b-24">
    <div class="col-12 text-center">
        <cm-toggle-switch
            [attr.data-label]="('Ticket_Template_List.Archived_Items' | trans) | async"
            (change)="toggleIsVisible()"
        ></cm-toggle-switch>
    </div>
</div>

<ng-container *ngIf="emailTemplateGroups$ | async as emailTemplateGroups; else loading">
    <div class="row col-12">
        <div class="list-group m-t-23 row col-12">
            <ng-container *ngFor="let emailTemplateGroup of emailTemplateGroups">
                <div class="list-group-content row"
                     (click)="emailTemplateGroup.is_visible && onEmailTemplateGroupClick(emailTemplateGroup)">
                    <div class="table-column col-10 heading-group row">
                        <div class="heading">
                            <h6>{{ emailTemplateGroup.name }}</h6>
                        </div>
                    </div>
                    <div class="table-column actions col-2">
                        <cm-context-menu>
                            <ng-container *ngIf="emailTemplateGroup.is_visible">
                                <cm-option [attr.data-display]="('General.Edit' | trans) | async" data-icon="edit"
                                           (cm-option-clicked)="onEmailTemplateGroupClick(emailTemplateGroup)"></cm-option>
                                <cm-option [attr.data-display]="('General.Archive' | trans) | async" data-icon="archive"
                                           (cm-option-clicked)="onDeleteEmailTemplateGroupClick(emailTemplateGroup)"></cm-option>
                            </ng-container>

                            <ng-container *ngIf="!emailTemplateGroup.is_visible">
                                <cm-option [attr.data-display]="('General.Unarchive' | trans) | async"
                                           data-icon="unarchive"
                                           (cm-option-clicked)="onRestoreEmailTemplateGroupClick(emailTemplateGroup)"></cm-option>
                            </ng-container>
                        </cm-context-menu>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="displayEmptyState">
        <div class="empty-state">
            <cm-image data-type="introduction"
                      [attr.data-title]="('Empty_State.Title' | trans) | async"
                      [attr.data-description]="('Empty_State.Description' | trans) | async"></cm-image>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
