<div class="row h-100 w-100 overflow-hidden" #parent>
    <div class="col-12 col-md-6">
        <div class="f-w-700 p-16">{{analyticsDashboardItem.name}}</div>
    </div>
    <div class="col-12 col-md-6 text-right menu transition">
        <ng-container *ngIf="showDownload">
            <button class="btn btn-secondary-icon" (click)="download()">
                <span class="cm-icon cm-icon-download" aria-hidden="true"></span>
            </button>
        </ng-container>
        <ng-container *ngIf="showSettings">
            <button class="btn btn-secondary-icon" (click)="openSettings()">
                <span class="cm-icon cm-icon-settings" aria-hidden="true"></span>
            </button>
            <button class="btn btn-secondary-icon drag-handle">
                <span class="cm-icon cm-icon-move" aria-hidden="true"></span>
            </button>
            <button class="btn btn-secondary-icon" (click)="deleteChart()">
                <span class="cm-icon cm-icon-close" aria-hidden="true"></span>
            </button>
        </ng-container>
    </div>
    <div class="col-12 text-center">
        <ng-container *ngIf="isLoading">
            <cm-indicator class="small"></cm-indicator>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <ng-container
                *ngIf="this.analyticsDashboardItem.analyticsDashboardItemSettings.length === 0 || data.length === 0">
                <cm-image data-type="mobileMarketingCloud" data-min-height="200"></cm-image>
                <ng-container *ngIf="showConfigure">
                    <button class="btn btn-secondary-icon-text" (click)="openSettings()">
                        <span aria-hidden="true" class="cm-icon cm-icon-add"></span>
                        <span>{{('Analytics.Add_Change_Data_Set' | trans)}}</span>
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
        <highcharts-chart
            [class.vertical-grid]="this.analyticsDashboardItem.isGridVerticalEnabled"
            [class.horizontal-grid]="this.analyticsDashboardItem.isGridHorizontalEnabled"
            [Highcharts]="highcharts"
            [callbackFunction]="chartCallback.bind(this)"
            [options]="options"
            [oneToOne]="true"
            style="width: 100%; min-height: 200px;"
            [class.available]="this.analyticsDashboardItem.analyticsDashboardItemSettings.length > 0 && data.length > 0">
        </highcharts-chart>
    </div>
</div>
