import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

    @Input() total: number;
    @Input() current: number;

    @Output() stepClick: EventEmitter<number> = new EventEmitter<number>();

    public steps;

    constructor() {
    }

    ngOnInit(): void {
        this.steps = Array(this.total).fill(0);
    }

    onStepClick(stepIndex: number): void {
        this.stepClick.emit(stepIndex);
    }

}
