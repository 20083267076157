<div class="row p-t-8 p-b-8">
    <ng-container *ngIf="!hideCurrency">
        <div class="card">
            <h2>{{ ('Organisation_Setting.Name_Currency' | trans) }}</h2>
            <p [innerHTML]="('Organisation_Setting.Description_Currency' | trans)"></p>
            <div><strong>Currency:</strong> {{ currencyId }}</div>
        </div>
    </ng-container>
</div>
<ng-container *ngFor="let settingsGroup of settingsGroups; let i = index">
    <div class="row p-t-8 p-b-8">
        <div *ngIf="!isMobile && shouldBeRightAligned(i)" class="col-lg-6">
            <ng-container *ngIf="findImageNameById(settingsGroup.name); let imageName">
                <cm-image [attr.data-type]="imageName" data-min-height="240"></cm-image>
            </ng-container>
        </div>
        <div class="col-12 col-lg-6 card">
            <h2 [innerHTML]="'Setting.Group_Title_' + settingsGroup.name | trans"></h2>
            <p [innerHTML]="'Setting.Group_Description_' + settingsGroup.name | trans"></p>
            <ng-container *ngFor="let setting of settingsGroup.settings">
                <div class="p-t-8 p-b-8" [hidden]="checkForHidden(setting.id)">
                    <h3>{{ ('Organisation_Setting.Name_' + setting.id | trans) }}</h3>
                    <p [innerHTML]="('Organisation_Setting.Description_' + setting.id | trans)"></p>
                    <ng-container *ngIf="setting.type === 'SWITCH'">
                        <app-form-switch [(value)]="setting.organisationSetting.value"
                                         [label]="('Organisation_Setting.Name_' + setting.id | trans)"
                                         (change)="settingUpdated(settingsGroup)"
                                         [disabled]="!setting.isEditable">
                        </app-form-switch>
                    </ng-container>
                    <ng-container
                        *ngIf="setting.type === 'TEXT' || setting.type === 'NUMBER' || setting.type === 'EMAIL'">
                        <app-form-input [(value)]="setting.organisationSetting.value"
                                        [label]="('Organisation_Setting.Name_' + setting.id | trans)"
                                        [placeholder]="('Organisation_Setting.Default_Placeholder' | trans)"
                                        [type]="setting.type"
                                        [readonly]="!setting.isEditable"
                                        (input)="settingUpdated(settingsGroup)"
                                        [formId]="formId"
                                        [required]="setting.isRequired">
                        </app-form-input>
                    </ng-container>
                    <ng-container *ngIf="setting.type === 'CHECKBOX'">
                        <app-form-checkbox [(value)]="setting.organisationSetting.value"
                                           [label]="('Organisation_Setting.Name_' + setting.id | trans)"
                                           [options]="setting.settingOptions"
                                           [readonly]="!setting.isEditable"
                                           (change)="settingUpdated(settingsGroup)"
                                           optionLabel="value"
                                           optionValue="value">
                        </app-form-checkbox>
                    </ng-container>
                    <ng-container *ngIf="setting.type === 'SELECT'">
                        <app-form-select [(value)]="setting.organisationSetting.settingOptionId"
                                         [label]="('Organisation_Setting.Name_' + setting.id | trans)"
                                         [options]="setting.settingOptions"
                                         [readonly]="!setting.isEditable"
                                         [optionLabelTranslate]="setting.labelTranslate"
                                         (change)="settingUpdated(settingsGroup)"
                                         optionLabel="label"
                                         optionValue="id"
                                         [formId]="formId"
                                         [required]="setting.isRequired">
                        </app-form-select>
                    </ng-container>
                    <ng-container *ngIf="setting.type === 'RADIO'">
                        <app-form-radio [(value)]="setting.organisationSetting.value"
                                        [label]="('Organisation_Setting.Name_' + setting.id | trans)"
                                        [options]="setting.settingOptions"
                                        [disabled]="!setting.isEditable"
                                        (change)="settingUpdated(settingsGroup)"
                                        optionLabel="value"
                                        optionValue="value">
                        </app-form-radio>
                    </ng-container>
                </div>
            </ng-container>
            <div class="col-12 text-right">
                <button class="btn btn-cta" (click)="saveSettingsGroupSettings(settingsGroup)"
                        [disabled]="!settingsGroup.isUpdated">
                    {{ ('General.Save' | trans) }}
                </button>
            </div>
        </div>
        <div *ngIf="!isMobile && !shouldBeRightAligned(i)" class="col-lg-6">
            <ng-container *ngIf="findImageNameById(settingsGroup.name); let imageName">
                <cm-image [attr.data-type]="imageName" data-min-height="240"></cm-image>
            </ng-container>
        </div>
    </div>
</ng-container>
