import {Component} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';

@Component({
    selector: 'app-dark-theme-toggle',
    templateUrl: './dark-theme-toggle.component.html',
    styleUrls: ['./dark-theme-toggle.component.scss']
})
export class DarkThemeToggleComponent {
    darkModeEnabled = localStorage.getItem('disable-forced-aurora-theme') === 'false';

    constructor(private dashboardService: DashboardService) {
    }

    toggleDarkMode() {
        localStorage.setItem('disable-forced-aurora-theme', `${this.darkModeEnabled}`);
        this.dashboardService.toggleDarkMode();
    }
}
