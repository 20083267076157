<ng-container *ngIf="type === 'DATA'">
    <cm-table-data #tableData [innerHTML]="displayValue | byPassSecurity"
                   [ngClass]="{'justify-content-end': alignRight}">
    </cm-table-data>
</ng-container>

<ng-container *ngIf="type === 'PROGRESS'">
    <cm-table-data #tableData data-progress>
        <cm-table-data-progress data-percentage="{{value}}"></cm-table-data-progress>
    </cm-table-data>
</ng-container>

<ng-container *ngIf="type === 'CHECKBOX'">
    <cm-table-data #tableData data-action-center (click)="checkBoxClicked($event)">
        <div class="form-group">
            <div class="form-row example">
                <div class="form-check">
                    <label class="row-checkbox">
                        <input type="checkbox" (change)="onCheckboxChange($event)" [checked]="selected ? true : null"/>
                        <span class="label"></span>
                    </label>
                </div>
            </div>
        </div>
    </cm-table-data>
</ng-container>

<ng-container *ngIf="type === 'ACTION'">
    <ng-container *ngIf="isLoading()">
        <cm-table-data class="justify-content-center loading">
            <ul>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </cm-table-data>
    </ng-container>
    <ng-container *ngIf="!isLoading()">
        <cm-table-data #tableData class="justify-content-center">
            <ng-container *ngIf="displayValue && displayValue.length > 0">
                <cm-context-menu>
                    <ng-container *ngFor="let option of displayValue">
                        <ng-container *ngIf="option.menuLine">
                            <cm-context-menu-line></cm-context-menu-line>
                        </ng-container>
                        <ng-container *ngIf="!option.menuLine">
                            <cm-option (cm-option-clicked)="actionClicked($event)" [id]="option.id"
                                       [disabled]="option.disabled ? option.disabled : null"
                                       [attr.title]="option.title ? option.title : null"
                                       [attr.data-display]="option.dataDisplay" [attr.data-icon]="option.dataIcon">
                            </cm-option>
                        </ng-container>
                    </ng-container>
                </cm-context-menu>
            </ng-container>
        </cm-table-data>
    </ng-container>
</ng-container>
