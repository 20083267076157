import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class SeatedApiService extends ApiService {
    getBaseUrl() {
        return this.baseUrl + '/seatedapi';
    }
}
