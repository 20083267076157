import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MoveRequestService {

    selectedRequestItems: any[] = [];

    constructor() {
    }

    toggleSelectedRequestItem(requestItem: any): void {
        const requestItemIndex = this.selectedRequestItems.findIndex(requestItemI => requestItemI.id === requestItem.id);

        if (requestItemIndex === -1) {
            requestItem.isSelected = true;
            this.selectedRequestItems.push(requestItem);
        } else {
            requestItem.isSelected = false;
            this.selectedRequestItems.splice(requestItemIndex, 1);
        }
    }
}
