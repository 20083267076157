<div class="filter bg-default-inverse-color" [class.active]="selectedOptions.length > 0 || selectedOptions.id"
     (click)="toggleDropdown()"
     #button>
    <div class="name">
        <ng-container *ngIf="isDisplayName()">
            {{name}}
        </ng-container>
        <ng-container *ngIf="selectedOptions.length > 0">
            <span class="selected">({{selectedOptions.length}})</span>
        </ng-container>
        <ng-container *ngIf="type === 'DATE_RANGE' && selectedOptions.id">
            <ng-container *ngFor="let dateRangeOption of dateRangeOptions">
                <ng-container *ngIf="dateRangeOption.value === selectedOptions.id">
                    <span class="selected">{{dateRangeOption.label | trans}}</span>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="selectedOptions.length > 0">
        <div class="close cl-hint-text-color" (click)="reset()">
            <span aria-hidden="true" class="cm-icon cm-icon-close on-icon-hover"></span>
        </div>
    </ng-container>
</div>

<div class="filter-dropdown form-group" [class.open]="isOpen" #dropdown>
    <div class="header" *ngIf="isSearchable && type === 'CHECKBOX'">
        <div class="form-input-special">
            <input type="text" class="form-control" (input)="search($event)"
                   [placeholder]="('General.Search' | trans)"/>
            <span class="input-left icon">
                <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
            </span>
        </div>
    </div>

    <div class="content">
        <ng-container *ngIf="type === 'CHECKBOX'">
            <app-setting-checkbox-picker [categories]="searchedOptions"
                                         [(selectedOptions)]="selectedOptions"
                                         [optionName]="optionLabel"
                                         [optionKey]="optionValue"
                                         optionsKey="options"
                                         categoryName="name"
                                         columns="1">
            </app-setting-checkbox-picker>
        </ng-container>

        <ng-container *ngIf="type === 'DATE_RANGE'">
            <app-form-radio [(value)]="selectedOptions.id" [options]="dateRangeOptions" (valueChange)="radioValueChanged($event)"
                            [optionLabelTranslate]="true"></app-form-radio>

            <ng-container *ngIf="selectedOptions.id === 'CUSTOM'">
                <app-form-datepicker-v3
                        [label]="'General.Start_At' | trans"
                        [value]="selectedOptions.startAt"
                        (change)="onStartDateChange($event)"></app-form-datepicker-v3>

                <app-form-datepicker-v3
                        [label]="'General.End_At' | trans"
                        [value]="selectedOptions.endAt"
                        (change)="onEndDateChange($event)"></app-form-datepicker-v3>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="type === 'EXPIRE_DATE_RANGE'">
            <app-form-radio [(value)]="selectedOptions.id" [options]="expireDateRangeOptions"
                            [optionLabelTranslate]="true"></app-form-radio>

            <ng-container *ngIf="selectedOptions.id === 'CUSTOM'">
                <app-form-datepicker-v3
                        [label]="'General.Start_At' | trans"
                        [value]="selectedOptions.startAt"
                        (change)="onStartDateChange($event)"></app-form-datepicker-v3>

                <app-form-datepicker-v3
                        [label]="'General.End_At' | trans"
                        [value]="selectedOptions.endAt"
                        (change)="onEndDateChange($event)"></app-form-datepicker-v3>
            </ng-container>
        </ng-container>
    </div>
</div>

