import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {mapOrderDelivery, OrderDelivery} from '../../models/order-delivery.class';
import {map} from 'rxjs/operators';

@Injectable()
export class OrderDeliveryService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getOrderDeliveries(orderId: string, params?: HttpParams): Observable<HttpResponse<OrderDelivery[]>> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<OrderDelivery[]>(this.baseUrl + `/v1.0/order/${orderId}/delivery`, {...config, observe: 'response'});
    }

    postOrderDelivery(body: any, params?: HttpParams): Observable<OrderDelivery> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<OrderDelivery>(this.baseUrl + `/v1.0/order/delivery`, body, config)
            .pipe(
                map((item: OrderDelivery) => {
                    return mapOrderDelivery(item);
                })
            );
    }
}
