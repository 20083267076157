export class Country {
    constructor(public id: string,
                public name: string) {
    }
}

export function mapCountry(item: any): Country {
    return new Country(
        item.ID,
        item.Name
    );
}

