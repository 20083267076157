import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Venue} from '../../models/venue.class';
import {VenueService} from './venue.service';
import {VenueSection} from '../../models/venue-section.class';
import { HttpParams } from '@angular/common/http';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {NotificationService} from '../notification-service/notification.service';
import {OrderItem} from '../../models/order-item.class';

@Injectable()
export class VenueManagementService {
    public venuesSubject: BehaviorSubject<Venue[]>;
    public venueSubject: BehaviorSubject<Venue>;
    public venue: Venue;
    public venueSectionSubject: BehaviorSubject<VenueSection>;
    public venueSection: VenueSection;
    public selectedVenueSeats: VenueSectionSeat[] = [];
    public selectedSeatsSubject: BehaviorSubject<VenueSectionSeat[]>;
    public params: HttpParams;
    public cache: any[] = [];

    constructor(private venueService: VenueService, private notificationService: NotificationService) {
        this.venueSubject = new BehaviorSubject(this.venue);
        this.venuesSubject = new BehaviorSubject([]);
        this.venueSectionSubject = new BehaviorSubject(this.venueSection);
        this.selectedSeatsSubject = new BehaviorSubject(this.selectedVenueSeats);
    }

    getVenue(venueUuid: string, params: HttpParams, callback?, cache = false) {
        const key = venueUuid + params.toString();

        if (cache && this.cache[key]) {
            this.venueSubject.next(this.cache[key]);

            return;
        }

        this.venueService.getVenue(venueUuid, params).subscribe((venue: Venue) => {
            this.venue = venue;
            this.cache[key] = venue;
            this.venueSubject.next(this.venue);

            if (callback) {
                callback();
            }
        });
    }

    getVenues() {
        this.venueService.getVenues(new HttpParams().set('take', -1).set('depth', '3')).subscribe(venues => {
            this.venuesSubject.next(venues);
        });
    }

    getVenueSection(venueId: string, venueSectionId: string, params: HttpParams) {
        this.params = params;
        this.venueService.getVenueSection(venueId, venueSectionId, params).subscribe((venueSection: VenueSection) => {
            this.venueSection = venueSection;
            this.venueSectionSubject.next(this.venueSection);
        });
    }

    updateVenueSection() {
        if (this.venue && this.venueSection) {
            this.venueService.getVenueSection(this.venue.id, this.venueSection.id, this.params).subscribe((venueSection: VenueSection) => {
                this.venueSection = venueSection;
                this.venueSectionSubject.next(this.venueSection);
            });
        }
    }

    getVenueSectionSeat(venueSectionSeatId: string, params: HttpParams, callback?: any) {
        if (this.venue && this.venueSection) {
            this.venueService.getVenueSectionSeat(this.venue.id, this.venueSection.id, venueSectionSeatId, params).subscribe({
                next: (venueSectionSeat: VenueSectionSeat) => {
                    const index = this.venueSection.seats.findIndex((seat) => {
                        return seat.id === venueSectionSeat.id;
                    });
                    this.venueSection.seats[index] = venueSectionSeat;
                    this.venueSectionSubject.next(this.venueSection);

                    if (callback) {
                        callback(venueSectionSeat);
                    }
                },
                error: () => {
                    // Suppress errors
                }
            });
        }
    }

    addSelectedSeat(venueSectionSeat: VenueSectionSeat) {
        if (venueSectionSeat.eventTickets && venueSectionSeat.eventTickets.length > 0) {
            this.notificationService.info('Let op, selectie bevat conflicten');
        }
        this.selectedVenueSeats.push(venueSectionSeat);
        this.selectedSeatsSubject.next(this.selectedVenueSeats);
    }

    fetchVenueSectionSeats(orderItems: OrderItem[] = []) {
        if (orderItems.length <= 0 || orderItems.length > 10) {
            this.updateVenueSection();
        } else {
            for (const item of orderItems) {
                let venueSectionSeatId = null;
                if (item.ticket) {
                    venueSectionSeatId = item.ticket.venueSectionSeatId;
                } else if (item.subscription) {
                    venueSectionSeatId = item.subscription.seat.id;
                }

                if (item.ticket) {
                    this.getVenueSectionSeat(venueSectionSeatId, new HttpParams()
                        .set('venueSectionSeat[available]', 'true')
                        .set('venueSectionSeat[details]', 'true')
                        .set('scope[eventId]', item.ticket.eventId)
                        .set('depth', '2'));
                } else if (item.subscription) {
                    this.getVenueSectionSeat(venueSectionSeatId, new HttpParams()
                        .set('venueSectionSeat[available]', 'true')
                        .set('venueSectionSeat[details]', 'true')
                        .set('scope[subscriptionTypeId]', item.subscription.subscriptionTypeId)
                        .set('depth', '2'));
                }
            }
        }
    }
}
