<div class="form-switch-text">
    <input type="radio" id="radio1" name="switch" [value]="option1.value" [(ngModel)]="value" (change)="inputChange()"/>
    <label for="radio1" class="left">
        <span class="text">
            <ng-container *ngIf="optionLabelTranslate">{{(option1.label | trans)}}</ng-container>
            <ng-container *ngIf="!optionLabelTranslate">{{option1.label}}</ng-container>
        </span>
    </label>
    <input type="radio" id="radio2" name="switch" [value]="option2.value" [(ngModel)]="value" (change)="inputChange()" />
    <label for="radio2" class="right">
        <span class="text">
            <ng-container *ngIf="optionLabelTranslate">{{(option2.label | trans)}}</ng-container>
            <ng-container *ngIf="!optionLabelTranslate">{{option2.label}}</ng-container>
        </span>
    </label>
</div>

<ng-container *ngIf="!isValid && errorMessage">
    <div class="form-input-validation">
        <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
    </div>
</ng-container>
