import {Component, Input, OnInit} from '@angular/core';
import {OrderDeliveryMethod} from '../../models/order-delivery-method.enum';
import {OrderDetailsDeliveries} from '../../models/order-details/order-details-deliveries.class';

@Component({
    selector: 'app-order-delivery-table',
    templateUrl: './order-delivery-table.component.html',
    styleUrls: ['./order-delivery-table.component.scss']
})
export class OrderDeliveryTableComponent implements OnInit {

    orderDeliveryMethod = OrderDeliveryMethod;

    @Input()
    orderDeliveries: OrderDetailsDeliveries[];

    constructor() {
    }

    ngOnInit(): void {
    }

}
