import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {PriceItem} from '../../models/price-item.class';
import {PricePlan} from '../../models/price-plan.class';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../notification-service/notification.service';

@Injectable()
export class PriceService {

    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient, private notificationService: NotificationService) {
    }

    getPricePlan(pricePlanId: string): Observable<PricePlan> {
        return this.http.get<PricePlan>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}`, this.config);
    }

    postPricePlan(pricePlanName: string, venueId: string, isTemplate: boolean) {
        const data = {
            name: pricePlanName,
            venueId: venueId,
            isTemplate: isTemplate
        };
        return this.http.post<PricePlan>(this.baseUrl + `/v1.0/price/priceplan`, data, this.config)
            .pipe(
                tap({
                    next: () => {
                        this.notificationService.showTranslatedNotification('success', 'price_plan', 'saved');
                    },
                    error: () => {
                        this.notificationService.showTranslatedNotification('error', 'price_plan', 'save_failed');
                    }
                }),
            );
    }

    clonePricePlan(pricePlanId: string, eventId: string): Observable<PricePlan> {
        const data = {
            eventId: eventId
        };

        return this.http.post<PricePlan>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}/clone`, data, this.config)
            .pipe(
                tap({
                    next: () => {
                        this.notificationService.showTranslatedNotification('success', 'price_plan', 'overwritten');
                    },
                    error: () => {
                        this.notificationService.showTranslatedNotification('error', 'price_plan', 'save_failed');
                    }
                }),
            );
    }

    getPricePlans(params: HttpParams): Observable<PricePlan[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<PricePlan[]>(this.baseUrl + `/v1.0/price/priceplan`, config);
    }

    patchPricePlan(pricePlanId: string, priceItems: PriceItem[]): Observable<PricePlan> {
        const data = {
            pricePlanId: pricePlanId,
            priceItems: priceItems
        };
        return this.http.patch<PricePlan>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}`, data, this.config)
            .pipe(
                tap({
                    next: () => {
                        this.notificationService.showTranslatedNotification('success', 'price_plan', 'saved');
                    },
                    error: () => {
                        this.notificationService.showTranslatedNotification('error', 'price_plan', 'save_failed');
                    }
                }),
            );
    }
}
