import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {TableRow} from '../models/abstract/table-row';
import {BulkSelect} from '../../orders/services/bulk-select';

@Directive({
    selector: '[appTableCheckboxes]'
})
export class TableCheckboxesDirective {

    @Input('appTableCheckboxes')
    bulkSelect: BulkSelect = new BulkSelect();

    @Output()
    headCheckboxClick: EventEmitter<TableCheckboxChangeEvent> = new EventEmitter<TableCheckboxChangeEvent>();

    @Output()
    rowCheckboxClick: EventEmitter<TableCheckboxChangeEvent> = new EventEmitter<TableCheckboxChangeEvent>();

    public onHeadCheckboxChange(e: Event): void {
        this.headCheckboxClick.emit(new TableCheckboxChangeEvent(
            (e.target as HTMLInputElement).checked
        ));
    }

    public onRowCheckboxChange(e: Event, row: TableRow): void {
        this.rowCheckboxClick.emit(new TableCheckboxChangeEvent(
            (e.target as HTMLInputElement).checked,
            row
        ));
    }
}

export class TableCheckboxChangeEvent {
    checked: boolean;
    row: TableRow | undefined;

    constructor(checked: boolean, row?: TableRow | undefined) {
        this.checked = checked;
        this.row = row;
    }
}
