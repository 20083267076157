import {Component, OnInit} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {OrderService} from '../../services/order-service/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Order} from '../../models/order.class';
import {switchMap} from 'rxjs/operators';
import {DialogConfirmationComponent} from '../dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {OrderDeliveryService} from '../../services/order-delivery-service/order-delivery.service';
import {MoveOrderService} from '../../services/move-order/move-order.service';
import {SidebarConfig, SidebarInterface} from '../../models/abstract/sidebar.interface';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';

@Component({
    selector: 'app-order-detail-sidebar',
    templateUrl: './order-detail-sidebar.component.html',
    styleUrls: ['./order-detail-sidebar.component.css']
})
export class OrderDetailSidebarComponent implements OnInit, SidebarInterface {

    order$: Observable<Order>;

    sidebarConfig: SidebarConfig = {
        closeAfterRefresh: false
    };

    constructor(
        private orderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private orderDeliveryService: OrderDeliveryService,
        private moveOrderService: MoveOrderService,
        private sidebarService: SidebarService,
        private orderManagementService: OrderManagementService
    ) {
    }

    ngOnInit(): void {
        this.order$ = this.route.params.pipe(
            switchMap(params => {
                return this.orderService.getOrder(
                    params.orderId,
                    new HttpParams()
                        .set('order[customer]', 'true')
                        .set('order[groupedOrderItems]', 'true')
                        .set('order[balance]', 'true')
                );
            })
        );
    }

    onResendOrder(order, method: string): void {

        const contact = (method === 'EMAIL') ? order.customer.email : order.customer.phoneNumber;

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Confirm_Resend_Title', tags: [{key: 'method', value: method.toLowerCase()}]},
            bodyText: {key: 'Dialog.Confirm_Resend_Body', tags: [{key: 'contact', value: contact}]},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'order-resend');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.orderDeliveryService.postOrderDelivery({
                    orderId: order.id,
                    method: method,
                    phoneNumber: order.customer.phoneNumber,
                    email: order.customer.email,
                    firstName: order.customer.firstName,
                    lastName: order.customer.lastName
                }).subscribe(orderDelivery => {
                    if (orderDelivery.method === 'DOWNLOAD_PDF') {
                        (window as any).open(orderDelivery.url, '_blank');
                        this.notificationService.showTranslatedNotification('success', 'ticket', 'downloaded', {
                            isPlural: true
                        });
                    } else {
                        this.notificationService.showTranslatedNotification('success', 'ticket', 'sent', {
                            isPlural: true
                        });
                    }
                });
            }
        });
    }

    onCancelOrder(orderId: string): void {
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Cancel_Order_Title'},
            bodyText: {key: 'Dialog.Cancel_Order_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'order-cancel');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.order$ = this.orderService.updateOrder(orderId, {status: 'CANCELED'}, new HttpParams()
                    .set('venueSectionSeat[sectionName]', 'true')
                    .set('order[customer]', 'true')
                    .set('order[groupedOrderItems]', 'true')
                );
            }
        });
    }

    editOrder(orderId: string): void {
        this.orderManagementService.getOrder(orderId, () => {
            this.sidebarService.closeSidebar();
        });
    }

    navigateToOrderDetail(orderId: string): void {
        this.router.navigate([{outlets: {primary: ['orders', orderId], detail: null, overlay: null}}]);
    }

    onMoveOrder(orderId: string): void {
        this.moveOrderService.addOrderItem(orderId, null);
    }

    onDownloadOrderClick(order: Order): void {
        this.orderDeliveryService.postOrderDelivery({
            orderId: order.id,
            method: 'DOWNLOAD_PDF',
        }).subscribe(orderDelivery => {
            (window as any).open(orderDelivery.url, '_blank');
            this.notificationService.showTranslatedNotification('success', 'ticket', 'downloaded', {
                isPlural: true
            });
        });
    }
}
