import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {PlatformService} from '../platform-service/platform.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    modules: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    modules$ = this.modules.asObservable();

    private config = {
        withCredentials: true,
        params: {},
        headers: {}
    };

    private baseUrl = `${environment.USER_MANAGEMENT_API_URL}`;

    constructor(private http: HttpClient, private platformService: PlatformService) {
    }

    public getModules(): Observable<any> {
        const technicalLink = this.platformService.getTechnicalLinkId();

        if (technicalLink) {
            const config = Object.assign({}, this.config);
            config.params = {
                rule_type: 'module'
            };
            config.headers = {
                'X-TF-SCOPE': 'SEATED',
                'x-TF-TECHNICALINKGUID': technicalLink
            };

            return this.http.get(this.baseUrl + '/permissions', config);
        }

        return of([]);
    }

    public isCmEmployee(): Observable<boolean> {
        if (!environment.production) {
            return of(true);
        }

        return this.getModules().pipe(map(
            modules => (modules.some(module => module.identifier === 'ST.EMPLOYEE'))
        ));
    }

    setModules(mappedModules) {
        this.modules.next(mappedModules);
    }
}
