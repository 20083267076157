import { HttpParams } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import moment from 'moment';
import {Observable} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {Country} from '../../models/country.class';
import {Customer} from '../../models/customer.class';
import {Language} from '../../models/language.class';
import {CustomerService} from '../../services/customer-service/customer.service';
import {CustomerTagService} from '../../services/customer-tag-service/customer-tag.service';
import {GlobalizationService} from '../../services/globalization-service/globalization.service';
import {LocaleService} from '../../../core/services/locale.service';
import {CustomFieldService} from '../../services/custom-field.service';
import {CustomField} from '../../models/Custom-field.class';
import {OrganisationManagementService} from '../../services/organisation-service/organisation-management.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomerManagementService} from '../../services/customer-management/customer-management.service';
import {CustomerTag} from '../../models/customer-tag.class';
import {Gender} from '../../enums/gender';
import {CustomFieldAnswer} from '../../models/custom-field-answer';

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

    customer$: Observable<Customer> = this.customerManagementService.customer$.pipe(
        tap(customer => this.onCustomerLoad(customer))
    );

    customer: Customer;
    @Output() customerSave: EventEmitter<Customer> = new EventEmitter();
    @Output() customerUpdate: EventEmitter<Customer> = new EventEmitter();
    @Output() customerDelete: EventEmitter<Customer> = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    customerAddress = '';
    customerTags = '';
    customerTagIds = [];
    customFields: CustomField[] = [];
    countries$: Observable<Country[]>;
    languages$: Observable<Language[]>;
    customerTags$: Observable<CustomerTag[]>;

    birthday: string;
    language: string;
    today = moment().toISOString();

    showFormInvalidMessage = false;

    public gender: typeof Gender = Gender;

    constructor(
        private globalizationService: GlobalizationService,
        private customerService: CustomerService,
        private customFieldService: CustomFieldService,
        private customerTagService: CustomerTagService,
        private localeService: LocaleService,
        private organisationManagementService: OrganisationManagementService,
        private sanitizer: DomSanitizer,
        private customerManagementService: CustomerManagementService) {
    }

    ngOnInit() {
        this.language = this.localeService.getLanguage().toUpperCase();
        this.countries$ = this.globalizationService.getCountries();
        this.languages$ = this.globalizationService.getLanguages();

        this.customerTags$ = this.customerTagService.getCustomerTags(new HttpParams().set('take', -1)).pipe(
            map(response => {
                return response.body;
            })
        );

        this.customFieldService.getCustomFields().subscribe(response => {
            this.customFields = response.body;
        });
    }

    onCustomerLoad(customer: Customer) {
        this.customer = customer;
        this.customerTagIds = this.customer.customerTags.map(item => item.id);

        if (this.customer.street && this.customer.houseNumber) {
            this.customerAddress = `${this.customer.street} ${this.customer.houseNumber}, `;
        }

        if (this.customer.city) {
            this.customerAddress += this.customer.city;
        }

        this.organisationManagementService.item$.pipe(
            filter(value => !!value),
            first()
        ).subscribe(organisation => {
            if (!this.customer.language && organisation.getSetting('CUSTOMER_DEFAULT_LANGUAGE')) {
                this.customer.language = organisation.getSetting('CUSTOMER_DEFAULT_LANGUAGE');
            }

            if (!this.customer.country && organisation.getSetting('CUSTOMER_DEFAULT_COUNTRY')) {
                this.customer.country = organisation.getSetting('CUSTOMER_DEFAULT_COUNTRY');
            }
        });
    }

    saveCustomer(customer: Customer) {
        if (this.isFormValid()) {
            customer.customerTags = this.customerTagIds;

            if (customer.customFieldAnswers) {
                customer.customFields = customer.customFieldAnswers.map(customFieldAnswer => {
                    return {
                        id: customFieldAnswer.customFieldId,
                        customFieldOptionIds: customFieldAnswer.customFieldOptionIds,
                        value: customFieldAnswer.value
                    };
                });
            }

            this.customerManagementService.saveCustomer(customer);
        } else {
            this.showFormInvalidMessage = true;
        }
    }

    cancelCustomer() {
        this.cancel.emit();
    }

    isFormValid() {
        return (this.customer.firstName || this.customer.lastName || this.customer.email || this.customer.companyName);
    }

    onCustomFieldAnswerChange(customFieldAnswer: CustomFieldAnswer, value: any): void {
        customFieldAnswer.value = value;
    }

}
