import {GroupedOrderItem} from './grouped-order-item.class';
import {Customer} from '../customer.class';
import {Order} from '../order.class';

export class LocalReservation {

    constructor(
        public customer: Customer = null,
        public groupedOrderItems: GroupedOrderItem[] = []) {
    }

    public orderId: string = null;

    public static fromOrder(order: Order): LocalReservation {
        const customer = order.customer;
        const groupedOrderItems = [];
        for (const orderItem of order.groupedOrderItems) {
            groupedOrderItems.push(GroupedOrderItem.fromOrderItemGroup(orderItem));
        }

        const localReservation = new LocalReservation(customer, groupedOrderItems);
        localReservation.orderId = order.id;

        return localReservation;
    }

    public clone(): LocalReservation {
        const clone = JSON.parse(JSON.stringify(this));
        const localReservation = new LocalReservation(clone.customer, clone.groupedOrderItems);
        localReservation.orderId = this.orderId;
        return localReservation;
    }

    public isLocalReservation(): boolean {
        return this.orderId === null;
    }

    public hasItems(): boolean {
        return this.groupedOrderItems.length > 0;
    }
}
