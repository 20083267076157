import {ModelBase} from '../abstract/model-base';
import {OrderItemDetails} from './order-item-details.class';

export class OrderItemListGroup extends ModelBase {
    public _venueSection: string;

    public name: string;
    public date: string;
    public orderItemDetails: OrderItemDetails[] = [];

    constructor() {
        super();
    }

    mapModel(data: any): OrderItemListGroup {
        const model = new OrderItemListGroup();

        model.name = data.name;
        model.date = data.date;
        model.orderItemDetails = data.orderItemDetails ? data.orderItemDetails.map((orderItemDetail: any) => (new OrderItemDetails()).mapModel(orderItemDetail)) : [];

        return model;
    }
}

export function mapOrderItemListGroup(item: any): OrderItemListGroup {
    return (new OrderItemListGroup()).mapModel(item);
}
