export const generateLoadingRows = (columnAmount: number, rowAmount = 10, replaceLastColumnWithActionColumn = true) => {
    const columns = Array(columnAmount).fill({value: null, options: [{name: 'data-is-loading', value: ''}]});

    if (replaceLastColumnWithActionColumn) {
        columns.splice(columns.length - 1, 1);
        columns.push({value: null, options: [{name: 'data-is-loading', value: ''}, {name: 'data-action', value: ''}]});
    }

    return Array(rowAmount).fill({
        id: null, rowData: columns
    });
};
