import { HttpParams } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../../shared/models/customer.class';
import {GuestManagerUser} from '../../../shared/models/guest-manager/guest-manager-user.class';
import {CustomerService} from '../../../shared/services/customer-service/customer.service';
import {DialogService} from '../../../shared/services/dialog-service/dialog.service';
import {NotificationService} from '../../../shared/services/notification-service/notification.service';
import {
    DialogConfirmationComponent
} from '../../../shared/components/dialogs/dialog-confirmation/dialog-confirmation.component';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    selector: 'app-customer-guest-manager-list',
    templateUrl: './customer-guest-manager-list.component.html',
    styleUrls: ['./customer-guest-manager-list.component.css']
})
export class CustomerGuestManagerListComponent implements OnInit {
    @Input() customer: Customer;
    error = false;
    errorMessage = null;

    guestManagerUser: GuestManagerUser;
    guestManagerForm = false;

    constructor(private notificationService: NotificationService,
                private customerService: CustomerService,
                private dialogService: DialogService,
                private clipboard: Clipboard) {
    }

    ngOnInit() {
        this.guestManagerUser = new GuestManagerUser(null, null, this.customer.firstName, this.customer.insertion, this.customer.lastName, this.customer.email, this.customer.phoneNumber);
    }

    addGuestManagerUser() {
        this.guestManagerUser.organisationId = this.customer.guestManagerOrganisationId;
        this.customerService.addGuestManagerUser(this.customer, this.guestManagerUser).subscribe({
            next: (user) => {
                this.customer.guestManager.users.push(user);
                this.guestManagerUser = new GuestManagerUser(
                    null,
                    this.customer.guestManagerOrganisationId,
                    this.customer.firstName,
                    this.customer.insertion,
                    this.customer.language,
                    this.customer.email,
                    this.customer.phoneNumber
                );
                this.guestManagerForm = false;
            },
            error: (error) => {
                this.errorMessage = error.error.details;
            }
        });
    }

    enableGuestManager(customer) {
        const body = {guestManager: true};
        this.error = false;

        this.customerService.patchCustomer(customer.id, body, new HttpParams().set('customer[guestManager]', 'true').set('depth', '1')).subscribe({
            next: (customerData) => {
                this.customer = customerData;
            },
            error: (error) => {
                this.error = error.error.code;
            }
        });
    }

    sendPasswordForgotMail(username: string) {
        this.customerService.resetGuestManagerPassword(username, this.customer.guestManagerOrganisationId).subscribe();
    }

    remoteLoginGuestManagerUser(userId: string) {
        this.customerService.remoteLoginGuestManagerUser(this.customer.id, userId).subscribe(response => {
            (window as any).open(response.remoteLoginUrl, '_blank');
        });
    }

    onCopyGuestManagerRemoteLoginClick(userId: string): void {
        this.customerService.remoteLoginGuestManagerUser(this.customer.id, userId).subscribe(response => {
            this.clipboard.copy(response.remoteLoginUrl);
            this.notificationService.showTranslatedNotification('success', 'link', 'copied');
        });
    }

    deleteGuestManagerUser(userId: string) {

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Delete_Guest_Manager_Account_Title'},
            bodyText: {key: 'Dialog.Delete_Guest_Manager_Account_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'customer-password-reset');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.customerService.deleteGuestManagerUser(this.customer.id, userId).subscribe(() => {
                    const index = this.customer.guestManager.users.findIndex((item: GuestManagerUser) => {
                        return item.id === userId;
                    }, 0);

                    if (index >= 0) {
                        this.customer.guestManager.users.splice(index, 1);
                    }

                    this.notificationService.showTranslatedNotification('success', 'account', 'deleted');
                });
            }
        });

    }

}
