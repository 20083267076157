export class SeatmapSeatInfo {
    constructor(
        public venueSectionSeatId: string,
        public productTypeId?: string,
        public eventCategoryIds?: string[]
    ) {
        this.venueSectionSeatId = venueSectionSeatId;
        this.eventCategoryIds = eventCategoryIds;
        this.productTypeId = productTypeId;
    }

    public isSelected(seatId: string, productId: string): boolean {
        return this.venueSectionSeatId === seatId && (!this.productTypeId || this.productTypeId === productId);
    }
}
