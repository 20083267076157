<cm-dialog [id]="id">

    <h3 slot="header" class="dialog-title">
        {{('Customer_Form.Relation_Blacklist' | trans)}}
    </h3>

    <div slot="body" class="form-row">
        {{('Customer_Form.Blacklist_Dialog_Body' | trans)}}

        <p>
            {{('Customer_Form.Relation' | trans)}}: {{ extraData.customer.getName() }}<br>
            {{('Customer_Form.Period' | trans)}}: {{ extraData.blacklistPeriod.startDate }} -
            {{ extraData.blacklistPeriod.endDate }}
        </p>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('Customer_Form.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('Customer_Form.Save' | trans)}}</button>
    </div>

</cm-dialog>
