import {Component, Input, OnInit} from '@angular/core';
import {Payment} from '../../models/payment.class';

@Component({
               selector:    'app-payment-table',
               templateUrl: './payment-table.component.html',
               styleUrls:   ['./payment-table.component.css']
           })
export class PaymentTableComponent implements OnInit {
    @Input() payments: Payment[] = [];
    @Input() isLoading           = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
