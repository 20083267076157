<div class="card card-action" [class.closed]="!isExpanded" [draggable]="!isExpanded">
    <div class="card-menu-header" (click)="$event.preventDefault(); $event.stopPropagation(); isExpanded = !isExpanded">
        <div class="row card-menu-header-title">
            <div class="col-10">
                @if (eventSaleSetting.name) {
                    <div>{{ eventSaleSetting.name }}</div>
                }
                <div class="f-s-13 m-b-8 m-t-4 datetime-span">
                    <ng-container
                            *ngIf="!eventSaleSetting.startAt && !eventSaleSetting.endAt">{{ ('General.Always' | trans) }}</ng-container>
                    <ng-container
                            *ngIf="eventSaleSetting.startAt"><span
                            class="f-w-200">{{ 'Date.From' | trans }}</span> {{ eventSaleSetting.startAt | date:'EE d MMM H:mm' }}
                    </ng-container>
                    <ng-container
                            *ngIf="eventSaleSetting.endAt"><span
                            class="f-w-200">{{ 'Date.Until' | trans }}</span> {{ eventSaleSetting.endAt | date:'EE d MMM H:mm' }}
                    </ng-container>
                </div>
            </div>
            <div class="col-2 text-right">
                <ng-container *ngIf="isExpanded">
                    <button class="btn btn-secondary-icon"><!-- delete-->
                        <span class="cm-icon cm-icon-delete" aria-hidden="true"
                              (click)="$event.stopPropagation(); deleteEventSettingSetting()"></span>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="row">
            @for (headerColumn of headerColumns; track headerColumn) {
                <div class="col">
                    <div class="f-s-12 f-w-200">{{ (headerColumn.title | trans) }}</div>
                    <div class="f-s-13 f-w-700">
                        <app-list-preview [items]="headerPreviewItemMapping[headerColumn.id]"
                                          [previewLength]="3" [separator]="'<br>'"
                                          [empty]="'All'"></app-list-preview>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="card-menu-wrapper">
        <div class="card-menu">
            <ng-container *ngFor="let eventSaleSettingType of eventSaleSettingTypes">
                <div class="card-menu-item" (click)="switchMenu(eventSaleSettingType.value)"
                     [class.active]="activeMenuItem == eventSaleSettingType.value"
                     [class.inactive]="eventSaleSettingType.value === null">
                    <ng-container *ngIf="eventSaleSettingType.key">
                        <div class="form-group">
                            <div class="form-row">
                                <div class="form-switch">
                                    <label class="switch">
                                        <input type="checkbox"
                                               [class.shop]="activeMenuItem === Settings.SHOP && activeMenuItem === eventSaleSettingType.value"
                                               [disabled]="isSettingDisabled(eventSaleSettingType)"
                                               [checked]="isSettingEnabled(eventSaleSettingType)"
                                               (change)="toggleEventSaleSetting(eventSaleSettingType)">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <span class="form-switch-label label" (click)="$event.preventDefault()">
                            {{ eventSaleSettingType.viewValue | trans }}
                        </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!eventSaleSettingType.key">
                        <span class="cm-icon cm-icon-list" aria-hidden="true"></span>
                        <span class="form-switch-label label" (click)="$event.preventDefault()">
                            {{ eventSaleSettingType.viewValue | trans }}
                        </span>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div class="card-menu-content form-group">
            <ng-container *ngFor="let eventSaleSettingType of eventSaleSettingTypes">

                <ng-container
                        *ngIf="activeMenuItem === Settings.DETAILS && activeMenuItem === eventSaleSettingType.value">
                    <div class="form-row p-l-16">
                        <app-form-input (valueChange)="changeEventSettingSetting()"
                                        [(value)]="eventSaleSetting.name"
                                        [label]="('General.Name' | trans)">
                        </app-form-input>
                    </div>
                </ng-container>

                <ng-container *ngIf="activeMenuItem === Settings.SHOP && activeMenuItem === eventSaleSettingType.value">
                    <app-setting-checkbox-picker
                            [categories]="[{id: 0, name: ('General.Shops' | trans), shops: shops}]"
                            [categoryName]="'name'"
                            [optionsKey]="'shops'"
                            [optionKey]="'id'"
                            [optionName]="'name'"
                            [selectedOptions]="eventSaleSetting.filterShopIds"
                            (selectionChanged)="onArraySelectionChanged(eventSaleSettingType.key, $event)"></app-setting-checkbox-picker>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.START_AT && activeMenuItem === eventSaleSettingType.value">
                    <div class="form-row p-l-16">
                        <app-form-datepicker-v3
                                [label]="'Restriction.Sale_Start' | trans"
                                [value]="eventSaleSetting.startAt"
                                [maxDate]="eventSaleSetting.endAt"
                                (change)="onStartDateChange($event)"></app-form-datepicker-v3>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.END_AT && activeMenuItem === eventSaleSettingType.value">
                    <div class="form-row p-l-16">
                        <app-form-datepicker-v3
                                [label]="'Restriction.Sale_End' | trans"
                                [value]="eventSaleSetting.endAt"
                                [minDate]="eventSaleSetting.startAt"
                                (change)="onEndDateChange($event)"></app-form-datepicker-v3>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.MAX_AMOUNT && activeMenuItem === eventSaleSettingType.value">
                    <div class="form-row p-l-16">
                        <app-form-input type="number"
                                        (valueChange)="changeEventSettingSetting()"
                                        [(value)]="eventSaleSetting.maxAmount"
                                        [label]="('Restriction.Max_Amount' | trans)">
                        </app-form-input>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.VERIFICATION_REQUIRED && activeMenuItem === eventSaleSettingType.value">
                    <ng-container *ngIf="isSettingDisabled(eventSaleSettingType)">
                        <div class="card-menu-content-disabled cursor-pointer" (click)="navigateToIntegrations()">
                            <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                            {{ ('Restriction.Verification_Required.Disabled' | trans) }}
                        </div>
                    </ng-container>
                    <span class="f-w-500">
                    {{ ('Restriction.Verification_Required.Title'| trans) }}
                </span>
                    <div class="form-row p-l-16">
                        <app-form-checkbox [(value)]="eventSaleSetting.verificationRequired"
                                           [options]="[{label: ('Restriction.Verification_Required' | trans)}]">
                        </app-form-checkbox>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.TOTAL_MIN_AMOUNT && activeMenuItem === eventSaleSettingType.value">
                    <div class="form-row p-l-16">
                        <app-form-input type="number"
                                        (valueChange)="changeEventSettingSetting()"
                                        [(value)]="eventSaleSetting.totalMinAmount"
                                        [label]="('Restriction.Total_Min_Amount' | trans)">
                        </app-form-input>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.CAPACITY && activeMenuItem === eventSaleSettingType.value">
                    <div class="warning-message bg-warning-color-light p-24 m-b-16">
                        <span class="cl-orange-200"><b>Note</b>: The max in sale capacity is not shared among multiple ticket type or venue section filters. The max in sale ticket is calculated for each combination of ticket type and venue section. If there is an overlap with other sale rules the highest value is used.</span>
                    </div>
                    <div class="form-row p-l-16">
                        <app-form-input type="number"
                                        (valueChange)="changeEventSettingSetting()"
                                        [(value)]="eventSaleSetting.capacity"
                                        [label]="('Event_Sale_Setting.Capacity' | trans)">
                        </app-form-input>
                    </div>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.VENUE_SECTION && activeMenuItem === eventSaleSettingType.value">
                    <ng-container *ngIf="isSettingDisabled(eventSaleSettingType)">
                        <div class="card-menu-content-disabled">
                            <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                            This setting is event specific
                        </div>
                    </ng-container>
                    <app-setting-checkbox-picker
                            [categories]="venue?.sections"
                            [categoryName]="'name'"
                            [optionsKey]="'sections'"
                            [optionKey]="'id'"
                            [optionName]="'name'"
                            [selectedOptions]="eventSaleSetting.venueSectionIds"
                            (selectionChanged)="onArraySelectionChanged(eventSaleSettingType.key, $event)"></app-setting-checkbox-picker>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.TICKET_TYPE && activeMenuItem === eventSaleSettingType.value">
                    <ng-container *ngIf="isSettingDisabled(eventSaleSettingType)">
                        <div class="card-menu-content-disabled">
                            <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                            This setting is event specific
                        </div>
                    </ng-container>
                    <app-setting-checkbox-picker
                            [categories]="[{id: 0, name: ('General.Ticket_Types' | trans), ticketTypes: ticketTypes}]"
                            [categoryName]="'name'"
                            [optionsKey]="'ticketTypes'"
                            [optionKey]="'id'"
                            [optionName]="'name'"
                            [selectedOptions]="eventSaleSetting.ticketTypeIds"
                            (selectionChanged)="onArraySelectionChanged(eventSaleSettingType.key, $event)"></app-setting-checkbox-picker>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.SUBSCRIPTION_TYPE && activeMenuItem === eventSaleSettingType.value">
                    <app-setting-checkbox-picker
                            [categories]="subscriptionTypeGroups"
                            [categoryName]="'key'"
                            [optionsKey]="'items'"
                            [optionKey]="'id'"
                            [optionName]="'name'"
                            [selectedOptions]="eventSaleSetting.filterSubscriptionTypeIds"
                            (selectionChanged)="onArraySelectionChanged(eventSaleSettingType.key, $event)"></app-setting-checkbox-picker>
                </ng-container>

                <ng-container
                        *ngIf="activeMenuItem === Settings.CUSTOMER_TAG && activeMenuItem === eventSaleSettingType.value">
                    <div class="row info-banner">
                        <div class="col-1 text-center">
                            <span class="cm-icon cm-icon-information-open" aria-hidden="true"></span>
                        </div>
                        <div class="col-11 p-t-4">
                            {{ ('Event_Sale_Setting.Customer_Tags_Hint' | trans) }}
                        </div>
                    </div>
                    <app-setting-checkbox-picker
                            [categories]="[{id: 0, name: ('General.Tags' | trans), tags: customerTags}]"
                            [categoryName]="'name'"
                            [optionsKey]="'tags'"
                            [optionKey]="'id'"
                            [optionName]="'name'"
                            [selectedOptions]="eventSaleSetting.filterCustomerTagIds"
                            (selectionChanged)="onArraySelectionChanged(eventSaleSettingType.key, $event)"></app-setting-checkbox-picker>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

