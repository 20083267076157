export abstract class Download {
    fileName: string;
    type: string;
    filters: any;
    total: number;
    batchSize = 500;
    _progress = 0;
    formatting = {};
    isExcelDownload = true;

    setFileName(fileName: string) {
        this.fileName = fileName;

        return this;
    }

    setTotal(total: number) {
        this.total = total;

        return this;
    }

    setBatchSize(batchSize: number) {
        this.batchSize = batchSize;

        return this;
    }

    setFilters(filters: any) {
        this.filters = filters;

        return this;
    }

    getTimezone() {
        let timezone = 'UTC';

        try {
            if (Intl.DateTimeFormat().resolvedOptions()) {
                timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }

            if ((window as any).getCmSSO().PersonPreferences.TimeZone) {
                timezone = (window as any).getCmSSO().PersonPreferences.TimeZone;
            }
        } catch (error) {
        }

        return timezone;
    }

    set progress(progress: number) {
        if (progress > 100) {
            progress = 100;
        }

        this._progress = progress;
    }

    get progress(): number {
        return this._progress;
    }

    abstract getData(type: string, skip: number, total: number, filters);
}
