import {AnalyticsDashboardItem, mapAnalyticsDashboardItem} from './analytics-dashboard-item.class';

export class AnalyticsDashboard {
    constructor(public id: string                               = null,
                public name: string                             = null,
                public dashboardItems: AnalyticsDashboardItem[] = [],
                public startAt: string                          = '-7days',
                public endAt: string                            = 'today') {
    }
}

export function mapAnalyticsDashboard(item: any): AnalyticsDashboard {
    return new AnalyticsDashboard(
        item.id,
        item.name,
        item.dashboardItems.map(dashboardItem => mapAnalyticsDashboardItem(dashboardItem)),
        item.startAt,
        item.endAt
    );
}
