import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {mapPaymentMethod, PaymentMethod} from '../../models/payment-method.class';
import {map} from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Payment} from '../../models/payment.class';

@Injectable()
export class PaymentService {

    private config = {
        withCredentials: true,
        params:          {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient) {
    }

    getPaymentMethods(params?: HttpParams): Observable<PaymentMethod[]> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<PaymentMethod[]>(this.baseUrl + `/v1.0/payment/method`, config).pipe(
            map((items: PaymentMethod[]) => {
                return items.map((item: PaymentMethod) => {
                    return mapPaymentMethod(item);
                });
            })
        );
    }

    getPayments(params: HttpParams): Observable<HttpResponse<Payment[]>> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Payment[]>(this.baseUrl + `/v1.0/payment`, {
            ...config,
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Payment[]>) => {
                const body = response.body.map(payment => {
                    return new Payment().mapModel(payment);
                });

                return response.clone({body});
            })
        );
    }

    getPaymentStatistics(params: HttpParams): Observable<any> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Payment>(this.baseUrl + `/v1.0/payment/statistics`, config);
    }

    getPaymentStatisticsGrouped(params: HttpParams): Observable<any> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Payment>(this.baseUrl + `/v1.0/payment/statistics/grouped`, config);
    }

    postPayment(body: any): Observable<Payment> {
        return this.http.post<Payment>(this.baseUrl + `/v1.0/payment`, body, this.config);
    }

    checkPaymentStatus(paymentId: string): Observable<Payment> {
        return this.http.post<Payment>(this.baseUrl + `/v1.0/payment/${paymentId}/check`, null, this.config).pipe(
            map((item: Payment) => {
                return new Payment().mapModel(item);
            })
        );
    }

}
