import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';

@Component({
               selector:    'app-dialog-alert',
               templateUrl: 'dialog-alert.component.html',
               styleUrls:   ['./dialog-alert.component.css']
           })
export class DialogAlertComponent extends DialogBase<IInput, IOutput> {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.titleText   = data.titleText;
        this.bodyText    = data.bodyText;
        this.confirmText = data.confirmText;
        this.extraData   = data.extraData;
    }

    public confirm() {
        super.confirm({
                          successful: true,
                          extraData:  'example'
                      });
    }

    public cancel() {
        super.cancel();
    }

}
