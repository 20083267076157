export class AnalyticsDashboardItemSetting {
    constructor(public id: string                         = null,
                public analyticsDashboardItemId: string   = null,
                public eventId: string                    = null,
                public subscriptionTypeId: string         = null,
                public venueSectionIds: string[]          = [],
                public ticketTypeIds: string[]            = [],
                public subscriptionTypePriceIds: string[] = [],
                public customerTagIds: string[]           = [],
                public type: string                       = null,
                public groupBy: string                    = null,
                public isCumulative: boolean              = null,
                public isStacked: boolean                 = null,
                public startAt: string                    = null,
                public endAt: string                      = null,
                public createdAt: string                  = null,
                public updatedAt: string                  = null) {
    }
}

export function mapAnalyticsDashboardItemSetting(item: any): AnalyticsDashboardItemSetting {
    return new AnalyticsDashboardItemSetting(
        item.id,
        item.analyticsDashboardItemId,
        item.eventId,
        item.subscriptionTypeId,
        item.venueSectionIds,
        item.ticketTypeIds,
        item.subscriptionTypePriceIds,
        item.customerTagIds,
        item.type,
        item.groupBy,
        item.isCumulative,
        item.isStacked,
        item.startAt,
        item.endAt,
        item.createdAt,
        item.updatedAt
    );
}
