import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    constructor(private router: Router) {
    }

    openSidebar(value: string): void {
        const options = {detail: value, overlay: null};

        this.router.navigate([{outlets: options}]);
    }

    closeSidebar(value?: string): void {
        if (value) {
            this.openSidebar(value);
        } else {
            this.router.navigate([{outlets: {detail: null, overlay: null}}]);
        }
    }

    closeOverlay(): void {
        this.router.navigate([{outlets: {overlay: null}}]);
    }
}
