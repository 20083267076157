<ng-container *ngFor="let orderDelivery of orderDeliveries">
    <div class="row order-delivery">
        <ng-container
                *ngTemplateOutlet="orderDeliveryMethodTemplate; context: { $implicit: orderDelivery }"></ng-container>
        <div class="text-right">
            <div class="fs-small" [cm-tooltip]="orderDelivery.sendAt | date:'EE d MMM y H:mm'">
                {{ orderDelivery.sendAt | date:'shortDate' }}
            </div>
        </div>
    </div>
</ng-container>

<ng-template #orderDeliveryMethodTemplate let-orderDelivery>
    <div>
        <span class="cm-icon" aria-hidden="true"
              [class.cm-icon-pdf]="orderDelivery.method === orderDeliveryMethod.DOWNLOAD_PDF"
              [class.cm-icon-email]="orderDelivery.method === orderDeliveryMethod.EMAIL"
              [class.cm-icon-message]="orderDelivery.method === orderDeliveryMethod.SMS"
              [class.cm-icon-user-open]="orderDelivery.method === orderDeliveryMethod.GUEST_MANAGER"
              [class.cm-icon-telegram]="orderDelivery.method === orderDeliveryMethod.POST"
              [class.cm-icon-delivery]="orderDelivery.method === orderDeliveryMethod.PICKUP"
              [class.cm-icon-print]="[
                orderDeliveryMethod.PRINT_A4,
                orderDeliveryMethod.PRINT_BOCA,
                orderDeliveryMethod.PRINT_PVC_CARD
            ].includes(orderDelivery.method)">
        </span>
    </div>

    <div class="order-delivery-method-name">
        {{ ('Order_Delivery.Event_' + orderDelivery.method | trans) }}<br>
        <span class="fs-small">{{ orderDelivery.firstName }} {{ orderDelivery.lastName }}</span>
        @switch (orderDelivery.method) {
            @case (orderDeliveryMethod.EMAIL) {
                <span class="fs-small">{{ orderDelivery.email }}</span>
            }
            @case (orderDeliveryMethod.SMS) {
                <span class="fs-small">{{ orderDelivery.phoneNumber }}</span>
            }
        }
    </div>
</ng-template>
