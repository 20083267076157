<div class="row">
    <div class="col-12 col-sm-6 offset-sm-1">
        <div class="page-title">
            <div class="page-title-breadcrumb">{{config.breadcrumbTranslationKey | trans }} /</div>
            <h1 class="page-title-title">{{config.titleTranslationKey | trans }}</h1>
            <app-stepper [current]="currentStep + 1" [total]="steps.length - 1"
                         (stepClick)="onStepClick($event)"></app-stepper>
        </div>

        <ng-content></ng-content>
    </div>

    <div class="col-12 col-sm-4 offset-sm-1 text-right">
        <cm-image [attr.data-type]="config.illustration" data-min-height="280"></cm-image>
    </div>
</div>
