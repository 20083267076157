import {map} from 'rxjs/operators';
import {Phrase} from '../interfaces/translation.interface';
import {TranslationService} from '../services/translation.service';
import {combineLatest, Observable} from 'rxjs';

// @dynamic
export class TranslationHelper {
    public static translateValue(translationService: TranslationService, value: string): Observable<string> {
        return translationService.getPhraseForLanguage(value).pipe(
            map((phrase: Phrase) => {
                return phrase !== undefined ? phrase.Value : '';
            })
        );
    }

    public static translateValues(translationService: TranslationService, values: string[]): Observable<string[]> {
        const requests = [];
        for (let i = 0; i < values.length; i++) {
            requests[i] = translationService.getPhraseForLanguage(values[i]);
        }

        return combineLatest(requests).pipe(
            map((phrases: Phrase[]) => {
                return phrases.map(phrase => phrase !== undefined ? phrase.Value : '');
            })
        );
    }
}
