import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EventManagementService} from '../../../services/event-service/event-management.service';
import {Observable} from 'rxjs/internal/Observable';
import {Event} from '../../../models/event.class';
import {ChartSetting} from '../../../models/chart-settings.class';
import {CompactType, DisplayGrid, GridsterConfig, GridType} from 'angular-gridster2';
import moment from 'moment';
import {AnalyticsDashboardItem} from '../../../models/analytics-dashboard-item.class';
import {AnalyticsDashboard} from '../../../models/analytics-dashboard.class';
import {ActivatedRoute} from '@angular/router';
import {AnalyticsDashboardService} from '../../../services/analytics-dashboard-service/analytics-dashboard.service';
import { HttpParams } from '@angular/common/http';
import {AnalyticsDashboardEditorService} from '../../../services/analytics-dashboard-editor-service/analytics-dashboard-editor.service';
import {CmTranslationService} from '../../../../core/services/cm-translation.service';
import {AnalyticsDashboardItemService} from '../../../services/analytics-dashboard-item-service/analytics-dashboard-item.service';

@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: './analytics-dashboard.component.html',
    styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit, OnDestroy {
    @Input() analyticsDashboardId: string;
    toggleVenueSectionTable = false;
    events$: Observable<Event[]>;
    eventIds: string[] = [];
    chartSettings = new ChartSetting();
    dateRange = '';
    analyticsDashboard: AnalyticsDashboard;
    dashboardOptions: GridsterConfig = {
        gridType: GridType.VerticalFixed,
        compactType: CompactType.None,
        margin: 32,
        outerMargin: false,
        useTransformPositioning: true,
        mobileBreakpoint: 640,
        minCols: 4,
        maxCols: 4,
        minRows: 2,
        maxRows: 20,
        maxItemCols: 4,
        minItemCols: 1,
        maxItemRows: 4,
        minItemRows: 1,
        maxItemArea: 2500,
        minItemArea: 1,
        defaultItemCols: 2,
        defaultItemRows: 2,
        fixedRowHeight: 150,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 10,
        scrollSpeed: 20,
        enableEmptyCellClick: false,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: false,
        enableEmptyCellDrag: false,
        enableOccupiedCellDrop: false,
        emptyCellDragMaxCols: 4,
        emptyCellDragMaxRows: 4,
        ignoreMarginInRow: false,
        draggable: {
            enabled: true,
            ignoreContent: true,
            dragHandleClass: 'drag-handle'
        },
        resizable: {
            enabled: true,
        },
        swap: true,
        pushItems: true,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushResizeItems: false,
        displayGrid: DisplayGrid.OnDragAndResize,
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false,
        itemResizeCallback: this.itemResize,
        itemChangeCallback: this.changedOptions,
    };
    update;
    editMode = false;
    data = [];
    today = moment().toISOString();
    startAt;
    endAt;

    constructor(private route: ActivatedRoute,
                private changeDetection: ChangeDetectorRef,
                private cmTranslationService: CmTranslationService,
                private analyticsDashboardService: AnalyticsDashboardService,
                private analyticsDashboardItemService: AnalyticsDashboardItemService,
                private eventManagementService: EventManagementService,
                private analyticsDashboardEditorService: AnalyticsDashboardEditorService) {
    }

    ngOnInit() {
        this.events$ = this.eventManagementService.list$;

        if (this.analyticsDashboardId) {
            this.analyticsDashboardService
                .get(this.analyticsDashboardId, new HttpParams().set('depth', '3'))
                .subscribe(analyticsDashboard => {
                    this.analyticsDashboard = analyticsDashboard;
                });
        } else {
            this.route.params.subscribe(params => {
                this.analyticsDashboardService
                    .get(params.analyticsDashboardId, new HttpParams().set('depth', '3'))
                    .subscribe(analyticsDashboard => {
                        this.analyticsDashboard = analyticsDashboard;
                    });
            });
        }
    }

    ngOnDestroy(): void {
        this.analyticsDashboardEditorService.setEditAnalyticsDashboardItem(null);
    }

    setRange(startAt: string, endAt: string) {
        this.analyticsDashboard.startAt = startAt;
        this.analyticsDashboard.endAt = endAt;
        this.analyticsDashboard = Object.assign({}, this.analyticsDashboard);
        this.updateDateRangeText();

        for (const dashboardItem of this.analyticsDashboard.dashboardItems) {
            dashboardItem.updatedAt = Date.now().toString();
        }
    }

    itemResize(item, itemComponent) {
        item.updatedAt = Date.now();
    }

    changedOptions(item, itemComponent) {
        item.updatedAt = Date.now();
    }

    removeItem(item) {
        this.analyticsDashboard.dashboardItems.splice(this.analyticsDashboard.dashboardItems.indexOf(item), 1);
    }

    addItem() {
        const dashboardItem = new AnalyticsDashboardItem();
        dashboardItem.name = 'New graph';
        dashboardItem.updatedAt = Date.now().toString();
        this.analyticsDashboard.dashboardItems.push(dashboardItem);

        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }

    updateAnalyticsDashboardItem(analyticsDashboardItem: AnalyticsDashboardItem) {
        analyticsDashboardItem.chartSetting = Object.assign({}, analyticsDashboardItem.chartSetting);
    }

    updateDateRangeText() {
        if (this.analyticsDashboard?.startAt || this.analyticsDashboard?.endAt) {
            this.dateRange = this.cmTranslationService.getPhraseForLanguage(`Analytics.Date_Range_Specific`);
        } else {
            this.dateRange = this.cmTranslationService.getPhraseForLanguage(`Analytics.Date_Range_All`);
        }
    }

    close() {
    }

    back() {
    }

    save() {
        this.analyticsDashboard.dashboardItems.forEach(dashboardItem => {

            dashboardItem.positionX = dashboardItem.gridster.x;
            dashboardItem.positionY = dashboardItem.gridster.y;
            dashboardItem.width = dashboardItem.gridster.cols;
            dashboardItem.height = dashboardItem.gridster.rows;

            if (dashboardItem.id) {
                this.analyticsDashboardItemService.patchAnalyticsDashboardItem(
                    this.analyticsDashboard.id,
                    dashboardItem.id,
                    dashboardItem
                ).subscribe();
            } else {
                this.analyticsDashboardItemService.postAnalyticsDashboardItem(
                    this.analyticsDashboard.id,
                    dashboardItem
                ).subscribe();
            }
        });
    }

}
