import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-statistics-groups',
    templateUrl: './statistics-groups.component.html',
    styleUrls: ['./statistics-groups.component.css']
})
export class StatisticsGroupsComponent {
    @Input() statisticGroups: any[] = [];
    @Input() isLoading = false;
}
