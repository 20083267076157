<ng-container *ngIf="isNative">
    <div class="native form-input" [class.error]="!isValid">
        <label>{{label}}</label>
        <select class="form-control w-100"
                (change)="inputChange()"
                [disabled]="disabled"
                [(ngModel)]="value"
                [required]="required">
            <ng-container *ngIf="optionAddNull">
                <option [value]="null">{{('General.None' | trans)}}</option>
            </ng-container>
            <ng-container *ngIf="options">
                <ng-container *ngFor="let option of options">
                    <option [value]="option[optionValue]">
                        <ng-container *ngIf="optionLabelTranslate">
                            {{(option[optionLabel]| trans)}}
                        </ng-container>
                        <ng-container *ngIf="!optionLabelTranslate">
                            {{option[optionLabel]}}
                        </ng-container>
                    </option>
                </ng-container>
            </ng-container>
        </select>
        <ng-container *ngIf="!isValid">
            <div class="form-input-validation">
                <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="!isNative">
    <div class="form-input" [class.error]="!isValid">
        <cm-select [attr.data-label]="label"
                   [disabled]="disabled"
                   [attr.required]="required"
                   [value]="value"
                   [attr.data-error]="errorMessage ? (errorMessage | trans) : null"
                   [attr.readonly]="readonly ? true : null"
                   (cm-option-clicked)="value = $event.detail.value; inputChange()"
                   (cm-option-checkbox-clicked)="updateArray($event.detail.value)">
            <ng-container *ngIf="optionAddNull">
                <cm-option slot="option"
                           [value]="null"
                           [attr.data-display]="('General.None' | trans)">
                </cm-option>
            </ng-container>
            <ng-container *ngIf="options">
                <ng-container *ngFor="let option of options">
                    <ng-container *ngIf="!isMultiselect">
                        <ng-container *ngIf="optionLabelTranslate">
                            <cm-option slot="option"
                                       [attr.value]="option[optionValue]"
                                       [attr.data-display]="(option[optionLabel]| trans)"
                                       [attr.data-display-description]="optionDescription ? (option[optionDescription] | trans) : null"
                                       [attr.disabled]="option[optionDisabled] ? true : null">
                            </cm-option>
                        </ng-container>
                        <ng-container *ngIf="!optionLabelTranslate">
                            <cm-option slot="option"
                                       [attr.value]="option[optionValue]"
                                       [attr.data-display]="option[optionLabel]"
                                       [attr.data-display-description]="optionDescription ? option[optionDescription] : null">
                            </cm-option>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isMultiselect">
                        <ng-container *ngIf="optionLabelTranslate">
                            <cm-select-option-checkbox slot="option"
                                                       [attr.value]="option[optionValue]"
                                                       [attr.data-display]="(option[optionLabel]| trans)">
                            </cm-select-option-checkbox>
                        </ng-container>
                        <ng-container *ngIf="!optionLabelTranslate">
                            <cm-select-option-checkbox slot="option"
                                                       [attr.value]="option[optionValue]"
                                                       [attr.data-display]="option[optionLabel]">
                            </cm-select-option-checkbox>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </cm-select>
    </div>
</ng-container>
