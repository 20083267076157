<div class="table-responsive">
    <table class="table full-width">
        <thead>
        <tr>
            <th>{{ ('Venue_Section.Name' | trans) }}</th>
            <th>{{ ('Venue_Section.Price' | trans) }}</th>
            <th>
                <ng-container *ngIf="venueSectionAvailable">
                    {{ ('Venue_Section.Available' | trans) }}
                </ng-container>
            </th>
            <th style="width: 72px"></th>
            <th style="width: 120px">{{ ('Venue_Section.Amount' | trans) }}</th>
            <th style="width: 72px"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="subscriptionType">
            <ng-container *ngFor="let subscriptionTypePrice of subscriptionTypePrices; let i = index">
                <ng-container *ngIf="subscriptionTypePrice.priceItems.length">
                    <tr>
                        <td>{{ subscriptionTypePrice.name }}</td>
                        <td>{{ priceItems[i]?.price | currency }}</td>
                        <td><ng-container *ngIf="venueSectionAvailable">{{ venueSectionAvailable - amountOfLocalGACapacity }}</ng-container></td>
                        <td class="button">
                            <button class="btn btn-icon"
                                    (click)="alterTicketTypeAmount(subscriptionTypePrice.id, '-')"
                                    [disabled]="!productsAmountSelected[subscriptionTypePrice.id] || productsAmountSelected[subscriptionTypePrice.id] <= 0">
                                <span class="cm-icon cm-icon-remove" aria-hidden="true"></span>
                            </button>
                        </td>
                        <td style="width: 80px">
                            <div class="form-group">
                                <div class="form-input">
                                    <input type="number" min="0" class="form-control input-amount" placeholder="0"
                                           [(ngModel)]="productsAmountSelected[subscriptionTypePrice.id]"
                                           [max]="venueSectionAvailable - amountOfLocalGACapacity"
                                           (input)="inputChanged($event, subscriptionTypePrice.id)">
                                </div>
                            </div>
                        </td>
                        <td class="button">
                            <button class="btn btn-icon" (click)="alterTicketTypeAmount(subscriptionTypePrice.id, '+')"
                                    [disabled]="shouldDisableAddProductTypeButton(venueSectionAvailable - amountOfLocalGACapacity, subscriptionTypePrice.id)">
                                <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
        <!-- Extra products -->
        <ng-container *ngIf="productTypePrices">
            <ng-container *ngFor="let productTypePrice of productTypePrices">
                <tr>
                    <td>{{ productTypePrice.name }}</td>
                    <td>{{ productTypePrice.priceItem.price | currency: productTypePrice.currency }}</td>
                    <td>{{ productTypePrice.available - productsAmountInReservation.get(productTypePrice.id) }}</td>
                    <td class="button">
                        <button class="btn btn-icon"
                                (click)="alterTicketTypeAmount(productTypePrice.id, '-')"
                                [disabled]="!productsAmountSelected[productTypePrice.id] || productsAmountSelected[productTypePrice.id] <= 0">
                            <span class="cm-icon cm-icon-remove" aria-hidden="true"></span>
                        </button>
                    </td>
                    <td style="width: 80px">
                        <div class="form-group">
                            <div class="form-input">
                                <input type="number" min="0"
                                       [max]="productTypePrice.available - productsAmountInReservation.get(productTypePrice.id)"
                                       class="form-control input-amount"
                                       placeholder="0"
                                       [(ngModel)]="productsAmountSelected[productTypePrice.id]"
                                       (change)="capacityChanged(productTypePrice.id, productTypePrice.available)"
                                       (input)="inputChanged($event, productTypePrice.id)">
                            </div>
                        </div>
                    </td>
                    <td class="button">
                        <button class="btn btn-icon"
                                (click)="alterTicketTypeAmount(productTypePrice.id, '+')"
                                [disabled]="shouldDisableAddProductTypeButton(productTypePrice.available, productTypePrice.id)">
                            <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let ticketType of ticketTypes">
            <ng-container *ngIf="ticketType.prices[0]">
                <tr>
                    <td>{{ ticketType.name }}</td>
                    <td>{{ ticketType.prices[0].price | currency: ticketType.prices[0].currency }}</td>
                    <td>
                        {{ venueSectionAvailable - amountOfLocalGACapacity }}
                    </td>
                    <td class="button">
                        <button class="btn btn-icon" (click)="alterTicketTypeAmount(ticketType.id, '-')"
                                [disabled]="!productsAmountSelected[ticketType.id] || (venueSectionAvailable - this.amountOfLocalGACapacity) <= 0">
                            <span class="cm-icon cm-icon-remove" aria-hidden="true"></span>
                        </button>
                    </td>
                    <td class="input">
                        <div class="form-group">
                            <div class="form-input">
                                <input type="number" min="0" class="form-control input-amount" placeholder="0"
                                       [(ngModel)]="productsAmountSelected[ticketType.id]"
                                       (input)="inputChanged($event, ticketType.id)"
                                       [max]="venueSectionAvailable - this.amountOfLocalGACapacity"
                                       (change)="capacityChanged(ticketType.id, venueSectionAvailable - this.amountOfLocalGACapacity)">
                            </div>
                        </div>
                    </td>
                    <td class="button">
                        <button class="btn btn-icon"
                                [disabled]="(venueSectionAvailable - this.amountOfLocalGACapacity) - productsAmountSelected[ticketType.id] <= 0"
                                (click)="alterTicketTypeAmount(ticketType.id, '+')">
                            <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </table>
</div>

<div class="row footer">
    <div class="col-12 text-right m-t-16">
        <button class="btn btn-cta" [disabled]="totalItems === 0 ? true : null" (click)="addCapacityOrder()">
            {{ ('Venue_Section.Add' | trans) }}
        </button>
    </div>
</div>

