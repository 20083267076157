<div [cm-tooltip]="list">
    <ng-container *ngIf="items.length">
        <span [innerHTML]="preview"></span>
        <ng-container *ngIf="items.length > previewLength">
            <span class="cl-selected-color">&nbsp;+{{ items.length - previewLength}}</span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!items.length">
        {{ empty }}
    </ng-container>
</div>
