import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {mapTicketType, TicketType} from '../../models/ticket-type.class';
import {NotificationService} from '../notification-service/notification.service';

@Injectable()
export class TicketTypeService {

    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient, private notificationService: NotificationService) {
    }

    getTicketTypes(eventId: string, params?: HttpParams): Observable<TicketType[]> {
        this.config.params = params;
        return this.http.get<TicketType[]>(this.baseUrl + `/v1.0/event/${eventId}/tickettype`, this.config).pipe(
            map(ticketTypes => {
                return ticketTypes.map(ticketType => mapTicketType(ticketType));
            })
        );
    }

    getTicketTypesByPricePlan(pricePlanId: string, params?: HttpParams): Observable<TicketType[]> {
        this.config.params = params;
        return this.http.get<TicketType[]>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}/tickettype`, this.config);
    }

    postTicketType(pricePlanId: string, ticketType: TicketType) {
        return this.http.post<TicketType>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}/tickettype`, ticketType, this.config);
    }

    patchTicketType(pricePlanId: string, ticketType: TicketType) {
        return this.http.patch<TicketType>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}/tickettype/${ticketType.id}`, ticketType, this.config);
    }

    deleteTicketType(pricePlanId: string, ticketTypeId: string) {
        return this.http.delete<any>(this.baseUrl + `/v1.0/price/priceplan/${pricePlanId}/tickettype/${ticketTypeId}`, this.config);
    }

}
