import {Injectable, Injector} from '@angular/core';
import {Event} from '../../models/event.class';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class EventManagementService extends StateManagementService {
    httpParams = new HttpParams()
        .set('event[externalData]', 'true')
        .set('event[eventCategoryName]', true)
        .set('depth', '3');

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new Event();

        this.initialize();
    }

}

