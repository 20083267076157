<div class="filter form-group">
    <div class="form-row">
        <div class="form-input-special">
            <input type="text" class="form-control" [placeholder]="placeholderValue ? (placeholderValue | trans) : ('General.Search' | trans)"
                   [formControl]="searchField"/>
            <span class="input-left icon">
                <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
            </span>
            <ng-container *ngIf="searchField.value">
            <span class="input-right icon" (click)="clearFilter()">
                <span aria-hidden="true" class="cm-icon cm-icon-close"></span>
            </span>
                <span class="input-right text">{{ results }}
                    <ng-container *ngIf="results !== 1"> {{('Search.Results' | trans)}}</ng-container>
                    <ng-container *ngIf="results === 1"> {{('Search.Result' | trans)}}</ng-container>
                </span>
            </ng-container>
        </div>
    </div>
</div>
