import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
    @Input() value: string;
    @Input() placeholderValue: string;
    @Input() results = 0;
    @Output() requestUpdate: EventEmitter<any> = new EventEmitter<any>();

    searchField: FormControl = new FormControl();

    constructor() {
    }

    ngOnInit() {
        this.searchField.setValue(this.value);
        this.searchField.valueChanges.pipe(debounceTime(500)).subscribe(input => {
            if (typeof input === 'string') {
                const search = input.trim();
                this.requestUpdate.emit(search);
            }
        });
    }

    clearFilter() {
        this.searchField.setValue(null);
        this.requestUpdate.emit();
    }

}
