import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-search-filter-boolean',
    templateUrl: './search-filter-boolean.component.html',
    styleUrls: ['./search-filter-boolean.component.scss']
})
export class SearchFilterBooleanComponent implements OnInit {

    @Input() toggled = false;
    @Input() name: string;
    @Output() toggledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
        this.toggledChange.emit(this.toggled);
    }

    buttonClicked() {
        this.toggled = !this.toggled;
        this.toggledChange.emit(this.toggled);
    }

    reset() {
        this.toggled = false;
    }
}
