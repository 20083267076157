<cm-dialog [id]="id"
           data-show-top-divider data-show-bottom-divider
           data-max-width="920px" data-max-height="480px">

    <div slot="header" class="dialog-title">
        <h3>{{('Dialog_File_Picker.File_Picker' | trans)}}</h3>

        <div class="tabs">
            <div class="tab" [class.active]="activeTab === 'UPLOADED_FILES'" (click)="onTabClicked('UPLOADED_FILES')">
                {{('Dialog_File_Picker.Uploaded_Files' | trans)}}
            </div>
            <div class="tab m-l-16" [class.active]="activeTab === 'FROM_COMPUTER'"
                 (click)="onTabClicked('FROM_COMPUTER')">
                {{('Dialog_File_Picker.From_Computer' | trans)}}
            </div>
        </div>
    </div>

    <div slot="body" class="form-row">
        <ng-container *ngIf="isLoading">
            <cm-indicator></cm-indicator>
        </ng-container>

        <ng-container *ngIf="activeTab === 'UPLOADED_FILES'">
            <div class="row">
                <ng-container *ngFor="let file of files; trackBy: fileTrackBy">
                    <div class="col-3 image-preview text-center"
                         [class.selected]="selectedFile && selectedFile.uuid === file.uuid"
                         (click)="onFileClicked(file)">
                        <ng-container [ngSwitch]="file.type">
                            <ng-container *ngSwitchCase="'IMAGE'">
                                <img [src]="file.link" [alt]="file.name">
                            </ng-container>
                            <ng-container *ngSwitchCase="'PDF'">
                                <img src="https://www.cm.com/app/aurora/svg/icons/pdf.svg" alt="PDF Icon">
                            </ng-container>
                        </ng-container>
                        <br>
                        {{ file.name }}
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="activeTab === 'FROM_COMPUTER'">
            <ng-container *ngIf="!uploadedFile">
                <div class="row m-t-32 m-b-32">
                    <div class="col-12 col-md-6 offset-md-3">
                        <cm-file-upload data-display="Upload file"
                                        data-max-file-size="4000000"
                                        (change)="onFileUploadSelected($event)"></cm-file-upload>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="uploadedFile">
                <div class="row">
                    <div class="col-6 offset-3 text-center">
                        <h3>{{ uploadedFile.name }}</h3>
                        <img [src]="uploadedFileBlob" [alt]="uploadedFile.name"><br>

                        <button class="btn btn-secondary"
                                (click)="onFileUploadUnsetClicked()">{{ 'Dialog_File_Picker.Change_File' | trans }}</button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('General.Cancel' | trans)}}</button>
        <ng-container *ngIf="activeTab === 'UPLOADED_FILES'">
            <button class="btn btn-primary" (click)="confirmSelect()"
                    [disabled]="!selectedFile">{{('General.Select' | trans)}}</button>
        </ng-container>
        <ng-container *ngIf="activeTab === 'FROM_COMPUTER'">
            <button class="btn btn-primary"
                    (click)="confirmUploadAndSelect()"
                    [disabled]="!uploadedFile">{{('General.Upload' | trans)}}</button>
        </ng-container>
    </div>

</cm-dialog>
