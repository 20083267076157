import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ListService {

    private cache: ListItem[][] = [];

    private readonly defaultListItemRequestConfig: ListItemRequestConfig = {
        getCached: true,
        pagination: false
    };

    constructor(private readonly http: HttpClient) {
    }

    public get(model: string, partialConfig: Partial<ListItemRequestConfig> = {}): Observable<ListItem[]> {
        const config: ListItemRequestConfig = {...this.defaultListItemRequestConfig, ...partialConfig};

        if (config.getCached && this.cache[model]) {
            return of(this.cache[model]);
        }

        return this.http.get<ListItem[]>(`${environment.CM_API_URL}/seatedapi/v1.0/dashboard/list/${model}`, {
            withCredentials: true
        }).pipe(map((response: any[]) => {
            const listItems: ListItem[] = response.map((item: any) => {
                return new ListItem(item.id, item.title, item.subtitle);
            });

            this.cache[model] = listItems;

            return listItems;
        }));
    }
}

export class ListItem {
    constructor(
        public readonly id: string,
        public readonly title: string,
        public readonly subtitle: string | null
    ) {
    }
}

export type ListItemRequestConfig = {
    getCached: boolean,
    pagination: boolean
};
