<div class="form-row">
    <div class="form-check">
        <label>
            <input type="checkbox" (change)="updateConsentSelection(consent)"
                   [checked]="inConsentSelection(consent)"
                   [disabled]="order.payedAt">

            <span class="label" [innerHTML]="consent.name"></span>
            <ng-container *ngIf="consent.isRequired">
                <span class="required-indicator">*</span>
            </ng-container>
            <ng-container *ngIf="consent.resource">
                <a class="consent-resource-link"
                   [href]="consent.resource.link"
                   target="_blank">
                    <span class="cm-icon cm-icon-pop-out small" aria-hidden="true"></span>
                </a>
            </ng-container>
        </label>
    </div>
</div>
