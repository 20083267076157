import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import {SportsApiService} from './sports-api.service';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiDiscoveryService {

    SPORTS_API_URL = environment.SPORTS_API_URL + '/sportsapi';

    constructor(private http: HttpClient, private sportsApi: SportsApiService) {
    }

    public getEndpoints(): Observable<Array<Endpoint>> {
        return this.http.get<Array<Endpoint>>(`${this.SPORTS_API_URL}/discover`).pipe(
            tap((endpoints: Array<Endpoint>) => this.sportsApi.setEndpoints(endpoints))
        );
    }
}

export type Endpoint = {
    id: string;
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
};

