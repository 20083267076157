import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {Venue} from '../../models/venue.class';
import {SeatedApiService} from '../../services/util/seated-api/seated-api.service';
import {BehaviorSubject, forkJoin, Observable, of, skip} from 'rxjs';
import {VenueSection} from '../../models/venue-section.class';
import { HttpParams } from '@angular/common/http';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import {map, switchMap, tap} from 'rxjs/operators';
import {SubscriptionType} from '../../models/subscription-type.class';
import {EventCategory} from '../../models/event-category.class';
import {Event} from '../../models/event.class';
import {LegendItem, SeatMapOptions} from '../seatmap/seatmap.component';
import {Blockade} from '../../models/blockade.class';
import {SeatMapTool} from '../../models/seatmap/seat-map-tool.interface';
import {SelectTool} from '../../models/seatmap/select-tool.class';
import {BlockadeTool} from '../../models/seatmap/blockade-tool.class';
import {Location} from '@angular/common';
import {BlockadeService} from '../../services/blockade.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EventService} from '../../services/event-service/event.service';
import {
    DialogVenueSectionSeatSettingComponent
} from '../dialogs/dialog-venue-section-seat-setting/dialog-venue-section-seat-setting.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {TranslationPipe} from '../../../core/pipes/translation.pipe';
import {SubscriptionService} from '../../services/subscription-service/subscription.service';
import {ReservationTool} from '../../models/seatmap/reservation-tool.class';
import {LocalReservationService} from '../../services/local-reservation.service';
import {OrderBarService} from '../../services/order-bar.service';
import {SeatmapSeatInfo} from './seatmap-seat-info';
import {VenueSectionContext} from '../../enums/venue-section/venue-section-context';


@Component({
    selector: 'app-venue-section-master-detail',
    templateUrl: './venue-section-master-detail.component.html',
    styleUrls: ['./venue-section-master-detail.component.scss']
})
export class VenueSectionMasterDetailComponent implements OnInit, OnChanges {

    @Input()
    venue: Venue;


    @Input()
    event: Event = null;

    @Input()
    subscriptionType: SubscriptionType = null;

    @Input()
    eventCategory: EventCategory;

    @Input()
    context: 'ORDER' | 'BLOCKADE' | 'MOVE' = 'BLOCKADE';

    @Input()
    seatMapOptions: SeatMapOptions = {
        showLegend: true,
        showName: true
    };

    @Output()
    venueSectionSeatSelected: EventEmitter<SeatmapSeatInfo[]> = new EventEmitter<SeatmapSeatInfo[]>();

    public venueSectionFilter: string = null;
    public selectedVenueSection: VenueSection = null;
    public visibleVenueSection: VenueSection = null;

    private venueSectionStream: BehaviorSubject<VenueSection> = new BehaviorSubject<VenueSection>(null);
    public venueSection$: Observable<VenueSection> = this.venueSectionStream.asObservable();

    private selectedVenueSectionSeatsStream: BehaviorSubject<SeatmapSeatInfo[]> = new BehaviorSubject<SeatmapSeatInfo[]>([]);
    public selectedVenueSectionSeatIds$: Observable<SeatmapSeatInfo[]> = this.selectedVenueSectionSeatsStream.asObservable().pipe(
        tap(venueSectionSeatIds => venueSectionSeatIds)
    );

    private blockades$: Observable<Blockade[]>;
    public blockades: Blockade[];
    public selectedBlockadeId: string = null;

    public tool: SeatMapTool;
    private defaultTool: SeatMapTool;

    public updatedBlockades$ = this.blockadeService.updatedBlockades$;

    private loadingDetailsStream: BehaviorSubject<any> = new BehaviorSubject<any>({isLoading: false});
    public loadingDetails$: Observable<any> = this.loadingDetailsStream.asObservable();

    public sidebarState: 'VENUE_SECTION_LIST' | 'VENUE_SECTION_DETAIL' = 'VENUE_SECTION_LIST';
    public venueSectionListSize: 'COMPACT' | 'DETAILED' = 'COMPACT';
    public venueSectionContext: VenueSectionContext;

    public isFullscreen = false;

    public legendItems = [
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Available'), '#70AD47'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Unavailable'), '#9CA3AB'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Issued'), '#FF4A00'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Issued_Multiday'), '#BF0000'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Reserved'), '#44546A'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Selected'), '#007FFF'),
        new LegendItem(this.translationPipe.transform('Seatmap.Status_Blocked'), '#000000'),
        new LegendItem(this.translationPipe.transform('Seat_Map.Legend.Current_Order'), '#FFBF00')
    ];

    constructor(
        private seatedApi: SeatedApiService,
        private blockadeService: BlockadeService,
        private elementRef: ElementRef,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private eventService: EventService,
        private dialogService: DialogService,
        private venueManagementService: VenueManagementService,
        private translationPipe: TranslationPipe,
        private renderer: Renderer2,
        private subscriptionTypeService: SubscriptionService,
        private localReservationService: LocalReservationService,
        private orderBarService: OrderBarService) {
    }

    ngOnInit(): void {
        this.getBlockades();

        this.venueSectionContext = this.subscriptionType ? VenueSectionContext.SUBSCRIPTION_TYPE : VenueSectionContext.EVENT;
        this.defaultTool = new ReservationTool({
            event: this.event,
            subscriptionType: this.subscriptionType,
            venueSection: this.selectedVenueSection,
            renderer: this.renderer
        });

        this.localReservationService.localReservation$.subscribe(_ => {
            const selectedSeats = this.event ?
                this.localReservationService.getSeatsForEvent(this.event?.id) :
                this.localReservationService.getSeatsForSubscriptionType(this.subscriptionType?.id);

            this.selectedVenueSectionSeatsStream.next(selectedSeats);
        });

        this.tool = this.defaultTool;

        this.orderBarService.orderBarExpanded$.pipe(
            skip(1)
        ).subscribe(expanded => {
            if (!expanded) {
                const selectedVenueSection = this.venueSectionStream.getValue();
                if (selectedVenueSection) {
                    this.setVenueSection(selectedVenueSection.id, true);
                }
            }
        });

        if (this.route.snapshot.params.venueSectionId) {
            this.sidebarState = 'VENUE_SECTION_DETAIL';
            this.setVenueSection(this.route.snapshot.params.venueSectionId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.context) {
            this.setSeatMapOptions(changes.context.currentValue);
        }
    }

    getEventCategories(): string[] {
        if (this.event) {
            return [this.event.eventCategoryId];
        }

        return this.subscriptionType.eventCategories.map(category => category.id);
    }

    setVenueSectionFilter(filter: string): void {
        this.venueSectionFilter = filter;
    }

    setVenueSection(venueSectionId: string, forceReload = false): void {
        if (this.selectedVenueSection?.id === venueSectionId && !forceReload) {
            this.sidebarState = 'VENUE_SECTION_DETAIL';
            return;
        }

        this.venueSectionStream.next(null);
        this.loadingDetailsStream.next({isLoading: true});

        // Get cheap venue section seats first, expensive details later
        const cheapHttpParams = new HttpParams()
            .set('venueSection[seats]', true);

        let httpParams = new HttpParams()
            .set('venueSection[seats]', true)
            .set('venueSection[available]', true)
            .set('venueSectionSeat[available]', true)
            .set('venueSectionSeat[details]', true)
            .set('skipEventTicketName', true)
            .set('depth', '4');

        let route = null;

        if (this.event) {
            route = ['/events', this.event.id, 'details', venueSectionId];
            httpParams = httpParams.set('scope[eventId]', this.event.id);
        } else if (this.subscriptionType) {
            route = ['/subscription-types', this.subscriptionType.id, 'details', venueSectionId];
            httpParams = httpParams.set('scope[subscriptionTypeId]', this.subscriptionType.id);
        }

        this.seatedApi.get<VenueSection>(new VenueSection(), {
            venueId: this.venue.id,
            venueSectionId: venueSectionId
        }, cheapHttpParams).pipe(
            tap(venueSectionResponse => {
                this.sidebarState = 'VENUE_SECTION_DETAIL';
                this.selectedVenueSection = venueSectionResponse;
                this.venueSectionStream.next(venueSectionResponse);

                if (route) {
                    this.location.go(route.join('/'));
                }
            }),
            switchMap((venueSection) => {
                const eventTicketHttpParams = new HttpParams()
                    .set('eventTicket[venueSectionIds]', venueSection.id)
                    .set('eventTicket[eventIds]', this.event?.id)
                    .set('eventTicket[customer]', 'true')
                    .set('eventTicket[order]', 'true')
                    .set('eventTicket[status]', 'ISSUED,RESERVED,RESELL_AVAILABLE,RESELL_RESERVED')
                    .set('take', -1);

                const subscriptionHttpParams = new HttpParams()
                    .set('subscription[venueSectionIds]', venueSection.id)
                    .set('subscription[subscriptionTypeIds]', this.subscriptionType?.id)
                    .set('subscription[customer]', 'true')
                    .set('subscription[order]', 'true')
                    .set('subscription[status]', 'ISSUED,RESERVED,RESELL_AVAILABLE,RESELL_RESERVED')
                    .set('take', -1);

                const eventTicketsSimple$ = this.eventService.getEventTicketsSimple(eventTicketHttpParams);
                const subscriptionsSimple$ = this.subscriptionTypeService.getSubscriptionsSimple(subscriptionHttpParams);

                const simple$ = this.event ? eventTicketsSimple$ : subscriptionsSimple$;

                return forkJoin({
                    venueSection: this.seatedApi.get<VenueSection>(new VenueSection(), {
                        venueId: this.venue.id,
                        venueSectionId
                    }, httpParams),
                    eventTickets: venueSection.isSeated ? simple$.pipe(
                        map(response => response.body)
                    ) : of([])
                }).pipe(map(venueSectionResponse => {
                    venueSectionResponse.venueSection.seats.forEach(seat => {
                        if (this.localReservationService.isBlockingEventCategorySeatInOrder(this.getEventCategories(), seat.id, this.venueSectionContext)) {
                            seat.available = 0;
                            seat.details.status = 'RESERVED';
                        }

                        seat.eventTickets = venueSectionResponse.eventTickets.filter(eventTicket => eventTicket.venueSectionSeatId === seat.id);
                    });

                    return venueSectionResponse.venueSection;
                }));
            })
        ).subscribe(venueSectionResponse => {
            this.selectedVenueSection = venueSectionResponse;
            this.venueSectionStream.next(venueSectionResponse);
            this.visibleVenueSection = venueSectionResponse;

            if (this.tool instanceof SelectTool || this.tool instanceof ReservationTool) {
                this.tool.options.venueSection = this.selectedVenueSection;
            }

            this.loadingDetailsStream.next({isLoading: false});

            if (route) {
                this.location.go(route.join('/'));
            }
        });
    }

    public setSeatMapOptions(seatMapOptions: SeatMapOptions) {
        Object.entries(seatMapOptions).forEach(entry => {
            this.seatMapOptions[entry[0]] = entry[1];
        });
    }

    public clearSelectedVenueSection(): void {
        const detailsRoute: string[] = this.getDetailsRoute();
        this.router.navigate(detailsRoute);

        this.sidebarState = 'VENUE_SECTION_LIST';

        this.tool = this.defaultTool;
        this.selectedBlockadeId = null;
    }

    public setSeatMapTool(name: string, options: any): void {
        if (name === 'BLOCKADE') {
            this.selectedBlockadeId = options.blockade.id;
            this.tool = new BlockadeTool(options);
        } else {
            this.selectedBlockadeId = null;
        }
    }

    toggleSidebar(): void {
        this.sidebarState = (this.sidebarState === 'VENUE_SECTION_LIST') ? 'VENUE_SECTION_DETAIL' : 'VENUE_SECTION_LIST';
        this.elementRef.nativeElement.classList.toggle('sidebar-closed');
    }

    openSidebar(): void {
        this.elementRef.nativeElement.classList.remove('sidebar-closed');
    }

    private getBlockades(): void {
        let blockadeParams = new HttpParams()
            .set('blockade[venueSectionSeats]', true);

        if (this.event) {
            blockadeParams = blockadeParams.set('scope[eventId]', this.event.id);
        }

        if (this.subscriptionType) {
            blockadeParams = blockadeParams.set('scope[subscriptionTypeId]', this.subscriptionType.id);
        }

        this.blockades$ = this.seatedApi.getMany<Blockade>(new Blockade(), blockadeParams);
        this.blockades$.subscribe(blockades => this.blockades = blockades);

        this.blockadeService.reset();

        this.updatedBlockades$ = this.blockadeService.updatedBlockades$;
    }

    onBlockadesSaved(): void {
        this.resetTool();
        this.getBlockades();
    }

    onUpdatedBlockadesDiscarded(): void {
        this.resetTool();
        this.resetVenueSectionSeats();
        this.blockadeService.reset();
    }

    onBlockadeClick(blockade: Blockade): void {
        if (this.loadingDetailsStream.getValue().isLoading) {
            return;
        }

        if (this.selectedBlockadeId === blockade.id) {
            this.resetTool();
            return;
        }

        this.setSeatMapTool('BLOCKADE', {blockade});
    }


    openVenueSectionSeatSettingDialog(venueSectionSeats: VenueSectionSeat[]) {
        this.dialogService.createDialogComponent(DialogVenueSectionSeatSettingComponent, {
            extraData: {
                eventId: this.event.id,
                eventCategoryId: this.event.eventCategoryId,
                venueSectionSeats: venueSectionSeats
            }
        }, 'edit-venue-section-seat-setting');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.venueManagementService.fetchVenueSectionSeats();
            }
        });
    }

    setVenueSectionListSize(size: 'COMPACT' | 'DETAILED'): void {
        this.venueSectionListSize = size;
    }

    public toggleFullscreen(): void {
        const host = this.elementRef.nativeElement;
        host.classList.toggle('fullscreen');

        this.isFullscreen = host.classList.contains('fullscreen');
    }

    private resetTool(): void {
        this.tool = this.defaultTool;
        this.selectedBlockadeId = null;
    }

    private resetVenueSectionSeats(): void {
        if (!this.selectedVenueSection) {
            return;
        }

        const mappedBlockades = [];
        this.blockades.forEach(blockade => {
            mappedBlockades[blockade.id] = {
                label: blockade.label,
                color: blockade.color
            };
        });

        this.selectedVenueSection.seats.forEach(venueSectionSeat => {
            if (!venueSectionSeat._originalBlockadeId) {
                venueSectionSeat._label = null;
                venueSectionSeat._color = null;
                venueSectionSeat._blockadeId = null;
                return;
            }

            const blockade = mappedBlockades[venueSectionSeat._originalBlockadeId];
            venueSectionSeat._label = blockade.label;
            venueSectionSeat._color = blockade.color;
        });
    }

    private getDetailsRoute(): string[] {
        if (this.event) {
            return ['/events', this.event.id, 'details'];
        }

        if (this.subscriptionType) {
            return ['/subscription-types', this.subscriptionType.id, 'details'];
        }

        return ['/'];
    }

    onVenueSectionSeatsDeselected(): void {
        this.selectedVenueSectionSeatsStream.next([]);
    }

    onVenueSectionSeatsSelected(venueSectionSeats: VenueSectionSeat[]) {
        let selectedVenueSectionSeats = this.selectedVenueSectionSeatsStream.getValue();
        for (const venueSectionSeat of venueSectionSeats) {
            if (selectedVenueSectionSeats.some(seat => seat.venueSectionSeatId === venueSectionSeat.id)) {
                selectedVenueSectionSeats = selectedVenueSectionSeats.filter(seat => seat.venueSectionSeatId !== venueSectionSeat.id);
                continue;
            }

            if (venueSectionSeat.isAvailable()) {
                selectedVenueSectionSeats.push(new SeatmapSeatInfo(venueSectionSeat.id));
            }
        }

        this.selectedVenueSectionSeatsStream.next([...selectedVenueSectionSeats]);
    }
}
