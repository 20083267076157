import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-seat-map-legend-item',
    templateUrl: './seat-map-legend-item.component.html',
    styleUrls: ['./seat-map-legend-item.component.css']
})
export class SeatMapLegendItemComponent implements OnInit {

    @Input()
    color = 'black';

    constructor() {
    }

    ngOnInit(): void {
    }

}
