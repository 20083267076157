<div class="row">
    <div class="col-12 col-md-3 p-r-16">

        <app-form-select
            [(value)]="width"
            [options]="widthOptions"
            optionLabel="label"
            optionValue="value"
            [label]="('Border_Picker.Border' | trans)"
            (valueChange)="onFieldChange('width', width)">
        </app-form-select>

    </div>

    <ng-container *ngIf="width !== 'none'">

        <div class="col-12 col-md-3 p-r-16">

            <app-form-select
                class="d-inline-block m-t-24"
                [(value)]="style"
                [options]="styleOptions"
                optionLabel="label"
                optionValue="value"
                (valueChange)="onFieldChange('style', style)">
            </app-form-select>

        </div>

        <div class="col-12 col-md-6">

            <app-form-input-color-picker [(value)]="color"
                                         [label]="(('Border_Picker.Color' | trans))"
                                         (valueChange)="onFieldChange('color', color)">
            </app-form-input-color-picker>

        </div>

    </ng-container>

</div>
