<div class="search-header">
    <div class="row">
        <div class="col-10">
            <h3 class="search-title">{{('Menu_Search_Results.Searching_For' | trans)}} <span
                class="search-title-search-term">{{results.search}}</span></h3>
        </div>
        <div class="col-2 text-right">
            <button class="btn btn-secondary-icon" (click)="onCloseClick()">
                <span class="cm-icon cm-icon-close" aria-hidden="true"></span>
            </button>
        </div>
    </div>
</div>

<div class="search-results">
    <ng-container *ngFor="let category of results.categories">
        <div class="row search-category">
            <div class="search-category-title col-12 col-sm-4">{{(category.translationKey | trans)}}</div>
            <div class="search-category-results col-12 col-sm-8">
                <ng-container *ngFor="let result of category.results">
                    <ng-container *ngIf="!result.isLoading">
                        <div class="search-result" (click)="onSearchResultClick(result)">
                            <div class="search-result-title">{{ result.title }}</div>
                            <div class="search-result-subtitle">{{ result.subtitle }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="result.isLoading">
                        <div class="search-result is-loading">
                            <div class="search-result-title skeleton-bar"></div>
                            <div class="search-result-subtitle skeleton-bar"></div>
                        </div>
                        <div class="search-result is-loading">
                            <div class="search-result-title skeleton-bar"></div>
                            <div class="search-result-subtitle skeleton-bar"></div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="category.results.length === 0">
                    <div class="search-result-title no-results">{{('General.No_Results' | trans)}}</div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
