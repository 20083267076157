<ng-container *ngIf="customer$ | async">

    <div class="datatable">

        <div class="datatable-header">
            <div class="datatable-header-item col-4">{{ ('Customer_Products.Product_Type_Name' | trans) }}</div>
            <div class="datatable-header-item col-4">{{ ('Customer_Products.Product_Type_Price_Name' | trans) }}</div>
            <div class="datatable-header-item col-3">{{ ('Customer_Products.Order' | trans) }}</div>
            <div class="datatable-header-item col-1"></div>
        </div>

        <ng-container *ngFor="let product of products$ | async">

            <div class="datatable-row reset-line-height">

                <div class="datatable-row-item col-4">
                    {{ product.productType.name }}
                </div>

                <div class="datatable-row-item col-4">
                    {{ product.productTypePrice.name }}
                </div>

                <div class="datatable-row-item col-3 cursor-pointer" [routerLink]="['/orders', product.order.id]">
                    <div class="title">{{ product.order.batchId }}</div>
                    <div class="subtitle">{{ product.order.paidAt | date }}</div>

                </div>

                <div class="datatable-row-item col-1 text-right">
                    <button class="btn btn-secondary-icon m-r-8" [cm-tooltip]="('General.Cancel' | trans)"
                            (click)="cancelProduct(product.id)">
                        <span class="cm-icon cm-icon-block" aria-hidden="true"></span>
                    </button>
                </div>

            </div>

        </ng-container>
    </div>

</ng-container>
