<ng-container *ngIf="unsavedChanges$ | async as unsavedChanges">
    <div class="floating-footer" [class.is-open]="unsavedChanges.length > 0">
        <div class="row">
            <div class="col-6 unsaved-changes-summary">
                {{ unsavedChanges.length }} changes
            </div>
            <div class="col-6 unsaved-changes-button">
                <button class="btn btn-cta"
                        (click)="onSaveEmailTemplateGroupSettingsClicked()">{{'General.Save' | trans }}</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderDistributionSettings$ | async">
    <ng-container *ngFor="let orderDistributionSetting of orderDistributionSettings">
        <div class="card form-group">
            <div class="row list-container">
                <div class="col-12 col-sm-4 file-list">
                    <div class="product-type-price-list datatable m-t-8">
                        <div class="datatable-header row">
                            <div class="datatable-header-item col-1"></div>
                            <div class="datatable-header-item col-11">{{('General.Conditions' | trans)}}</div>
                        </div>

                        <ng-container *ngFor="let rule of rules">
                            <div class="datatable-row row" (click)="onRuleSelected(orderDistributionSetting, rule)"
                                 [class.active]="orderDistributionSetting.activeRule.id === rule.id">
                                <div class="datatable-row-item col-1"></div>
                                <div class="datatable-row-item col-11">
                                    <div class="rule-title">{{(rule.label | trans)}}</div>
                                    <div class="rule-summary">
                                        <ng-container *ngIf="rule.field">
                                            <ng-container
                                                *ngIf="orderDistributionSetting[rule.field].length > 0; else noneSelected">
                                                {{ orderDistributionSetting[rule.field].length }}
                                                {{('Upsell_Setting.Selected' | trans)}}
                                            </ng-container>
                                            <ng-template #noneSelected>
                                                {{('Upsell_Setting.None_Selected' | trans)}}
                                            </ng-template>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 col-sm-8 sidebar">
                    <div class="row content form-group">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="m-b-16">{{(orderDistributionSetting.activeRule.label | trans)}}</h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ng-container *ngIf="orderDistributionSetting.activeRule.id === 'SETTINGS'">
                                        <ng-container *ngIf="orderDistributionSetting.type === 'EMAIL_TEMPLATE_GROUP'">
                                            <div class="form-row">
                                                <div class="form-check">
                                                    <label>
                                                        <input type="checkbox"
                                                               (click)="onSettingChange(orderDistributionSetting, 'isDownloadable', !orderDistributionSetting['isDownloadable'])"
                                                               [(ngModel)]="orderDistributionSetting['isDownloadable']">
                                                        <span class="label">
                                                            {{('Email_Template.Show_Download_Button' | trans)}}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-check">
                                                    <label>
                                                        <input type="checkbox"
                                                               (click)="onSettingChange(orderDistributionSetting, 'isMobileEnabled', !orderDistributionSetting['isMobileEnabled'])"
                                                               [(ngModel)]="orderDistributionSetting['isMobileEnabled']">
                                                        <span class="label">
                                                            {{('Email_Template.Enable_Mobile_Ticket' | trans)}}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="orderDistributionSetting.id">
                                                <div class="row m-t-64">
                                                    <div class="col-12">
                                                        <cm-button [attr.data-label]="'General.Delete' | trans"
                                                                   data-icon="delete"
                                                                   data-button-type="icon-text"
                                                                   data-destructive
                                                                   (click)="onDeleteOrderDistributionSetting(orderDistributionSetting)"></cm-button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="orderDistributionSetting.type === 'TICKET_TEMPLATE'">
                                            <cm-select
                                                data-label="{{('Event_Ticket_Template.Barcode_Type' | trans)}}"
                                                (cm-option-clicked)="onSettingChange(orderDistributionSetting, 'barcodeType', $event.detail.value)"
                                                [attr.value]="orderDistributionSetting.barcodeType">
                                                <ng-container *ngFor="let barcodeType of barcodeTypes">
                                                    <cm-option slot="option" value="{{barcodeType}}"
                                                               data-display="{{barcodeType}}"></cm-option>
                                                </ng-container>
                                            </cm-select>
                                            <div class="m-t-16"></div>
                                            <cm-select
                                                data-label="{{('Event_Ticket_Template.Ticket_Template_Type' | trans)}}"
                                                (cm-option-clicked)="onSettingChange(orderDistributionSetting, 'ticketTemplateType', $event.detail.value)"
                                                [attr.value]="orderDistributionSetting.ticketTemplateType">
                                                <ng-container *ngFor="let templateType of templateTypes">
                                                    <cm-option slot="option" value="{{templateType}}"
                                                               data-display="{{templateType}}"></cm-option>
                                                </ng-container>
                                            </cm-select>

                                            <ng-container *ngIf="orderDistributionSetting.id">
                                                <div class="row m-t-64">
                                                    <div class="col-12">
                                                        <cm-button [attr.data-label]="'General.Delete' | trans"
                                                                   data-icon="delete"
                                                                   data-button-type="icon-text"
                                                                   data-destructive
                                                                   (click)="onDeleteOrderDistributionSetting(orderDistributionSetting)"></cm-button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="orderDistributionSetting.activeRule.id === 'EVENT'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Events' | trans), events: events}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'events'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [optionSubtitle]="'_startAtFormatted'"
                                            [selectedOptions]="orderDistributionSetting.filterEventIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>


                                    <ng-container *ngIf="orderDistributionSetting.activeRule.id === 'TICKET_TYPE'">
                                        <ng-container *ngIf="orderDistributionSetting.filterEventIds.length === 0">
                                            {{('Upsell_Setting.Ticket_Type_No_Event' | trans)}}
                                        </ng-container>
                                        <app-setting-checkbox-picker
                                            [categories]="getSelectedEvents(orderDistributionSetting)"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'ticketTypes'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterTicketTypeIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="orderDistributionSetting.activeRule.id === 'SUBSCRIPTION_TYPE'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Subscriptions' | trans), subscriptionTypes: subscriptionTypes}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'subscriptionTypes'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterSubscriptionTypeIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="orderDistributionSetting.activeRule.id === 'SUBSCRIPTION_TYPE_PRICE'">
                                        <ng-container
                                            *ngIf="orderDistributionSetting.filterSubscriptionTypeIds.length === 0">
                                            {{('Upsell_Setting.Subscription_Type_Price_No_Subscription_Type' | trans)}}
                                        </ng-container>
                                        <app-setting-checkbox-picker
                                            [categories]="getSelectedSubscriptionTypes(orderDistributionSetting)"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'subscriptionTypePrices'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterSubscriptionTypePriceIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="orderDistributionSetting.activeRule.id === 'PRODUCT_TYPE'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Products' | trans), productTypes: productTypes}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'productTypes'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterProductTypeIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="orderDistributionSetting.activeRule.id === 'PRODUCT_TYPE_PRICE'">
                                        <ng-container
                                            *ngIf="orderDistributionSetting.filterProductTypeIds.length === 0">
                                            {{('Upsell_Setting.Product_Type_Price_No_Product_Type' | trans)}}
                                        </ng-container>
                                        <app-setting-checkbox-picker
                                            [categories]="getSelectedProductTypes(orderDistributionSetting)"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'productTypePrices'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterProductTypePriceIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container *ngIf="orderDistributionSetting.activeRule.id === 'SHOP'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Shops' | trans), shops: shops}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'shops'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterShopIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="orderDistributionSetting.activeRule.id === 'VENUE_SECTION_GROUP'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Venue_Section_Groups' | trans), venueSectionGroups: orderDistributionSetting.venueSectionGroups}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'venueSectionGroups'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterVenueSectionGroupIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>

                                    <ng-container *ngIf="orderDistributionSetting.activeRule.id === 'CUSTOMER_TAG'">
                                        <app-setting-checkbox-picker
                                            [categories]="[{id: 0, name: ('General.Tags' | trans), tags: customerTags}]"
                                            [categoryKey]="'id'"
                                            [categoryName]="'name'"
                                            [optionsKey]="'tags'"
                                            [optionKey]="'id'"
                                            [optionName]="'name'"
                                            [selectedOptions]="orderDistributionSetting.filterCustomerTagIds"
                                            (selectionChanged)="onArraySelectionChanged(orderDistributionSetting, orderDistributionSetting.activeRule.field, $event)"></app-setting-checkbox-picker>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<div class="card card-add card-action" (click)="onAddOrderDistributionSettingClick()">
    <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
    <span class="m-t-8">{{('Sale_Plan.New_Rule' | trans)}}</span>
</div>
