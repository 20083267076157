import {ModelBase} from '../abstract/model-base';

export class OrderDetailsCostItem extends ModelBase {
    public amount: number;
    public label: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsCostItem {
        const model = new OrderDetailsCostItem();

        model.amount = data.amount;
        model.label = data.label;

        return model;
    }
}
