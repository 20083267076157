import {SeatMapTool} from './seat-map-tool.interface';
import {VenueSectionSeat} from '../venue-section-seat.class';
import {ServiceInjector} from '../../services/service-injector.service';
import {BlockadeChangeEvent, BlockadeService} from '../../services/blockade.service';
import {Blockade} from '../blockade.class';

export class BlockadeTool extends SeatMapTool {

    private blockadeService: BlockadeService;

    private isSelecting = false;
    private action: 'ADDING' | 'REMOVING';

    constructor(options: any = {}) {
        super(options);

        this.blockadeService = ServiceInjector.injector.get(BlockadeService);
    }

    seatMouseDown(venueSectionSeat: VenueSectionSeat) {
        this.action = venueSectionSeat._blockadeId ? 'REMOVING' : 'ADDING';

        this.handleBlockadeChangeEvent(venueSectionSeat);
    }

    seatMouseMove(e: any, venueSectionSeat: VenueSectionSeat) {
        //
    }

    seatMouseEnter(e: any, venueSectionSeat: VenueSectionSeat): void {
        if (this.isSelecting) {
            this.handleBlockadeChangeEvent(venueSectionSeat);
        }
    }

    seatMouseOut(e: any, venueSectionSeat: VenueSectionSeat): void {
        //
    }

    svgMouseDown(e: any) {
        this.isSelecting = true;
    }

    svgMouseMove(e: any) {
        //
    }

    svgMouseUp(e: any): void {
        this.isSelecting = false;
        this.action = null;
    }

    svgMouseLeave(e: any): void {
        this.isSelecting = false;
        this.action = null;
    }

    private handleBlockadeChangeEvent(venueSectionSeat: VenueSectionSeat): void {
        const blockade = this.options.blockade;

        // Create blockade change event
        const blockadeChangeEvent: BlockadeChangeEvent = {
            venueSectionSeatId: venueSectionSeat.id
        };

        // Change is local only
        if (!venueSectionSeat._originalBlockadeId) {
            if (this.action === 'REMOVING' && venueSectionSeat._blockadeId === blockade.id) {
                this.blockadeService.removeBlockadeChangeEvent(venueSectionSeat.id);

                // Clicked a seat that is already in this blockade, remove it
                // Since it is local only there is no need to send a blockade change event
                this.resetSeatBlockadeProperties(venueSectionSeat);
            } else if (this.action === 'ADDING') {
                this.blockadeService.removeBlockadeChangeEvent(venueSectionSeat.id);

                // Clicked a new blockade
                this.setSeatBlockadeProperties(venueSectionSeat, blockade);

                blockadeChangeEvent.addToBlockadeId = blockade.id;
            }
        } else {
            if (this.action === 'REMOVING' && venueSectionSeat._blockadeId === blockade.id) {
                this.blockadeService.removeBlockadeChangeEvent(venueSectionSeat.id);
                this.resetSeatBlockadeProperties(venueSectionSeat);

                blockadeChangeEvent.addToBlockadeId = null;
                blockadeChangeEvent.removeFromBlockadeId = blockade.id;
            } else if (this.action === 'ADDING') {
                this.blockadeService.removeBlockadeChangeEvent(venueSectionSeat.id);

                // Clicked a new blockade
                this.setSeatBlockadeProperties(venueSectionSeat, blockade);

                blockadeChangeEvent.addToBlockadeId = blockade.id;
                blockadeChangeEvent.removeFromBlockadeId = venueSectionSeat._originalBlockadeId;
            }
        }

        if ((blockadeChangeEvent.addToBlockadeId || blockadeChangeEvent.removeFromBlockadeId) && (blockadeChangeEvent.addToBlockadeId !== blockadeChangeEvent.removeFromBlockadeId)) {
            this.blockadeService.addBlockadeChangeEvent(blockadeChangeEvent);
        }
    }

    private setSeatBlockadeProperties(venueSectionSeat: VenueSectionSeat, blockade: Blockade): void {
        venueSectionSeat._color = blockade.color;
        venueSectionSeat._label = blockade.label;
        venueSectionSeat._blockadeId = blockade.id;
    }

    private resetSeatBlockadeProperties(venueSectionSeat: VenueSectionSeat): void {
        venueSectionSeat._color = null;
        venueSectionSeat._label = null;
        venueSectionSeat._blockadeId = null;
    }

    getSelectedSeats(): VenueSectionSeat[] {
        return [];
    }
}
