<div class="row">
    <div class="col-6 p-r-16">

        <cm-datetime-picker
            [attr.data-linked-end]="linkedEndId"
            data-datetime="{{startDate}}"
            [attr.min]="minDate"
            data-show-time
            data-label="{{ startLabel }}"
            data-error-required="Deze datum moet ingevuld worden"
            data-error-range="Deze datum valt niet in de range"
            data-error-invalid="Gelieve een geldige datum op te geven"
            data-precision="{{precision}}"
            [data-locale]="locale"
            (change)="inputChangeStart($event)"
            data-format="{{displayFormat}}"
        ></cm-datetime-picker>
    </div>
    <div class="col-6 p-r-16">
        <cm-datetime-picker
            [attr.id]="id"
            data-datetime="{{endDate}}"
            data-show-time
            data-label="{{ endLabel }}"
            data-error-required="Deze datum moet ingevuld worden"
            data-error-range="Deze datum valt niet in de range"
            data-error-invalid="Gelieve een geldige datum op te geven"
            data-precision="{{precision}}"
            [data-locale]="locale"
            (change)="inputChangeEnd($event)"
            data-format="{{displayFormat}}"
        ></cm-datetime-picker>
    </div>
</div>
