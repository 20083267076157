import {ModelBase} from '../abstract/model-base';

export class OrderItemDetails extends ModelBase {
    public _hasLocationDetails: boolean;

    public id: string;
    public status: string;
    public type: string;
    public price: number;
    public vat: number;
    public customerName: string;
    public hash: string;
    public note: string;
    public downloadUrl: string;
    public isPersonalisationRequired: boolean;
    public personalisedCustomerName: string;
    public personalisationUrl: string;
    public productTypeName: string;
    public sectionName: string;
    public seatRow: string;
    public seatSeat: string;
    public venueSectionAccessGroupName: string;
    public consumerServiceFeeLabel: string;
    public consumerServiceFeeAmount: number;
    public retributionTaxFeeLabel: string;
    public retributionTaxFeeAmount: number;

    constructor() {
        super();
    }

    mapModel(data: any): OrderItemDetails {
        const model = new OrderItemDetails();

        model.id = data.id;
        model.status = data.status;
        model.type = data.type;
        model.price = data.price;
        model.vat = data.vat;
        model.customerName = data.customerName;
        model.hash = data.hash;
        model.note = data.note;
        model.downloadUrl = data.downloadUrl;
        model.isPersonalisationRequired = data.isPersonalisationRequired;
        model.personalisedCustomerName = data.personalisedCustomerName;
        model.personalisationUrl = data.personalisationUrl;
        model.productTypeName = data.productTypeName;
        model.sectionName = data.sectionName;
        model.seatRow = data.seatRow;
        model.seatSeat = data.seatSeat;
        model.venueSectionAccessGroupName = data.venueSectionAccessGroupName;
        model.consumerServiceFeeLabel = data.consumerServiceFeeLabel;
        model.consumerServiceFeeAmount = data.consumerServiceFeeAmount;
        model.retributionTaxFeeLabel = data.retributionTaxFeeLabel;
        model.retributionTaxFeeAmount = data.retributionTaxFeeAmount;

        model._hasLocationDetails = !!model.venueSectionAccessGroupName || !!(model.seatRow && model.seatSeat);

        return model;
    }
}
