import {Component, Input} from '@angular/core';
import {AnalyticsDashboardItem} from '../../../models/analytics-dashboard-item.class';
import {AnalyticsDashboard} from '../../../models/analytics-dashboard.class';
import {
    AnalyticsDashboardItemService
} from '../../../services/analytics-dashboard-item-service/analytics-dashboard-item.service';

@Component({
    selector: 'app-analytics-dashboard-item',
    templateUrl: './analytics-dashboard-item.component.html',
    styleUrls: ['./analytics-dashboard-item.component.css']
})
export class AnalyticsDashboardItemComponent {
    @Input() analyticsDashboard: AnalyticsDashboard;
    @Input() analyticsDashboardItem: AnalyticsDashboardItem;
    @Input() updatedAt: string;
    @Input() editMode: boolean;

    constructor(private analyticsDashboardItemService: AnalyticsDashboardItemService) {
    }

    delete() {
        this.analyticsDashboardItemService.deleteAnalyticsDashboardItem(this.analyticsDashboard.id, this.analyticsDashboardItem.id).subscribe();
        this.analyticsDashboard.dashboardItems.splice(this.analyticsDashboard.dashboardItems.indexOf(this.analyticsDashboardItem), 1);
    }
}
