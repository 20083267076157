import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {Subject, debounceTime, distinctUntilChanged} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {DiscountCampaign} from '../../../models/discount-campaign';
import {DiscountCampaignManagementService} from '../../../services/discount-campaign-management-service/discount-campaign-management.service';
import {OrderManagementService} from '../../../services/order-service/order-management.service';
import {takeUntil} from 'rxjs/operators';
import {Order} from '../../../models/order.class';
import {Discount} from '../../../models/discount';

@Component({
    selector: 'app-dialog-order-add-discount',
    templateUrl: './dialog-order-add-discount.component.html',
    styleUrls: ['./dialog-order-add-discount.component.css']
})
export class DialogOrderAddDiscountComponent extends DialogBase<IInput, IOutput> implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    public bodyText: any;
    public confirmText: string;
    selectedDiscountOption = 'DISCOUNT_CAMPAIGN';
    discountCampaigns$: Observable<DiscountCampaign[]>;
    discountCampaign = new Subject<string>();
    discountCode = new Subject<string>();
    discount: Discount;
    order: Order;

    constructor(dialogService: DialogService,
                private orderManagementService: OrderManagementService,
                private discountCampaignManagementService: DiscountCampaignManagementService) {
        super(dialogService);
    }

    ngOnInit() {
        this.discountCampaigns$ = this.discountCampaignManagementService.list$ as Observable<DiscountCampaign[]>;
        this.orderManagementService.orderSubject.subscribe(order => {
            this.order = order;
        });

        this.discountCampaign.pipe(
            takeUntil(this.destroy$),
            distinctUntilChanged()
        ).subscribe(value => {
            this.orderManagementService.addOrderDiscountCampaign(value, () => {
                this.discount = this.order.discounts[0];
            });
        });

        this.discountCode.pipe(
            takeUntil(this.destroy$),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(value => {
            this.orderManagementService.addOrderDiscount(value, () => {
                this.discount = this.order.discounts[0];
            });
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    setModal(data: IInput) {
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: {}
        });
    }

    public cancel() {
        if (this.discount) {
            this.orderManagementService.deleteOrderDiscount(this.discount.id);
        }

        super.cancel();
    }

    onDiscountCodeChange(event) {
        this.discountCode.next(event.target.value);
    }

    onDiscountCampaignChange(event) {
        this.discountCampaign.next(event.target.value);
    }
}
