import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceModeService implements OnDestroy {

    public isNavigatingToMaintenancePage = false;

    constructor(private http: HttpClient, private router: Router) {
    }

    ngOnDestroy(): void {
        this.isNavigatingToMaintenancePage = false;
    }

    public canRedirectToMaintenancePage(): boolean {
        return this.isNavigatingToMaintenancePage || window.location.pathname.split('/').pop() === 'scheduled-maintenance';
    }

    public getMaintenanceMessage(): Observable<MaintenanceMessage> {
        const options = {
            withCredentials: true,
            params: new HttpParams()
                .set('ignoreRedirect', true)
        };

        return this.http.get<any>(`${environment.CM_API_URL}/seatedapi/v1.0/maintenance/message`, options).pipe(
            map(response => {
                return new MaintenanceMessage(
                    response.startAt,
                    response.endAt,
                    response.statusPageUrl
                );
            }),
            catchError(() => of(undefined)),
            filter(value => !!value)
        );
    }

    public getMaintenanceStatus(): Observable<boolean> {
        const options = {
            withCredentials: true,
            params: new HttpParams()
                .set('ignoreRedirect', true)
        };

        return this.http.get<boolean>(`${environment.CM_API_URL}/seatedapi/v1.0/is-in-maintenance`, options);
    }
}

export class MaintenanceMessage {
    public readonly startAt: string;
    public readonly endAt: string;
    public readonly statusPageUrl: string | undefined;

    constructor(startAt: string, endAt: string, statusPageUrl: string | undefined) {
        this.startAt = startAt;
        this.endAt = endAt;
        this.statusPageUrl = statusPageUrl;
    }
}
