import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }

    setFocusMode(isFocusMode: boolean = true): void {
      const body = document.querySelector('body');
      isFocusMode ? body.classList.add('focus') : body.classList.remove('focus');
    }

    toggleDarkMode(): void {
        document.querySelector('body').classList.toggle('dark');
    }

    openSidenav(): void {
        (document.querySelector('cm-sidenav') as any).openSidenav();
    }

    closeSidenav(): void {
        (document.querySelector('cm-sidenav') as any).closeSidenav();
    }
}
