<div class="datatable datatable-hover">
    <div class="datatable-header">
        <div class="datatable-header-item col-1"></div>
        <div class="datatable-header-item col">{{('General.Payment_Method' | trans)}}</div>
        <div class="datatable-header-item col">{{('General.Person' | trans)}}</div>
        <div class="datatable-header-item col">{{('Order.Order_Number' | trans)}}</div>
        <div class="datatable-header-item col">{{('General.Tickets' | trans)}}</div>
        <div class="datatable-header-item col">{{('General.Amount' | trans)}}</div>
    </div>

    <ng-container *ngIf="payments; else loadingSmall">
        <ng-container *ngFor="let payment of payments;">
            <div class="datatable-row" [class.fade-out]="isLoading" [routerLink]="['/orders', payment.orderId]">
                <div class="datatable-row-item col-1" [cm-tooltip]="payment.status">
                    <span *ngIf="payment.status === 'OPEN'" class="f-s-15 cm-icon cm-icon-time-fill cl-warning-color" aria-hidden="true"></span>
                    <span *ngIf="payment.status === 'SUCCESS'" class="f-s-15 cm-icon cm-icon-verification cl-success-color" aria-hidden="true"></span>
                    <span *ngIf="payment.status === 'EXPIRED'" class="f-s-15 cm-icon cm-icon-time-fill cl-error-color" aria-hidden="true"></span>
                    <span *ngIf="payment.status === 'CANCELED'" class="f-s-15 cm-icon cm-icon-error cl-error-color" aria-hidden="true"></span>
                    <span *ngIf="payment.status === 'FAILED'" class="f-s-15 cm-icon cm-icon-error cl-error-color" aria-hidden="true"></span>
                </div>
                <div class="datatable-row-item col">
                    <span *ngIf="payment.method === 'IDEAL'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-ideal vertical-middle" aria-hidden="true"></span>
                    <span *ngIf="payment.method === 'BANCONTACT'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-bancontact vertical-middle" aria-hidden="true"></span>
                    <span *ngIf="payment.method === 'SOFORT_BANKING'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-sofort vertical-middle" aria-hidden="true"></span>
                    <span *ngIf="payment.method === 'MASTER_CARD'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-mastercard vertical-middle" aria-hidden="true"></span>
                    <span *ngIf="payment.method === 'VISA_CARD'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-visa vertical-middle" aria-hidden="true"></span>
                    <span *ngIf="payment.method === 'BANK_TRANSFER'" [cm-tooltip]="payment.method" class="cm-payment cm-payment-bank-transfer vertical-middle" aria-hidden="true"></span>
                    <span class="m-l-8">{{payment.method}}</span>
                </div>
                <div class="datatable-row-item col">{{payment.actor?.name}}</div>
                <div class="datatable-row-item col">{{payment.order.batchId}}</div>
                <div class="datatable-row-item col">{{payment.order.orderItemAmount | number}}</div>
                <div class="datatable-row-item col">{{payment.balance | currency:payment.currency}}</div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>
