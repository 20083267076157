<cm-date-time-range-picker
    [attr.placeholder]="(startDatePlaceholder | trans)"
    [attr.data-timezone]="timezone"
    [attr.data-end-placeholder]="(endDatePlaceholder | trans)"
    [attr.data-label]="(dateLabel | trans)"
    [attr.data-end-label]="(endDateLabel | trans)"
    [attr.data-error-required]="requiredError"
    [attr.data-format]="dateTimeFormat"
    [attr.data-datetime]="startDateTime"
    [attr.data-end-datetime]="endDateTime"
    [attr.min]="minDate"
    (change)="inputChange($event)">
</cm-date-time-range-picker>
