<ng-container *ngIf="type === 'text'">
    <div class="form-input-special" [class.error]="!isValid">
        <input class="form-control spacing-left-icons-0 cursor-pointer"
               type="text"
               (change)="inputChange()"
               [(ngModel)]="value"
               [autocomplete]="autocomplete"
               [required]="required"
               [disabled]="disabled"
               readonly
               [attr.placeholder]="placeholder || null"
               [class.has-error]="!isValid"
               (click)="onLanguageClicked()">
        <label [class.has-error]="!isValid">{{label}}</label>
        <span class="input-right icon" (click)="onLanguageClicked()">
            <span aria-hidden="true" class="cm-icon cm-icon-language cm-icon-hover"></span>
        </span>
        <ng-container *ngIf="!isValid && errorMessage">
            <div class="form-input-validation">
                <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="type === 'multiline'">
    <div class="form-input multiline" [class.error]="!isValid">
        <div class="textarea form-control cursor-pointer" [innerHTML]="value" (click)="onLanguageClicked()"></div>
        <label [class.has-error]="!isValid">{{label}}</label>
        <span class="input-right icon" (click)="onLanguageClicked()">
            <span aria-hidden="true" class="cm-icon cm-icon-language cm-icon-hover"></span>
        </span>
        <ng-container *ngIf="!isValid && errorMessage">
            <div class="form-input-validation">
                <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
            </div>
        </ng-container>
    </div>
</ng-container>

<cm-ticketing-translation-dialog
    [title]="label"
    [translationObject]="translation[translationValuesKey]"
    [multiLine]="type === 'multiline'"
    #multiTranslationModal
    (translationChanged)="onTranslationChange($event)"
></cm-ticketing-translation-dialog>
