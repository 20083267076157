export class Selected {
    public dateRange                          = {
        id:      null,
        startAt: null,
        endAt:   null
    };
    public expireDateRange                    = {
        id:      null,
        startAt: null,
        endAt:   null
    };
    public status: string[]                   = [];
    public eventIds: string[]                 = [];
    public eventCategoryIds: string[]         = [];
    public ticketTypeIds: string[]            = [];
    public subscriptionTypeIds: string[]      = [];
    public subscriptionTypePriceIds: string[] = [];
    public venueSectionIds: string[]          = [];
    public customerTagIds: string[]           = [];
    public importIds: string[]                = [];
    public orderTagIds: string[]              = [];
    public shopIds: string[]                  = [];
    public campaignIds: string[]              = [];
    public discountCampaignIds: string[]      = [];
    public paymentMethodIds: string[]         = [];
    public payoutIds: string[]                = [];
    public personIds: string[]                = [];
    public activityLogEvents: string[]        = [];
    public checkedIn                          = false;
    public productTypeIds: string[] = [];
    public productTypePriceIds: string[] = [];

    constructor() {
    }
}
