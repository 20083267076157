import {Injectable} from '@angular/core';
import {CmTranslationService} from './cm-translation.service';
import {isLocaleSupported} from '../models/locale';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    private currentLang = 'en-gb';

    constructor(private translationService: CmTranslationService) {
        this.setLanguage(this.currentLang);
    }

    setLanguage(lang: string) {
        this.currentLang = isLocaleSupported(lang) ? lang : this.currentLang;
        this.translationService.setLanguage(this.currentLang);
        moment.locale(this.currentLang);
    }

    getLanguage() {
        return this.currentLang;
    }

    getShortLanguage() {
        switch (this.currentLang) {
            case 'en-gb':
                return 'EN';
            case 'en-us':
                return 'EN';
            case 'nl-nl':
                return 'NL';
            case 'de-de':
                return 'DE';
            case 'fr-fr':
                return 'FR';
            default:
                return 'EN';
        }
    }

    getCountryCode() {
        switch (this.currentLang) {
            case 'en-gb':
                return 'GB';
            case 'en-us':
                return 'US';
            case 'nl-nl':
                return 'NL';
            case 'de-de':
                return 'DE';
            case 'fr-fr':
                return 'FR';
            case 'nl-be':
                return 'BE';
            case 'es-es':
                return 'ES';
            case 'zh-sg':
                return 'SG';
            case 'zh-tw':
                return 'TW';
            default:
                return 'NL';
        }
    }
}
