import {ProductGroupType} from '../../enums/order/product-group-type';
import {OrderItemReservation} from './order-item-reservation.class';
import {OrderItemGroup} from '../order-item-group.class';

export class GroupedOrderItem {
    hasConflict = false;

    constructor(
        public id: string,
        public name: string,
        public type: ProductGroupType,
        public items: OrderItemReservation[],
        public eventCategoryIds: string[] = [],
        public venueId: string = null) {
    }

    public static fromOrderItemGroup(orderItemGroup: OrderItemGroup): GroupedOrderItem {
        const venueId = orderItemGroup.venueId;
        const name = orderItemGroup.name;
        const id = orderItemGroup.id;

        let type: ProductGroupType;
        switch (orderItemGroup.type) {
            case 'TICKET':
                type = ProductGroupType.EVENT_TICKET;
                break;
            case 'SUBSCRIPTION':
                type = ProductGroupType.SUBSCRIPTION;
                break;
            case 'PRODUCT':
                type = ProductGroupType.PRODUCT;
                break;
        }

        let items = [];
        for (const item of orderItemGroup.items) {
            switch (type) {
                case ProductGroupType.EVENT_TICKET:
                    items.push(OrderItemReservation.fromEventTicketItem(item));
                    break;
                case ProductGroupType.SUBSCRIPTION:
                    items.push(OrderItemReservation.fromSubscriptionItem(item, venueId));
                    break;
                case ProductGroupType.PRODUCT:
                    items.push(OrderItemReservation.fromProductItem(item));
                    break;
            }
        }

        // order the items by price name, venue section name and price
        items = items.sort((a, b) => {
            if (a.priceName < b.priceName) {
                return -1;
            }
            if (a.priceName > b.priceName) {
                return 1;
            }

            if (a.venueSectionName < b.venueSectionName) {
                return -1;
            }

            if (a.venueSectionName > b.venueSectionName) {
                return 1;
            }

            return a.price > b.price ? -1 : 1;
        });

        return new GroupedOrderItem(id, name, type, items, orderItemGroup.eventCategoryIds, venueId);
    }
}
