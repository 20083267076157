import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import {CustomFieldSetting} from '../../models/custom-field-setting';
import {Shop} from '../../models/shop.class';
import {SubscriptionType} from '../../models/subscription-type.class';
import {TicketType} from '../../models/ticket-type.class';
import {Event} from '../../models/event.class';

@Component({
    selector: 'app-custom-field-setting-card',
    templateUrl: './custom-field-setting-card.component.html',
    styleUrls: ['./custom-field-setting-card.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldSettingCardComponent implements OnInit, OnChanges {

    @Input() customFieldSetting: CustomFieldSetting;
    @Input() shops: Shop[];
    @Input() events: Event[];
    @Input() ticketTypes: TicketType[];
    @Input() subscriptionTypes: SubscriptionType[];

    @Output() settingChange: EventEmitter<CustomFieldSetting> = new EventEmitter<CustomFieldSetting>();
    @Output() settingDelete: EventEmitter<CustomFieldSetting> = new EventEmitter<CustomFieldSetting>();

    isExpanded = false;
    menuItems = [
        {
            value: 'SHOPS',
            translationKey: 'Custom_Field_Setting.Shops',
            list: 'shopIds'
        },
        {
            value: 'EVENTS',
            translationKey: 'Custom_Field_Setting.Events',
            list: 'eventIds'
        },
        {
            value: 'TICKET_TYPES',
            translationKey: 'Custom_Field_Setting.Ticket_Types',
            list: 'ticketTypeIds'
        },
        {
            value: 'SUBSCRIPTION_TYPES',
            translationKey: 'Custom_Field_Setting.Subscription_Types',
            list: 'subscriptionTypeIds'
        }
    ];
    activeMenuItem = 'SHOPS';

    constructor(private crd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.activeMenuItem = 'SHOPS';

        this.crd.markForCheck();
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.crd.markForCheck();
        if (changes.events) {
            if (this.customFieldSetting.eventIds.length === 1) {
                const event = this.events.find(eventI => eventI.id === this.customFieldSetting.eventIds[0]);
                if (event) {
                    this.ticketTypes = event.ticketTypes;
                }
            } else {
                this.ticketTypes = null;
            }
        }
    }

    onMenuItemClicked(menuItem) {
        this.activeMenuItem = menuItem.value;
    }

    isSettingEnabled(setting) {
        return this.customFieldSetting[setting.list].length > 0;
    }

    isInSelection(list, item) {
        return this.customFieldSetting[list].includes(item.id);
    }

    onSelectionChanged(list, item) {
        const itemIndex = this.customFieldSetting[list].findIndex(listItem => listItem === item.id);

        if (itemIndex >= 0) {
            this.customFieldSetting[list].splice(itemIndex, 1);
        } else {
            this.customFieldSetting[list].push(item.id);
        }

        if (list === 'ticketTypeIds') {
            this.customFieldSetting.subscriptionTypeIds = [];
        }

        if (list === 'subscriptionTypeIds') {
            this.customFieldSetting.ticketTypeIds = [];
        }

        if (this.customFieldSetting.eventIds.length === 1) {
            this.ticketTypes = this.events.find(event => event.id === this.customFieldSetting.eventIds[0]).ticketTypes;
        } else {
            this.ticketTypes = null;
            this.customFieldSetting.ticketTypeIds = [];
        }

        this.settingChange.emit(this.customFieldSetting);

        this.crd.markForCheck();
    }

    onDeleteCustomFieldSettingClicked() {
        this.settingDelete.emit(this.customFieldSetting);
    }

}
