import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2
} from '@angular/core';
import {isMobile} from '../../../core/helpers/device-helper';

@Component({
    selector: 'app-stepper-step',
    templateUrl: './stepper-step.component.html',
    styleUrls: ['./stepper-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperStepComponent implements OnInit {

    public buttonSize = isMobile ? 'large' : 'medium';
    private _hasAdvancedStep = false;
    private _isLastStep = false;
    public isActive = false;

    private host: HTMLElement;

    @Input() config: StepperStepConfig;

    @Input() nextDisabled: boolean | (() => boolean);

    @Output() activated: EventEmitter<any> = new EventEmitter<any>();
    @Output() deactivated: EventEmitter<any> = new EventEmitter<any>();

    @Output() previous: EventEmitter<any> = new EventEmitter<any>();
    @Output() next: EventEmitter<any> = new EventEmitter<any>();
    @Output() advanced: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.host = this.elementRef.nativeElement;
    }

    activate(): void {
        this.activated.emit();
        this.isActive = true;
        this.renderer.setStyle(this.host, 'display', 'block');
    }

    deactivate(): void {
        if (this.isActive) {
            this.deactivated.emit();
        }

        this.renderer.setStyle(this.host, 'display', 'none');
    }

    onPreviousClick(): void {
        this.previous.emit();
    }

    onNextClick(): void {
        this.next.emit();
    }

    onAdvancedClick(): void {
        this.advanced.emit();
    }

    get hasAdvancedStep(): boolean {
        return this._hasAdvancedStep;
    }

    set hasAdvancedStep(value: boolean) {
        this._hasAdvancedStep = value;
        this.cdr.detectChanges();
    }

    get isLastStep(): boolean {
        return this._isLastStep;
    }

    set isLastStep(value: boolean) {
        this._isLastStep = value;
        this.cdr.detectChanges();
    }
}

export class StepperStepConfig {
    titleTranslationKey: string;
}
