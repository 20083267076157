<cm-sidenav>
    <div class="form-group search">
        <div class="form-input-special">
            <input type="text" class="form-control" [placeholder]="('General.Search' | trans)" #search>
            <span class="input-left icon">
                <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
            </span>
        </div>
    </div>
    <ng-container *ngFor="let module of modules">
        <cm-sidenav-option [attr.slot]="module.position" [routerLink]="module.route" routerLinkActive="active">
            <span class="cm-icon" [ngClass]="'cm-icon-'+module.icon" aria-hidden="true"></span>
            <span>{{(module.label | trans)}}</span>
        </cm-sidenav-option>
    </ng-container>
    <cm-sidenav-option slot="bottom"></cm-sidenav-option>
</cm-sidenav>

<ng-container *ngIf="search$ | async as results">
    <ng-container *ngIf="results">
        <app-menu-search-results [results]="results"
                                 (searchResultClicked)="onSearchResultClicked()"
                                 (closeClicked)="onCloseClicked()"></app-menu-search-results>
    </ng-container>
</ng-container>
