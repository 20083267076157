<cm-dialog [id]="id" data-max-width="720px">
    <h3 slot="header" class="dialog-title">
        <span class="cm-icon cm-icon-tag" aria-hidden="true"></span> {{ ('Dialog_Order_Add_Discount.Title' | trans) }}
    </h3>

    <div slot="body" class="form-row">
        <div class="form-group">
            <div class="row">
                <div class="col-12">
                    <div class="form-row">
                        <app-form-text-switch
                            [option1]="{label:('Dialog_Order_Add_Discount.Campaign' | trans) , value: 'DISCOUNT_CAMPAIGN'}"
                            [option2]="{label:('Dialog_Order_Add_Discount.Code' | trans) , value: 'DISCOUNT_CODE'}"
                            [(value)]="selectedDiscountOption">
                        </app-form-text-switch>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="selectedDiscountOption === 'DISCOUNT_CAMPAIGN'">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <div class="form-row">
                            <app-form-select [label]="('General.Discount_Campaign' | trans)"
                                             [options]="discountCampaigns$ | async"
                                             (change)="onDiscountCampaignChange($event)"
                                             optionLabel="name"
                                             optionValue="id">
                            </app-form-select>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedDiscountOption === 'DISCOUNT_CODE'">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <div class="form-row">
                            <app-form-input [label]="('Dialog_Order_Add_Discount.Code' | trans)"
                                            (keyup)="onDiscountCodeChange($event)">
                            </app-form-input>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{ ('General.Cancel' | trans) }}</button>
        <button class="btn btn-primary"
                (click)="confirm()">{{ ('General.Apply' | trans) }} {{ (discount ? discount.amount : 0) | currency }}
        </button>
    </div>
</cm-dialog>
