import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Product} from '../../models/product';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient) {
    }

    getProducts(params?: HttpParams): Observable<Product[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Product[]>(this.baseUrl + `/v1.0/product`, config);
    }

    cancelProduct(productId: string): Observable<any> {
        return this.http.patch(this.baseUrl + `/v1.0/product/${productId}`, {status: 'CANCELED'}, this.config);
    }
}
