import {Download} from './download.interface';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ExportService} from '../../export-service/export.service';

export class PaymentReportDownload extends Download {
    type = 'payment';
    filters: HttpParams;
    batchSize: number;

    constructor(private exportService: ExportService) {
        super();
    }

    getData(type: string, skip: number, total: number, filters) {
        const httpParams = this.filters;

        return this.exportService.getPaymentReport(
            httpParams
                .set('skip', skip.toString())
                .set('take', this.batchSize.toString())
                .set('timezone', this.getTimezone())
                .set('payment[paymentTags]', 'true')
        ).pipe(
            map((response: HttpResponse<any[]>) => {
                return response.body;
            })
        );
    }
}
