import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';

@Component({
    selector: 'app-dialog-blacklist',
    templateUrl: './dialog-blacklist.component.html',
    styleUrls: ['./dialog-blacklist.component.css']
})
export class DialogBlacklistComponent extends DialogBase<IInput, IOutput> {
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
        this.extraData = data.extraData;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: this.extraData
        });
    }

    public cancel() {
        super.cancel();
    }

}
