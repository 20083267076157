import {ModelBase} from '../abstract/model-base';
import {OrderDetailsRefund} from './order-details.refund';

export class OrderDetailsPayment extends ModelBase {
    public id: string;
    public callbackSuccessUrl: string;
    public callbackCanceledUrl: string;
    public callbackExpiredUrl: string;
    public callbackFailedUrl: string;
    public createdAt: string;
    public description: string;
    public method: string;
    public status: string;
    public paymentUrl: string;
    public balance: string;
    public currency: string;
    public issuerId: string;
    public isInstallment: boolean;
    public payedAt: string;
    public canceledAt: string;
    public expiredAt: string;
    public failedAt: string;
    public refunds: OrderDetailsRefund[];

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsPayment {
        const model = new OrderDetailsPayment();

        model.id = data.id;
        model.callbackSuccessUrl = data.callbackSuccessUrl;
        model.callbackCanceledUrl = data.callbackCanceledUrl;
        model.callbackExpiredUrl = data.callbackExpiredUrl;
        model.callbackFailedUrl = data.callbackFailedUrl;
        model.createdAt = data.createdAt;
        model.description = data.description;
        model.method = data.method;
        model.status = data.status;
        model.paymentUrl = data.paymentUrl;
        model.balance = data.balance;
        model.currency = data.currency;
        model.issuerId = data.issuerId;
        model.isInstallment = data.isInstallment;
        model.payedAt = data.payedAt;
        model.canceledAt = data.canceledAt;
        model.expiredAt = data.expiredAt;
        model.failedAt = data.failedAt;
        model.refunds = data.refunds ? data.refunds.map((refund: any) => (new OrderDetailsRefund()).mapModel(refund)) : [];

        return model;
    }
}
