import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalReservation} from '../models/reservation/local-reservation.class';
import {GroupedOrderItem} from '../models/reservation/grouped-order-item.class';
import {ProductGroupType} from '../enums/order/product-group-type';
import {OrderItemReservation} from '../models/reservation/order-item-reservation.class';
import {OrderItemReservationRequestHelper} from '../helpers/request/order-item-reservation-request-helper';
import {OrderItemConflictResult} from '../models/order-item-conflict-result/order-item-conflict-result.class';
import {ReservationDifferenceHelper, ReservationDifferences} from '../models/reservation/reservation-difference-helper';
import {SeatmapSeatInfo} from '../components/venue-section-master-detail/seatmap-seat-info';
import {VenueSectionContext} from '../enums/venue-section/venue-section-context';

@Injectable({
    providedIn: 'root'
})
export class LocalReservationService {
    private localReservationSource: BehaviorSubject<LocalReservation> = new BehaviorSubject<LocalReservation>(null);
    public localReservation$: Observable<LocalReservation>;

    private syncedReservationSource: BehaviorSubject<LocalReservation> = new BehaviorSubject<LocalReservation>(null);
    public syncedReservation$: Observable<LocalReservation>;

    constructor() {
        this.localReservationSource = new BehaviorSubject<LocalReservation>(new LocalReservation());
        this.localReservation$ = this.localReservationSource.asObservable();
        this.syncedReservationSource = new BehaviorSubject<LocalReservation>(new LocalReservation());
        this.syncedReservation$ = this.syncedReservationSource.asObservable();
    }

    resetLocalReservation() {
        this.localReservationSource.next(new LocalReservation());
    }

    getAmountOfItems(): number {
        return this.getValue().groupedOrderItems.reduce(
            (total, groupedOrderItem) => total + groupedOrderItem.items.length, 0
        );
    }

    getSeatsForSubscriptionType(subscriptionTypeId: string): SeatmapSeatInfo[] {
        const reservation = this.getValue();
        const groupedOrderItem =
            reservation.groupedOrderItems.find(groupedOrderItem =>
                groupedOrderItem.id === subscriptionTypeId && groupedOrderItem.type === ProductGroupType.SUBSCRIPTION
            );

        return groupedOrderItem?.items.map(item => new SeatmapSeatInfo(
            item.venueSectionSeatId,
            groupedOrderItem.id,
            groupedOrderItem.eventCategoryIds)) ?? [];
    }

    getSeatsForEvent(eventId: string): SeatmapSeatInfo[] {
        const reservation = this.getValue();
        const groupedOrderItem =
            reservation.groupedOrderItems.find(groupedOrderItem =>
                groupedOrderItem.id === eventId && groupedOrderItem.type === ProductGroupType.EVENT_TICKET
            );

        return groupedOrderItem?.items.map(item => new SeatmapSeatInfo(
            item.venueSectionSeatId,
            groupedOrderItem.id,
            groupedOrderItem.eventCategoryIds)) ?? [];
    }

    isReservationActive(): boolean {
        return this.localReservationSource.getValue()?.hasItems();
    }

    getDifferences(): ReservationDifferences {
        const syncedReservation = this.syncedReservationSource.getValue();
        const localReservation = this.localReservationSource.getValue();
        return new ReservationDifferenceHelper().getDifferences(syncedReservation, localReservation);
    }

    getAmountOfLocalCapacityByProductType(productTypeId: string) {
        const reservation = this.localReservationSource.getValue();
        const calculateTotalReducer = (total: number, groupedOrderItem: Partial<GroupedOrderItem>) => {
            return total + groupedOrderItem.items.filter(item =>
                item.typeId === productTypeId
            ).length;
        };

        return reservation.groupedOrderItems.reduce((total, groupedOrderItem) => {
            return calculateTotalReducer(total, groupedOrderItem);
        }, 0);
    }

    getAmountOfLocalCapacityBySeatId(groupedOrderItemId: string, venueSectionSeatId: string): number {
        const reservation = this.localReservationSource.getValue();
        const calculateTotalReducer = (total: number, groupedOrderItem: Partial<GroupedOrderItem>) => {
            return total + groupedOrderItem.items.filter(item =>
                item.venueSectionSeatId === venueSectionSeatId && groupedOrderItem.id === groupedOrderItemId
            ).length;
        };

        if (!reservation.orderId) {
            return reservation.groupedOrderItems.reduce((total, groupedOrderItem) => {
                return calculateTotalReducer(total, groupedOrderItem);
            }, 0);
        }

        const reservationDifferences = this.getDifferences();
        if (!reservationDifferences.isChanged || !reservationDifferences.groupedOrderItems) {
            return 0;
        }

        return reservationDifferences.groupedOrderItems.reduce((total, groupedOrderItem) => {
            return calculateTotalReducer(total, groupedOrderItem);
        }, 0);
    }

    getGroupedOrderItems() {
        return this.getValue().groupedOrderItems;
    }

    private getValue() {
        return this.localReservationSource.getValue();
    }

    public setLocalReservation(localReservation: LocalReservation, updateSyncedReservation = false) {
        if (updateSyncedReservation) {
            this.setSyncedReservation(localReservation.clone());
        }

        this.localReservationSource.next(localReservation);
    }

    private setSyncedReservation(syncedReservation: LocalReservation) {
        this.syncedReservationSource.next(syncedReservation);
    }

    setReservationConflicts(conflicts: OrderItemConflictResult[]): LocalReservation {
        const eventConflictResults = new Map<string, OrderItemConflictResult[]>();
        for (const conflict of conflicts) {
            for (const eventCategory of conflict.eventCategoryIds) {
                if (!eventConflictResults.has(eventCategory)) {
                    eventConflictResults.set(eventCategory, []);
                }

                eventConflictResults.get(eventCategory).push(conflict);
            }
        }

        const reservation = this.localReservationSource.getValue();

        eventConflictResults.forEach((conflicts, eventCategory) => {
            reservation.groupedOrderItems.forEach(groupedOrderItem => {
                if (groupedOrderItem.eventCategoryIds.indexOf(eventCategory) === -1) {
                    return;
                }

                groupedOrderItem.items = groupedOrderItem.items.map(item => {
                    const conflict = conflicts.find(conflict => conflict.venueSectionSeatId === item.venueSectionSeatId);
                    if (conflict) {
                        item.hasConflict = true;
                        conflicts.splice(conflicts.indexOf(conflict), 1);
                    }

                    return item;
                });

                if (groupedOrderItem.items.some(item => item.hasConflict)) {
                    groupedOrderItem.hasConflict = true;
                }

                return groupedOrderItem;
            });
        });

        return reservation;
    }

    deleteOrderItemReservation(orderItemReservation: OrderItemReservation) {
        const syncedReservation = this.syncedReservationSource.getValue();
        this.deleteFromReservation(orderItemReservation, syncedReservation);

        const localReservation = this.localReservationSource.getValue();
        this.deleteFromReservation(orderItemReservation, localReservation);

        this.syncedReservationSource.next(syncedReservation);
        this.localReservationSource.next(localReservation);
    }

    private deleteFromReservation(orderItemReservation: OrderItemReservation, reservation: LocalReservation): void {
        reservation.groupedOrderItems = reservation.groupedOrderItems.map(groupedOrderItem => {
            for (let i = 0; i < groupedOrderItem.items.length; i++) {
                const orderItem = groupedOrderItem.items[i];

                if (JSON.stringify(orderItem) === JSON.stringify(orderItemReservation)) {
                    groupedOrderItem.items.splice(i, 1);
                    break;
                }
            }
            return groupedOrderItem;
        });

        reservation.groupedOrderItems = reservation.groupedOrderItems.filter(groupedOrderItem => groupedOrderItem.items.length > 0);
    }

    findOrderItem(type: ProductGroupType, typeId: string, venueSectionSeatId: string = null): {
        groupedOrderItem: GroupedOrderItem,
        item: OrderItemReservation
    } {
        for (const groupedOrderItem of this.localReservationSource.getValue().groupedOrderItems) {
            if (groupedOrderItem.id !== typeId) {
                continue;
            }

            if (type === ProductGroupType.PRODUCT) {
                return {groupedOrderItem, item: groupedOrderItem.items[0]};
            }

            const foundOrderItem = groupedOrderItem.items.find(item => item.venueSectionSeatId === venueSectionSeatId);
            if (foundOrderItem) {
                return {groupedOrderItem, item: foundOrderItem};
            }
        }

        return null;
    }

    isBlockingEventCategorySeatInOrder(categoryIds: string[], venueSectionSeatId: string, venueSectionContext: VenueSectionContext): boolean {
        const isEventContext = venueSectionContext === VenueSectionContext.EVENT;
        for (const groupedOrderItem of this.localReservationSource.getValue().groupedOrderItems) {
            const isSimilarContext =
                isEventContext && groupedOrderItem.type === ProductGroupType.EVENT_TICKET ||
                !isEventContext && groupedOrderItem.type === ProductGroupType.SUBSCRIPTION;
            // Seats with a similar context do not interfere
            if (isSimilarContext) {
                continue;
            }

            const eventCategoryIdsSet = new Set(groupedOrderItem.eventCategoryIds);
            let hasCommonEventCategory = false;
            for (const categoryId of categoryIds) {
                if (eventCategoryIdsSet.has(categoryId)) {
                    hasCommonEventCategory = true;
                    break;
                }
            }

            if (!hasCommonEventCategory) {
                continue;
            }

            const foundOrderItem = groupedOrderItem.items.find(item => item.venueSectionSeatId === venueSectionSeatId);
            if (foundOrderItem) {
                return true;
            }
        }

        return null;
    }

    deleteOrderItemsWithIdsFromReservation(orderItemIds: string[]): LocalReservation {
        const reservation = this.localReservationSource.getValue();
        reservation.groupedOrderItems.forEach(groupedOrderItem => {
            groupedOrderItem.items = groupedOrderItem.items.filter(item => {
                return !orderItemIds.includes(item.orderItemId);
            });
        });
        reservation.groupedOrderItems =
            reservation.groupedOrderItems.filter(groupedOrderItem => groupedOrderItem.items.length > 0);

        const syncedReservation = this.syncedReservationSource.getValue();
        syncedReservation.groupedOrderItems.forEach(groupedOrderItem => {
            groupedOrderItem.items = groupedOrderItem.items.filter(item => {
                return !orderItemIds.includes(item.orderItemId);
            });
        });
        syncedReservation.groupedOrderItems =
            reservation.groupedOrderItems.filter(groupedOrderItem => groupedOrderItem.items.length > 0);

        this.syncedReservationSource.next(syncedReservation);
        this.localReservationSource.next(reservation);
        return reservation;
    }

    deleteOrderItemFromReservation(type: ProductGroupType, typeId: string, venueSectionSeatId: string = null): boolean {
        let isOrderItemRemoved = false;
        const reservation = this.localReservationSource.getValue();
        reservation.groupedOrderItems = reservation.groupedOrderItems.map(groupedOrderItem => {
            if (groupedOrderItem.id !== typeId) {
                return groupedOrderItem;
            }

            if (type === ProductGroupType.PRODUCT) {
                // todo: Check if products should be checked per item
                groupedOrderItem.items.pop();
                groupedOrderItem.hasConflict = false;
                isOrderItemRemoved = true;

                return groupedOrderItem;
            }

            const eventTicketIndex = groupedOrderItem.items.findIndex(item =>
                item.venueSectionSeatId === venueSectionSeatId
            );

            if (eventTicketIndex > -1) {
                groupedOrderItem.items.splice(eventTicketIndex, 1);
                groupedOrderItem.hasConflict = false;
                isOrderItemRemoved = true;
            }

            return groupedOrderItem;
        });

        if (!isOrderItemRemoved) {
            return false;
        }

        reservation.groupedOrderItems =
            reservation.groupedOrderItems.filter(groupedOrderItem => groupedOrderItem.items.length > 0);

        this.localReservationSource.next(reservation);
        return true;
    }

    initializePrices(determinationFunction: any) {
        const reservation = this.localReservationSource.getValue();
        reservation.groupedOrderItems = reservation.groupedOrderItems.map(groupedOrderItem => {
            groupedOrderItem.items = groupedOrderItem.items.map(item => {
                if (item.price !== null) {
                    return item;
                }

                const itemPrice = determinationFunction(item.typeId, item.venueSectionGroupId);

                if (itemPrice === null) {
                    throw new Error('Price not found for item');
                }

                item.price = itemPrice;
                return item;
            });

            return groupedOrderItem;
        });

        this.localReservationSource.next(reservation);
    }

    addOrderItem(
        type: ProductGroupType,
        name: string,
        typeId: string,
        orderItem: OrderItemReservation,
        eventCategoryIds: string[] = [],
        venueId: string = null
    ) {
        const reservation = this.localReservationSource.getValue();
        let groupedOrderItem = reservation.groupedOrderItems.find(groupedOrderItem => groupedOrderItem.id === typeId);

        if (!groupedOrderItem) {
            groupedOrderItem = new GroupedOrderItem(typeId, name, type, [], eventCategoryIds, venueId);
            reservation.groupedOrderItems.unshift(groupedOrderItem);
        }

        groupedOrderItem.items.unshift(orderItem);

        this.localReservationSource.next(reservation);
    }

    collectAddOrderItemsRequestBody(groupedOrderItems: GroupedOrderItem[]) {
        const result = [];

        if (!groupedOrderItems) {
            return result;
        }

        for (const groupedOrderItem of groupedOrderItems) {
            for (const item of groupedOrderItem.items) {
                result.push(OrderItemReservationRequestHelper.getRequestBody(
                    groupedOrderItem.type,
                    groupedOrderItem.id,
                    item.venueSectionSeatId,
                    item.typeId,
                    item.venueSectionAccessId,
                    item.note,
                    item.price
                ));
            }
        }

        return result;
    }

    isActive(): boolean {
        return this.getValue().hasItems();
    }
}
