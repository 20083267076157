import {Injectable, Injector} from '@angular/core';
import {SubscriptionType} from '../../models/subscription-type.class';
import {StateManagementService} from '../util/state-management-service/state-management.service';

@Injectable({
                providedIn: 'root'
            })
export class SubscriptionTypeManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new SubscriptionType();

        this.initialize();
    }

}
