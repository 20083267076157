import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable, map} from 'rxjs';
import {ActivityLog} from '../../models/activity-log.class';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ActivityLogService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getActivityLogs(params?: HttpParams): Observable<HttpResponse<ActivityLog[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<ActivityLog[]>(this.baseUrl + `/v1.0/activitylog`, {...config, observe: 'response'});
    }

    getActivityLogEvents() {
        return this.http.get<any>(this.baseUrl + `/v1.0/activitylog/events`, this.config).pipe(map((data: any[]) => {
            return data;
        }));
    }
}
