import {Printer} from './printer.class';

export class PrintFormat {
    constructor(public type: string,
                public name: string,
                public printers: Printer[]) {
    }

}

export function mapPrintFormat(item: any): PrintFormat {
    return new PrintFormat(
        item.type,
        item.name,
        item.printers
    );
}
