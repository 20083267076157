import {Layer} from 'konva/lib/Layer';

export class TicketTemplateRender {
    public x: number;
    public y: number;
    public width: number;
    public height: number;
    public rotation: number;
    public ticketTemplateComponent: any;
    public layer: Layer;
    public additionalInformation: string = null;

    constructor(data) {
        this.mapTicketTemplateRender(data);
    }

    mapTicketTemplateRender(data): TicketTemplateRender {
        this.x = data.x;
        this.y = data.y;
        this.width = data.width;
        this.height = data.height;
        this.rotation = data.rotation;
        this.ticketTemplateComponent = data.ticketTemplateComponent;
        this.layer = data.layer;
        this.additionalInformation = data.additionalInformation;

        return this;
    }
}
