<cm-telephone-input [attr.data-error-message]="('General.Invalid_Phone_Number' | trans)"
                    [attr.data-error-required]="('General.Required' | trans)"
                    [attr.data-label]="label"
                    [attr.data-language]="language"
                    [attr.data-countrycode]="countryCode"
                    [attr.placeholder]="placeholder"
                    [attr.value]="value"
                    [attr.required]="required"
                    (change)="inputChange($event.detail)">
</cm-telephone-input>
