import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-menu-search-results',
    templateUrl: './menu-search-results.component.html',
    styleUrls: ['./menu-search-results.component.scss']
})
export class MenuSearchResultsComponent {

    @Input()
    results: any;

    @Output()
    searchResultClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    closeClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router, private elementRef: ElementRef) {
    }

    onSearchResultClick(result) {
        this.searchResultClicked.emit();
        this.router.navigate([result.route]);
    }

    onCloseClick() {
        this.closeClicked.emit();
    }

    @HostListener('document:click', ['$event'])
    outsideClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.onCloseClick();
        }
    }

}
