<div class="page-header">
    <div class="breadcrumb">
        <a class="breadcrumb-item" [routerLink]="['/orders']">{{('Menu.Orders' | trans)}}</a>
        <a class="breadcrumb-item"></a>
    </div>
    <h1>{{('Payment_List.Title' | trans) }}</h1>
</div>

<div class="page-content">
    <div class="page-section">
        <!--        <div class="row">-->
        <!--            <div class="col-12 text-right">-->
        <!--                <button class="btn btn-primary-icon m-l-8" (click)="download()" [cm-tooltip]="('General.Download' | trans)">-->
        <!--                    <span class="cm-icon cm-icon-download" aria-hidden="true"></span>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="row no-print">
            <div class="col-12 col-sm-8">
                <app-search-filter-container [settings]="filterSettings"
                                             [statusOptions]="statusOptions"
                                             [(selected)]="selectedFilters"
                                             (selectedChange)="onFilterUpdate()">
                </app-search-filter-container>
            </div>
            <div class="col-12 col-sm-4 text-right">
                <button class="btn btn-primary-icon button" [disabled]="statisticGroups.length <= 0" (click)="onPrint()" [cm-tooltip]="'General.Print' | trans">
                    <span class="cm-icon cm-icon-print" aria-hidden="true"></span>
                </button>
                <button class="btn btn-primary-icon button" [disabled]="statisticGroups.length <= 0" (click)="download()" [cm-tooltip]="'General.Download' | trans">
                    <span class="cm-icon cm-icon-download" aria-hidden="true"></span>
                </button>
                <span class="view">{{'General.View' | trans}}:</span>
                <button class="btn btn-secondary-icon" (click)="setView('PAYMENT_LIST_GROUPED_BY_TYPE')"
                        [class.active]="view === 'PAYMENT_LIST_GROUPED_BY_TYPE'">
                    <span class="cm-icon cm-icon-ticket" aria-hidden="true"></span>
                </button>
                <button class="btn btn-secondary-icon" (click)="setView('PAYMENT_LIST')"
                        [class.active]="view === 'PAYMENT_LIST'">
                    <span class="cm-icon cm-icon-money" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <app-statistics [isLoading]="isLoading" [statistics]="statistics"></app-statistics>

        <ng-container *ngIf="view === 'PAYMENT_LIST_GROUPED_BY_TYPE'">
            <div class="row">
                <div class="col-12">
                    <app-statistics-groups [isLoading]="isLoading"
                                           [statisticGroups]="statisticGroups"></app-statistics-groups>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="view === 'PAYMENT_LIST'">
            <div class="row">
                <div class="col-12">
                    <app-payment-table [isLoading]="isLoading" [payments]="payments$ | async"></app-payment-table>
                    <app-pagination [skip]="pagination.skip" [take]="pagination.take" [total]="pagination.total"
                                    (paginationChange)="onPaginationChange($event)"></app-pagination>
                </div>
            </div>
        </ng-container>
    </div>
</div>
