import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmTranslationService} from '../../../core/services/cm-translation.service';

@Component({
    selector: 'app-border-picker',
    templateUrl: './border-picker.component.html',
    styleUrls: ['./border-picker.component.css']
})
export class BorderPickerComponent implements OnInit {

    @Input() border: string;

    @Output() borderChanged: EventEmitter<string> = new EventEmitter<string>();

    width = '2px';
    style = 'solid';
    color = 'rgba(0, 0, 0, 1)';

    widthOptions = [];
    styleOptions = [];
    styleOptionsList = ['Solid', 'Dashed', 'Dotted', 'Double'];

    constructor(
        private cmTranslationService: CmTranslationService
    ) {
    }

    ngOnInit() {
        if (this.border) {
            const split = this.border.split(' ');
            this.width = split[0];
            this.style = split[1];
            this.color = split.slice(2).join('');
        }

        this.widthOptions.push({
            label: this.cmTranslationService.getPhraseForLanguage(`General.None`),
            value: 'none'
        });

        for (let i = 1; i <= 4; i++) {
            this.widthOptions.push({
                label: `${i} ${this.cmTranslationService.getPhraseForLanguage('Border_Picker.Pixels')}`,
                value: `${i}px`
            });
        }

        this.styleOptionsList.forEach(option => {
            this.styleOptions.push({
                label: this.cmTranslationService.getPhraseForLanguage(`Border_Picker.Border_Style_${option}`),
                value: option.toLowerCase()
            });
        });
    }

    onFieldChange(field: string, value: string) {
        if (field === 'width' && value === 'none') {
            this.width = value;
            this.style = null;
            this.color = null;

            const border = 'none';

            this.borderChanged.emit(border);
        } else {
            this[field] = value;

            if (this.style === null) {
                this.style = 'solid';
            }
            if (this.color === null) {
                this.color = 'rgba(0, 0, 0, 1)';
            }

            const border = this.generateBorderString();

            this.borderChanged.emit(border);
        }
    }

    generateBorderString() {
        return `${this.width} ${this.style} ${this.color}`;
    }

}
