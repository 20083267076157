export class EventStatistics {
    constructor(public id: string,
                public name: string,
                public capacity: number,
                public reserved: number,
                public issued: number,
                public available: number,
                public checkedIn: number,
                public venueSections?: EventStatistics[]) {
    }
}

export function mapEventStatistics(item: any): EventStatistics {
    return new EventStatistics(
        item.id,
        item.name,
        item.capacity,
        item.reserved,
        item.issued,
        item.available,
        item.checkedIn,
        item.venueSections
    );
}

