import {Injectable, Injector} from '@angular/core';
import {Organisation} from '../../models/guest-manager/organisation.class';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrganisationManagementService extends StateManagementService {
    httpParams = new HttpParams()
        .set('depth', '2')
        .set('organisation[organisationSettings]', 'true')
        .set('includePersonGuid', 'true');

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new Organisation();
        this.initialize(null, false);
    }
}
