<cm-dialog [id]="id">
    <h3 slot="header" class="dialog-title">
        {{('Event_Category.Add_Event_Category' | trans)}}
    </h3>

    <div slot="body">
        <div class="form-group">
            <div class="form-row">
                <app-form-input
                    formId="createEventCategory"
                    [label]="('General.Name' | trans)"
                    [(value)]="eventCategoryName"
                    [required]="true">
                </app-form-input>
            </div>
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('Customer_Form.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('Customer_Form.Save' | trans)}}</button>
    </div>
</cm-dialog>
