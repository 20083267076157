export class ChartSetting {
    constructor(public type               = 'spline',
                public title              = '',
                public subtitle           = '',
                public backgroundGradient = false,
                public legend             = true,
                public grid               = {
                    horizontal: true,
                    vertical:   false
                },
                public label              = {
                    horizontal: true,
                    vertical:   true
                },
                public stacked: boolean   = true) {
    }
}

