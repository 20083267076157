import {EventTicket} from '../event-ticket.class';
import {Subscription} from '../subscription.class';
import {Product} from '../product';

export class OrderItemReservation {
    public hasConflict: boolean;
    constructor(public typeId: string,
                public priceName: string,
                public venueId: string = null,
                public venueSectionName: string = null,
                public venueSectionGroupId: string = null,
                public venueSectionSeatId: string = null,
                public row: number = null,
                public seat: number = null,
                public venueSectionAccessId: string = null,
                public note: string = null,
                public orderItemId: string = null,
                public price: number = null) {
    }

    public static fromSubscriptionItem(item: any, venueId: string): OrderItemReservation {
        const subscription = item.subscription as Subscription;
        return new OrderItemReservation(
            subscription.subscriptionTypePriceId,
            subscription.subscriptionTypePrice.name,
            venueId,
            subscription.seat?.sectionName,
            subscription.venueSectionGroupId,
            subscription.venueSectionSeatId,
            subscription.seat?.row,
            subscription.seat?.seat,
            subscription.venueSectionAccessIds ? subscription.venueSectionAccessIds[0] : null,
            item.note,
            subscription.orderItemId,
            item.price
        );
    }

    public static fromEventTicketItem(item: any): OrderItemReservation {
        const eventTicket = item.ticket as EventTicket;
        return new OrderItemReservation(
            eventTicket.ticketTypeId,
            eventTicket.ticketType.name,
            eventTicket.venueId,
            eventTicket.seat.sectionName,
            eventTicket.venueSectionGroupId,
            eventTicket.venueSectionSeatId,
            eventTicket.seat.row,
            eventTicket.seat.seat,
            eventTicket.venueSectionAccessIds ? eventTicket.venueSectionAccessIds[0] : null,
            item.note,
            eventTicket.orderItemId,
            item.price
        );
    }

    public static fromProductItem(item: any): OrderItemReservation {
        const product = item.product as Product;
        const orderItemReservation = new OrderItemReservation(
            product.productTypePriceId,
            product.productTypePrice.name
        );
        orderItemReservation.price = item.price;
        orderItemReservation.note = item.note;
        orderItemReservation.orderItemId = product.orderItemId;

        return orderItemReservation;
    }
}
