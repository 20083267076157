import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'venueSectionFilter'
})
export class VenueSectionFilterPipe implements PipeTransform {

    transform(rings: any[], filter: string): any[] {
        if (!rings) {
            return [];
        }

        if (!filter) {
            return rings;
        }

        filter = filter.toLowerCase();

        return rings
            .map(ring => {
                return {
                    ...ring,
                    sections: ring.sections.filter(section => section.name.toLowerCase().includes(filter))
                };
            })
            .filter(ring => ring.sections.length);
    }

}
