import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'cm-ticketing-data-table-header-data',
  templateUrl: './data-table-header-data.component.html',
  styleUrls: ['./data-table-header-data.component.css']
})
export class DataTableHeaderDataComponent implements OnInit, AfterViewInit {
    @ViewChild('tableDataHeader') tableDataHeader: ElementRef;
    @Output() sortChangedEmitter: EventEmitter<any> = new EventEmitter();
    @Output() stickColumnEmitter: EventEmitter<any> = new EventEmitter();
    @Output() checkboxChangedEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() actionClickedEmitter: EventEmitter<any> = new EventEmitter();
    @Input() id;
    @Input() value;
    @Input() options;

    @Input() checkBoxState: 'CHECKED' | 'UNCHECKED' | 'INTERMEDIATE' = 'UNCHECKED';

    public type: 'DATA' | 'ACTION' | 'CHECKBOX' = 'DATA';

    constructor() { }

    ngOnInit(): void {
        this.type = this.getType();
    }

    ngAfterViewInit(): void {
        for (const option of this.options) {
            this.tableDataHeader.nativeElement.setAttribute(option.name, option.value);
        }
    }

    sortChanged(id, event): void {
        this.sortChangedEmitter.emit({
            id,
            type: event.detail.type
        });
    }

    stickColumnCalled(event): void {
        if (event?.detail?.columnId && event.detail.stickyDirection === 'left') {
            this.tableDataHeader.nativeElement.parentElement.style.position = 'sticky';
            this.tableDataHeader.nativeElement.parentElement.style.left = '0';
            this.tableDataHeader.nativeElement.parentElement.style.zIndex = '19';
            this.stickColumnEmitter.emit(event);
        }
    }

    onCheckboxChange(event): void {
        event.target.checked = false;
        this.checkboxChangedEmitter.emit(event);
    }

    actionClicked(event): void {
        this.actionClickedEmitter.emit({
            id: event.target.id,
            headerId: this.id
        });
    }

    private getType(): 'DATA' | 'ACTION' | 'CHECKBOX' {
        if (this.isAction()) {
            return 'DATA';
        }

        if (this.isCheckbox()) {
            return 'CHECKBOX';
        }

        return 'DATA';
    }

    isCheckbox(): boolean {
        return this.options?.some((option => option.name === 'data-checkbox'));
    }

    isAction(): boolean {
        return this.options?.some((option => option.name === 'data-action'));
    }
}
