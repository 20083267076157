import {ModelBase} from '../abstract/model-base';
import {OrderDetailsPayment} from './order-details-payment.class';
import {OrderDetailsOrderTag} from './order-details-order-tag.class';
import {OrderDetailsCustomer} from './order-details-customer.class';
import {OrderDetailsDeliveries} from './order-details-deliveries.class';
import {OrderDetailsInvoice} from './order-details-invoice.class';
import {OrderDetailsDiscounts} from './order-details-discounts.class';
import {OrderDetailsCostItem} from './order-details-cost-item.class';

export class OrderDetails extends ModelBase {
    public id: string;
    public createdAt: string;
    public expireAt: string;
    public isGuestManager: boolean;
    public externalReference: string;
    public status: string;
    public batchId: string;
    public importId: string;
    public isPersonalisationRequired: boolean;
    public personalisationUrl: string;
    public downloadUrl: string;
    public directCheckoutLink: string;
    public customer: OrderDetailsCustomer;
    public invoice: OrderDetailsInvoice;
    public campaignId: string;
    public totalDiscount: number;
    public totalPrice: number;
    public discounts: OrderDetailsDiscounts[];
    public payments: OrderDetailsPayment[];
    public orderTags: OrderDetailsOrderTag[];
    public orderDeliveries: OrderDetailsDeliveries[];
    public additionalCosts: OrderDetailsCostItem[];
    public shopId: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetails {
        const model = new OrderDetails();

        model.id = data.id;
        model.createdAt = data.createdAt;
        model.expireAt = data.expireAt;
        model.isGuestManager = data.isGuestManager;
        model.batchId = data.batchId;
        model.importId = data.importId;
        model.externalReference = data.externalReference;
        model.status = data.status;
        model.isPersonalisationRequired = data.isPersonalisationRequired;
        model.personalisationUrl = data.personalisationUrl;
        model.downloadUrl = data.downloadUrl;
        model.directCheckoutLink = data.directCheckoutLink;
        model.customer = data.customer ? (new OrderDetailsCustomer()).mapModel(data.customer) : null;
        model.invoice = data.invoice ? (new OrderDetailsInvoice()).mapModel(data.invoice) : null;
        model.campaignId = data.campaignId;
        model.totalDiscount = data.totalDiscount;
        model.totalPrice = data.totalPrice;
        model.discounts = data.discounts ? data.discounts.map((discount: any) => (new OrderDetailsDiscounts()).mapModel(discount)) : [];
        model.payments = data.payments ? data.payments.map((payment: any) => (new OrderDetailsPayment()).mapModel(payment)) : [];
        model.orderTags = data.orderTags ? data.orderTags.map((orderTag: any) => (new OrderDetailsOrderTag()).mapModel(orderTag)) : [];
        model.orderDeliveries = data.deliveries ? data.deliveries.map((delivery: any) => (new OrderDetailsDeliveries()).mapModel(delivery)) : [];
        model.additionalCosts = data.additionalCosts ? data.additionalCosts.map((additionalCost: any) => (new OrderDetailsCostItem()).mapModel(additionalCost)) : [];
        model.shopId = data.shopId;

        return model;
    }
}
