import {NotificationHandler} from './notification-handler';
import {Notification} from '../../models/notification.class';
import {BehaviorSubject} from 'rxjs';

export class ProgressNotificationHandler implements NotificationHandler {

    private notificationStream: BehaviorSubject<Notification[]>;

    constructor(notificationStream: BehaviorSubject<Notification[]>) {
        this.notificationStream = notificationStream;
    }

    public handle(notification: Notification): void {
        this.notificationStream.next([
            new Notification(notification.content.topic, 'BULK_ACTION', {
                total: notification.content.total,
                current: notification.content.current
            })
        ]);
    }
}
