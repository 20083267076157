<gridster-item [item]="analyticsDashboardItem.gridster">
    <div class="card h-100 overflow-hidden">
        <app-analytics-chart [analyticsDashboard]="analyticsDashboard"
                             [analyticsDashboardItem]="analyticsDashboardItem"
                             [updateData]="analyticsDashboardItem.updatedAt"
                             [showDownload]="true"
                             [showConfigure]="true"
                             [showSettings]="editMode"
                             (delete)="delete()">
        </app-analytics-chart>
    </div>
</gridster-item>
