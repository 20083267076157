export class CsvDownload {

    public downloadArrayAsCsv(data: any[], customFileName = null): void {
        const csvContent = 'data:text/csv;charset=utf-8,' + '"sep=,"\r\n' + data.map((error: any) => Object.values(error).join(',')).join('\n');
        const encodedUri = encodeURI(csvContent);

        const fileName = customFileName ?? this.generateFileName();

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
    }

    private generateFileName(): string {
        const exportDate = new Date().toISOString().slice(0, 10);
        return `${exportDate} - Seated Ticketing export`;
    }
}
