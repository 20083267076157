<ng-container *ngIf="reservation else loading">
    <div class="row list-group p-b-8">
        <ng-container *ngIf="conflictedOrderItemsGrouped$ | async as conflictedOrderItemsGrouped">
            <ng-container
                *ngTemplateOutlet="conflictedOrderItemsTable; context:{ orderItemsGrouped: conflictedOrderItemsGrouped }"></ng-container>
        </ng-container>
    </div>
    <div class="row list-group">
        <div *ngIf="!isMobile" class="header-bar row">
            <div class="col-3 subheading-02"></div>
            <div class="col-2 subheading-02">{{ ('Order_Table.Access_Group' | trans) }}</div>
            <div class="col-2 subheading-02">{{ ('Order_Table.Type' | trans) }}</div>
            <div class="col-2 subheading-02">{{ ('Order_Table.Note' | trans) }}</div>
            <div class="col-2 subheading-02">{{ ('Order_Table.Price' | trans) }}</div>
        </div>
        <ng-container *ngIf="localReservation$ | async as localReservation">
            <ng-container
                *ngTemplateOutlet="groupedOrderItemsTable; context:{ orderItemsGrouped: localReservation.groupedOrderItems }"></ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #conflictedOrderItemsTable let-orderItemsGrouped="orderItemsGrouped">
    @if (orderItemsGrouped.length) {
        <cm-inline-notification data-type="warning">
            <span slot="icon" class="cm-icon cm-icon-warning-open" aria-hidden="true"></span>
            <div slot="buttons">
                <cm-button
                    [attr.data-label]="'Order_Item_Conflicts.Remove_Order_Items' | trans"
                    data-button-style="primary"
                    data-button-type="text"
                    (click)="removeConflictedOrderItems(orderItemsGrouped)"></cm-button>
            </div>
            {{ 'Order_Item_Conflicts.Text' | trans }}
        </cm-inline-notification>

        <ng-container *ngFor="let orderItemGroup of orderItemsGrouped">
            <div class="col-12 conflict-group">
                <div class="list-group-content">
                    <div class="header-label row">{{ orderItemGroup.name }}</div>
                </div>
                <div class="row col-12">
                    <ng-container *ngFor="let orderItem of orderItemGroup.items">
                        <div class="row list-group-content col-12 d-flex flex-flow-column">
                            <div>
                                {{ orderItem.priceName }}
                            </div>
                            <ng-container *ngIf="orderItem.venueSectionName">
                                <div class="subtitle">{{ orderItem.venueSectionName }}</div>
                                <div *ngIf="getOrderItemReservationName(orderItem) as name"
                                     class="subtitle">{{ name }}
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    }
</ng-template>

<ng-template #groupedOrderItemsTable let-orderItemsGrouped="orderItemsGrouped">
    <ng-container *ngFor="let orderItemGroup of orderItemsGrouped; let i = index">
        <div class="col-12 list-group-content row">
            <div class="header-label" [ngClass]="isMobile ? 'col-4' : 'col-3'">{{ orderItemGroup.name }}</div>
            <ng-container *ngIf="!isMobile">
                <div class="col-2">
                    <ng-container
                        *ngTemplateOutlet="accessGroupHeaderTemplate; context:{orderItemGroup: orderItemGroup}">
                    </ng-container>
                </div>
                <div class="col-2">
                    <ng-container
                        *ngTemplateOutlet="ticketTypeHeaderTemplate; context:{orderItemGroup: orderItemGroup}">
                    </ng-container>
                </div>
                <div class="col-2"></div>
                <div class="col-2">
                    <ng-container
                        *ngTemplateOutlet="orderPriceHeaderTemplate; context:{orderItemGroup: orderItemGroup, index: i}">
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="isMobile">
                <div class="col-2"></div>
                <div class="col-4"></div>
            </ng-container>
        </div>
        <ng-container *ngFor="let orderItem of orderItemGroup.items">
            @defer (on viewport) {
                @if (!isLoading) {
                    <ng-container *ngIf="isMobile; else nonMobile">
                        <ng-container
                            *ngTemplateOutlet="mobileOrderItemRow;
                                        context:{orderItem: orderItem, orderItemGroup: orderItemGroup}">
                        </ng-container>
                    </ng-container>
                    <ng-template #nonMobile>
                        <ng-container
                            *ngTemplateOutlet="nonMobileOrderItemRow;
                                        context:{orderItem: orderItem, orderItemGroup: orderItemGroup}">
                        </ng-container>
                    </ng-template>
                } @else {
                    <div class="order-item-skeleton w-100 d-flex align-items-center justify-content-center">
                        <div class="skeleton-loading"></div>
                    </div>
                }
            } @placeholder {
                <div class="skeleton-loading"></div>
            }
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #mobileOrderItemRow let-orderItem="orderItem" let-orderItemGroup="orderItemGroup">
    <div class="row list-group-content">
        <div class="table-column col-4 row">
            <div class="col-12">
                {{ orderItem.priceName }}
            </div>
            <ng-container *ngIf="orderItem.venueSectionName">
                <div class="col-12 subtitle">
                    {{ orderItem.venueSectionName }}<br>
                    {{ getOrderItemReservationName(orderItem) }}
                </div>
            </ng-container>
        </div>
        <div class="table-column col-2">
        </div>
        <div class="table-column col-4">
            <ng-container
                *ngTemplateOutlet="itemPriceTemplate; context:{orderItem: orderItem}"></ng-container>
        </div>
        <div class="table-column col-2 actions">
            <cm-context-menu>
                <cm-option data-icon="edit" (click)="showDetailsFor(orderItem.id)"
                           [attr.data-display]="('Order_Item.Show_Details' | trans)"></cm-option>

                <cm-option data-icon="delete"
                           (click)="deleteOrderItem(orderItem)"
                           [attr.data-display]="('Order_Item.Delete' | trans)"></cm-option>
            </cm-context-menu>
        </div>
    </div>
    <ng-container *ngIf="showDetailsItemId === orderItem.id">
        <div class="row list-group-content no-border">
            <div class="col-6">
                {{ 'Order_Table.Access_Group' | trans }}
            </div>
            <div class="col-6">
                <ng-container
                    *ngTemplateOutlet="accessGroupTemplate; context:{orderItem: orderItem}"></ng-container>
            </div>
        </div>
        <div class="row list-group-content no-border">
            <div class="col-6">
                {{ 'Order_Table.Type' | trans }}
            </div>
            <div class="col-6">
                <ng-container
                    *ngTemplateOutlet="ticketTypeTemplate; context:{orderItem: orderItem, orderItemGroup: orderItemGroup}"></ng-container>
            </div>
        </div>
        <div class="row list-group-content no-border">
            <div class="col-6">
                {{ 'Order_Table.Note' | trans }}
            </div>
            <div class="col-6">
                <ng-container *ngTemplateOutlet="noteTemplate; context:{orderItem: orderItem}"></ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #nonMobileOrderItemRow let-orderItem="orderItem" let-orderItemGroup="orderItemGroup">
    <div class="row list-group-content">
        <div class="col-3 row">
            <div class="col-12">
                {{ orderItem.priceName }}
            </div>
            <ng-container *ngIf="orderItem.venueSectionName">
                <div class="col-12 subtitle">
                    {{ orderItem.venueSectionName }}<br>
                    {{ getOrderItemReservationName(orderItem) }}
                </div>
            </ng-container>
        </div>
        <div class="col-2">
            <ng-container
                *ngTemplateOutlet="accessGroupTemplate; context:{orderItem: orderItem}"></ng-container>
        </div>
        <div class="col-2">
            <ng-container
                *ngTemplateOutlet="ticketTypeTemplate; context:{orderItem: orderItem, orderItemGroup: orderItemGroup}"></ng-container>
        </div>
        <div class="col-2">
            <ng-container *ngTemplateOutlet="noteTemplate; context:{orderItem: orderItem}"></ng-container>
        </div>
        <div class="col-2">
            <ng-container
                *ngTemplateOutlet="itemPriceTemplate; context:{orderItem: orderItem}"></ng-container>
        </div>
        <div class="col-1">
            <cm-button data-button-type="icon"
                       data-button-style="secondary"
                       data-icon="delete"
                       [attr.data-label]="('Order_Item.Delete' | trans)"
                       (click)="deleteOrderItem(orderItem)">
            </cm-button>
        </div>
    </div>
</ng-template>

<ng-template #noteTemplate let-orderItem="orderItem">
    <div class="form-group">
        <div class="form-input">
            <input type="text" name="orderItemNote" [placeholder]="('Order_Table.Ticket_Note' | trans)"
                   id="orderItemNote" class="form-control"
                   (change)="updateOrderItemNote(orderItem, $event.target.value)"
                   [(ngModel)]="orderItem.note">
        </div>
    </div>
</ng-template>

<ng-template #orderPriceHeaderTemplate let-orderItemGroup="orderItemGroup" let-index="index">
    <div class="form-group">
        <div class="form-input">
            <input placeholder="{{('Order_Table.Price' | trans)}}"
                   class="form-control f-w-200"
                   currencyMask
                   min="0.00"
                   [ngModel]="null"
                   (focusout)="updateOrderPrice($event, orderItemGroup.items, $event.target.value)">
        </div>
    </div>
</ng-template>

<ng-template #ticketTypeHeaderTemplate let-orderItemGroup="orderItemGroup">
    <div class="form-group" *ngIf="getGroupOrderItemTypes(orderItemGroup.id) as orderItemTypes">
        <div class="form-input">
            <select class="form-control" data-component="cm-select"
                    (change)="updateOrderItemTypes($event, orderItemGroup, $event.target.value, orderItemGroup.items)">
                <option *ngIf="orderItemTypes.length > 1">{{ ('Order_Table.Price_Type' | trans) }}</option>
                <ng-container *ngFor="let ticketType of orderItemTypes">
                    <option [value]="ticketType.id">
                        {{ ticketType.name }}
                    </option>
                </ng-container>
            </select>
            <span></span>
        </div>
    </div>
</ng-template>

<ng-template #ticketTypeTemplate let-orderItem="orderItem" let-orderItemGroup="orderItemGroup">
    <div class="form-group"
         *ngIf="venueSectionOrderItemTypes.get(orderItemGroup.id)?.get(orderItem.venueSectionGroupId) ?? getOrderItemTypes(orderItemGroup.id) as orderItemTypes">
        <div class="form-input">
            <select class="form-control f-w-200"
                    data-component="cm-select"
                    [ngModel]="orderItem.typeId"
                    (change)="updateOrderItemTypes(null, orderItemGroup, $event.target.value, [orderItem])">
                <ng-container *ngFor="let type of orderItemTypes">
                    <option [value]="type.id">
                        {{ type.name }}
                    </option>
                </ng-container>
            </select>
            <span></span>
        </div>
    </div>
</ng-template>

<ng-template #accessGroupTemplate let-orderItem="orderItem">
    <div class="form-group" *ngIf="getVenue(orderItem.venueId) as venue;">
        <div class="form-input">
            <select class="form-control f-w-200"
                    [ngModel]="orderItem.venueSectionAccessId"
                    (change)="updateOrderItemsVenueSectionAccessId($event.target.value, [orderItem])"
                    data-component="cm-select">
                <option value="">{{ ('Order_Table.No_Access_Group' | trans) }}
                </option>
                <ng-container>
                    <ng-container *ngFor="let section of venue.sections">
                        <option disabled>{{ section.name }}</option>
                        <option *ngFor="let venueSection of section.sections"
                                [value]="venueSection.id">{{ venueSection.name }}
                        </option>
                    </ng-container>
                </ng-container>
                <option *ngFor="let venueSection of venueSections"
                        [value]="venueSection.id">{{ venueSection.name }}
                </option>
            </select>
            <span></span>
        </div>
    </div>
</ng-template>

<ng-template #accessGroupHeaderTemplate let-orderItemGroup="orderItemGroup">
    <div class="form-group" *ngIf="getVenue(orderItemGroup.venueId) as venue;">
        <div class="form-input">
            <select class="form-control"
                    (change)="updateOrderItemsVenueSectionAccessId($event.target.value, orderItemGroup.items)"
                    data-component="cm-select">
                <option value="">{{ ('Order_Table.Access_Group' | trans) }}
                </option>
                <ng-container>
                    <ng-container *ngFor="let section of venue.sections">
                        <option disabled>{{ section.name }}</option>
                        <option *ngFor="let venueSection of section.sections"
                                [value]="venueSection.id">{{ venueSection.name }}
                        </option>
                    </ng-container>
                </ng-container>
                <option *ngFor="let venueSection of venueSections"
                        [value]="venueSection.id">{{ venueSection.name }}
                </option>
            </select>
            <span></span>
        </div>
    </div>
</ng-template>

<ng-template #itemPriceTemplate let-orderItem="orderItem">
    <div class="form-group">
        <div class="form-input">
            <input placeholder="{{('Order_Table.Price' | trans)}}"
                   class="form-control f-w-200"
                   currencyMask
                   min="0.00"
                   [(ngModel)]="orderItem.price">
        </div>
    </div>
    <ng-container *ngFor="let costItemAgreement of orderItem.additionalCosts">
        <ng-container *ngIf="costItemAgreement.amount > 0">
            <div class="order-item-cost-item-agreement">
                {{ costItemAgreement.label }}: {{ costItemAgreement.amount | currency }}
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #loading>
    <cm-indicator data-hide-label></cm-indicator>
</ng-template>

<ng-template #loadingSmall>
    <cm-indicator class="small" data-hide-label></cm-indicator>
</ng-template>
