<div class="row justify-content-between align-items-baseline">
    <h3>{{ ('Activity_Log.Activity_Log' | trans) }}</h3>
    <app-search-filter-container [settings]="filterSettings" [(selected)]="selectedFilters"
                                 (selectedChange)="onFilterUpdate()"></app-search-filter-container>
</div>
<div class="list-group">
    <div class="container-group">
        @if (activityLogs$ | async; as activityLogs) {
            <ng-container *ngFor="let activityLog of activityLogs; let i = index">
                <div class="container-group-row row" [class.m-b-16]="i != activityLogs.length - 1">
                    <div class="container-group-item row">
                        <div class="container-content without-avatar">
                            <div class="container-group-col header row">
                                <div class="col">
                                    <div class="activity-log-title"
                                         [cm-tooltip]="('Activity_Log.View_Details' | trans)"
                                         (click)="openActivityLogDetailDialog(activityLog)">{{ ('Activity_Log_Event.' + activityLog.event | trans) }}
                                    </div>
                                    <div class="row container-sub-header">
                                        <div>{{ activityLog.createdAt | date:'EE d MMM y H:mm' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-group-col footer p-t-8">
                                <ng-container *ngIf="activityLog.actor">
                                            <span class="m-r-16">
                                        <span class="cm-icon m-r-4" [class.cm-icon-user-fill]="activityLog.actor.name"
                                              [class.cm-icon-bot]="!activityLog.actor.name" aria-hidden="true"></span>
                                                <span>{{ activityLog.actor.name ? activityLog.actor.name : ('Activity_Log.Unknown' | trans) }}</span>
                                            </span>
                                </ng-container>

                                <ng-container
                                        *ngIf="activityLog.orderId && activityLog.event !== 'ORDER_DELETE'">
                                            <span (click)="onOrderClicked(activityLog.orderId)" class="m-r-16">
                                        <span class="cm-icon cm-icon-clean m-r-4" aria-hidden="true"></span>
                                                <span class="link">{{ ('General.Order' | trans) }}</span>
                                            </span>
                                </ng-container>

                                <ng-container *ngIf="activityLog.customerId">
                                            <span (click)="onCustomerClicked(activityLog.customerId)" class="m-r-16">
                                        <span class="cm-icon cm-icon-user-open m-r-4" aria-hidden="true"></span>
                                                <span class="link">{{ ('General.Customer' | trans) }}</span>
                                            </span>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        } @else {
            <cm-indicator></cm-indicator>
        }
    </div>
</div>
<mat-paginator [length]="activityLogsTableTotal" [pageSize]="activityLogsTablePageSize" [hidePageSize]="true" [pageIndex]="activityLogsTableIndex"
               [pageSizeOptions]="[5, 10, 25, 50, 100, 250]" (page)="activityLogsTableUpdate($event)">
</mat-paginator>


<app-dialog #activityLogDetailDialog [title]="('Activity_Log.Detail_Dialog.Title' | trans)" [width]="880">
    <table class="table w-100">
        <thead>
        <tr>
            <th style="width: 20%"></th>
            <th style="width: 40%">{{ ('Activity_Log.Detail_Dialog.Previous_Value' | trans) }}</th>
            <th style="width: 40%">{{ ('Activity_Log.Detail_Dialog.Current_Value' | trans) }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container
                *ngFor="let row of ((activityLogDetailDialog.data?.activityLog | activityLogChangedValues) | keyvalue)">
            <tr [class.bg-warning-color-light]="row.value.isChanged">
                <td style="width: 20%">{{ row.key }}</td>
                <td style="width: 40%">{{ row.value.previous }}</td>
                <td style="width: 40%">{{ row.value.current }}</td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</app-dialog>
