<div class="row m-b-16">
    <div class="col-11">
        <ng-container *ngIf="showUnstyleableMessageTypeBanner">
            <cm-inline-notification data-type="information">
    <span slot="icon"
          class="cm-icon cm-icon-warning-open"
          aria-hidden="true"></span>
                <h6 class="banner-title" slot="title">{{ ('Email_Template_Editor.Not_Styleable_Banner' | trans) | async }}</h6>
            </cm-inline-notification>
        </ng-container>
    </div>

    <div class="col-1 text-right">
        <cm-tooltip [attr.data-tooltip-text]="('Editor.Toggle_Settings' | trans) | async">
            <button class="btn btn-primary-icon settings-button" (click)="toggleFilter()">
                <span class="cm-icon cm-icon-settings"></span>
            </button>
        </cm-tooltip>
    </div>
</div>

<ng-container *ngIf="state$ | async as state">

    <!-- Filter email  -->
    <cm-filter #cmfilter attr.data-title="{{('Email_Template_Editor.Settings_Title' | trans) | async}}">

        <ng-container *ngIf="emailTemplate">
        <div class="form-group">
            <div class="message-type-selector">
                <ng-container *ngIf="emailTemplate && messageTypeIds">
                    <cm-select
                        (cm-option-clicked)="getAndRenderEmailTemplate(state.emailTemplateGroup.uuid, $event.detail.value)"
                        [value]="emailTemplate.message_type_id">
                        <cm-option *ngFor="let messageType of messageTypeIds" slot="option"
                                          value="{{messageType.id}}" data-display="{{messageType.name}}">
                        </cm-option>
                    </cm-select>
                </ng-container>
            </div>

            <!-- Event email settings -->
            <cm-filter-group
                [attr.data-title]="('Email_Template_Editor.Email_Template_Group_Settings_Title' | trans) | async">

                <div
                    class="filter-group-description">{{ ('Email_Template_Editor.Email_Template_Group_Settings_Body' | trans) | async }}</div>

                <div class="m-b-24 m-t-24">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="text" class="form-control" id="email-template-group-name"
                                   [attr.value]="emailTemplateGroup.name"
                                   (change)="emailTemplateGroup.name = $event.target.value">
                            <label
                                for="email-template-group-name">{{('Email_Template_Editor.Email_Template_Group_Name' | trans) | async}}</label>
                        </div>
                    </div>
                </div>

                <!-- Colours -->
                <div class="m-b-24 m-t-24">
                    <!-- Background color -->
                    <div class="form-row">
                        <cm-color-picker data-hide-alpha="true"
                                         data-label="{{('Email_Template_Groups.Background_Colour' | trans) | async}}"
                                         [attr.data-color]="emailTemplateGroup.background_color"
                                         (change)="backgroundColourChanged($event)"></cm-color-picker>
                    </div>
                    <!-- Background highlight color -->
                    <div class="form-row">
                        <cm-color-picker data-hide-alpha="true"
                                         data-label="{{('Email_Template_Groups.Background_Highlight_Colour' | trans) | async}}"
                                         [attr.data-color]="emailTemplateGroup.background_highlight_color"
                                         (change)="backgroundHighlightColourChanged($event)"></cm-color-picker>
                    </div>
                    <!-- Background highlight text color -->
                    <div class="form-row">
                        <cm-color-picker data-hide-alpha="true"
                                         data-label="{{('Email_Template_Groups.Background_Highlight_Text_Colour' | trans) | async}}"
                                         [attr.data-color]="emailTemplateGroup.background_highlight_text_color"
                                         (change)="backgroundHighlightTextColourChanged($event)"></cm-color-picker>
                    </div>
                </div>
                <!-- Enable calendar -->
                <div class="m-b-24 m-t-24">
                    <div class="form-row">
                        <div class="form-check">
                            <label>
                                <input type="checkbox" [checked]="emailTemplateGroup.is_showing_calendar_items"
                                       (change)="switchCalendar($event)"/>
                                <span class="label">{{('Email_Template_Groups.Enable_Calendar' | trans) | async}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <span [innerText]="('Email_Template_Editor.Banner.Logo.Size' | trans) | async"></span>
                </div>
                <ng-container>
                    <div class="row m-t-8">
                        <span class="filter-title">{{ ('Email_Template_Editor.Banner_Header' | trans) | async }}</span>
                    </div>
                    <ng-container *ngIf="emailTemplateGroup.logo_url !== defaultLogoUrl || emailTemplateGroup.banner_url">
                        <div class="border-current-image m-t-16">
                            <div class="reset-button">
                                <button class="btn btn-secondary-icon" (click)="resetHeaderImage()">
                                    <span class="cm-icon cm-icon-close"></span>
                                </button>
                            </div>
                            <div class="image-scaling">
                                <img [attr.src]="emailTemplateGroup.banner_url || emailTemplateGroup.logo_url">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="emailTemplateGroup.logo_url === defaultLogoUrl && !emailTemplateGroup.banner_url">
                        <div class="m-t-16">
                            <cm-file-upload name="header_banner_url"
                                            accept=".jpg, .png" data-max-file-size="1048576"
                                            data-display="{{('Email_Template_Groups.Drop_Files' | trans) | async}}"
                                            (change)="uploadImage($event, emailImagePositionEnum.HEADER)">
                            </cm-file-upload>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container>
                    <div class="row m-t-8">
                        <span class="filter-title">{{ ('Email_Template_Editor.Banner_Footer' | trans) | async }}</span>
                    </div>
                    @if (emailTemplateGroup.footer_banner_url) {
                        <div class="border-current-image m-t-16">
                            <div class="reset-button">
                                <button class="btn btn-secondary-icon" (click)="resetFooterBannerImage()">
                                    <span class="cm-icon cm-icon-close"></span>
                                </button>
                            </div>
                            <div class="image-scaling">
                                <img [attr.src]="emailTemplateGroup.footer_banner_url">
                            </div>
                        </div>
                    } @else {
                        <div class="m-t-16">
                            <cm-file-upload name="footer_banner_url"
                                            accept=".jpg, .png" data-max-file-size="1048576"
                                            data-display="{{('Email_Template_Groups.Drop_Files' | trans) | async}}"
                                            (change)="uploadImage($event, emailImagePositionEnum.FOOTER)">
                            </cm-file-upload>
                        </div>
                    }
                </ng-container>
            </cm-filter-group>
            <!-- Email template settings -->
            <cm-filter-group
                [attr.data-title]="('Email_Template_Editor.Email_Template_Settings_Title' | trans) | async">

                <div
                    class="filter-group-description">{{ ('Email_Template_Editor.Email_Template_Settings_Body' | trans) | async }}</div>

                <!-- Sender name -->
                <div class="filter-group-margin m-b-24 m-t-16">
                    <div class="form-row">
                        <div class="form-input">
                            <input type="text" [(ngModel)]="emailTemplate.sender_name"
                                   (input)="changeSenderName($event)" class="form-control" id="formControl1">
                            <label for="formControl1">{{('Email_Template_Groups.Sender_Name' | trans) | async}}</label>
                        </div>
                    </div>
                </div>

                <div class="m-t-16">
                    <div class="form-row">
                        <div class="form-input">
                            <label>{{('Email_Template_Groups.Subject' | trans) | async}}</label>
                            <div class="form-control textarea-translation-box" (click)="openSubjectEditor()"
                                 [innerHTML]="emailTemplate.subject">
                            </div>
                            <span class="input-right icon pointer" (click)="openSubjectEditor()">
                                <span aria-hidden="true" class="cm-icon cm-icon-language cm-icon-hover"></span>
                            </span>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="emailTemplate.message_type_id === 'ORDER'">
                    <div class="m-t-16">
                        <div class="form-row">
                            <div class="form-input">
                                <label>{{('Email_Template.Button_Text' | trans) | async}}</label>
                                <div class="form-control textarea-translation-box" (click)="openButtonTextEditor()"
                                     [innerHTML]="emailTemplate.button_text">
                                </div>
                                <span class="input-right icon pointer" (click)="openButtonTextEditor()">
                                <span aria-hidden="true" class="cm-icon cm-icon-language cm-icon-hover"></span>
                            </span>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </cm-filter-group>
            <div class="m-t-24 align-right m-r-24">
                <button class="btn btn-primary" (click)="onEmailSaveClick()">
                    <span>{{('Generic.Forms.SaveButton' | trans) | async}}</span>
                </button>
            </div>

        </div>

        </ng-container>

    </cm-filter>
</ng-container>

<ng-container *ngIf="emailTemplate">
    <cm-ticketing-translation-dialog #messageTextTranslationDialog
                                     [title]="('Email_Template.Message_Text' | trans) | async"
                                     [translationObject]="emailTemplateMessageTexts"
                                     [multiLine]="true"
                                     (translationChanged)="setMessageTextsForCurrentMessageType($event)">
    </cm-ticketing-translation-dialog>

    <cm-ticketing-translation-dialog #subjectTextTranslationDialog [title]="('Email_Template.Subject' | trans) | async"
                                     [translationObject]="emailTemplateSubjectTexts"
                                     (translationChanged)="setSubjectTextsForCurrentMessageType($event)">
    </cm-ticketing-translation-dialog>

    <cm-ticketing-translation-dialog #buttonTextTranslationDialog [title]="('Email_Template.Button_Text' | trans) | async"
                                     [translationObject]="emailTemplateButtonTexts"
                                     (translationChanged)="setButtonTextForCurrentMessageType($event)">
    </cm-ticketing-translation-dialog>
</ng-container>


<!-- Template editor -->
<div id="email-template-editor">
</div>
