import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {mapResource, Resource} from '../../models/event-resource.class';
import {Store} from '../../../core/state-management/store';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {DownloadManagerService} from '../download-manager-service/download-manager.service';
import {ResourceReportDownload} from '../download-manager-service/types/resource-report-download';
import {ExportService} from '../export-service/export.service';

@Injectable()
export class ResourceService {

    public store = new Store<{
        isLoading: boolean,
        isEmpty: boolean,
        resources: Resource[],
        search: string,
        resourceTypeFilter: 'IMAGE' | 'PDF' | undefined,
        paginationSkip: number,
        paginationTake: number,
        paginationTotal: number
    }>({
        isLoading: false,
        isEmpty: false,
        resources: [],
        search: undefined,
        resourceTypeFilter: undefined,
        paginationSkip: 0,
        paginationTake: 10,
        paginationTotal: undefined
    });

    private config = {
        withCredentials: true,
        params: {},
        headers: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(
        private http: HttpClient,
        private downloadManager: DownloadManagerService,
        private exportService: ExportService) {
    }

    public initialize(): void {
        combineLatest([
            this.store.select('search'),
            this.store.select('resourceTypeFilter'),
            this.store.select('paginationSkip'),
            this.store.select('paginationTake'),
            this.store.refresh$
        ]).pipe(
            debounceTime(300),
            tap(() => this.store.setState({
                isLoading: true,
                isEmpty: false,
                resources: []
            })),
            switchMap(([search, resourceTypeFilter, skip, take]) => {
            let httpParams = new HttpParams()
                .set('skip', skip)
                .set('take', take);

            if (search) {
                httpParams = httpParams.set('resource[search]', search);
            }

            if (resourceTypeFilter) {
                httpParams = httpParams.set('resource[type]', resourceTypeFilter);
            }

            return this.getResourcesAsHttpResponse(httpParams);
        })).subscribe((response: HttpResponse<Resource[]>) => {
            this.store.setState({
                isLoading: false,
                isEmpty: response.body.length === 0,
                resources: response.body,
                paginationSkip: parseInt(response.headers.get('X-CM-PAGINATION-SKIP'), 10),
                paginationTake: parseInt(response.headers.get('X-CM-PAGINATION-TAKE'), 10),
                paginationTotal: parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10)
            });
        });
    }

    getResources(httpParams?: HttpParams): Observable<Resource[]> {
        if (!httpParams) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.set('take', -1);

        const config = this.config;
        config.params = httpParams;

        return this.http.get<Resource[]>(this.baseUrl + `/v1.0/resource`, config);
    }

    getResourcesAsHttpResponse(httpParams?: HttpParams): Observable<HttpResponse<Resource[]>> {
        const config = Object.assign({}, {...this.config, observe: undefined});
        config.params = httpParams;
        config.observe = 'response';

        return this.http.get<Resource[]>(this.baseUrl + `/v1.0/resource`, config).pipe(
            map((response: HttpResponse<Resource[]>) => {
                const body = response.body.map((item: any) => {
                    return mapResource(item);
                });

                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }

    postResource(resource, resourceName): Observable<Resource> {
        const uploadData = new FormData();
        uploadData.append('file', resource, resource.name);
        uploadData.append('name', resourceName);

        const config = this.config;
        config.params = new HttpParams().set('width', '1920').set('height', '1080');

        return this.http.post<Resource>(this.baseUrl + `/v1.0/resource`, uploadData, this.config);
    }

    deleteResource(resourceId) {
        return this.http.delete<Resource>(this.baseUrl + `/v1.0/resource/${resourceId}`, this.config);
    }

    public downloadResources(): void {
        let httpParams = new HttpParams();

        if (this.store.snapshot.search) {
            httpParams = httpParams.set('resource[search]', this.store.snapshot.search);
        }

        if (this.store.snapshot.resourceTypeFilter) {
            httpParams = httpParams.set('resource[type]', this.store.snapshot.resourceTypeFilter);
        }

        const exportDate = new Date().toISOString().slice(0, 10);
        const fileName = `${exportDate} - File manager`;

        this.downloadManager.downloadData(
            new ResourceReportDownload(this)
                .setFilters(httpParams)
                .setTotal(this.store.snapshot.paginationTotal)
                .setFileName(fileName)
        );
    }

}
