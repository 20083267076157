import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Customer} from '../../models/customer.class';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {distinctUntilChanged, filter, first, tap} from 'rxjs/operators';
import {CustomerManagementService} from '../../services/customer-management/customer-management.service';
import {CustomerOrderService} from '../../services/customer-order-service/customer-order.service';
import {CustomerOrder} from '../../models/customer-order';

@Component({
    selector: 'app-order-list-simple',
    templateUrl: './order-list-simple.component.html',
    styleUrls: ['./order-list-simple.component.css']
})
export class OrderListSimpleComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    customer$: Observable<Customer> = this.customerManagementService.customer$.pipe(
        filter<Customer>(Boolean),
        distinctUntilChanged(),
        tap(customer => this.onCustomerLoad(customer))
    );

    @Input() customerId: string;
    @Input() discountCampaignIds: string[] = [];

    // Table
    customerOrders$: Observable<CustomerOrder[]>;
    customerOrdersSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    public paginationSkip: any = 0;
    public paginationTake: any = 10;
    public paginationTotal: any = 0;
    public paginationOptions = JSON.stringify([5, 10, 25, 50, 100]);

    private statusClasses = {
        CANCELED: 'cm-icon-warning cl-error-color',
        EXPIRED: 'cm-icon-time-fill cl-error-color',
        COMPLETE: 'cm-icon-verification cl-success-color',
        RESERVED: 'cm-icon-time-fill cl-warning-color',
        OPEN: 'cm-icon-creditcard cl-warning-color',
        REFUNDED: 'cm-icon-refund bg-default-color'
    };

    public ordersTableLoading = false;

    @ViewChild('table')
    set tableElement(tableElement: ElementRef) {
        setTimeout(() => {
            try {
                tableElement.nativeElement.autoSizeColumns();
            } catch (e) {
            }
        });
    }

    constructor(private customerOrderService: CustomerOrderService,
                private customerManagementService: CustomerManagementService) {
    }

    ngOnInit() {
        this.customerOrders$ = this.customerOrdersSubject.asObservable();
        this.customer$.pipe(first()).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onCustomerLoad(customer: Customer) {
        this.customerId = customer.id;

        this.ordersTableUpdate();
    }

    ordersTableUpdate() {
        const httpParams = new HttpParams()
            .set('skip', this.paginationSkip)
            .set('take', this.paginationTake)
            .set('depth', 1);

        this.customerOrderService.getCustomerOrder(this.customerId, httpParams).subscribe((response: HttpResponse<CustomerOrder[]>) => {
            this.paginationTotal = parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10);
            this.customerOrdersSubject.next(response.body);
        });
    }

    public getStatusIconClasses(status: string): string {
        return this.statusClasses[status];
    }

    public onPaginationChange(paginationEvent: any): void {
        const pagination = paginationEvent.detail;

        this.paginationTake = pagination.take;
        this.paginationSkip = pagination.skip;

        this.ordersTableUpdate();
    }
}
