import {Injectable, Injector} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {Payout} from '../../models/payout';
import {StateManagementService} from '../util/state-management-service/state-management.service';

@Injectable({
  providedIn: 'root'
})
export class PayoutManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new Payout();

        this.initialize(
            new HttpParams()
                .set('orderBy', 'payout_at')
                .set('order', 'DESC')
        );
    }
}
