import {ModelBase} from '../abstract/model-base';

export class OrderDetailsCustomerBlackList extends ModelBase {
    public startAt: string;
    public endAt: string;
    public source: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsCustomerBlackList {
        const model = new OrderDetailsCustomerBlackList();

        model.startAt = data.startAt;
        model.endAt = data.endAt;
        model.source = data.source;

        return model;
    }
}
