import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SubscriptionType} from '../../models/subscription-type.class';
import {TicketType} from '../../models/ticket-type.class';
import {SubscriptionTypePrice} from '../../models/subscription-type-price.class';
import {Event} from '../../models/event.class';
import {VenueSectionSeat} from '../../models/venue-section-seat.class';
import { HttpParams } from '@angular/common/http';
import {TicketTypeService} from '../../services/ticket-service/ticket.service';
import {ProductTypePrice} from '../../models/product-type-price';
import {PriceItem} from '../../models/price-item.class';
import {LocalReservationService} from '../../services/local-reservation.service';
import {ProductGroupType} from '../../enums/order/product-group-type';
import {OrderItemReservation} from '../../models/reservation/order-item-reservation.class';
import {OrderBarService} from '../../services/order-bar.service';
import {NotificationService} from '../../services/notification-service/notification.service';

@Component({
    selector: 'app-order-capacity-list',
    templateUrl: './order-capacity-list.component.html',
    styleUrls: ['./order-capacity-list.component.css']
})
export class OrderCapacityListComponent implements OnInit {
    @Input() venueSectionId?: string;
    @Input() venueSectionGroupId?: string;
    @Input() venueSectionSeat?: VenueSectionSeat;
    @Input() venueSectionName?: string;
    @Input() venueSectionAvailable?: number;

    @Input() event?: Event;

    @Input() productTypePrices?: ProductTypePrice[];
    @Input() productTypeId?: string;
    @Input() productTypeName?: string;

    @Input() subscriptionType?: SubscriptionType;

    ticketTypes: TicketType[];
    productsAmountSelected = [];

    subscriptionTypePrices: SubscriptionTypePrice[] = [];
    priceItems: PriceItem[] = [];

    amountOfLocalGACapacity: number;
    productsAmountInReservation: Map<string, number> = new Map<string, number>();

    public totalItems = 0;

    constructor(private ticketTypeService: TicketTypeService,
                private changeDetector: ChangeDetectorRef,
                private localReservationService: LocalReservationService,
                private orderBarService: OrderBarService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.updateAmountOfLocalCapacity();

        this.orderBarService.orderBarExpanded$.subscribe(expanded => {
            if (!expanded) {
                this.updateView();
            }
        });

        if (!this.subscriptionType) {
            return;
        }

        this.subscriptionTypePrices = this.subscriptionType.subscriptionTypePrices.filter(s => {
            return s.priceItems.filter(priceItem => priceItem.venueSectionGroupId === this.venueSectionGroupId || !priceItem.venueSectionGroupId).length;
        });

        this.subscriptionTypePrices.forEach(subscriptionTypePrice => {
            this.productsAmountSelected[subscriptionTypePrice.id] = 0;
        });

        this.priceItems = this.subscriptionTypePrices.map(typePrice => {
            return typePrice.priceItems.filter(priceItem => priceItem.venueSectionGroupId === this.venueSectionGroupId || !priceItem.venueSectionGroupId)[0];
        });
    }

    shouldDisableAddProductTypeButton(availability: number, productId: string): boolean {
        const productAmountSelected = this.productsAmountSelected[productId];
        const productAmountInReservation = this.productsAmountInReservation.get(productId);
        return (availability - productAmountInReservation - productAmountSelected) <= 0 ||
            productAmountSelected >= availability || availability === 0;
    }

    updateAmountOfLocalCapacity(): void {
        if (!this.productTypeId) {
            this.amountOfLocalGACapacity = this.localReservationService.getAmountOfLocalCapacityBySeatId(
                this.event?.id ?? this.subscriptionType.id,
                this.venueSectionSeat?.id
            );
        } else {
            this.productTypePrices.forEach(productTypePrice => {
                this.productsAmountInReservation.set(
                    productTypePrice.id,
                    this.localReservationService.getAmountOfLocalCapacityByProductType(productTypePrice.id)
                );
            });
        }
    }

    updateView(): void {
        if (!this.event || !this.venueSectionId || !this.venueSectionSeat) {
            return;
        }

        this.ticketTypeService.getTicketTypesByPricePlan(this.event.pricePlanId, new HttpParams()
            .set('ticketType[venueSectionId]', this.venueSectionId)
            .set('depth', '3')
        ).subscribe(ticketTypes => {
            this.ticketTypes = ticketTypes;
            ticketTypes.forEach(ticketType => {
                this.productsAmountSelected[ticketType.id] = 0;
            });
            this.changeDetector.markForCheck();
        });
    }


    alterTicketTypeAmount(productId: string, type: string) {
        if (!this.productsAmountSelected[productId]) {
            this.productsAmountSelected[productId] = 0;
        }

        if (type === '+') {
            this.productsAmountSelected[productId] += 1;
        }

        if (type === '-') {
            this.productsAmountSelected[productId] -= 1;
        }

        this.updateTotalItems();
    }

    capacityChanged(productId: string, available: number): void {
        const orderAmount = this.productsAmountSelected[productId];
        if (orderAmount < 0) {
            this.productsAmountSelected[productId] = 0;
            return;
        }

        if (available < orderAmount) {
            this.productsAmountSelected[productId] = available;
        }
    }

    addCapacityOrder() {
        for (const itemId in this.productsAmountSelected) { // tslint:disable-line
            for (let i = 0; i < this.productsAmountSelected[itemId]; i++) {
                if (this.event) {
                    this.localReservationService.addOrderItem(
                        ProductGroupType.EVENT_TICKET,
                        this.event.name,
                        this.event.id,
                        new OrderItemReservation(
                            itemId,
                            this.ticketTypes.find(t => t.id === itemId).name,
                            this.event.venueId,
                            this.venueSectionName,
                            this.venueSectionGroupId,
                            this.venueSectionSeat.id,
                            this.venueSectionSeat.row,
                            this.venueSectionSeat.seat
                        ),
                        [this.event.categoryId],
                        this.event.venueId
                    );
                } else if (this.subscriptionType) {
                    this.localReservationService.addOrderItem(
                        ProductGroupType.SUBSCRIPTION,
                        this.subscriptionType.name,
                        this.subscriptionType.id,
                        new OrderItemReservation(
                            itemId,
                            this.subscriptionTypePrices.find(p => p.id === itemId).name,
                            this.subscriptionType.venueId,
                            this.venueSectionName,
                            this.venueSectionGroupId,
                            this.venueSectionSeat?.id,
                            this.venueSectionSeat?.row,
                            this.venueSectionSeat?.seat
                        ),
                        this.subscriptionType.eventCategories.map(category => category.id),
                        this.subscriptionType.venueId
                    );
                } else if (this.productTypePrices) {
                    this.localReservationService.addOrderItem(
                        ProductGroupType.PRODUCT,
                        this.productTypeName,
                        this.productTypeId,
                        new OrderItemReservation(
                            itemId,
                            this.productTypePrices.find(p => p.id === itemId).name
                        )
                    );
                }
            }
        }

        this.notificationService.showTranslatedSuccessNotification('order_item', 'added');

        this.resetCapacityOrder();
        this.updateAmountOfLocalCapacity();
    }

    resetCapacityOrder(): void {
        const keys = Object.keys(this.productsAmountSelected);
        for (let i = 0; i < keys.length; i++) {
            this.productsAmountSelected[keys[i]] = 0;
        }

        this.totalItems = 0;
    }

    hasSubscriptionTypePriceInVenueSection(subscriptionTypePrices: SubscriptionTypePrice[]) {
        if (!this.venueSectionId) {
            return false;
        }

        for (const subscriptionTypePrice of subscriptionTypePrices) {
            if (subscriptionTypePrice.priceItems.length > 0) {
                return true;
            }
        }

        return false;
    }

    public inputChanged(event, id): void {
        // remove the leading zeros
        event.target.value = +event.target.value;

        if (event.target.max && (+event.target.max < event.target.valueAsNumber)) {
            event.target.value = +event.target.max;
            this.productsAmountSelected[id] = event.target.value;
        }

        this.updateTotalItems();
    }

    private updateTotalItems(): void {
        this.totalItems = Object.values(this.productsAmountSelected).reduce((acc, cur) => {
            acc += cur;
            return acc;
        });

        if (!this.totalItems) {
            this.totalItems = 0;
        }
    }
}
