import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {mapSubscription, Subscription} from '../../models/subscription.class';
import {mapSubscriptionType, SubscriptionType} from '../../models/subscription-type.class';
import {NotificationService} from '../notification-service/notification.service';

@Injectable()
export class SubscriptionService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient, private notificationService: NotificationService) {
    }

    getSubscriptionTypes(): Observable<SubscriptionType[]> {
        return this.http.get<SubscriptionType[]>(this.baseUrl + `/v1.0/subscription/type`, this.config)
            .pipe(
                map((items: SubscriptionType[]) => {
                    return items.map(item => {
                        return mapSubscriptionType(item);
                    });
                })
            );
    }

    getSubscriptions(params?: HttpParams): Observable<HttpResponse<Subscription[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Subscription[]>(this.baseUrl + `/v1.0/subscription`, {
            ...config,
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Subscription[]>) => {
                const body = response.body.map((item: Subscription) => {
                    return mapSubscription(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }

    updateSubscription(subscriptionId: string, body: any, params?: HttpParams): Observable<Subscription> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Subscription>(this.baseUrl + `/v1.0/subscription/${subscriptionId}`, body, config)
            .pipe(
                map(subscription => {
                    return mapSubscription(subscription);
                }),
                tap(() => {
                    this.notificationService.showTranslatedNotification('success', 'subscription', 'updated');
                })
            );
    }

    updateSubscriptions(body: any): Observable<Subscription[]> {
        return this.http.patch<Subscription[]>(this.baseUrl + `/v1.0/subscription`, body, this.config)
            .pipe(
                map(items => {
                    return items.map(item => {
                        return mapSubscription(item);
                    });
                }),
                tap((items) => {
                    this.notificationService.showTranslatedNotification('success', 'subscription', 'updated', {
                        isPlural: (items.length !== 1)
                    });
                })
            );
    }

    getSubscriptionTypeStatistics(params?: HttpParams): Observable<any> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<any>(this.baseUrl + `/v1.0/subscription/statistics`, {...config});
    }

    renewSubscription(subscriptionId: string, params: HttpParams): Observable<Subscription> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<Subscription>(this.baseUrl + `/v1.0/subscription/${subscriptionId}/renew`, {}, this.config)
            .pipe(
                map((item: Subscription) => mapSubscription(item))
            );
    }

    getSubscriptionsSimple(params?: HttpParams): Observable<HttpResponse<any[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<any[]>(this.baseUrl + `/v1.0/subscription/simple`, {
            ...config,
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Subscription[]>) => {
                const body = response.body.map((item: Subscription) => {
                    return mapSubscription(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }
}
