import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import {SearchBarcodeResult} from '../../models/search-barcode-result.class';

@Injectable({
    providedIn: 'root'
})
export class BarcodeService {

    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    public find(barcode: string): Observable<SearchBarcodeResult[]> {
        this.config.params = new HttpParams().set('search', barcode);
        return this.http.get<SearchBarcodeResult[]>(this.baseUrl + `/v1.0/barcode`, this.config);
    }
}
