import {StateSubject} from './state-subject';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, share} from 'rxjs/operators';

export class Store<T extends object> {
    public refresh$: Subject<any> = new BehaviorSubject(undefined);

    private state: StateSubject<T>;

    constructor(value: T) {
        this.state = new StateSubject(value);
    }

    public setState(value: Partial<T>): void {
        this.state.next({
            ...this.state.value,
            ...value
        });
    }

    get snapshot(): T {
        return this.state.value;
    }

    public select<K extends keyof T>(key: K): Observable<T[K]> {
        return this.state.value$.pipe(
            map(state => state[key]),
            distinctUntilChanged((a, b) => a === b),
            share()
        );
    }

    public refresh(): void {
        this.refresh$.next(null);
    }

    public reset(): void {
        this.state.reset();
    }
}
