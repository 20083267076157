<ng-container *ngIf="reportExport.status === 'GENERATING' || reportExport.status === 'PENDING'">
    <cm-indicator
        class="small"
        data-type="circular"
        data-hide-label
        data-image="https://www.cm.com/app/aurora/svg/icons/download.svg"
    ></cm-indicator>
</ng-container>

<ng-container *ngIf="reportExport.status === 'FAILED'">
    <span class="cm-icon cm-icon-close bg-error-color list-item-icon" aria-hidden="true"></span>
</ng-container>

<ng-container *ngIf="reportExport.status === 'FINISHED'">
    <span class="cm-icon cm-icon-check bg-success-color list-item-icon" aria-hidden="true"></span>
</ng-container>
