import {Injectable} from '@angular/core';
import {Endpoint} from './api-discovery.service';
import {Observable} from 'rxjs';
import {Vat} from './shared/models/vat.class';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SportsApiService {

    private endpoints: Array<Endpoint> = [];

    constructor(private http: HttpClient) {
    }

    public setEndpoints(endpoints: Array<Endpoint>): void {
        this.endpoints = endpoints;
    }

    private getEndpointUrl(id: string): string | null {
        const endpoint = this.endpoints.find(endpoint => endpoint.id === id);

        if (!endpoint) {
            console.error(`Endpoint [${id}] not found`);
            return null;
        }

        return endpoint.url;
    }

    public getManyVat(): Observable<Array<Vat>> {
        let url: string = this.getEndpointUrl('GET_ALL_VAT');

        // Backwards compatibility until Sports API is production ready
        if (!url) {
            url = `${environment.CM_API_URL}/seatedapi/v1.0/vat`;
        }

        return this.http.get<Array<Vat>>(url, {
            withCredentials: true
        });
    }
}
