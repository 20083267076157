<div class="card">
    <div class="card-title">
        {{ config.titleTranslationKey | trans }}
    </div>

    <div class="card-content form-group">
        <ng-content></ng-content>
    </div>
</div>

<div class="row step-actions">
    <div class="col-6 col-sm-4">
        <cm-button
            data-icon="previous"
            data-button-style="secondary"
            data-button-type="icon-text"
            [attr.data-label]="('Stepper_Step.Actions.Back' | trans)"
            [attr.data-button-size]="buttonSize"
            (click)="onPreviousClick()">
        </cm-button>
    </div>
    <div class="col-6 col-sm-8 text-right">
        <ng-container *ngIf="hasAdvancedStep">
            <cm-button
                data-button-style="secondary"
                data-button-type="text"
                [attr.data-label]="('Stepper_Step.Actions.Advanced' | trans)"
                [attr.data-button-size]="buttonSize"
                [attr.disabled]="nextDisabled ? true :null"
                (click)="onAdvancedClick()">
            </cm-button>
        </ng-container>
        <cm-button
            [attr.data-icon]="isLastStep ? 'check' : 'next'"
            data-button-style="cta"
            data-button-type="icon-text"
            [attr.data-label]="(isLastStep ? 'Stepper_Step.Actions.Complete' : 'Stepper_Step.Actions.Next') | trans"
            [attr.data-button-size]="buttonSize"
            [attr.disabled]="nextDisabled ? true :null"
            [attr.data-rtl]="!isLastStep"
            (click)="onNextClick()">
        </cm-button>
    </div>
</div>
