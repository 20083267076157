<div class="preview-container p-16">
    <div class="row">
        <div class="col-lg-8">
            <div class="preview-row">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
            <div class="preview-row m-t-8">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
            <div class="preview-row m-t-8">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
            <div class="preview-row m-t-8">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
            <div class="preview-row m-t-8">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-1">
                    <div class="boca-divider"></div>
                </div>
                <div class="col-lg-5">
                    <div class="preview-ticket-info">
                        <span class="cm-icon cm-icon-qr-code"></span>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="preview-ticket-info-codes">
                        <span class="cm-icon cm-icon-text"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
