import {Component, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../../../services/translation.service';

@Component({
    selector: 'cm-ticketing-pvccard-custom',
    templateUrl: '../../shared/ticket-template-shared-custom.component.html',
    styleUrls: ['./ticket-template-pvccard-custom.component.scss' , '../../shared/shared-presets.scss']
})
export class TicketTemplatePvccardCustomComponent implements OnInit {

    @Input() currentLang = 'nl';

    constructor(private translationService: TranslationService) { }

    ngOnInit(): void {
        this.translationService.setLanguage(this.currentLang);
    }

}
