<div [class.error]="!!errorMessage" [ngClass]="{'form-input-special': !!icon, 'form-input': !icon}">
    <input class="form-control"
           [type]="type.toLowerCase()"
           (change)="inputChange()"
           [(ngModel)]="value"
           [autocomplete]="autocomplete"
           [required]="required"
           [disabled]="disabled"
           [readonly]="readonly"
           [maxlength]="maxLength"
           [attr.placeholder]="placeholder || null"
           [class.has-error]="!!errorMessage">
    <ng-container *ngIf="label">
        <label [class.has-error]="!!errorMessage">
            {{label}}<span *ngIf="required" class="cm-required-star cl-label-required-color m-l-4">*</span>
        </label>
    </ng-container>
    <ng-container *ngIf="icon">
        <span class="input-left icon">
            <span aria-hidden="true" class="cm-icon" [ngClass]="'cm-icon-' + icon"></span>
        </span>
    </ng-container>
    <ng-container *ngIf="errorMessage">
        <div class="form-input-validation">
            <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
        </div>
    </ng-container>
</div>
