import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListItem, ListService} from '../../../list/list.service';

@Component({
    selector: 'app-campaign-selector',
    templateUrl: './campaign-selector.component.html',
    styleUrls: ['./campaign-selector.component.scss']
})
export class CampaignSelectorComponent implements OnInit {
    @Input() campaignId: string;
    @Output() campaignIdSelected: EventEmitter<any> = new EventEmitter<any>();
    campaigns: any[] = [];
    campaignsLoaded = false;

    constructor(private listService: ListService) {
    }

    ngOnInit(): void {
        this.listService.get('campaign').subscribe({
            next: (listItems: ListItem[]): void => {
                this.campaigns = listItems;
                this.campaignsLoaded = true;
            },
            error: () => {
                this.campaigns = [];
                this.campaignsLoaded = true;
            }
        });
    }
}
