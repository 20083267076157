import {
    AfterViewInit,
    Component, DEFAULT_CURRENCY_CODE,
    ElementRef,
    EventEmitter, Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {OrderDistributionService} from '../../services/order-distribution.service';
import {BehaviorSubject} from 'rxjs';
import {MobileTemplate} from '../../models/mobile-template';
import {ResourceService} from '../../services/resource.service';

@Component({
    selector: 'cm-ticketing-mobile-template-editor',
    templateUrl: './mobile-template-editor.component.html',
    styleUrls: ['./mobile-template-editor.component.css']
})
export class MobileTemplateEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('cmfilter') cmfilter: ElementRef;
    @Output()
    navigateBack: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    editMobileTemplate: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    saveMobileTemplate: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    mobileTemplateId: string;
    @Input()
    newMobileTemplate: boolean;

    @Input() timezone: any = null;

    public mobileTemplate$;
    public mobileTemplate: MobileTemplate;
    public templateElement;
    public bannerFile: File;
    public backgroundFile: File;
    public img;
    public bannerImageChanged: boolean;
    public backgroundImageChanged: boolean;
    public incorrectDimension: boolean;
    public loadingEditor: boolean;
    public bannerImageUrl: string;
    public backgroundImageUrl: string;

    constructor(private orderDistributionService: OrderDistributionService, private resourceService: ResourceService, @Inject(DEFAULT_CURRENCY_CODE) private currencyId: string) {
    }

    ngOnInit(): void {
        if (!this.newMobileTemplate) {
            this.mobileTemplate$ = this.orderDistributionService.getMobileTemplate(this.mobileTemplateId);
            this.mobileTemplate$.subscribe((mobileTemplate: MobileTemplate) => {
                this.mobileTemplate = mobileTemplate;
                this.orderDistributionService.getMobileTemplateExampleEditor(mobileTemplate, null, null, this.timezone, this.currencyId).subscribe(() => {
                }, (error) => {
                    this.renderMobileTemplate(error.error.text);
                    this.cmfilter.nativeElement.open();
                });
            });
        } else {
            this.mobileTemplate$ = new BehaviorSubject(this.mobileTemplate);
            this.mobileTemplate = new MobileTemplate({
                uuid: '25e5e51a-a209-4e88-b702-932a0f10f86a',
                name: 'Title mobile template',
                primary_background_color: '#0074E8',
                is_showing_event_date: true,
                is_showing_expiry_date: false,
                is_showing_price: true,
                is_showing_purchase_date: false,
                is_visible: true,
            });
            this.orderDistributionService.getMobileTemplateExampleEditor(this.mobileTemplate, null, null, this.timezone, this.currencyId).subscribe(() => {
            }, (error) => {
                this.mobileTemplate$.next(this.mobileTemplate);
                this.renderMobileTemplate(error.error.text);
                this.cmfilter.nativeElement.open();
            });
        }
    }

    ngAfterViewInit(): void {
        this.cmfilter.nativeElement.open();
    }

    ngOnDestroy(): void {
        this.cmfilter.nativeElement.close();
    }

    nameChanged(event): void {
        if (event) {
            this.mobileTemplate.name = event.target.value;
            this.exampleChanged();
        }
    }

    exampleChanged(): void {
        this.loadingEditor = true;
        this.orderDistributionService.getMobileTemplateExampleEditor(this.mobileTemplate, this.bannerImageUrl, this.backgroundImageUrl, this.timezone, this.currencyId).subscribe(() => {
        }, (error) => {
            this.renderMobileTemplate(error.error.text);
            this.loadingEditor = false;
        });
    }

    toggleFilter(): void {
        if (this.cmfilter.nativeElement.className !== '') {
            this.cmfilter.nativeElement.close();
        } else {
            this.cmfilter.nativeElement.open();
        }
    }

    renderMobileTemplate(html): void {
        if (this.templateElement) {
            this.templateElement.remove();
        }
        this.templateElement = document.createElement('div');
        this.templateElement.innerHTML = html;

        const styleElement = document.createElement('style');
        styleElement.appendChild(
            document.createTextNode(`
            .banner {
                width: 640px;
                height: 200px;
            }

            .container {
                padding-left: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
            }

            .mobile-template-example .container {
                padding-left: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
            }

            .container .main-content {
                box-shadow: none !important;
            }

            .logo {
                width: 75px;
            }`)
        );

        this.templateElement.prepend(styleElement);
        const templateEditor = document.getElementById('mobile-template-example');
        if (templateEditor) {
            if (templateEditor.shadowRoot) {
                templateEditor.shadowRoot.innerHTML = html;
                templateEditor.shadowRoot.prepend(styleElement);
            } else {
                templateEditor.attachShadow({mode: 'open'});
                templateEditor.shadowRoot.innerHTML = html;
                templateEditor.shadowRoot.prepend(styleElement);
            }
        }
    }

    switchEventDate(event): void {
        if (event) {
            this.mobileTemplate.is_showing_event_date = !this.mobileTemplate.is_showing_event_date;
            this.exampleChanged();
        }
    }

    switchExpiryDate(event): void {
        if (event) {
            this.mobileTemplate.is_showing_expiry_date = !this.mobileTemplate.is_showing_expiry_date;
            this.exampleChanged();
        }
    }

    switchPrice(event): void {
        if (event) {
            this.mobileTemplate.is_showing_price = !this.mobileTemplate.is_showing_price;
            this.exampleChanged();
        }
    }

    switchPurchaseDate(event): void {
        if (event) {
            this.mobileTemplate.is_showing_purchase_date = !this.mobileTemplate.is_showing_purchase_date;
            this.exampleChanged();
        }
    }

    googleWalletColorChanged(event): void {
        if (event) {
            this.mobileTemplate.primary_background_color = event.detail.hex;
        }
    }

    uploadBackgroundImage(event): void {
        if (event && event.target && event.target.fileInput && event.target.fileInput.files[0]) {
            this.backgroundImageChanged = true;
            this.img = new Image();
            // Read file for checking image size
            for (const file of event.target.fileInput.files) {
                const reader = new FileReader();

                reader.onload = (e: ProgressEvent) => {
                    const content: any = (e.target as FileReader).result;
                    this.img.src = content;
                    this.img.onload = ($event: any) => {
                        this.backgroundFile = event.target.fileInput.files[0];
                        this.uploadResource(this.backgroundFile, 'BACKGROUND');
                        // this.checkSize();
                    };
                };
                // use this for images
                reader.readAsDataURL(file);
            }
        } else if (event && event.target && !event.target.fileInput) {
            // remove file
            this.backgroundImageChanged = false;
            this.img = null;
            this.backgroundFile = null;
        }
    }

    uploadBannerImage(event): void {
        if (event && event.target && event.target.fileInput && event.target.fileInput.files[0]) {
            this.bannerImageChanged = true;
            this.img = new Image();
            // Read file for checking image size
            for (const file of event.target.fileInput.files) {
                const reader = new FileReader();

                reader.onload = (e: ProgressEvent) => {
                    const content: any = (e.target as FileReader).result;
                    this.img.src = content;
                    this.img.onload = ($event: any) => {
                        this.bannerFile = event.target.fileInput.files[0];
                        this.uploadResource(this.bannerFile, 'BANNER');
                        // this.checkSize();
                    };
                };
                // use this for images
                reader.readAsDataURL(file);
            }
        } else if (event && event.target && !event.target.fileInput) {
            // remove file
            this.bannerImageChanged = false;
            this.img = null;
            this.bannerFile = null;
        }
    }

    uploadResource(file, type): void {
        this.resourceService.uploadImage(file).subscribe((response) => {
            if (type === 'BANNER') {
                this.bannerImageUrl = response.link;
            } else if (type === 'BACKGROUND') {
                this.backgroundImageUrl = response.link;
            }
            this.exampleChanged();
        });
    }

    onSaveClick(): void {
        if (this.newMobileTemplate) {
            delete this.mobileTemplate.uuid;
            this.newMobileTemplate = false;
            this.postMobileTemplate();
        } else {
            this.orderDistributionService.editMobileTemplate(this.mobileTemplate).subscribe((editedMobileTemplate: MobileTemplate) => {
                if (this.backgroundImageChanged) {
                    this.orderDistributionService.saveImageMobileTemplate(this.mobileTemplate.uuid, this.backgroundFile, 'BACKGROUND').subscribe(() => {
                    }, (error) => {
                        if (error.status === 413) {
                            (window as any).AddErrorNotification('Background file too large');
                        }
                    });
                }
                if (this.bannerImageChanged) {
                    this.orderDistributionService.saveImageMobileTemplate(this.mobileTemplate.uuid, this.bannerFile, 'BANNER').subscribe(() => {
                    }, (error) => {
                        if (error.status === 413) {
                            (window as any).AddErrorNotification('Banner file too large');
                        }
                    });
                }
                this.mobileTemplate = editedMobileTemplate;
                this.editMobileTemplate.emit(editedMobileTemplate);
                (window as any).AddSuccessNotification('Updated successful');
            });
        }
    }

    postMobileTemplate(): void {
        this.orderDistributionService.saveMobileTemplate(this.mobileTemplate).subscribe((newMobileTemplate: MobileTemplate) => {
            this.mobileTemplate = newMobileTemplate;
            if (this.backgroundImageChanged) {
                setTimeout(() => {
                    this.orderDistributionService.saveImageMobileTemplate(this.mobileTemplate.uuid, this.backgroundFile, 'BACKGROUND').subscribe(() => {
                    }, (error) => {
                        if (error.status === 413) {
                            (window as any).AddErrorNotification('Background file too large');
                        }
                    });
                }, (1000));
            }
            if (this.bannerImageChanged) {
                setTimeout(() => {
                    this.orderDistributionService.saveImageMobileTemplate(this.mobileTemplate.uuid, this.bannerFile, 'BANNER').subscribe(() => {
                    }, (error) => {
                        if (error.status === 413) {
                            (window as any).AddErrorNotification('Banner file too large');
                        }
                    });
                }, (1000));
            }
            this.saveMobileTemplate.emit(newMobileTemplate);
            (window as any).AddSuccessNotification('Saved successful');
        });
    }

    backToOverview(): void {
        this.cmfilter.nativeElement.close();
    }
}
