import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Table} from '../../models/preview/table/table.class';

@Component({
    selector: 'app-simple-table',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./simple-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent implements AfterViewInit, OnChanges {

    @Input()
    table: Table;

    @ViewChild('tableElement')
    tableElement: ElementRef;

    ngAfterViewInit(): void {
        this.autoSizeColumns();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.tableElement) {
            return;
        }

        this.autoSizeColumns();
    }

    private autoSizeColumns(): void {
        setTimeout(() => {
            this.tableElement.nativeElement.autoSizeColumns();
        });
    }
}
