export class PaymentMethodIssuer {
    constructor(public id: string,
                public name: string,
                public imageUrl: string) {
    }

}

export function mapPaymentMethodIssuer(item: any): PaymentMethodIssuer {
    return new PaymentMethodIssuer(
        item.id,
        item.name,
        item.imageUrl
    );
}

