import {Component, Input, OnInit} from '@angular/core';
import {Consent} from '../../../models/consent.class';
import {Order} from '../../../models/order.class';

@Component({
    selector: 'app-consent-details',
    templateUrl: './consent-details.component.html',
    styleUrls: ['./consent-details.component.scss']
})
export class ConsentDetailsComponent implements OnInit {

    @Input() order: Order;
    @Input() consent: Consent;
    @Input() updateConsentSelection;
    @Input() acceptedConsentIds;

    inConsentSelection(consent) {
        return this.acceptedConsentIds.findIndex(item => {
            return item === consent.id;
        }) >= 0;
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
