import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';
import moment from 'moment';

@Component({
    selector: 'app-linked-datepicker',
    templateUrl: './linked-datepicker.component.html',
    styleUrls: ['./linked-datepicker.component.css']
})
export class LinkedDatepickerComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() startLabel;
    @Input() endLabel;
    @Input() hint;
    @Input() startDate;
    @Input() endDate;
    @Input() required;
    @Input() autocomplete;
    @Input() locale;
    @Input() inputFormat = 'LL';
    @Input() outputFormat = 'LL';
    @Input() displayFormat = 'LLL';
    @Input() precision = 'YMD';
    @Input() minDate;
    @Input() maxDate = moment().add(120, 'years').toISOString();
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    isValid = true;
    errorMessage: string;
    linkedEndId;
    userChangedEndDate = false;

    constructor(private formManagementService: FormManagementService) {

    }

    ngOnInit() {
        this.formManagementService.subscribe(this.formId, this);

        this.startDate = moment(this.startDate, this.inputFormat).toISOString(true);
        this.endDate = moment(this.endDate, this.inputFormat).toISOString(true);

        if (this.endDate) {
            this.userChangedEndDate = true;
        }

        setTimeout(() => {
            document.querySelectorAll('cm-datetime-picker .form-input-validation').forEach(el => {
                el.remove();
            });

            document.querySelectorAll('cm-datetime-picker .form-input').forEach(el => {
                el.setAttribute('style', 'padding: 0;');
            });

            document.querySelectorAll('cm-datetime-picker .form-row').forEach(el => {
                el.setAttribute('style', 'margin: 0;');
            });
        });

        this.linkedEndId = `#${this.id}`;

        this.minDate = moment(this.minDate).toISOString();
    }

    inputChangeStart(event) {
        if (event.detail) {
            this.startDate = moment(event.detail.datetime, this.outputFormat).toISOString(true);
            this.valueChange.emit({
                startDate: this.startDate
            });

            if (!this.userChangedEndDate) {
                this.endDate = moment(event.detail.datetime, this.outputFormat).add(1, 'hour').toISOString(true);
                this.valueChange.emit({
                    endDate: this.endDate
                });
            }
            this.validate();
        }
    }

    inputChangeEnd(event) {
        if (event.detail) {
            this.userChangedEndDate = true;
            this.endDate = moment(event.detail.datetime, this.outputFormat).toISOString(true);
            this.valueChange.emit({
                endDate: this.endDate
            });
            this.validate();
        }
    }

    validateStartDate() {
        let isValid = true;
        this.errorMessage = '';

        if (this.startDate && this.startDate.error) {
            isValid = false;
            this.errorMessage = this.startDate.error.message;
        }

        if (this.required && !this.startDate) {
            isValid = false;
            this.errorMessage = 'General.Required';
        }

        if (this.minDate && this.startDate && this.startDate.datetime) {
            if (!moment(this.startDate.datetime).isSameOrAfter(moment(this.minDate))) {
                isValid = false;
                this.errorMessage = 'Error.Date_Should_Be_In_The_Future';
            }
        }

        if (this.maxDate && this.startDate && this.startDate.datetime) {
            if (!moment(this.startDate.datetime).isSameOrBefore(moment(this.maxDate))) {
                isValid = false;
                this.errorMessage = 'Error.Date_Not_Allowed';
            }
        }

        return isValid;
    }

    validateEndDate() {
        let isValid = true;
        this.errorMessage = '';

        if (this.endDate && this.endDate.error) {
            isValid = false;
            this.errorMessage = this.endDate.error.message;
        }

        if (this.required && !this.endDate) {
            isValid = false;
            this.errorMessage = 'General.Required';
        }

        if (this.minDate && this.endDate && this.endDate.datetime) {
            if (!moment(this.endDate.datetime).isSameOrAfter(moment(this.minDate))) {
                isValid = false;
                this.errorMessage = 'Error.Date_Should_Be_In_The_Future';
            }
        }

        if (this.maxDate && this.endDate && this.endDate.datetime) {
            if (!moment(this.endDate.datetime).isSameOrBefore(moment(this.maxDate))) {
                isValid = false;
                this.errorMessage = 'Error.Date_Not_Allowed';
            }
        }

        return isValid;
    }

    validate() {
        this.isValid = (this.validateStartDate() && this.validateEndDate());
        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
    }

}
