<div class="form-input" [class.error]="!isValid" [class.hidden]="hidden">
    <input class="form-control currency p-r-64"
           currencyMask
           min="0.00"
           [type]="type"
           [(ngModel)]="value"
           (ngModelChange)="inputChange()"
           [autocomplete]="autocomplete"
           [required]="required"
           [placeholder]="placeholder"
           [ngClass]="{'p-r-72': _selectedVat?.length > 1, 'p-r-80': _selectedVat?.length > 2, 'p-r-88': _selectedVat?.length > 3 }"
           [class.has-error]="!isValid">
    <label [class.has-error]="!isValid">{{ label }}</label>
    <cm-context-menu class="vat-dropdown">
        <div class="vat dropdown-button" slot="icon">
            {{ _selectedVat + '%' }}
        </div>
        @if (useVat) {
            <ng-container *ngFor="let vatOption of vatOptions">
                <cm-option [attr.data-display]="vatOption + '%'"
                           (cm-option-clicked)="selectedVat = vatOption; inputChange()"></cm-option>
            </ng-container>
        }
    </cm-context-menu>
</div>
