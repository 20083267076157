<div class="form-input form-switch">
    <label class="switch">
        <input type="checkbox"
               (change)="inputChange()"
               [(ngModel)]="value"
               [disabled]="disabled"
               [readonly]="readonly"
               [class.has-error]="!isValid"
               [name]="id"/>
        <span class="slider round"></span>
        <span class="label">{{label}}</span>
    </label>
</div>

<ng-container *ngIf="!isValid && errorMessage">
    <div class="form-input-validation">
        <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
    </div>
</ng-container>
