<ng-container *ngFor="let step of steps, let i = index">
    <span class="step"
          [class.active]="(i + 1) <= current"
          [class.complete]="current >= total" (click)="(i + 1) <= current && onStepClick(i)"></span>
</ng-container>

<span class="label">
    <ng-container *ngIf="current < total">
        STEP {{ current }} OF {{ total }}
    </ng-container>

    <ng-container *ngIf="current >= total">
        <span class="cm-icon cm-icon-check small" aria-hidden="true"></span> READY
    </ng-container>
</span>
