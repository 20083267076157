<div class="dev-account-switch">
    <div class="form-group">
        <div class="form-row">
            <div class="form-input-special">
                <input type="text" (input)="search($event.target.value)"
                       placeholder="Switch account"
                       [value]="selectedOrganisation?.name"
                       id="accountSwitchAutocomplete"
                       autocomplete="off"
                       class="form-control">

                <span class="input-left icon">
                    <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
                </span>

                <cm-autocomplete data-linked-input="input#accountSwitchAutocomplete"
                                 (cm-option-clicked)="selectOrganisation($event.detail.value)">
                    <ng-container *ngFor="let filteredOrganisation of filteredOrganisations">
                        <cm-option slot="option" [attr.data-display]="filteredOrganisation.name"
                                   [value]="filteredOrganisation.technicalLinkId"
                                   data-use-html="true">
                            <li class="cursor-pointer select-option">
                                <div class="row">
                                    <div class="col-10 p-l-16">
                                        <a class="p-l-0 select-option-title"
                                           [title]="filteredOrganisation.name">{{filteredOrganisation.name}}</a>
                                        <div
                                            class="select-option-subtitle">{{filteredOrganisation.technicalLinkId}}</div>
                                    </div>
                                </div>
                            </li>
                        </cm-option>
                    </ng-container>
                </cm-autocomplete>
            </div>
        </div>
    </div>
</div>

