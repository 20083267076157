import {Injectable, Injector} from '@angular/core';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import {Campaign} from '../../models/campaign.class';

@Injectable({
    providedIn: 'root'
})
export class CampaignManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new Campaign();

        this.initialize();
    }

}
