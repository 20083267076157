import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TranslationPipe} from './pipes/translation.pipe';
import {RouterModule} from '@angular/router';
import {TooltipDirective} from './directives/tooltip.directive';
import {CountdownModule} from 'ngx-countdown';
import {TagPipe} from './pipes/tag.pipe';
import {SelectAccountComponent} from './components/select-account/select-account.component';
import {GridsterModule} from 'angular-gridster2';
import {ByPassSecurityPipe} from './pipes/by-pass-security.pipe';
import {VenueSectionFilterPipe} from './pipes/venue-section-filter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {InputColorPickerComponent} from './components/form/input-color-picker/input-color-picker.component';
import {InputComponent} from './components/form/input/input.component';
import {InputCurrencyComponent} from './components/form/input-currency/input-currency.component';
import {InputPhoneNumberComponent} from './components/form/input-phone-number/input-phone-number.component';
import {InputTranslationComponent} from './components/form/input-translation/input-translation.component';
import {SelectComponent} from './components/form/select/select.component';
import {CheckboxComponent} from './components/form/checkbox/checkbox.component';
import {DatepickerComponent} from './components/form/datepicker/datepicker.component';
import {RadioGroupComponent} from './components/form/radio-group/radio-group.component';
import {RadioComponent} from './components/form/radio/radio.component';
import {SwitchComponent} from './components/form/switch/switch.component';
import {TextSwitchComponent} from './components/form/text-switch/text-switch.component';
import {CommonModule} from '@angular/common';
import {LinkedDatepickerComponent} from './components/form/linked-datepicker/linked-datepicker.component';
import {AuthInterceptor} from './routing/auth.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ScanServiceTableRowPipe} from './pipes/scan-service-table-row.pipe';
import {OrderTableRowPipe} from './pipes/order-table-row.pipe';
import {SettingsComponent} from '../shared/components/settings/settings.component';
import {TimezoneComponent} from './components/form/timezone/timezone.component';
import {RangePickerComponent} from './components/form/range-picker/range-picker.component';
import {DiscountCodeTableRowPipe} from './pipes/discount-code-table-row.pipe';
import {ActivityLogChangedValuesPipe} from './pipes/activity-log-changes-values.pipe';
import {TagFilterPipe} from './pipes/tag-filter.pipe';
import {DatepickerV3Component} from './components/form/datepicker-v3/datepicker-v3.component';
import {FilterPipe} from './pipes/filter.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {FeatureFlagDirective} from './directives/feature-flag.directive';
import {NgxCurrencyDirective} from 'ngx-currency';
import {ComponentLibraryModule} from '../component-library/component-library.module';

@NgModule({
    declarations: [
        TranslationPipe,
        TooltipDirective,
        FeatureFlagDirective,
        TagPipe,
        SelectAccountComponent,
        ByPassSecurityPipe,
        VenueSectionFilterPipe,
        InputColorPickerComponent,
        InputComponent,
        InputCurrencyComponent,
        InputPhoneNumberComponent,
        InputTranslationComponent,
        SelectComponent,
        CheckboxComponent,
        DatepickerV3Component,
        DatepickerComponent,
        RadioGroupComponent,
        RadioComponent,
        SwitchComponent,
        TextSwitchComponent,
        LinkedDatepickerComponent,
        ScanServiceTableRowPipe,
        OrderTableRowPipe,
        SettingsComponent,
        TimezoneComponent,
        RangePickerComponent,
        DiscountCodeTableRowPipe,
        FilterPipe,
        TagFilterPipe,
        ActivityLogChangedValuesPipe,
        JoinPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatRadioModule,
        RouterModule,
        CountdownModule,
        GridsterModule,
        DragDropModule,
        ClipboardModule,
        NgxCurrencyDirective,
        ComponentLibraryModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatRadioModule,
        TranslationPipe,
        TooltipDirective,
        FeatureFlagDirective,
        TagPipe,
        CountdownModule,
        GridsterModule,
        ByPassSecurityPipe,
        VenueSectionFilterPipe,
        DragDropModule,
        ClipboardModule,
        InputColorPickerComponent,
        InputComponent,
        InputCurrencyComponent,
        InputPhoneNumberComponent,
        InputTranslationComponent,
        SelectComponent,
        CheckboxComponent,
        DatepickerV3Component,
        DatepickerComponent,
        RadioGroupComponent,
        RadioComponent,
        SwitchComponent,
        TextSwitchComponent,
        ScanServiceTableRowPipe,
        OrderTableRowPipe,
        SettingsComponent,
        RangePickerComponent,
        DiscountCodeTableRowPipe,
        TimezoneComponent,
        FilterPipe,
        TagFilterPipe,
        ActivityLogChangedValuesPipe,
        JoinPipe,
        NgxCurrencyDirective
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        TranslationPipe,
        ByPassSecurityPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
}
