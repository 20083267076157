import { HttpParams } from '@angular/common/http';

export class HttpParamsHelper {
    /**
     * Sets the given param name and value on the given http params or a new one if null
     * @param httpParams The http params to attach the given param name and value to, or null to create a new one
     * @param paramName The param name
     * @param paramValue The param value
     * @protected
     */
    public static setHttpParam(httpParams: HttpParams = null, paramName: string, paramValue: string): HttpParams {
        httpParams = httpParams ?? new HttpParams();
        return httpParams.set(paramName, paramValue);
    }
}
