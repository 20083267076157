import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsComponent implements OnChanges {
    @Input() context: StatisticsContext | undefined;
    @Input() statistics: any = [];
    @Input() isLoading = false;
    @Input() showChange = false;
    mappedStatistics: any[] = [];

    public currencyId: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.statistics && this.statistics && this.statistics.length > 0) {
            this.mappedStatistics = [...this.statistics];

            this.mappedStatistics.forEach((statistic, i) => {
                if (statistic.id === 'PRODUCTS' && statistic.value === 0) {
                    this.mappedStatistics.splice(i, 1);
                }

                if (statistic.id === 'DISCOUNT_AMOUNT' && statistic.value === 0) {
                    this.mappedStatistics.splice(i, 1);
                }

                if (statistic.id === 'CHECK_INS') {
                    this.mappedStatistics.splice(i, 1);
                }
            });

            this.isLoading = false;
        }
    }
}

export class StatisticsContext {
    context: 'EVENT' | 'SUBSCRIPTION';
    contextId: string;
}
