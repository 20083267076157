<div class="list-group row col-12">
    <ng-content></ng-content>
    <ng-container *ngFor="let row of data">
        <ng-container *ngTemplateOutlet="rowTemplate || defaultRow; context: { $implicit: row }"></ng-container>
    </ng-container>
</div>

<ng-template #defaultRow let-row>
    <div class="list-group-content row">
        <ng-container *ngFor="let cell of row | keyvalue">
            <div class="table-column col-1">
                {{ cell.value | json }}
            </div>
        </ng-container>
    </div>
</ng-template>
