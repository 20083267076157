import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import {Event} from '../models/event.class';
import {LocaleService} from '../../core/services/locale.service';

@Pipe({
    name: 'startEndDateTime'
})
export class StartEndDateTimePipe implements PipeTransform {

    constructor(private localeService: LocaleService) {
    }

    transform(value: Event, ...args: string[]): unknown {
        const locale = this.localeService.getLanguage();
        const timezone = args[0] ?? '';

        const startAtDate = formatDate(value.startAt, 'longDate', locale, timezone);
        const startAtTime = formatDate(value.startAt, 'HH:mm', locale, timezone);
        let endAtDate = formatDate(value.endAt, 'longDate', locale, timezone);
        endAtDate = startAtDate === endAtDate ? '' : `${endAtDate} `;
        const endAtTime = formatDate(value.endAt, 'HH:mm', locale, timezone);

        return (`${startAtDate} ${startAtTime} - ${endAtDate}${endAtTime} ${timezone}`).trim();
    }

}
