export class Notification {
    public readonly topic: string;
    public readonly processId: string;
    public readonly content: any;

    constructor(topic: string, processId: string, content: any) {
        this.topic = topic;
        this.processId = processId;
        this.content = content;
    }
}
