<ng-container *ngIf="order$ | async as order; else loading">

    <div class="header">
        <div class="row">
            <div class="col-12 col-sm-9">
                <h1>{{ order.batchId }}</h1>
            </div>
            <div class="col-12 col-sm-3 text-right">
                <ng-container *ngIf="order.status === 'RESERVED'">
                    <button class="btn btn-primary-icon m-r-8" (click)="editOrder(order.id)"
                            [cm-tooltip]="'Order.Edit_Order' | trans">
                        <span class="cm-icon cm-icon-edit" aria-hidden="true"></span>
                    </button>
                </ng-container>
                <button class="btn btn-primary-icon" (click)="navigateToOrderDetail(order.id)"
                        [cm-tooltip]="'Order.Details' | trans">
                    <span class="cm-icon cm-icon-right" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <div class="row status-bar">
            <div class="col-10">
                <div class="inline-notification"
                     [cm-tooltip]="'Order.Status' | trans">
                    <div class="item-left">
                        <span *ngIf="order.status === 'RESERVED'"
                              class="cm-icon cm-icon-time-fill cl-warning-color small" aria-hidden="true"></span>
                        <span *ngIf="order.status === 'OPEN'" class="cm-icon cm-icon-creditcard cl-warning-color small"
                              aria-hidden="true"></span>
                        <span *ngIf="order.status === 'COMPLETE'"
                              class="cm-icon cm-icon-verification cl-success-color small" aria-hidden="true"></span>
                        <span *ngIf="order.status === 'EXPIRED'" class="cm-icon cm-icon-time-fill cl-error-color small"
                              aria-hidden="true"></span>
                        <span *ngIf="order.status === 'CANCELED'" class="cm-icon cm-icon-warning cl-error-color small"
                              aria-hidden="true"></span>
                        <span *ngIf="order.status === 'REFUNDED'" class="cm-icon cm-icon-refund cl-black-50 small"
                              aria-hidden="true"></span>
                    </div>
                    <div class="title">
                        <span class="cl-selected-color">
                            {{('Order_Status.' + order.status | trans)}}
                        </span>
                    </div>
                </div>

                <ng-container *ngIf="order.importId">
                    <div class="inline-notification"
                         [cm-tooltip]="'Order_Detail.Go_To_Import' | trans"
                         [routerLink]="['/settings/imports', order.importId]">
                        <div class="item-left">
                            <span class="cm-icon cm-icon-pop-in cl-selected-color small" aria-hidden="true"></span>
                        </div>
                        <div class="title">
                            <span class="cl-selected-color">
                                {{('General.Imported' | trans) }} - {{order.externalReference}}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="order.campaignId">
                    <div class="inline-notification"
                         [cm-tooltip]="'Order_Detail.Go_To_Campaign' | trans"
                         [routerLink]="['/settings/campaigns', order.campaignId]">
                        <div class="item-left">
                            <span class="cm-icon cm-icon-megaphone cl-selected-color small" aria-hidden="true"></span>
                        </div>
                        <div class="title">
                            <span class="cl-selected-color">
                                {{ order.campaign.name }}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="col-2 text-right">
                <cm-context-menu>
                    <ng-container *ngIf="order.status === 'COMPLETE'">
                        <cm-option data-icon="email"
                                   [attr.data-display]="('Order_Detail.Resend_Email' | trans)"
                                   (cm-option-clicked)="onResendOrder(order,'EMAIL')"></cm-option>
                        <cm-option data-icon="message"
                                   [attr.data-display]="('Order_Detail.Resend_SMS' | trans)"
                                   (cm-option-clicked)="onResendOrder(order,'SMS')"></cm-option>

                        <cm-context-menu-line></cm-context-menu-line>

                        <cm-option data-icon="download"
                                   [attr.data-display]="('General.Download' | trans)"
                                   (cm-option-clicked)="onDownloadOrderClick(order)"></cm-option>

                        <cm-context-menu-line></cm-context-menu-line>
                    </ng-container>

                    <cm-option data-icon="edit"
                               [disabled]="order.isGuestManager ? true : null"
                               [attr.data-tooltip-text]="order.isGuestManager ? ('Order.Cannot_Move_Guest_Manager_Orders' | trans) : null"
                               [attr.data-display]="('Order_Detail.Move_Order' | trans)"
                               (cm-option-clicked)="onMoveOrder(order.id)">
                    </cm-option>

                    <ng-container *ngIf="order.status === 'COMPLETE'">
                        <cm-context-menu-line></cm-context-menu-line>

                        <cm-option
                            class="cl-red"
                            data-icon="close"
                            [attr.data-display]="('General.Cancel' | trans)"
                            (cm-option-clicked)="onCancelOrder(order.id)"></cm-option>
                    </ng-container>
                </cm-context-menu>
            </div>
        </div>
    </div>

    <cm-expansion-panel data-is-open data-no-border>
        <cm-expansion-panel-header>
            <p style="font-weight: bold">{{ ('Order_Detail.Order_Summary' | trans) }}</p>
        </cm-expansion-panel-header>
        <cm-expansion-panel-content slot="content">
            <app-order-summary [order]="order"></app-order-summary>
        </cm-expansion-panel-content>
    </cm-expansion-panel>

    <cm-expansion-panel data-is-open data-no-border>
        <cm-expansion-panel-header>
            <p style="font-weight: bold">{{ ('Order_Detail.Customer' | trans) }}</p>
        </cm-expansion-panel-header>
        <cm-expansion-panel-content slot="content">
            <ng-container *ngIf="order.customer">
                <div class="row">
                    <div class="col-10">
                        <div class="customer-name">{{ order.customer.getName() }}</div>
                        <div class="customer-external-reference">{{ order.customer.externalReference1 }}</div>
                        <div class="customer-contact">{{ order.customer.email }}</div>
                    </div>
                    <div class="col-2 text-right">
                        <button class="btn btn-primary-icon" [routerLink]="['/customers', order.customer.id]">
                            <span class="cm-icon cm-icon-right" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </cm-expansion-panel-content>
    </cm-expansion-panel>

    <cm-expansion-panel data-no-border>
        <cm-expansion-panel-header>
            <p style="font-weight: bold">{{ ('Order_Detail.Activity_Log' | trans) }}</p>
        </cm-expansion-panel-header>
        <cm-expansion-panel-content slot="content">
            <app-activity-log-v2 [orderIds]="[order.id]"></app-activity-log-v2>
        </cm-expansion-panel-content>
    </cm-expansion-panel>

</ng-container>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
