import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {VenueSection} from '../../models/venue-section.class';

@Component({
    selector: 'app-venue-section-list-item',
    templateUrl: './venue-section-list-item.component.html',
    styleUrls: ['./venue-section-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VenueSectionListItemComponent {

    @Input() venueSection: VenueSection;
    @Input() mode: 'COMPACT' | 'DETAILED' = 'COMPACT';
    @Input() selected = false;

}
