import {ModelBase} from './abstract/model-base';

export class Language extends ModelBase {
    public id: string;
    public name: string;
    public isShopSupported: boolean;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/language',
        });
    }

    mapModel(data: any): Language {
        const model = new Language();
        model.id = data.id;
        model.name = data.name;
        model.isShopSupported = data.isShopSupported;
        return model;
    }
}
