<div class="row">
    <div class="col-5">
        <h4>{{('List_Picker.Available' | trans)}}</h4>
        <div class="header-link" (click)="onSelectAllClicked()">{{('List_Picker.Select_All' | trans)}}</div>
    </div>

    <div class="col-5 offset-2">
        <h4>{{('List_Picker.Selected' | trans)}}</h4>
        <div class="header-link" (click)="onDeselectAllClicked()">{{('List_Picker.Deselect_All' | trans)}}</div>
    </div>
</div>

<div class="row list-items-container">
    <div class="col-5 list-items">
        <ng-container *ngFor="let listItem of list">
            <ng-container *ngTemplateOutlet="listItemTemplate; context:{listItem: listItem, hidden: isListItemSelected(listItem)}"></ng-container>
        </ng-container>

        <ng-container *ngIf="selectedList.length === list.length">
            <h5>
                {{('List_Picker.All_Selected' | trans)}}
            </h5>
        </ng-container>
    </div>
    <div class="col-2 text-center p-t-64">
        <span class="cm-icon cm-icon-switch" aria-hidden="true"></span>
    </div>
    <div class="col-5 list-items">
        <ng-container *ngFor="let listItem of selectedList">
            <ng-container *ngTemplateOutlet="listItemTemplate; context:{listItem: listItem, hidden: false}"></ng-container>
        </ng-container>

        <ng-container *ngIf="selectedList.length === 0">
            <h5>
                {{('List_Picker.None_Selected' | trans)}}
            </h5>
        </ng-container>
    </div>
</div>

<ng-template #listItemTemplate let-listItem="listItem" let-hidden="hidden">
    <div [hidden]="hidden || (hideDisabledItems && listItem.isDisabled ?? false)" class="list-item" [class.disabled]="listItem.isDisabled ?? false"
         (click)="toggleSelectListItem(listItem)">
        {{ listItem.name }} <span *ngIf="listItem.isDisabled ?? false">({{disabledItemNameExpansion}})</span>
    </div>
</ng-template>
