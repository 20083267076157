import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {CustomerTagService} from '../../../services/customer-tag-service/customer-tag.service';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {Observable} from 'rxjs';
import {CustomerTag} from '../../../models/customer-tag.class';
import {map} from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-dialog-import-customer-tag',
    templateUrl: './dialog-import-customer-tag.component.html',
    styleUrls: ['./dialog-import-customer-tag.component.css']
})
export class DialogImportCustomerTagComponent extends DialogBase<IInput, IOutput> {
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    public customerTagImportFile: File;
    public isCustomerTagImportFileUploadStarted = false;
    public isCustomerTagImportFileUploadDone = false;

    public customerTagImportFileRowCount: number;

    public customerTags$: Observable<CustomerTag[]> = this.customerTagService.getCustomerTags(new HttpParams().set('take', -1)).pipe(map(response => response.body));

    private selectedCustomerTagId: string;
    private isRemoveCustomerTags = false;

    constructor(dialogService: DialogService, private customerTagService: CustomerTagService, private notificationService: NotificationService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
        this.extraData = data.extraData;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: this.extraData
        });
    }

    public cancel() {
        super.cancel();
    }

    setCustomerTagImportFile(event) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            this.customerTagImportFile = file;

            const data = new Uint8Array((e as any).target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            const range = XLSX.utils.decode_range(worksheet['!ref']);
            this.customerTagImportFileRowCount = range.e.r - range.s.r + 1;
        };

        reader.readAsArrayBuffer(file);
    }

    confirmCustomerTagImportDialog() {
        if (this.customerTagImportFile) {
            this.isCustomerTagImportFileUploadStarted = true;
            this.customerTagService.postCustomerTagImport(this.selectedCustomerTagId, this.isRemoveCustomerTags, this.customerTagImportFile).subscribe({
                next: () => {
                    this.isCustomerTagImportFileUploadDone = true;
                    this.notificationService.showTranslatedNotification('success', 'customer_tag', 'imported');
                }, error: () => {
                    this.notificationService.showTranslatedNotification('error', 'customer_tag', 'import_failed');
                }
            });
        }
    }

    closeCustomerTagImportDialog() {
        this.customerTagImportFile = null;
        this.cancel();
    }

    onCustomerTagSelect(selectEvent: any): void {
        this.selectedCustomerTagId = selectEvent.detail.value;
    }

    setRemoveTags(removeCustomerTags: boolean): void {
        this.isRemoveCustomerTags = removeCustomerTags;
    }

}
