export class MobileTemplate {
    public uuid: string;
    public name: string;
    public primary_background_color: string;
    public is_default: boolean;
    public is_showing_event_date: boolean;
    public is_showing_expiry_date: boolean;
    public is_showing_price: boolean;
    public is_showing_purchase_date: boolean;
    public created_at: string;
    public is_visible: boolean;

    constructor(data) {
        this.mapMobileTemplate(data);
    }

    mapMobileTemplate(data): MobileTemplate {
        this.uuid = data.uuid;
        this.name = data.name;
        this.primary_background_color = data.primary_background_color;
        this.is_default = data.is_default;
        this.is_showing_event_date = data.is_showing_event_date;
        this.is_showing_expiry_date = data.is_showing_expiry_date;
        this.is_showing_price = data.is_showing_price;
        this.is_showing_purchase_date = data.is_showing_purchase_date;
        this.created_at = data.created_at;
        this.is_visible = data.is_visible;

        return this;
    }
}
