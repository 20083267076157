<cm-dialog [id]="id" data-max-width="640px">
    <h3 slot="header" class="dialog-title">
        {{('Customer_List.Export' | trans)}}
    </h3>

    <div slot="body">
        <div class="form-group">
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onCustomFieldsChanged($event.target.checked)">
                    <span class="label">{{('Customer_Export.Custom_Field' | trans)}}</span>
                </label>
            </div>
        </div>

        <div class="form-group">
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onPersonalLinkChanged($event.target.checked)">
                    <span class="label">{{('Customer_Export.Personal_Link' | trans)}}</span>
                </label>
            </div>
        </div>

        <ng-container *ngIf="personalLink">
            <div class="form-group">
                <ng-container *ngIf="shops$ | async as shops">
                    <cm-select (cm-option-clicked)="personalLinkShopId = $event.detail.value"
                               [placeholder]="('General.Shop' | trans)"
                               [value]="shops[0].id">
                        <ng-container *ngFor="let shop of shops">
                            <cm-option slot="option" [value]="shop.id"
                                       [attr.data-display]="shop.name"></cm-option>
                        </ng-container>
                    </cm-select>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="isCmEmployee">
            <div class="form-group">
                <div class="form-check">
                    <label>
                        <input type="checkbox" (change)="onCustomerIbanChanged($event.target.checked)">
                        <span class="label">{{('Customer_Export.Customer_Iban' | trans)}}</span>
                    </label>
                </div>
            </div>
        </ng-container>
    </div>

    <div slot="footer">
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-primary" (click)="confirm()">{{('General.Download' | trans)}}</button>
            </div>
        </div>
    </div>
</cm-dialog>
