import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Point} from '../../models/point.class';

@Injectable({
                providedIn: 'root'
            })
export class AnalyticsService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config  = {
        withCredentials: true,
        params:          {}
    };

    constructor(private http: HttpClient) {
    }

    post(body: any, params?: HttpParams): Observable<any> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<any>(this.baseUrl + `/v1.0/analytics`, body, config);
    }
}
