<ng-container *ngIf="mobileTemplates$ | async as templates">
    <div class="row card-wrapper tile-view justify-content-left m-l--8 m-r--8 w-calc-16">
        <ng-container *ngFor="let mobileTemplate of mobileTemplates; let i = index">
            <ng-container *ngIf="mobileTemplate.is_visible">
                <!-- Template editor -->
                <div class="card-container col-6" (click)="!mobileTemplate.is_default ? onMobileTemplateClick(mobileTemplate) : ''">
                    <div class="card m-8">
                        <div class="card-header">
                            <div class="meta-title">
                                <span class="heading">{{ mobileTemplate.name }}</span>
                            </div>
                        </div>
                        <div class="card-media tile justify-content-center">
                            <div class="mobile-template-example" id="mobile-template-{{i}}"></div>
                        </div>
                        <div class="card-media min-height-auto row tile">
                            <div class="row col-12 align-items-center">
                                <div class="col-8">
                                    {{('Ticket_Template_List.Showing_Event_Date' | trans) | async}}
                                </div>
                                <div class="col-4 text-right">
                                    <span class="cm-icon" [ngClass]="mobileTemplate.is_showing_event_date ? 'cm-icon-check activated' : 'cm-icon-close'" aria-hidden="true"></span>
                                </div>
                            </div>
                            <div class="row col-12 align-items-center">
                                <div class="col-8">
                                    {{('Ticket_Template_List.Showing_Expiry_Date' | trans) | async}}
                                </div>
                                <div class="col-4 text-right">
                                    <span class="cm-icon" [ngClass]="mobileTemplate.is_showing_expiry_date ? 'cm-icon-check activated' : 'cm-icon-close'" aria-hidden="true"></span>
                                </div>
                            </div>
                            <div class="row col-12 align-items-center">
                                <div class="col-8">
                                    {{('Ticket_Template_List.Showing_Price' | trans) | async}}
                                </div>
                                <div class="col-4 text-right">
                                    <span class="cm-icon" [ngClass]="mobileTemplate.is_showing_price ? 'cm-icon-check activated' : 'cm-icon-close'" aria-hidden="true"></span>
                                </div>
                            </div>
                            <div class="row col-12 align-items-center">
                                <div class="col-8">
                                    {{('Ticket_Template_List.Showing_Purchase_Date' | trans) | async}}
                                </div>
                                <div class="col-4 text-right">
                                    <span class="cm-icon" [ngClass]="mobileTemplate.is_showing_purchase_date ? 'cm-icon-check activated' : 'cm-icon-close'" aria-hidden="true"></span>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!mobileTemplate.is_default">
                            <div class="card-menu">
                                <cm-context-menu (click)="$event.stopPropagation()">
                                    <cm-option data-icon="edit" data-display="Edit" (cm-option-clicked)="onMobileTemplateClick(mobileTemplate)"></cm-option>
                                    <cm-option data-icon="archive" data-display="Archive" (cm-option-clicked)="onDeleteMobileTemplateClick(mobileTemplate)"></cm-option>
                                </cm-context-menu>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!mobileTemplates">
    <div class="load-indicator">
        <cm-indicator></cm-indicator>
    </div>
</ng-container>
