import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Event} from '../../models/event.class';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ExternalBarcodeService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    deleteBarcodes(event: Event): Observable<Event> {
        return this.http.delete<Event>(this.baseUrl + `/v1.0/externalbarcode/event/${event.id}`, this.config);
    }
}
