<ng-container *ngFor="let orderItem of orderItems; let i = index">
    <ng-container *ngIf="i < 5 || showAllOrderItems">
        <div class="row order-summary-row">
            <div class="col-9">
                <div class="group-name">{{ orderItem.name }}</div>
                <div class="order-item-name">{{ orderItem.orderItem.name }}</div>
            </div>
            <div class="col-3 text-right">
                {{ orderItem.price | currency }}
                <ng-container *ngFor="let costItemAgreement of orderItem.additionalCosts">
                    <ng-container *ngIf="costItemAgreement.amount > 0">
                        <div class="order-item-cost-item-agreement">
                            {{ costItemAgreement.label }}: {{ costItemAgreement.amount | currency:costItemAgreement.currencyId }}
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="order.orderItemAmount > 5 && showAllOrderItems === false">
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-secondary" (click)="showAllOrderItems = true">
                {{ ('Order_Summary.Show_All_Order_Items' | trans | tags:[{
                key: 'amount',
                value: order.orderItemAmount
            }]) }}
            </button>
        </div>
    </div>
</ng-container>

<div class="discounts">
    <ng-container *ngFor="let discount of order.discounts">
        <div class="row order-summary-row">
            <div class="col-9">
                {{ discount.name }}
            </div>
            <div class="col-3 text-right">
                -{{ discount.amount | currency}}
            </div>
        </div>
    </ng-container>
</div>

<div class="row order-summary-row order-summary-total">
    <div class="col-9">
        <ng-container
            *ngIf="order.totalDiscountAmount > 0">{{('Order_Table.Price_After_Discount' | trans)}}</ng-container>
        <ng-container *ngIf="order.totalDiscountAmount === 0">{{('Order_Table.Price' | trans)}}</ng-container>
    </div>
    <div class="col-3 text-right">
        {{order.getPrice()| currency}}
    </div>
</div>

<ng-template #orderItemRow let-group="group" let-orderItem="orderItem" let-orderItemItem="orderItemItem">
    <div class="row order-summary-row">
        <div class="col-9">
            <div class="group-name">{{ group.name }}</div>
            <div class="order-item-name">{{ orderItemItem.name }}</div>
        </div>
        <div class="col-3 text-right">
            {{ orderItem.price | currency:orderItem.currency }}
        </div>
    </div>
</ng-template>
