import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {AnalyticsDashboard, mapAnalyticsDashboard} from '../../models/analytics-dashboard.class';

@Injectable({
                providedIn: 'root'
            })
export class AnalyticsDashboardService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config  = {
        withCredentials: true,
        params:          {},
        responseType:    null
    };

    constructor(private http: HttpClient) {
    }

    get(analyticsDashboardId: string, params?: HttpParams): Observable<AnalyticsDashboard> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<AnalyticsDashboard>(this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}`, config).pipe(
            map((item: AnalyticsDashboard) => {
                return mapAnalyticsDashboard(item);
            })
        );
    }
}
