<div class="download-container">
    <ng-container *ngIf="download$ | async as download;">
        <ng-container *ngIf="download">
            <div class="notify">
                <div class="notify-item row">
                    <div class="meta-icon f-s-26">
                        <cm-indicator class="col-6 m-t-16" data-type="circular"
                                      data-image="{{download.progress < 100 ? 'https://www.cm.com/app/aurora/svg/icons/download.svg' : 'https://www.cm.com/app/aurora/svg/icons/check.svg'}}"
                                      data-percentage="{{download.progress}}">
                        </cm-indicator>
                    </div>
                    <div class="meta-info m-l-8">
                        <div class="meta-info-title">Downloading {{download.total}} {{download.type}}(s)<br>
                            Progress: {{download.progress}}%
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
