import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

export class StateSubject<T> extends BehaviorSubject<T> {
    private readonly initialValue: T;

    constructor(value: T) {
        super(value);

        this.initialValue = value;
    }

    update(value: T): void {
        this.next(value);
    }

    get value$(): Observable<T> {
        return super.asObservable().pipe(
            distinctUntilChanged((a: T, b: T) => a === b)
        );
    }

    reset(): void {
        this.next(this.initialValue);
    }
}
