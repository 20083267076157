import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    @Input()
    data: any[];

    @ContentChild('rowTemplate')
    rowTemplate: TemplateRef<any> | undefined;

    constructor() {
    }

    ngOnInit(): void {
    }
}
