<ng-container *ngIf="label">
    <label [class.has-error]="!isValid">{{label}}</label>
</ng-container>
<div [class.list-horizontal]="listHorizontal">
    <div class="form-input">
        <ng-container *ngFor="let option of options">
            <div class="form-radio">
                <input class="form-check-input"
                       type="radio"
                       [(ngModel)]="value"
                       [name]="id"
                       [value]="option[optionValue]"
                       [required]="required"
                       [disabled]="disabled"
                       [readonly]="readonly"
                       [class.has-error]="!isValid">
                <label class="form-check-label" (click)="value = option[optionValue]; inputChange()">
                    <ng-container *ngIf="optionLabelTranslate">
                        {{(option[optionLabel]| trans)}}
                    </ng-container>
                    <ng-container *ngIf="!optionLabelTranslate">
                        {{option[optionLabel]}}
                    </ng-container>
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="!isValid && errorMessage">
            <div class="form-input-validation">
                <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
            </div>
        </ng-container>
    </div>
</div>
