<cm-date-time-picker
    class="col-12"
    (change)="dateChange($event)"
    [attr.data-datetime]="value"
    [attr.required]="isRequired ? true : undefined"
    [attr.placeholder]="placeholder"
    [attr.data-label]="label"
    [attr.max]="maxDate"
    [attr.min]="minDate"
    [attr.data-error-invalid]="errorMessage"
    [attr.data-format]="dateFormat"
    [attr.disabled]="disabled ? true : null"
    [attr.data-include-date-in-time]="includeDateInTime ? true : null">
</cm-date-time-picker>
