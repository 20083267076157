import {Injectable} from '@angular/core';
import moment from 'moment';

@Injectable({
                providedIn: 'root'
            })
export class GroupByService {

    constructor() {
    }

    public groupByYear(items: any[], groupByKey: string, pinByKey?: string): Group[] {
        const groups     = [];
        let groupByValue = null;
        for (const item of items) {
            if (pinByKey && item[pinByKey]) {
                groupByValue = null;
            } else {
                groupByValue = moment(item[groupByKey]).format('Y');
            }

            const groupItem = groups.find(group => group.key === groupByValue);
            if (!groupItem) {
                groups.push({
                                key:   groupByValue,
                                items: [item]
                            });
            } else {
                groupItem.items.push(item);
            }
        }

        return groups;
    }
}

export class Group {
    constructor(public key: string,
                public items: any[]) {
    }
}
