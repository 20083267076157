import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {VenueSection} from '../../../models/venue-section.class';

@Component({
    selector: 'app-dialog-ring-settings',
    templateUrl: './dialog-ring-settings.component.html',
    styleUrls: ['./dialog-ring-settings.component.css']
})
export class DialogRingSettingsComponent extends DialogBase<IInput, IOutput> {
    public bodyText: any;
    public confirmText: string;

    public venueSection: VenueSection;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;

        this.venueSection = data.extraData.venueSection;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: this.venueSection
        });
    }

    public cancel() {
        super.cancel();
    }
}
