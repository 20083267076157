<div class="form-group">
    <div class="form-row">
        <div class="form-input-special">
            <ng-container *ngIf="label">
                <label>{{label}}</label>
            </ng-container>

            <input type="text" (input)="onSearchValueChanged($event)"
                   [placeholder]="('General.Search' | trans)"
                   [value]="selectedEvent?.name"
                   id="eventautocomplete"
                   autocomplete="off"
                   class="form-control">

            <span class="input-left icon">
                <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
            </span>

            <cm-autocomplete data-linked-input="input#eventautocomplete"
                             (cm-option-clicked)="selectEvent($event.detail.value)">
                <ng-container *ngFor="let event of events$ | async">
                    <cm-option slot="option" [attr.data-display]="event.name" [value]="event.id"
                                      data-use-html="true">
                        <li class="cursor-pointer select-option">
                            <div class="row">
                                <div class="col-2">
                                    <ng-container *ngIf="event.resources[0]">
                                        <img class="image" [src]="event.resources[0].resource.link">
                                    </ng-container>
                                </div>
                                <div class="col-10 p-l-16">
                                    <a class="p-l-0 select-option-title" [title]="event.name">{{event.name}}</a>
                                    <div class="select-option-subtitle">{{event.startAt | date:'EE d MMM y'}}</div>
                                </div>
                            </div>
                        </li>
                    </cm-option>
                </ng-container>
            </cm-autocomplete>
        </div>
    </div>
</div>

