import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cm-ticketing-boca-default-2',
    templateUrl: './ticket-template-boca-default-2.component.html',
    styleUrls: ['./ticket-template-boca-default-2.component.scss', '../../shared/shared-presets.scss']
})
export class TicketTemplateBocaDefault2Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
