import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Campaign, mapCampaign} from '../../models/campaign.class';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getCampaigns(params?: HttpParams): Observable<HttpResponse<Campaign[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Campaign[]>(this.baseUrl + `/v1.0/campaign`, {...config, observe: 'response'})
            .pipe(
                map((response: HttpResponse<Campaign[]>) => {
                    const body = response.body.map((item: Campaign) => {
                        return mapCampaign(item);
                    });
                    return new HttpResponse({body: body, headers: response.headers});
                })
            );
    }

    getCampaign(campaignId: string, params?: HttpParams): Observable<Campaign> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Campaign>(this.baseUrl + `/v1.0/campaign/${campaignId}`, config)
            .pipe(
                map((item: Campaign) => {
                    return mapCampaign(item);
                })
            );
    }

    patchCampaign(campaign: Campaign, params?: HttpParams): Observable<Campaign> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Campaign>(this.baseUrl + `/v1.0/campaign/${campaign.id}`, campaign, config)
            .pipe(
                map((item: Campaign) => {
                    return mapCampaign(item);
                })
            );
    }

    postCampaign(campaign: Campaign): Observable<Campaign> {
        return this.http.post<Campaign>(this.baseUrl + `/v1.0/campaign`, campaign, this.config)
            .pipe(
                map((item: Campaign) => {
                    return mapCampaign(item);
                })
            );
    }

    deleteCampaign(campaignId: string): Observable<any> {
        return this.http.delete<any>(this.baseUrl + `/v1.0/campaign/${campaignId}`, this.config);
    }
}
