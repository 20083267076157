<div class="setting-sidebar form-group transition" [class.open]="editAnalyticsDashboardItem">
    <div class="content">
        <ng-container *ngIf="editAnalyticsDashboardItem">
            <div class="row">
                <div class="col-12">
                    <app-analytics-chart [analyticsDashboard]="analyticsDashboard"
                                         [analyticsDashboardItem]="editAnalyticsDashboardItem"
                                         [updateData]="editAnalyticsDashboardItem.updatedAt">
                    </app-analytics-chart>
                </div>
            </div>

            <cm-expansion-panel data-no-border="true" data-is-open="true">
                <cm-expansion-panel-header>
                    <p style="font-weight: bold">{{('Analytics.Styling_General' | trans)}}</p>
                </cm-expansion-panel-header>
                <cm-expansion-panel-content slot="content">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <app-form-input [label]="('General.Name' | trans)"
                                                [(value)]="editAnalyticsDashboardItem.name">
                                </app-form-input>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Legend' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isLegendEnabled"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Grid_Horizontal' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isGridHorizontalEnabled"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Grid_Vertical' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isGridVerticalEnabled"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Label_Horizontal' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isLabelHorizontalEnabled"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Label_Vertical' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isLabelVerticalEnabled"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                            <div class="form-row">
                                <app-form-switch [label]="('Analytics.Stacked' | trans)"
                                                 [(value)]="editAnalyticsDashboardItem.isStacked"
                                                 (change)="update()">
                                </app-form-switch>
                            </div>
                        </div>
                    </div>
                </cm-expansion-panel-content>
            </cm-expansion-panel>
            <cm-expansion-panel data-no-border="true">
                <cm-expansion-panel-header>
                    <p style="font-weight: bold">{{('Analytics.Data_Sets' | trans)}}</p>
                </cm-expansion-panel-header>
                <cm-expansion-panel-content slot="content">
                    <ng-container
                        *ngFor="let analyticsDashboardItemSetting of editAnalyticsDashboardItem.analyticsDashboardItemSettings">
                        <app-analytics-dashboard-item-setting
                            [analyticsDashboardItemSetting]="analyticsDashboardItemSetting"
                            [events]="events"
                            [subscriptionTypes]="subscriptionTypes"
                            (change)="update(analyticsDashboardItemSetting)"
                            (deleteClick)="onAnalyticsDashboardItemSettingDelete($event)">
                        </app-analytics-dashboard-item-setting>
                    </ng-container>

                    <div class="card card-add card-action" (click)="add()">
                        <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                        <span class="m-t-8">{{('Sale_Plan.New_Rule' | trans)}}</span>
                    </div>
                </cm-expansion-panel-content>
            </cm-expansion-panel>


            <!--            <cm-tab-group class="cm-tab-group" first-selected="0">-->
            <!--                <cm-tab class="cm-tab" [attr.data-display]="('Analytics.Styling_General' | trans)" slot="content">-->
            <!--                    <div class="p-16">-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-12">-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-input [label]="('General.Name' | trans)"-->
            <!--                                                    [(value)]="editAnalyticsDashboardItem.name">-->
            <!--                                    </app-form-input>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Legend' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isLegendEnabled"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Grid_Horizontal' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isGridHorizontalEnabled"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Grid_Vertical' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isGridVerticalEnabled"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Label_Horizontal' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isLabelHorizontalEnabled"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Label_Vertical' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isLabelVerticalEnabled"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                                <div class="form-row">-->
            <!--                                    <app-form-switch [label]="('Analytics.Stacked' | trans)"-->
            <!--                                                     [(value)]="editAnalyticsDashboardItem.isStacked"-->
            <!--                                                     (change)="update()">-->
            <!--                                    </app-form-switch>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </cm-tab>-->
            <!--                <cm-tab class="cm-tab" [attr.data-display]="('Analytics.Data_Sets' | trans)" slot="content">-->
            <!--                    <div class="p-16">-->
            <!--                        &lt;!&ndash;                        <ng-container *ngFor="let analyticsDashboardItemSetting of editAnalyticsDashboardItem.analyticsDashboardItemSettings; let i = index">&ndash;&gt;-->
            <!--                        <ng-container *ngFor="let analyticsDashboardItemSetting of editAnalyticsDashboardItem.analyticsDashboardItemSettings">-->
            <!--                            <app-analytics-dashboard-item-setting-->
            <!--                                [analyticsDashboardItemSetting]="analyticsDashboardItemSetting"-->
            <!--                                [events]="events"-->
            <!--                                [subscriptionTypes]="subscriptionTypes"-->
            <!--                                (change)="update(analyticsDashboardItemSetting)">-->
            <!--                            </app-analytics-dashboard-item-setting>-->
            <!--                        </ng-container>-->

            <!--                        <div class="card card-add card-action" (click)="add()">-->
            <!--                            <span class="cm-icon cm-icon-add" aria-hidden="true"></span>-->
            <!--                            <span class="m-t-8">{{('Sale_Plan.New_Rule' | trans)}}</span>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </cm-tab>-->
            <!--            </cm-tab-group>-->
        </ng-container>
    </div>

    <div class="actions">
        <div class="row">
            <div class="col-6">
                <button class="btn btn-primary" (click)="close()">
                    {{('General.Close' | trans)}}
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-cta" (click)="save()">
                    {{('General.Save' | trans)}}
                    <ng-container *ngIf="changedAnalyticsDashboardItemSettings.length > 0">
                        ({{changedAnalyticsDashboardItemSettings.length}})
                    </ng-container>
                </button>
            </div>
        </div>
    </div>
</div>
