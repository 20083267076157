<div class="venue-section" [class.selected]="selected"
     [ngClass]="{'compact': mode === 'COMPACT', 'detailed': mode === 'DETAILED'}">
    <ng-container *ngIf="mode === 'COMPACT'">
        <div class="venue-section-name">{{ venueSection.name }}</div>
        <div
            class="venue-section-stats-compact">{{ venueSection.available }}</div>
    </ng-container>

    <ng-container *ngIf="mode === 'DETAILED'">
        <div class="venue-section-name">{{ venueSection.name }}</div>
        <div class="venue-section-stats-detailed">
            <div class="stat">
                <div class="stat-label">{{ 'Venue_Section_List_Item.Capacity' | trans }}</div>
                <div class="stat-value">{{ venueSection.totalCapacity }}</div>
            </div>
            <div class="stat">
                <div class="stat-label">{{ 'Venue_Section_List_Item.Available' | trans }}</div>
                <div class="stat-value">{{ venueSection.available }}</div>
            </div>
            <div class="stat">
                <div class="stat-label">{{ 'Venue_Section_List_Item.Blocked' | trans }}</div>
                <div class="stat-value">{{ venueSection.venueSectionSeatBlockadeAmount }}</div>
            </div>
        </div>
    </ng-container>
</div>
