<div class="card card-action" [class.closed]="!isExpanded" [draggable]="!isExpanded">
    <div class="card-menu-header" (click)="$event.preventDefault(); $event.stopPropagation(); isExpanded = !isExpanded">
        <div class="row">
            <div class="col-10">
                <div class="row" style="overflow: hidden; height: 40px;">
                    <div class="col-1 d-none d-md-block"></div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('Custom_Field_Setting.Shops' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="customFieldSetting.shopIds.length > 0">
                                {{ customFieldSetting.shopIds.length }}
                            </ng-container>
                            <ng-container *ngIf="customFieldSetting.shopIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('Custom_Field_Setting.Events' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="customFieldSetting.eventIds.length > 0">
                                {{ customFieldSetting.eventIds.length }}
                            </ng-container>
                            <ng-container *ngIf="customFieldSetting.eventIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('Custom_Field_Setting.Ticket_Types' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="customFieldSetting.ticketTypeIds.length > 0">
                                {{ customFieldSetting.ticketTypeIds.length }}
                            </ng-container>
                            <ng-container *ngIf="customFieldSetting.ticketTypeIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div
                            class="f-s-12 f-w-200">{{('Custom_Field_Setting.Subscription_Types' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="customFieldSetting.subscriptionTypeIds.length > 0">
                                {{ customFieldSetting.subscriptionTypeIds.length }}
                            </ng-container>
                            <ng-container *ngIf="customFieldSetting.subscriptionTypeIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 text-right">
                <ng-container *ngIf="isExpanded">
                    <button class="btn btn-secondary-icon">
                        <span class="cm-icon cm-icon-delete" aria-hidden="true"
                              (click)="$event.stopPropagation(); onDeleteCustomFieldSettingClicked()"></span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="card-menu">
        <ng-container *ngFor="let menuItem of menuItems">
            <div class="card-menu-item" (click)="onMenuItemClicked(menuItem)"
                 [class.active]="activeMenuItem === menuItem.value">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-switch">
                            <label class="switch">
                                <input type="checkbox"
                                       [checked]="isSettingEnabled(menuItem)"
                                       (click)="$event.preventDefault()"
                                >
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <span class="form-switch-label label" (click)="$event.preventDefault()">
                            {{ menuItem.translationKey | trans }}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="card-menu-content form-group">
        <ng-container *ngIf="activeMenuItem === 'SHOPS'">
            <div class="row">
                <ng-container *ngFor="let shop of shops">
                    <div class="col-12 col-md-6">
                        <div class="form-check m-b-8">
                            <label>
                                <input type="checkbox"
                                       [checked]="isInSelection('shopIds', shop)"
                                       (change)="onSelectionChanged('shopIds', shop)">
                                <span class="label">{{shop.name}}</span>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="activeMenuItem === 'EVENTS'">
            <div class="row">
                <ng-container *ngFor="let event of events">
                    <div class="col-12 col-md-6">
                        <div class="form-check m-b-8">
                            <label>
                                <input type="checkbox"
                                       [checked]="isInSelection('eventIds', event)"
                                       (change)="onSelectionChanged('eventIds', event)">
                                <span class="label">{{event.name}}</span>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="activeMenuItem === 'TICKET_TYPES'">
            <ng-container *ngIf="!ticketTypes || customFieldSetting.subscriptionTypeIds.length > 0">
                <div class="card-menu-content-disabled">
                    <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                    Only possible when only 1 event is selected, and no subscription types are selected.<br>
                    Create multiple setting blocks if you want to filter ticket types.
                </div>
            </ng-container>

            <ng-container *ngIf="ticketTypes && customFieldSetting.subscriptionTypeIds.length === 0">
                <div class="row">
                    <ng-container *ngFor="let ticketType of ticketTypes">
                        <div class="col-12 col-md-6">
                            <div class="form-check m-b-8">
                                <label>
                                    <input type="checkbox"
                                           [checked]="isInSelection('ticketTypeIds', ticketType)"
                                           (change)="onSelectionChanged('ticketTypeIds', ticketType)">
                                    <span class="label">{{ticketType.name}}</span>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="activeMenuItem === 'SUBSCRIPTION_TYPES'">

            <ng-container *ngIf="customFieldSetting.ticketTypeIds.length > 0">
                <div class="card-menu-content-disabled">
                    <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                    Subscription types cannot be combined with ticket types.<br>
                    Create multiple setting blocks if you want to add subscription types.
                </div>
            </ng-container>

            <ng-container *ngIf="customFieldSetting.ticketTypeIds.length === 0">
                <div class="row">
                    <ng-container *ngFor="let subscriptionType of subscriptionTypes">
                        <div class="col-12 col-md-6">
                            <div class="form-check m-b-8">
                                <label>
                                    <input type="checkbox"
                                           [checked]="isInSelection('subscriptionTypeIds', subscriptionType)"
                                           (change)="onSelectionChanged('subscriptionTypeIds', subscriptionType)">
                                    <span class="label">{{subscriptionType.name}}</span>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

