import {CurrencyPipe} from '@angular/common';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {FormControl, FormControlDirective} from '@angular/forms';
import moment from 'moment';
import {debounceTime, map, tap} from 'rxjs/operators';
import {Event, mapEvent} from '../../models/event.class';
import {Order} from '../../models/order.class';
import {Printer} from '../../models/printer.class';
import {PrintFormat} from '../../models/printFormat.class';
import {Consent} from '../../models/consent.class';
import {VenueSection} from '../../models/venue-section.class';
import {CustomerService} from '../../services/customer-service/customer.service';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {EventService} from '../../services/event-service/event.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {OrderDeliveryService} from '../../services/order-delivery-service/order-delivery.service';
import {OrderManagementService} from '../../services/order-service/order-management.service';
import {PaymentService} from '../../services/payment-service/payment.service';
import {PrintService} from '../../services/print-service/print.service';
import {VenueManagementService} from '../../services/venue-service/venue-management.service';
import {DialogConfirmationComponent} from '../dialogs/dialog-confirmation/dialog-confirmation.component';
import {DialogEditCustomerComponent} from '../dialogs/dialog-edit-customer/dialog-edit-customer.component';
import {Customer, mapCustomer} from '../../models/customer.class';
import {DialogComponent} from '../dialogs/dialog/dialog.component';
import {SeatedApiService} from '../../services/util/seated-api/seated-api.service';
import {OrderTag} from '../../models/order-tag.class';
import {LocaleService} from '../../../core/services/locale.service';
import {getTechnicalLink} from '../../../core/helpers/platform-helper';
import {WindowHelper} from '../../helpers/util/window-helper';
import {PaymentMethod, PaymentMethodEnum} from '../../models/payment-method.class';
import {OrderListService} from '../../../orders/services/order-list.service';
import {LocalReservation} from '../../models/reservation/local-reservation.class';
import {LocalReservationService} from '../../services/local-reservation.service';
import {OrderBarService} from '../../services/order-bar.service';
import {
    DialogOrderAddDiscountComponent
} from '../dialogs/dialog-order-add-discount/dialog-order-add-discount.component';
import {
    mapOrderItemConflictResult,
    OrderItemConflictResult
} from '../../models/order-item-conflict-result/order-item-conflict-result.class';
import {finalize, Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, AfterViewInit, AfterContentInit, OnChanges, OnDestroy {
    public isMobile = WindowHelper.isMobile();
    public error: string;
    @ViewChild('customerForm', {read: FormControlDirective}) customerForm: FormControlDirective;
    @ViewChild('footer') footer;

    localReservation: LocalReservation;

    order: Order;

    customer: Customer;
    kioskMode = false;
    acceptedConsentIds: string[] = [];
    filteredCustomers$: Observable<Customer[]>;
    events: Event[];
    venueSection: VenueSection;
    customerField: FormControl = new FormControl();
    paymentMethods: PaymentMethod[];
    paymentMethodIssuerId: string;
    paymentMethod: PaymentMethod;
    paymentProcessing = false;
    orderDelivery = [];
    expirationDateTime: string;
    selectedExpirationOption: string;
    deliveryPhoneNumber: string;
    deliveryEmail: string;
    deliveryFirstName: string;
    deliveryLastName: string;
    orderTags: any[] = [];
    today = new Date();

    syncedReservation: LocalReservation;

    isAddingOrUpdatingOrder = false;

    // consents
    optIns = [];
    terms = [];

    // Printer
    defaultPrinter: Printer;
    printFormats: PrintFormat[] = [];

    language;

    @ViewChild('newOrderTagDialog') newOrderTagDialog: DialogComponent;

    public step: OrderStep = OrderStep.ORDER;
    public orderStep = OrderStep;

    public expireAtOptions: {
        value: string,
        amount: number | string,
        unit: 'HOUR' | 'DAY' | 'DAYS' | 'WEEK' | 'WEEKS' | 'MONTH' | 'MONTHS' | 'CUSTOM'
    }[];

    private localReservationSubscription: Subscription;
    private syncedReservationSubscription: Subscription;
    private orderSubscription: Subscription;
    private orderBarExpandedSubscription: Subscription;
    private getEventsSubscription: Subscription;

    constructor(private customerService: CustomerService,
                private paymentService: PaymentService,
                private orderManagementService: OrderManagementService,
                private venueManagementService: VenueManagementService,
                private orderDeliveryService: OrderDeliveryService,
                private printService: PrintService,
                private eventService: EventService,
                private currencyPipe: CurrencyPipe,
                private notificationService: NotificationService,
                private dialogService: DialogService,
                private seatedApi: SeatedApiService,
                private localeService: LocaleService,
                private orderListService: OrderListService,
                private orderBarService: OrderBarService,
                private localReservationService: LocalReservationService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.initializeExpireOptions();

        this.seatedApi.getMany<OrderTag>(new OrderTag()).subscribe(orderTags => {
            this.orderTags = orderTags.filter(orderTag => !orderTag.isLocked);
        });

        this.customerField.valueChanges
            .pipe(debounceTime(500))
            .subscribe(search => {
                    if (typeof search === 'string') {
                        this.filteredCustomers$ = this.customerService.getCustomers(new HttpParams().set('customer[search]', search).set('depth', '1')).pipe(
                            map((response: HttpResponse<Customer[]>) => {
                                return response.body;
                            }),
                            finalize(() => this.cdr.detectChanges())
                        );
                    }
                }
            );

        this.localReservationSubscription = this.localReservationService.localReservation$.pipe(
            tap(reservation => {
                this.localReservation = reservation;
                if (reservation?.customer) {
                    this.customer = reservation.customer;
                    this.localReservation.customer = this.customer;
                }

                if (this.step === OrderStep.PAY && !this.orderBarService.isOrderBarExpanded()) {
                    this.navigateToOrderStep();
                }
            })
        ).subscribe();

        this.syncedReservationSubscription = this.localReservationService.syncedReservation$.subscribe(reservation => {
            this.syncedReservation = reservation.clone();
        });

        this.orderSubscription = this.orderManagementService.order$.subscribe(order => {
            this.order = order;
            if (this.order) {
                this.localReservation = LocalReservation.fromOrder(order);
                this.updateOrder(true);
            }
        });

        this.getEventsSubscription =
            this.eventService.getEvents(new HttpParams().set('event[after]', moment().toISOString()).set('depth', '1')).subscribe(response => {
                this.events = response.body.map(event => mapEvent(event));
                // Select default value for dropdown
                if (this.events[0]) {
                    this.selectedExpirationOption = this.events[0].startAt;
                }
            });

        const technicalLinkId = getTechnicalLink();
        this.kioskMode = (localStorage.getItem(`seatedDashboard.${technicalLinkId}.order.kioskMode`) === 'true');

        if (this.kioskMode && localStorage.getItem(`seatedDashboard.${technicalLinkId}.order.customer`)) {
            this.setCustomer(mapCustomer(JSON.parse(localStorage.getItem(`seatedDashboard.${technicalLinkId}.order.customer`))));
        }

        this.defaultPrinter = this.printService.getDefaultPrinter();
        this.printFormats = this.printService.getPrintFormats();
        this.language = this.localeService.getLanguage();
    }

    openDiscountDialog() {
        this.dialogService.createDialogComponent(DialogOrderAddDiscountComponent, {
            extraData: {
                order: this.order,
            }
        }, 'order-add-discount');
    }

    deleteOrderDiscount(discountId: string) {
        this.orderManagementService.deleteOrderDiscount(discountId);
    }

    private initializeExpireOptions(): void {
        this.expireAtOptions = [
            {value: moment().add(1, 'hours').toISOString(), amount: 1, unit: 'HOUR'},
            {value: moment().add(1, 'day').toISOString(), amount: 1, unit: 'DAY'},
            {value: moment().add(3, 'days').toISOString(), amount: 3, unit: 'DAYS'},
            {value: moment().add(5, 'days').toISOString(), amount: 5, unit: 'DAYS'},
            {value: moment().add(1, 'week').toISOString(), amount: 1, unit: 'WEEK'},
            {value: moment().add(2, 'weeks').toISOString(), amount: 2, unit: 'WEEKS'},
            {value: moment().add(1, 'month').toISOString(), amount: 1, unit: 'MONTH'},
            {value: moment().add(2, 'months').toISOString(), amount: 2, unit: 'MONTHS'},
            {value: moment().add(3, 'months').toISOString(), amount: 3, unit: 'MONTHS'},
            {value: moment().add(6, 'months').toISOString(), amount: 6, unit: 'MONTHS'},
            {value: moment().add(12, 'months').toISOString(), amount: 12, unit: 'MONTHS'},
            {value: 'CUSTOM', amount: '', unit: 'CUSTOM'}
        ];
    }

    public isNextButtonDisabled(): boolean {
        switch (this.step) {
            case OrderStep.ORDER:
                return (!this.customer || !this.localReservation?.groupedOrderItems.length || this.isAddingOrUpdatingOrder);
            case OrderStep.PAY:
                return !(this.paymentMethod || this.order.status === 'COMPLETE' || this.order.status === 'OPEN') || this.paymentProcessing || this.customExpireValidation();
            case OrderStep.SEND:
                return this.orderDelivery['PRINT'] && !this.defaultPrinter;
        }

        return false;
    }

    public nextButtonClick(): void {
        switch (this.step) {
            case OrderStep.ORDER:
                this.addOrUpdateOrder();
                return;
            case OrderStep.PAY:
                this.addPayment();
                return;
            case OrderStep.SEND:
                this.completeOrder();
                return;
        }
    }

    private navigateToOrderStep(): void {
        const parsedLocalReservation = JSON.parse(JSON.stringify(this.localReservation));
        this.syncedReservation = new LocalReservation(parsedLocalReservation.customer, parsedLocalReservation.groupedOrderItems);
        this.step = OrderStep.ORDER;

        setTimeout(() => {
            this.customerForm?.valueAccessor.writeValue(this.customer.getName());
        });
    }

    public previousButtonClick(): void {
        switch (this.step) {
            case OrderStep.PAY:
                this.navigateToOrderStep();
                return;
            case OrderStep.SEND:
                this.step = OrderStep.PAY;
                return;
        }
    }

    expireOptionChanged(option): void {
        this.selectedExpirationOption = option.detail.value;
        this.expirationDateTime = this.selectedExpirationOption === 'CUSTOM' ? this.expirationDateTime : this.selectedExpirationOption;
    }

    customExpireDateTimeChanged(event): void {
        this.expirationDateTime = event.detail.datetime;
    }

    customExpireValidation(): boolean {
        if (this.paymentMethod.method !== 'RESERVE') {
            return false;
        }

        if (!this.expirationDateTime) {
            this.error = 'Custom_Field.Is_Required';
            return true;
        }

        const isExpired = this.expirationDateTime <= new Date().toISOString();
        this.error = isExpired ? 'Error.Date_Should_Be_In_The_Future' : '';
        return isExpired;
    }

    private distinguishConsents(consents: Consent[]): void {
        if (!consents || consents.length === 0) {
            return;
        }

        this.terms = consents.filter(consent => consent.isRequired);
        this.optIns = consents.filter(consent => !consent.isRequired);
    }

    ngAfterViewInit() {
        if (this.customerForm && this.customer) {
            this.customerForm.valueAccessor.writeValue(this.customer.getName());
            this.customerForm.control.setValue(this.customer, {
                emitModelToViewChange: false
            });
        }
    }

    ngAfterContentInit() {
        if (this.order?.customer) {
            this.customer = this.order.customer;
        }
    }

    private updateOrder(updateSyncedReservation = false): void {
        if (!this.order) {
            return;
        }

        if (this.order.customer && this.customerForm) {
            this.setCustomer(this.order.customer, updateSyncedReservation);
        }

        for (const consent of this.order.consents) {
            if (consent.isRequired || consent.isAccepted) {
                this.updateConsentSelection(consent, true);
            }
        }

        this.distinguishConsents(this.order.consents);
    }

    ngOnChanges() {
        this.updateOrder();
    }

    deleteOrder() {
        if (this.order) {
            this.dialogService.createDialogComponent(DialogConfirmationComponent, {
                titleText: {
                    key: 'Order.Delete'
                },
                bodyText: {key: 'Order.Delete.Confirm_Body'},
                cancelText: 'Dialog.Cancel',
                confirmText: 'Dialog.Ok'
            }, 'order-delete');

            this.dialogService.emitDataSubject.subscribe(response => {
                if (!response.cancelled) {
                    this.orderManagementService.deleteOrder(this.order.id);
                    this.localReservationService.resetLocalReservation();
                    this.orderBarService.setOrderBarExpanded(false);
                }
            });
            return;
        }

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {
                key: 'Reservation.Delete'
            },
            bodyText: {key: 'Reservation.Delete.Confirm_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'order-delete');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.localReservationService.resetLocalReservation();
                this.orderBarService.setOrderBarExpanded(false);
                this.notificationService.showTranslatedSuccessNotification('order', 'deleted');
            }
        });
    }

    addOrUpdateOrder() {
        this.isAddingOrUpdatingOrder = true;

        this.deliveryPhoneNumber = this.customer.phoneNumber;
        this.deliveryEmail = this.customer.email;

        if (this.kioskMode) {
            localStorage.setItem(`seatedDashboard.${getTechnicalLink()}.order.customer`, JSON.stringify(this.customer));
        }

        this.paymentMethod = undefined;
        this.paymentService.getPaymentMethods()
            .subscribe((paymentMethods: PaymentMethod[]) => {
                this.paymentMethods = paymentMethods;
            });

        const onComplete = () => {
            this.isAddingOrUpdatingOrder = false;
            this.step = OrderStep.PAY;
            this.localReservationService.setLocalReservation(this.localReservation, true);
            // Get deleted order item updates
            this.orderManagementService.refreshSelectedOrder();
        };

        const onError = (error) => {
            const conflictErrorCodes = [4002, 400129];
            if (conflictErrorCodes.indexOf(error.code) > -1 && error.details?.length > 0) {
                this.setConflicts(error.details.map(conflict => mapOrderItemConflictResult(conflict)));
                this.notificationService.showTranslatedErrorNotification('order', 'conflicts_occurred');
            } else if (error.code === 400106) {
                this.notificationService.showTranslatedErrorNotification('order', 'max_item_limit_reached');
            } else {
                this.notificationService.showTranslatedNotification('error', 'order', 'update_failed');
            }

            this.isAddingOrUpdatingOrder = false;
        };

        if (this.localReservation?.orderId) {
            this.orderManagementService.processReservationDifferences(this.localReservationService)
                .subscribe({
                        complete: () => {
                            onComplete();
                        },
                        error: err => {
                            const error = err.error;
                            onError(error);
                        }
                    }
                );
            return;
        }

        this.orderManagementService.addOrder(() => {
            const requestBody = this.localReservationService.collectAddOrderItemsRequestBody(
                this.localReservationService.getGroupedOrderItems()
            );

            this.orderManagementService.addOrderItemsSimple(requestBody).subscribe({
                complete: () => {
                    this.orderManagementService.updateOrder({
                        customerId: this.customer.id
                    }, () => {
                        onComplete();
                    });
                },
                error: (err) => {
                    const error = err.error;
                    this.orderManagementService.deleteOrderSimple().subscribe();
                    onError(error);
                }
            });
        }, false);
    }

    setConflicts(conflictDetails: OrderItemConflictResult[]): void {
        this.localReservationService.setLocalReservation(this.localReservationService.setReservationConflicts(conflictDetails));
    }

    getDiscountLabel(): string {
        return this.order?.discounts?.length === 0 ? 'Order_Table.Add_Discount' : 'Order_Table.Edit_Discount';
    }

    addPayment() {
        if (this.order.status === 'COMPLETE' || this.order.status === 'OPEN') {
            this.step = OrderStep.SEND;
            return;
        }

        const orderItems = [];
        for (const groupedItem of this.orderManagementService.order.groupedOrderItems) {
            orderItems.concat(groupedItem.items);
        }

        this.orderManagementService.updateOrder({
            orderTags: this.order.orderTags ? this.order.orderTags.map(orderTag => orderTag.id) : [],
            campaignId: this.order.campaignId
        });

        if (!this.paymentProcessing && !this.order.payedAt &&
            (this.paymentMethod.method === PaymentMethodEnum.PIN || this.paymentMethod.method === PaymentMethodEnum.CASH)
        ) {
            this.paymentProcessing = true;

            this.dialogService.createDialogComponent(DialogConfirmationComponent, {
                titleText: {
                    key: 'Dialog.Order_Payment_Fulfilled_Title',
                    tags: [{
                        key: 'paymentMethod',
                        value: this.paymentMethod.name
                    }
                    ]
                },
                bodyText: {
                    key: 'Dialog.Order_Payment_Fulfilled_Body',
                    tags: [{
                        key: 'totalPrice',
                        value: this.currencyPipe.transform(this.order.getPrice())
                    }
                    ]
                },
                cancelText: 'Dialog.Cancel',
                confirmText: 'Dialog.Ok'
            }, 'payment');

            this.dialogService.emitDataSubject.subscribe(response => {
                if (!response.cancelled) {
                    this.acceptPayment(this, orderItems);
                } else {
                    this.paymentProcessing = false;
                }
            });

        } else if (!this.paymentProcessing && !this.order.payedAt && this.paymentMethod.method !== PaymentMethodEnum.RESERVE) {
            this.paymentProcessing = true;
            this.acceptPayment(this, orderItems);
        }

        if (this.paymentMethod && this.paymentMethod.method === PaymentMethodEnum.RESERVE) {
            this.venueManagementService.fetchVenueSectionSeats(orderItems);
            this.paymentProcessing = true;
            this.orderManagementService.updateOrder({
                customerId: this.customer.id,
                expireAt: this.expirationDateTime
            }, () => {
                this.orderBarService.setOrderBarExpanded(false);
                this.localReservationService.resetLocalReservation();
                this.orderManagementService.unsetOrder();
                this.notificationService.showTranslatedNotification('success', 'order', 'reserved');
                this.paymentProcessing = false;
                this.orderListService.store.refresh();
            });
        }
    }

    acceptPayment(context, orderItems) {
        const body = {
            orderId: context.order.id,
            customer: context.customer,
            acceptedConsentIds: context.acceptedConsentIds,
            paymentMethod: {
                method: context.paymentMethod.method,
                issuerId: context.paymentMethodIssuerId
            }
        };

        context.paymentService.postPayment(body).subscribe({
            next: (payment) => {
                context.venueManagementService.fetchVenueSectionSeats(orderItems);
                context.order.payedAt = payment.payedAt;

                if (payment.status === 'SUCCESS') {
                    context.order.status = 'COMPLETE';
                }

                if (payment.status === 'OPEN') {
                    context.order.status = 'OPEN';
                }

                context.paymentProcessing = false;
                setTimeout(() => {
                    // Bug fix dont move to next step
                    context.step = OrderStep.SEND;
                }, 25);
            },
            error: (error) => {
                context.paymentProcessing = false;
                throw error;
            }
        });
    }

    completeOrder() {
        const order = this.orderManagementService.order;
        for (const orderDeliveryMethod of this.orderDelivery) {
            if (orderDeliveryMethod === 'PRINT') {
                this.printService.print(this.defaultPrinter.type, {
                    orderId: order.id,
                    method: this.defaultPrinter.type,
                    phoneNumber: this.deliveryPhoneNumber,
                    email: this.deliveryEmail,
                    firstName: this.deliveryFirstName,
                    lastName: this.deliveryLastName
                });
            } else {
                this.orderDeliveryService.postOrderDelivery({
                    orderId: order.id,
                    method: orderDeliveryMethod,
                    phoneNumber: this.deliveryPhoneNumber,
                    email: this.deliveryEmail,
                    firstName: this.deliveryFirstName,
                    lastName: this.deliveryLastName
                }).subscribe(orderDelivery => {
                    if (orderDeliveryMethod === 'DOWNLOAD_PDF') {
                        (window as any).open(orderDelivery.url, '_blank');
                    }
                });
            }
        }

        this.notificationService.showTranslatedNotification('success', 'order', 'processed');

        this.orderManagementService.unsetOrder();
        this.localReservationService.resetLocalReservation();
        this.orderBarService.setOrderBarExpanded(false);
    }

    setDefaultPrinter() {
        this.printService.setDefaultPrinter(this.defaultPrinter);
    }

    updateConsentSelection(consent: Consent, forceAdd: boolean = false) {
        const index = this.acceptedConsentIds.findIndex(item => {
            return item === consent.id;
        }, 0);

        if (index >= 0 && !forceAdd) {
            this.acceptedConsentIds.splice(index, 1);
        }

        if (index < 0) {
            this.acceptedConsentIds.push(consent.id);
        }
    }

    printerSelected(defaultPrinter, printer) {
        return defaultPrinter && printer && defaultPrinter.id === printer.id && defaultPrinter.name === printer.name && defaultPrinter.type === printer.type;
    }

    switchKioskMode(event) {
        this.kioskMode = event.detail.checked;
        localStorage.setItem(`seatedDashboard.${getTechnicalLink()}.order.kioskMode`, (this.kioskMode).toString());
    }

    changeCustomer(customerId: string) {
        const valid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(customerId);
        if (!valid) {
            this.setCustomer(null);
            return;
        }

        this.customerService.getCustomer(customerId).subscribe(response => {
            this.setCustomer(response);
            if (this.order) {
                this.order.customer = this.customer;
            }
        });
    }

    private setCustomer(customer?: Customer, updateSyncedReservation = false): void {
        this.customer = customer;
        this.localReservation.customer = this.customer;
        this.localReservationService.setLocalReservation(this.localReservation, updateSyncedReservation);

        if (this.customerForm) {
            this.customerForm.valueAccessor.writeValue(this.customer?.getName());
            this.customerForm.control.setValue(this.customer, {
                emitModelToViewChange: false
            });
        }
    }

    updateOrderDeliverySelection(value: string) {
        const index = this.orderDelivery.findIndex(item => {
            return item === value;
        }, 0);
        if (index >= 0) {
            this.orderDelivery.splice(index, 1);
        } else {
            this.orderDelivery.push(value);
        }
    }

    inOrderDeliverySelection(value: string) {
        return this.orderDelivery.findIndex(item => {
            return item === value;
        }) >= 0;
    }

    openEditCustomerDialog() {
        this.dialogService.createDialogComponent(DialogEditCustomerComponent, {
            cancelText: 'Cancel',
            confirmText: 'Add'
        }, 'edit-customer');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.changeCustomer((response as any).data.extraData.id);
            }
        });
    }

    onOrderTagChange(orderTagChangeEvent: any): void {
        const orderTags = this.order.orderTags;
        const orderTagId = orderTagChangeEvent.detail.value;

        const orderTagIndex = orderTags.findIndex(orderTag => orderTag.id === orderTagId);

        if (orderTagIndex > -1) {
            orderTags.splice(orderTagIndex, 1);
        } else {
            orderTags.push(this.orderTags.find(orderTag => orderTag.id === orderTagId));
        }

        this.order.orderTags = orderTags;
    }

    onAddOrderTagClick(): void {
        this.newOrderTagDialog.open();
    }

    onNewOrderTagSave(newOrderTagData: any): void {
        const newOrderTag = (new OrderTag()).mapModel(newOrderTagData);

        this.seatedApi.post<OrderTag>(newOrderTag, {}, newOrderTag).subscribe(orderTag => {
            this.orderTags.push(orderTag);
            this.onOrderTagChange({detail: {value: orderTag.id}});
        });
    }

    public getStepIndex(): number {
        return Object.keys(OrderStep).findIndex(step => step === this.step);
    }

    paymentMethodChanged(method: PaymentMethodEnum) {
        if (method === PaymentMethodEnum.RESERVE) {
            this.expirationDateTime = this.selectedExpirationOption === 'CUSTOM' ? moment().add(2, 'weeks').toISOString() : this.selectedExpirationOption;
            return;
        }

        this.expirationDateTime = null;
    }

    campaignChanged(campaignId: string) {
        this.order.campaignId = campaignId;
    }

    ngOnDestroy(): void {
        this.orderSubscription?.unsubscribe();
        this.getEventsSubscription?.unsubscribe();
        this.syncedReservationSubscription?.unsubscribe();
        this.localReservationSubscription?.unsubscribe();
        this.orderBarExpandedSubscription?.unsubscribe();
    }
}

export enum OrderStep {
    ORDER = 'ORDER',
    PAY = 'PAY',
    SEND = 'SEND'
}
