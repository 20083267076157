<div class="search-filter-container">
    <ng-container *ngFor="let setting of settings">
        <ng-container *ngIf="setting.isPrimary">
            <ng-container *ngIf="setting.type === 'DATE_RANGE'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.dateRange"
                                   type="DATE_RANGE">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'STATUS'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.status"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: statusOptions}]"
                                   optionLabel="label"
                                   optionValue="value">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'PAYMENT_METHOD'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.paymentMethodIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: paymentMethodOptions}]"
                                   optionLabel="label"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'IMPORTED'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.importIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: importedOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'PAYOUT'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.payoutIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: payoutOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'EVENT_CATEGORIES'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.eventCategoryIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: eventCategoryOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'PERSON'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="onFilterUpdateEvent()"
                                   [(selectedOptions)]="selected.personIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: personOptions}]"
                                   optionLabel="label"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'EVENT'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="onFilterUpdateEvent()"
                                   [(selectedOptions)]="selected.eventIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: eventOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'TICKET_TYPE' && selected.eventIds.length > 0">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.ticketTypeIds"
                                   [options]="ticketTypeOptions"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'SUBSCRIPTION_TYPE'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="onFilterUpdateSubscriptionType()"
                                   [(selectedOptions)]="selected.subscriptionTypeIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: subscriptionTypeOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'SUBSCRIPTION_TYPE_PRICE' && selected.subscriptionTypeIds.length > 0">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.subscriptionTypePriceIds"
                                   [options]="subscriptionTypePriceOptions"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'PRODUCT_TYPE'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="onFilterUpdateProductType()"
                                   [(selectedOptions)]="selected.productTypeIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: productTypeOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'PRODUCT_TYPE_PRICE' && selected.productTypeIds.length > 0">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.productTypePriceIds"
                                   [options]="productTypePriceOptions"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'VENUE_SECTION' && (selected.eventIds.length > 0 || selected.subscriptionTypeIds.length > 0)">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.venueSectionIds"
                                   [options]="venueSectionOptions"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'CUSTOMER_TAG'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.customerTagIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: customerTagOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'ORDER_TAG'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.orderTagIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: orderTagOptions}]"
                                   optionLabel="title"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'SHOP'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.shopIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: shopOptions}]"
                                   optionLabel="title"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'CAMPAIGN'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.campaignIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: campaignOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'DISCOUNT_CAMPAIGN'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.discountCampaignIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: discountCampaignOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'START_AT'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.discountCampaignIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans)}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'END_AT'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.discountCampaignIds"
                                   [options]="[{name: (setting.nameTranslationKey | trans)}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'ACTIVITY_LOG_EVENT'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.activityLogEvents"
                                   [options]="[{name: (setting.nameTranslationKey | trans), options: activityLogEventOptions}]"
                                   optionLabel="name"
                                   optionValue="id">
                </app-search-filter>
            </ng-container>

            <ng-container *ngIf="setting.type === 'CHECKED_IN'">
                <app-search-filter-boolean [name]="(setting.nameTranslationKey | trans)"
                                           [(toggled)]="selected.checkedIn"
                                           (toggledChange)="change()"></app-search-filter-boolean>
            </ng-container>

            <ng-container *ngIf="setting.type === 'EXPIRE_DATE_RANGE'">
                <app-search-filter [name]="(setting.nameTranslationKey | trans)"
                                   (selectedOptionsChange)="change()"
                                   [(selectedOptions)]="selected.expireDateRange"
                                   type="EXPIRE_DATE_RANGE">
                </app-search-filter>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="showAddAdditionalFilters()">
        <div class="filter-add">
            <cm-context-menu>
                <span class="cm-icon cm-icon-add cm-icon-hover" slot="icon" aria-hidden="true"></span>

                <ng-container *ngFor="let setting of settings">
                    <ng-container *ngIf="!setting.isPrimary">
                        <cm-option
                            (cm-option-clicked)="setting.isPrimary = true"
                            [attr.data-display]="(setting.nameTranslationKey | trans)">
                        </cm-option>
                    </ng-container>
                </ng-container>
            </cm-context-menu>
        </div>
    </ng-container>
</div>
