<cm-table data-name="table">
    <cm-table-head>
        <cm-table-row>
            <ng-container *ngIf="isCheckboxes">
                <cm-table-header data-action-center>
                    <div class="form-group">
                        <div class="form-row example">
                            <div class="form-check">
                                <label>
                                    <input type="checkbox"
                                           [class.pseudo-state-indeterminate]="areSomeSelected() ? true : null"
                                           [checked]="isAllCheckboxChecked()"
                                           (click)="checkboxesDirective.onHeadCheckboxChange($event)">
                                    <span class="label"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </cm-table-header>
            </ng-container>
            <ng-container *ngTemplateOutlet="headers || defaultHeader; context: { $implicit: data }"></ng-container>
        </cm-table-row>
    </cm-table-head>

    <cm-table-body>
        <ng-container *ngIf="data && !isLoading">
            <ng-container *ngFor="let row of data">
                <cm-table-row
                    [attr.data-selected]="isRowSelected(row)"
                    (click)="onRowClick($event, row)">
                    <ng-container *ngIf="isCheckboxes">
                        <cm-table-data data-action-center>
                            <div class="form-group">
                                <div class="form-row example">
                                    <div class="form-check">
                                        <label>
                                            <input type="checkbox"
                                                   [checked]="isRowSelected(row)"
                                                   (change)="checkboxesDirective.onRowCheckboxChange($event, row)">
                                            <span class="label"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </cm-table-data>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="rows || defaultRow; context: { $implicit: row }"></ng-container>
                </cm-table-row>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isLoading">
            <ng-container *ngFor="let i of loadingRowArray">
                <cm-table-row>
                    <ng-container *ngIf="isCheckboxes">
                        <cm-table-data data-is-loading></cm-table-data>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="loadingRows || defaultLoadingRows"></ng-container>
                </cm-table-row>
            </ng-container>
        </ng-container>
    </cm-table-body>
</cm-table>

<ng-template #defaultHeader let-data>
    <ng-container *ngFor="let header of data[0] | keyvalue:originalOrder">
        <cm-table-header>{{ header.key }}</cm-table-header>
    </ng-container>
</ng-template>

<ng-template #defaultRow let-row>
    <ng-container *ngFor="let cell of row | keyvalue:originalOrder">
        <cm-table-data>{{ cell.value }}</cm-table-data>
    </ng-container>
</ng-template>

<ng-template #defaultLoadingRows>
    <cm-table-data data-is-loading></cm-table-data>
    <cm-table-data data-is-loading></cm-table-data>
    <cm-table-data data-is-loading></cm-table-data>
</ng-template>
