import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, concat} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';
import {Import} from '../../models/import.class';
import {Router} from '@angular/router';
import {NotificationService} from '../notification-service/notification.service';

@Injectable({
    providedIn: 'root'
})
export class ImportService {

    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    private postImportBody: any;
    private postImportSettingsBody: any;
    private postImportMappingBody: any;
    private postImportRowsBody: any;

    private uploadPercentageBehaviorSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    uploadPercentage$ = this.uploadPercentageBehaviorSubject.asObservable();

    private chunkSize = 1000;
    private totalChunks = 0;
    private chunksDone = 0;

    constructor(private http: HttpClient, private router: Router, private notificationService: NotificationService) {
    }

    getImport(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Import>(this.baseUrl + `/v2.0/import/${importId}`, this.config);
    }

    getImports(params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Import[]>(this.baseUrl + `/v2.0/import`, {...config, observe: 'response'});
    }

    getImportStatistics(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Import[]>(this.baseUrl + `/v2.0/import/${importId}/statistics`, config);
    }

    getImportRows(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<any[]>(this.baseUrl + `/v2.0/import/${importId}/row`, {...config, observe: 'response'});
    }

    postImport(params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post(this.baseUrl + `/v2.0/import`, this.postImportBody, config).pipe(
            concatMap((response: any) => {

                const postRequests = [
                    this.http.post(this.baseUrl + `/v2.0/import/${response.id}/setting/bulk`, this.postImportSettingsBody, config),
                    this.http.post(this.baseUrl + `/v2.0/import/${response.id}/mapping/bulk`, this.postImportMappingBody, config)
                ];

                const chunks = [];
                for (let i = 0; i < this.postImportRowsBody.length; i += this.chunkSize) {
                    chunks.push(this.postImportRowsBody.slice(i, i + this.chunkSize));
                }

                this.totalChunks = chunks.length;

                chunks.forEach((chunk, i) => {
                    postRequests.push(this.http.post(this.baseUrl + `/v2.0/import/${response.id}/row/bulk`, chunk, config).pipe(
                        tap(_ => {
                            this.chunksDone = i + 1;
                            this.uploadPercentageBehaviorSubject.next(Math.round((i / chunks.length) * 100));
                        })
                    ));
                });

                return concat(...postRequests).pipe(
                    map(_ => response)
                );
            }),
            tap(response => {
                if (this.chunksDone === this.totalChunks) {
                    this.uploadPercentageBehaviorSubject.next(100);
                    setTimeout(() => {
                        // Show the user that we got to 100%
                        this.notificationService.showTranslatedNotification('success', 'import', 'added');
                        this.router.navigate(['/settings', 'imports', response.id]);
                    }, 2000);
                }
            })
        );
    }

    patchImport(importId: string, body, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Import>(this.baseUrl + `/v2.0/import/${importId}`, body, config);
    }

    deleteImport(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.delete<Import>(this.baseUrl + `/v2.0/import/${importId}`, config);
    }

    setPostImportBody(name: string, fileName: string): void {

        this.postImportBody = {
            name,
            filename: fileName
        };
    }

    setPostImportSettingsBody(groups: any[]): void {
        const settings: any[] = [];

        groups.forEach(columnGroup => {
            columnGroup.settings?.filter(setting => !!setting.value && setting.value !== 'null')
                .forEach(setting => {
                    settings.push({
                        settingId: setting.field,
                        value: setting.value
                    });
                });
        });

        this.postImportSettingsBody = settings;
    }

    setPostImportMappingBody(importMappingPrimary: any[], importMappingSecondary: any[], importId?: string): void {
        let importMappings = importMappingPrimary;

        for (const secondaryMapping of importMappingSecondary) {
            importMappings = importMappings.concat(secondaryMapping.mapping);
        }

        importMappings = importMappings.filter(mapping => mapping.inputColumn && mapping.outputType && mapping.outputColumn);

        for (const importMapping of importMappings) {
            importMapping.importId = importId ?? null;
        }

        this.postImportMappingBody = importMappings;
    }

    setPostImportRowsBody(rows: any[]): void {
        this.postImportRowsBody = rows.map(row => {
            return {content: row};
        });
    }

    getImportMapping(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<any>(this.baseUrl + `/v2.0/import/${importId}/mapping`, config);
    }

    updateImportMapping(importId: string, params?: HttpParams) {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch(this.baseUrl + `/v2.0/import/${importId}/mapping/bulk`, this.postImportMappingBody, config);
    }
}
