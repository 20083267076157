<div class="stepper">
    <cm-stepper [attr.data-current-step]="getStepIndex()">
        <cm-stepper-step slot="stepper-step" data-display="Order"></cm-stepper-step>
        <cm-stepper-step slot="stepper-step" data-display="Payment"></cm-stepper-step>
        <cm-stepper-step slot="stepper-step" data-display="Send"></cm-stepper-step>
    </cm-stepper>
</div>

<div class="content">
    <ng-container *ngIf="step === orderStep.ORDER">
        <div class="step-order">
            <div class="customer">
                <div class="row">
                    <div [ngClass]="isMobile ? 'col-10' : 'col-3'">
                        <div class="form-group">
                            <div class="form-row">
                                <div class="form-input-special">
                                    <label>{{ ('Order.Customer' | trans) }}</label>
                                    <input placeholder="{{('Order.Customer' | trans)}}"
                                           class="form-control"
                                           id="customerAutocomplete"
                                           #customerForm="ngForm"
                                           [formControl]="customerField"
                                           (change)="changeCustomer($event.target.value)">
                                    <span class="input-left icon">
                        <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
                    </span>
                                    <cm-autocomplete data-linked-input="input#customerAutocomplete"
                                                     (cm-option-clicked)="changeCustomer($event.detail.value)">
                                        <ng-container *ngIf="filteredCustomers$ | async as customers">
                                            <cm-option *ngIf="customers.length === 0" slot="option" disabled="true"
                                                       [attr.data-display]="('General.No_Results' | trans)"></cm-option>
                                            <ng-container *ngFor="let customer of customers">
                                                <cm-option slot="option"
                                                           [attr.value]="customer.id"
                                                           [attr.data-display]="customer.getName()"
                                                           data-use-html="true">
                                                    <div class="row select-option">
                                                        <div class="col-2 text-right p-r-8">
                                            <span
                                                *ngIf="customer.guestManagerOrganisationId"
                                                class="cm-icon cm-icon-group-open"
                                                aria-hidden="true"></span>
                                                            <span *ngIf="customer.type === 'PERSON'"
                                                                  class="cm-icon cm-icon-user-open"
                                                                  aria-hidden="true"></span>
                                                            <span *ngIf="customer.type === 'COMPANY'"
                                                                  class="cm-icon cm-icon-company"
                                                                  aria-hidden="true"></span>
                                                        </div>
                                                        <div class="col-10">
                                                            <span class="f-s-14">{{ customer.getName() }}</span><br>
                                                            <span class="f-s-12">{{ customer.getExtraDetails() }}</span>
                                                        </div>
                                                    </div>
                                                </cm-option>
                                            </ng-container>
                                        </ng-container>
                                    </cm-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right m-l-8">
                        <cm-button
                            class="add-customer-button"
                            data-icon="add"
                            data-button-style="primary"
                            data-button-type="icon"
                            (click)="openEditCustomerDialog()">
                        </cm-button>
                    </div>
                </div>
            </div>
            <div class="order">
                @if (!isAddingOrUpdatingOrder) {
                    <app-basket-order-table></app-basket-order-table>
                } @else {
                    <cm-indicator [attr.data-label]="('Loading.Processing_Order' | trans)"></cm-indicator>
                }
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === orderStep.PAY">
        <div class="step-pay">
            <div class="row form-group p-t-32 content">
                <div class="col-12 col-md-5 d-none d-md-block">
                    <ng-container *ngIf="customer">
                        <cm-expansion-panel-container data-multiple-open>
                            <cm-expansion-panel>
                                <cm-expansion-panel-header data-multi-open
                                                           data-label="Customer"></cm-expansion-panel-header>
                                <cm-expansion-panel-content slot="content">
                                    <div class="datatable customer">
                                        <div class="datatable-row">
                                            <div class="datatable-row-item col-3">
                                                {{ ('Order.Name' | trans) }}
                                            </div>
                                            <div class="datatable-row-item col-7">
                                                {{ customer.firstName }} {{ customer.insertion }}
                                                {{ customer.lastName }}
                                            </div>
                                        </div>

                                        <div class="datatable-row">
                                            <div class="datatable-row-item col-3">
                                                {{ ('Order.Address' | trans) }}
                                            </div>
                                            <div class="datatable-row-item col-7">
                                                {{ customer.street }} {{ customer.houseNumber }}
                                                <ng-container
                                                    *ngIf="(customer.street || customer.houseNumber) && (customer.postalCode || customer.city)">
                                                    ,
                                                </ng-container>
                                                {{ customer.postalCode }} {{ customer.city }}
                                            </div>
                                        </div>

                                        <div class="datatable-row">
                                            <div class="datatable-row-item col-3">
                                                {{ ('Order.Email' | trans) }}
                                            </div>
                                            <div class="datatable-row-item col-7">
                                                {{ customer.email }}
                                            </div>
                                        </div>

                                        <div class="datatable-row">
                                            <div class="datatable-row-item col-3">
                                                {{ ('Order.Phone_Number' | trans) }}
                                            </div>
                                            <div class="datatable-row-item col-7">
                                                {{ customer.phoneNumber }}
                                            </div>
                                        </div>
                                    </div>
                                </cm-expansion-panel-content>
                            </cm-expansion-panel>
                            <cm-expansion-panel data-is-open>
                                <cm-expansion-panel-header data-multi-open
                                                           data-label="Order ({{order.getPrice() | currency}})">
                                </cm-expansion-panel-header>
                                <cm-expansion-panel-content slot="content">

                                    <div class="datatable">
                                        <ng-container *ngFor="let orderItemGroup of order.groupedOrderItems">
                                            <ng-container *ngFor="let orderItem of orderItemGroup.items">
                                                <div class="datatable-row order-item-price">
                                                    <div class="datatable-row-item col-9">

                                                        <ng-container *ngIf="orderItem.ticket">
                                                            {{ orderItem.ticket.name }}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="orderItem.product">{{ orderItem.product.name }}
                                                        </ng-container>
                                                        <ng-container *ngIf="orderItem.subscription">
                                                            {{ orderItem.subscription.name ?? orderItem.subscription.subscriptionTypeName }}
                                                        </ng-container>
                                                    </div>
                                                    <div class="datatable-row-item col-3 text-right">
                                                        {{ orderItem.price | currency }}
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngFor="let costItemAgreement of orderItem.additionalCosts">
                                                    <ng-container *ngIf="costItemAgreement.amount > 0">
                                                        <div class="datatable-row fee">
                                                            <div class="datatable-row-item col-10">
                                                                {{ costItemAgreement.label }}
                                                            </div>
                                                            <div class="datatable-row-item col-2 text-right">
                                                                + {{ costItemAgreement.amount | currency }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let costItemAgreement of order.additionalCosts">
                                            <ng-container *ngIf="costItemAgreement.amount > 0">
                                                <div class="datatable-row">
                                                    <div class="datatable-row-item col-10">
                                                        {{ costItemAgreement.label }}
                                                    </div>
                                                    <div class="datatable-row-item col-2 text-right">
                                                        + {{ costItemAgreement.amount | currency }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngFor="let discount of order.discounts">
                                            <div class="datatable-row discount">
                                                <div class="datatable-row-item col-8">
                                                    {{ discount.name }}
                                                </div>
                                                <div class="datatable-row-item col-3 text-right cl-success-color">
                                                    - {{ discount.amount | currency }}
                                                </div>
                                                <div class="datatable-row-item col-2">
                                                    <cm-button
                                                        *ngIf="!order.payedAt"
                                                        class="delete"
                                                        data-icon="delete"
                                                        data-button-style="secondary"
                                                        data-button-type="icon"
                                                        data-button-size="small"
                                                        (click)="deleteOrderDiscount(discount.id)">
                                                    </cm-button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="order-total-amount">
                                        <div class="order-total-amount-header">
                                            {{ ('Order.Total_Amount' | trans) }}
                                        </div>
                                        <div class="order-total-amount-number">
                                            {{ order.getPrice() | currency }}
                                        </div>
                                    </div>
                                </cm-expansion-panel-content>
                            </cm-expansion-panel>
                        </cm-expansion-panel-container>
                    </ng-container>
                </div>
                <div class="col-12 col-md-3 offset-md-1">
                    <div class="section">
                        <h3>{{ ('Order.Discount' | trans) }}</h3>
                        <cm-button [attr.data-label]="(getDiscountLabel() | trans)"
                                   data-icon="tag"
                                   data-button-style="cta"
                                   data-button-type="icon-text"
                                   data-button-style="secondary"
                                   (click)="openDiscountDialog()">
                        </cm-button>
                    </div>
                    <div class="section payment-methods">
                        <h3>{{ ('Order.Payment_Method' | trans) }}</h3>
                        <ng-container *ngIf="!(order.status === 'COMPLETE' || order.status === 'OPEN')">
                            <ng-container *ngIf="paymentMethods; else loadingSmall">
                                <ng-container *ngFor="let paymentMethodItem of paymentMethods; let i = index">
                                    <ng-container
                                        *ngIf="(order.getPrice() === 0 && (paymentMethodItem.method === 'FREE' || paymentMethodItem.method === 'RESERVE')) || (order.getPrice() > 0 && paymentMethodItem.method !== 'FREE')">
                                        <div class="form-row">
                                            <div class="form-radio">
                                                <input class="form-check-input"
                                                       type="radio"
                                                       name="paymentMethod"
                                                       (ngModelChange)="paymentMethodChanged($event.method)"
                                                       [(ngModel)]="paymentMethod"
                                                       [attr.disabled]="paymentProcessing ? true : undefined"
                                                       [value]="paymentMethodItem"
                                                       [id]="'payment-method-' + i">
                                                <label class="form-check-label" [for]="'payment-method-' + i">
                                                        <span class="label">{{ paymentMethodItem.name }}
                                                            <ng-container *ngIf="paymentMethodItem.fee > 0">
                                                                ({{ paymentMethodItem.fee | currency: paymentMethodItem.currency }}
                                                                )
                                                            </ng-container>
                                                        </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="paymentMethod && paymentMethod.issuers.length > 0 && paymentMethod.method === paymentMethodItem.method">
                                            <div class="form-row m-l-24">
                                                <app-form-select [(value)]="paymentMethodIssuerId"
                                                                 [options]="paymentMethod.issuers"
                                                                 optionLabel="name"
                                                                 optionValue="id">
                                                </app-form-select>
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="paymentMethod && paymentMethod.method === 'RESERVE' && paymentMethodItem.method === 'RESERVE'">
                                            <div class="form-row">
                                                <div class="form-input">
                                                    <cm-select
                                                        [attr.data-label]="'Order.Expiration_Time' | trans"
                                                        [attr.value]="selectedExpirationOption"
                                                        data-show-filter-from="0"
                                                        (cm-option-clicked)="expireOptionChanged($event)">
                                                        <ng-container *ngFor="let event of events">
                                                            <ng-container *ngIf="event.startAt">
                                                                <cm-option slot="option"
                                                                           [attr.value]="event.startAt"
                                                                           [attr.data-display]="('Order.Until' | trans) + ' ' + event.name"></cm-option>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngFor="let expireAtOption of expireAtOptions">
                                                            <cm-option slot="option"
                                                                       [attr.data-display]="expireAtOption.amount + ' ' + ('Order.' + expireAtOption.unit | trans)"
                                                                       [attr.value]="expireAtOption.value"></cm-option>
                                                        </ng-container>
                                                    </cm-select>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-input">
                                                    <app-form-datepicker-v3
                                                        [isRequired]="true"
                                                        [disabled]="selectedExpirationOption !== 'CUSTOM'"
                                                        (change)="customExpireDateTimeChanged($event)"
                                                        [label]="'Order.Expiration_Date_Time' | trans"
                                                        [minDate]="today"
                                                        [value]="expirationDateTime"></app-form-datepicker-v3>
                                                    <ng-container *ngIf="error">
                                                        <span class="error">{{ error | trans }}</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="order.status === 'COMPLETE' || order.status === 'OPEN'">
                            <p class="f-s-12">
                                    <span class="cm-icon cm-icon-check f-s-20" style="color: #26C485;"
                                          aria-hidden="true"></span>
                                {{ ('Order.Paid' | trans) }}
                            </p>
                        </ng-container>
                    </div>

                    <div class="section">
                        <h3>{{ ('General.Order_Tags' | trans) }}</h3>
                        <cm-select data-checkboxes data-action-button
                                   (cm-option-checkbox-clicked)="onOrderTagChange($event)"
                                   [attr.value]="order.getOrderTags()">
                            <ng-container *ngFor="let orderTag of orderTags">
                                <cm-select-option-checkbox slot="option"
                                                           [attr.data-display]="orderTag.name"
                                                           [attr.value]="orderTag.id"
                                                           [attr.selected]="order.hasTag(orderTag.id)"></cm-select-option-checkbox>
                            </ng-container>
                            <cm-select-action slot="action">
                                <button class="btn btn-secondary-icon-text" (click)="onAddOrderTagClick()">
                                    <span class="cm-icon cm-icon-add" aria-hidden="true"></span>
                                    <span>{{ ('Order_Tag.New_Order_Tag' | trans) }}</span>
                                </button>
                            </cm-select-action>
                        </cm-select>
                    </div>

                    <div class="section">
                        <app-campaign-selector [campaignId]="order.campaignId"
                                               (campaignIdSelected)="campaignChanged($event)"></app-campaign-selector>
                    </div>
                </div>

                <div class="section col-12 col-md-3">
                    <div class="consent-container" *ngIf="order.consents && order.consents.length > 0">
                        <ng-container *ngIf="terms?.length">
                            <h3>{{ ('Order.Terms' | trans) }}</h3>
                            <div class="form-group">
                                <ng-container *ngFor="let term of terms">
                                    <app-consent-details
                                        [order]="order" [consent]="term"
                                        [updateConsentSelection]="updateConsentSelection"
                                        [acceptedConsentIds]="acceptedConsentIds"></app-consent-details>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="optIns?.length">
                            <h3>{{ ('Order.OptIns' | trans) }}</h3>
                            <div class="form-group">
                                <ng-container *ngFor="let optIn of optIns">
                                    <app-consent-details
                                        [order]="order" [consent]="optIn"
                                        [updateConsentSelection]="updateConsentSelection"
                                        [acceptedConsentIds]="acceptedConsentIds"></app-consent-details>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === orderStep.SEND">
        <div class="step-send form-group" [class.is-mobile]="isMobile">
            <app-card (click)="updateOrderDeliverySelection('EMAIL')"
                      [class.card-active]="inOrderDeliverySelection('EMAIL')">
                <div class="title">
                    <span class="cm-icon cm-icon-email" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.Email' | trans) }}</span>
                </div>
                <div class="form-input">
                    <input type="email" class="form-control"
                           placeholder="{{('Order.Email' | trans)}}" [(ngModel)]="deliveryEmail"
                           (click)="$event.stopPropagation()">
                </div>
            </app-card>

            <app-card (click)="updateOrderDeliverySelection('SMS')"
                      [class.card-active]="inOrderDeliverySelection('SMS')">
                <div class="title">
                    <span class="cm-icon cm-icon-message" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.SMS' | trans) }}</span>
                </div>
                <div class="form-input cap-form-row">
                    <app-form-input-phone-number [(value)]="deliveryPhoneNumber" [dropdown]="false"
                                                 (click)="$event.stopPropagation()"></app-form-input-phone-number>
                </div>
            </app-card>

            <app-card (click)="updateOrderDeliverySelection('DOWNLOAD_PDF')"
                      [class.card-active]="inOrderDeliverySelection('DOWNLOAD_PDF')">
                <div class="title">
                    <span class="cm-icon cm-icon-pdf" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.Download_PDF' | trans) }}</span>
                </div>
            </app-card>

            <app-card (click)="updateOrderDeliverySelection('PRINT')"
                      [class.card-active]="inOrderDeliverySelection('PRINT')">
                <div class="title">
                    <span class="cm-icon cm-icon-print" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.Print' | trans) }}</span>
                </div>
                <div class="form-input">
                    <select class="form-control"
                            data-component="cm-select"
                            (click)="$event.stopPropagation()"
                            [(ngModel)]="defaultPrinter"
                            [compareWith]="printerSelected"
                            [selectedIndex]="1"
                            (change)="setDefaultPrinter()">
                        <option value="null"
                                disabled>{{ ('Printer_List.Select_Printer' | trans) }}
                        </option>
                        <ng-container *ngFor="let printFormat of printFormats">
                            <ng-container *ngIf="printFormat.printers.length > 0">
                                <option value="null" disabled>{{ printFormat.name }}</option>
                                <ng-container *ngFor="let printer of printFormat.printers">
                                    <option [ngValue]="printer">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ printer.name }}
                                    </option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </select>
                </div>
            </app-card>
            <ng-container *ngIf="order.customer.guestManagerOrganisationId">
                <app-card (click)="updateOrderDeliverySelection('GUEST_MANAGER')"
                          [class.card-active]="inOrderDeliverySelection('GUEST_MANAGER')">
                    <div class="title">
                        <span class="cm-icon cm-icon-group-open" aria-hidden="true"></span>
                        <span class="subtitle">{{ ('Order.Guest_Manager' | trans) }}</span>
                    </div>
                </app-card>
            </ng-container>

            <app-card (click)="updateOrderDeliverySelection('POST')"
                      [class.card-active]="inOrderDeliverySelection('POST')">
                <div class="title">
                    <span class="cm-icon cm-icon-delivery" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.Post_Delivery' | trans) }}</span>
                </div>
            </app-card>

            <app-card (click)="updateOrderDeliverySelection('PICKUP')"
                      [class.card-active]="inOrderDeliverySelection('PICKUP')">
                <div class="title">
                    <span class="cm-icon cm-icon-inbox" aria-hidden="true"></span>
                    <span class="subtitle">{{ ('Order.Pickup' | trans) }}</span>
                </div>
            </app-card>
        </div>
    </ng-container>
</div>

<div class="footer">
    <ng-container *ngIf="step === orderStep.ORDER">
        <cm-toggle-switch
            [attr.data-label]="'Order.Kiosk_Mode' | trans"
            [attr.checked]="kioskMode ? true : undefined"
            (change)="switchKioskMode($event)"
            data-label-position="right"
        ></cm-toggle-switch>
    </ng-container>

    <ng-container *ngIf="step === orderStep.PAY || step === orderStep.SEND">
        <cm-button
            data-label="Back"
            data-button-style="secondary"
            data-button-type="text"
            data-button-size="medium"
            [attr.disabled]="order.status === 'COMPLETE' ? true : undefined"
            (click)="previousButtonClick()">
        </cm-button>
    </ng-container>

    <ng-container *ngIf="step === orderStep.ORDER">
        <cm-button
            data-label="{{('Order.Delete_Order' | trans)}}"
            data-button-style="secondary"
            data-button-type="text"
            data-button-size="medium"
            data-rtl
            [attr.disabled]="isAddingOrUpdatingOrder ? true : undefined"
            (click)="deleteOrder()">
        </cm-button>
    </ng-container>
    <cm-button
        [attr.data-icon]="step === orderStep.SEND ? 'check' : 'right'"
        [attr.data-label]="step === orderStep.SEND ?
            (orderDelivery.length > 0 ? ('General.Send_And_Close' | trans) : ('General.Close' | trans)) :
            ('General.Next' | trans)"
        data-button-style="cta"
        data-button-type="icon-text"
        data-button-size="medium"
        data-rtl
        [attr.disabled]="isNextButtonDisabled() ? true : undefined"
        (click)="nextButtonClick()">
    </cm-button>
</div>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>

<app-dialog #newOrderTagDialog [title]="('Order_Tag.New_Order_Tag' | trans)" (save)="onNewOrderTagSave($event)">
    <div class="form-group">
        <div class="form-row">
            <div class="form-input">
                <input class="form-control" name="name" ngModel>
                <label>{{ ('Order_Tag.Name' | trans) }}</label>
            </div>
        </div>
    </div>
</app-dialog>
