export class TicketTemplateComponent {
    public positionX: number;
    public positionY: number;
    public width: number;
    public height: number;
    public rotation: number;
    public fontFamily: string;
    public fontSize: number;
    public fontAlignment: string;
    public fontStyle: string;
    public type: string;
    public text: string;
    public value: string;
    public title: string;

    constructor(data) {
        this.mapTicketTemplate(data);
    }

    mapTicketTemplate(data): TicketTemplateComponent {
        this.positionX = data.positionX;
        this.positionY = data.positionY;
        this.width = data.width;
        this.height = data.height;
        this.rotation = data.rotation;
        this.fontFamily = data.fontFamily;
        this.fontSize = data.fontSize;
        this.fontAlignment = data.fontAlignment;
        this.fontStyle = data.fontStyle;
        this.type = data.type;
        this.text = data.text;
        this.value = data.value;
        this.title = data.title;

        return this;
    }
}
