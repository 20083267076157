import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerFilters} from '../../models/customer-filters.class';
import {CustomerListItem, mapCustomerListItem} from '../../models/customer-list-item.class';

@Injectable()
export class CustomerListService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {},
        responseType: null
    };

    constructor(private http: HttpClient) {
    }

    getCustomerListItems(params?: HttpParams, filters?: CustomerFilters): Observable<HttpResponse<CustomerListItem[]>> {
        if (filters) {
            const filterKeys = Object.keys(filters);
            filterKeys.forEach(key => {
                if (filters[key] && filters[key] !== 'null' && filters[key].length > 0 || filters[key] === true) {
                    params = params.set(`customer[${key}]`, filters[key].toString());
                }
            });
        }

        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<CustomerListItem[]>(this.baseUrl + `/v1.0/customer-list`, {...config, observe: 'response'}).pipe(
            map((response: HttpResponse<CustomerListItem[]>) => {
                const body = response.body.map((item: CustomerListItem) => {
                    return mapCustomerListItem(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }
}
