export class TicketTemplateComponentDatabase {
    public position_x: number;
    public position_y: number;
    public width: number;
    public height: number;
    public rotation: number;
    public ticket_template_component_id: string;
    public value: string;

    constructor(data) {
        this.mapTicketTemplate(data);
    }

    mapTicketTemplate(data): TicketTemplateComponentDatabase {
        this.position_x = data.position_x;
        this.position_y = data.position_y;
        this.width = data.width;
        this.height = data.height;
        this.rotation = data.rotation;
        this.ticket_template_component_id = data.typeID;
        this.value = data.value;

        return this;
    }
}
