<!-- Modal -->
<cm-dialog data-show-top-divider
           data-show-bottom-divider
           data-max-width="800px"
           #cmModal>
    <!-- Header -->
    <h4 slot="header" class="dialog-title">
        {{title}}
    </h4>
    <!-- Body -->
    <div slot="body" class="dialog-body">
        <!-- Translations -->
        <ng-container *ngFor="let translation of translationArray; let i = index">
            <div class="col-lg-12 m-b-16">
                <div class="form-group" align="left">
                    <ng-container *ngIf="!multiLine && !textArea">
                        <div class="form-row">
                            <div class="form-input-special">
                                <input type="text" (input)="translationObjectChanged($event, translation)"
                                       [(ngModel)]="translationObject[translation]" class="form-control"
                                       [id]="title + i" [readonly]="readonly">
                                <cm-tooltip
                                    [attr.data-tooltip-text]="('Language.' + checkTranslationFlag(translation.toUpperCase()) | trans) | async">
                                    <span class="input-left icon">
                                        <span
                                            class="cm-flag cm-flag-{{checkTranslationFlag(translation.toUpperCase())}} m-t-5"
                                            aria-hidden="true"></span>
                                    </span>
                                </cm-tooltip>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="multiLine">
                        <quill-editor [(ngModel)]="translationObject[translation]"
                                      (onEditorCreated)="onQuillEditorCreated($event)">
                            <div quill-editor-toolbar>
                                <div class="ql-formats">
                                    <span
                                        class="cm-flag cm-flag-{{checkTranslationFlag(translation.toUpperCase())}} small"
                                        aria-hidden="true"></span>
                                </div>
                                <div class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-underline"></button>
                                </div>
                                <div class="ql-formats">
                                    <button class="ql-list" value="ordered"></button>
                                    <button class="ql-list" value="bullet"></button>
                                </div>
                                <div class="ql-formats">
                                    <button class="ql-clean"></button>
                                </div>
                                <div class="ql-formats">
                                    <button class="ql-link"></button>
                                </div>
                                <div class="ql-formats">
                                    <select class="ql-header">
                                        <option value="1">Title</option>
                                        <option value="2">Subheading</option>
                                        <option value="3">Small Paragraph</option>
                                        <option selected="selected">Normal text</option>
                                    </select>
                                </div>
                            </div>
                        </quill-editor>
                    </ng-container>
                    <ng-container *ngIf="textArea">
                        <div class="form-row">
                            <div class="row">
                                <div class="col-6">
                                    <span
                                        class="cm-flag cm-flag-{{checkTranslationFlag(translation.toUpperCase())}} small m-l-16"
                                        aria-hidden="true"></span>
                                </div>
                                <div class="col-6 justify-content-end d-flex align-items-center">
                                    <ng-container *ngIf="maxChars && translationObject[translation]">
                                        <span
                                            [ngClass]="{'error-red': translationObject[translation].length > maxChars}">{{translationObject[translation].length}}</span>
                                        / {{maxChars}}
                                    </ng-container>
                                </div>
                            </div>
                            <cm-textarea [id]="title + i"
                                         ngDefaultControl
                                         value="{{translationObject[translation]}}"
                                         [(ngModel)]="translationObject[translation]"
                                         (input)="translationObjectChanged($event, translation)">
                            </cm-textarea>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- Footer -->
    <div slot="footer">
        <!-- Buttons -->
        <button class="btn btn-secondary" *ngIf="!readonly" (click)="closeModal(true)">
            {{('Generic.Forms.Cancel_Button' | trans) | async}}
        </button>
        <button class="btn btn-primary" (click)="saveChanges()"
                [disabled]="maxChars && checkMaxChars()">
            <ng-container *ngIf="!readonly; else okText">
                {{('Generic.Forms.Apply_Button' | trans) | async}}
            </ng-container>
            <ng-template #okText>
                {{('Generic.Forms.Ok_Button' | trans) | async}}
            </ng-template>
        </button>
    </div>
</cm-dialog>
