<div class="card card-menu-container card-action m-b-32" [class.closed]="!isExpanded">
    <div class="card-menu-header" (click)="isExpanded = !isExpanded">
        <div class="row">
            <div class="col-10">
                <div class="row" style="overflow: hidden; height: 40px;">
                    <div class="col-4 d-none d-md-block">
                        <ng-container *ngIf="analyticsDashboardItemSetting.eventId">
                            <div class="f-s-12 f-w-200">{{('General.Event' | trans)}}</div>
                            <div class="f-s-13 f-w-700">
                                {{getEventName(analyticsDashboardItemSetting.eventId)}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="analyticsDashboardItemSetting.subscriptionTypeId">
                            <div class="f-s-12 f-w-200">{{('General.Subscription' | trans)}}</div>
                            <div class="f-s-13 f-w-700">
                                {{getSubscriptionTypeName(analyticsDashboardItemSetting.subscriptionTypeId)}}
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="!analyticsDashboardItemSetting.eventId && !analyticsDashboardItemSetting.subscriptionTypeId">
                            <div class="f-s-12 f-w-200">{{('Analytics.Data_Set' | trans)}}</div>
                            <div class="f-s-13 f-w-700">
                                -
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('General.Venue_Sections' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="analyticsDashboardItemSetting.venueSectionIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                            <ng-container *ngIf="analyticsDashboardItemSetting.venueSectionIds.length > 0">
                                {{analyticsDashboardItemSetting.venueSectionIds.length}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('General.Customer_Tags' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="analyticsDashboardItemSetting.customerTagIds.length === 0">
                                {{('General.All' | trans)}}
                            </ng-container>
                            <ng-container *ngIf="analyticsDashboardItemSetting.customerTagIds.length > 0">
                                {{analyticsDashboardItemSetting.customerTagIds.length}}
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class="f-s-12 f-w-200">{{('Analytics.Cumulative' | trans)}}</div>
                        <div class="f-s-13 f-w-700">
                            <ng-container *ngIf="!analyticsDashboardItemSetting.isCumulative">
                                {{('General.No' | trans)}}
                            </ng-container>
                            <ng-container *ngIf="analyticsDashboardItemSetting.isCumulative">
                                {{('General.Yes' | trans)}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 text-right">
                <ng-container *ngIf="isExpanded">
                    <button class="btn btn-secondary-icon"><!-- delete-->
                        <span class="cm-icon cm-icon-delete" aria-hidden="true" (click)="delete()"></span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="card-menu">
        <ng-container *ngFor="let setting of settings">
            <div class="card-menu-item" (click)="switchMenu(setting)" [class.active]="activeMenuItem == setting.key"
                 [class.inactive]="setting.key === null">
                <ng-container *ngIf="setting.switch">
                    <div class="form-group">
                        <div class="form-row">
                            <div class="form-switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           [disabled]="isSettingDisabled(setting)"
                                           [checked]="isSettingEnabled(setting)"
                                           (change)="toggleSetting(setting)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <span class="form-switch-label label" (click)="$event.preventDefault()">{{(setting.viewValue | trans)}}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!setting.switch">
                    <label>{{setting.viewValue | trans}}</label>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="card-menu-content form-group">
        <ng-container *ngFor="let setting of settings">
            <ng-container *ngIf="activeMenuItem === 'type' && activeMenuItem === setting.key">
                <div *ngIf="isSettingDisabled(setting)" class="form-row p-l-16">
                    <ng-container>
                        <div class="card-menu-content-disabled">
                            <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                            Select an event or subscription first
                        </div>
                    </ng-container>
                </div>
                <div class="form-row p-l-16" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <app-form-radio (valueChange)="triggerChange()"
                                    [(value)]="analyticsDashboardItemSetting.type"
                                    [optionLabelTranslate]="true"
                                    [options]="chartTypesOptions">
                    </app-form-radio>
                </div>
            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'eventId' && activeMenuItem === setting.key">
                <ng-container *ngIf="isSettingDisabled(setting)">
                    <div class="card-menu-content-disabled">
                        <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                        This setting is event specific
                    </div>
                </ng-container>
                <div class="row" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <div class="row list-row">
                        <div class="col-12 col-md-8">
                            <div class="row">
                                <app-form-radio
                                    (valueChange)="triggerChange(setting)"
                                    [(value)]="analyticsDashboardItemSetting.eventId"
                                    [options]="events"
                                    optionLabel="name"
                                    optionValue="id">
                                </app-form-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'subscriptionTypeId' && activeMenuItem === setting.key">
                <ng-container *ngIf="isSettingDisabled(setting)">
                    <div class="card-menu-content-disabled">
                        <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                        This setting is event specific
                    </div>
                </ng-container>
                <div class="row" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <div class="row list-row">
                        <div class="col-12 col-md-8">
                            <div class="row">
                                <app-form-radio
                                    (valueChange)="triggerChange(setting)"
                                    [(value)]="analyticsDashboardItemSetting.subscriptionTypeId"
                                    [options]="subscriptionTypes"
                                    optionLabel="name"
                                    optionValue="id">
                                </app-form-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'groupBy' && activeMenuItem === setting.key">
                <ng-container *ngIf="isSettingDisabled(setting)">
                    <div class="card-menu-content-disabled">
                        <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                        Select an event or subscription first
                    </div>
                </ng-container>
                <div class="form-row p-l-16" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <app-form-radio (valueChange)="triggerChange()"
                                    [(value)]="analyticsDashboardItemSetting.groupBy"
                                    [optionLabelTranslate]="true"
                                    [options]="groupByOptions">
                    </app-form-radio>
                </div>
            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'isCumulative' && activeMenuItem === setting.key">
                <ng-container *ngIf="isSettingDisabled(setting)">
                    <div class="card-menu-content-disabled">
                        <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                        Select an event or subscription first
                    </div>
                </ng-container>
                <div class="form-row p-l-16" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <app-form-checkbox (valueChange)="triggerChange()"
                                       [(value)]="analyticsDashboardItemSetting.isCumulative"
                                       [options]="[{label: (setting.viewValue | trans)}]">
                    </app-form-checkbox>
                </div>
            </ng-container>

            <ng-container *ngIf="activeMenuItem === 'venueSectionIds' && activeMenuItem === setting.key">
                <ng-container *ngIf="isSettingDisabled(setting)">
                    <div class="card-menu-content-disabled">
                        <span class="cm-icon cm-icon-warning-open cl-error-color m-r-16" aria-hidden="true"></span>
                        Select an event or subscription first
                    </div>
                </ng-container>
                <div class="row" [class.overflow-hidden]="isSettingDisabled(setting)">
                    <ng-container *ngIf="venue">
                        <ng-container *ngFor="let venueSection of venue.sections">
                            <div class="row list-row">
                                <div class="col-12 col-md-4 list-row-item">
                                    <span class="f-s-14">{{venueSection.name}}</span><br>
                                    <span class="header-link"
                                          (click)="updateArraySelection(setting.key, venueSection.sections)">
                                        {{('Restriction.Select_All' | trans)}}
                                    </span>
                                </div>
                                <div class="col-12 col-md-8">
                                    <div class="row">
                                        <ng-container *ngFor="let section of venueSection.sections">
                                            <div class="col-12 col-md-6">
                                                <div class="form-check m-b-8">
                                                    <label>
                                                        <input type="checkbox"
                                                               [checked]="inSelection(setting.key, section)"
                                                               (change)="updateSelection(setting.key, section)">
                                                        <span class="label">{{section.name}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
