import {Injectable, Injector} from '@angular/core';
import {StateManagementService} from '../util/state-management-service/state-management.service';
import {Shop} from '../../models/shop.class';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ShopManagementService extends StateManagementService {

    constructor(injector: Injector) {
        super(injector);
        this.modelClass = new Shop();

        this.httpParams = new HttpParams()
            .set('depth', '3')
            .set('setting[settingOptions]', 'true')
            .set('setting[shopSetting]', 'true')
            .set('setting[value]', 'true');

        this.initialize();
    }

}
