import {PaymentMethodIssuer} from './payment-method-issuer.class';

export class PaymentMethod {
    constructor(public id: string = null,
                public method: string = null,
                public name: string = null,
                public imageUrl: string = null,
                public fee: number = 0,
                public currency: string = null,
                public issuers: PaymentMethodIssuer[] = [],
                public isEnabled: boolean = false,
                public isDashboard: boolean = false,
                public isAvailable: boolean = false,
                public isVisible: boolean = true,
                public isVisibleDashboard: boolean = true,
                public iban?: string,
                public ibanAccountHolder?: string,
                public feeAmount?: number,
                public feePercentage?: number) {
    }

}

export function mapPaymentMethod(item: any): PaymentMethod {
    return new PaymentMethod(
        item.id,
        item.method,
        item.name,
        item.imageUrl,
        item.fee,
        item.currency,
        item.issuers,
        item.isEnabled,
        item.isDashboard,
        item.isAvailable,
        item.isVisible,
        item.isVisibleDashboard,
        item.iban,
        item.ibanAccountHolder,
        item.feeAmount,
        item.feePercentage
    );
}

export enum PaymentMethodEnum {
    FREE = 'FREE',
    RESERVE = 'RESERVE',
    PIN = 'PIN',
    CASH = 'CASH',
}

