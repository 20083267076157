<div class="form-input" [class.error]="!isValid">
    <cm-datetime-picker data-format="{{displayFormat}}"
                        data-label="{{label}}"
                        data-datetime="{{value}}"
                        data-precision="{{precision}}"
                        [data-locale]="locale"
                        [data-required]="required"
                        [min]="minDate"
                        [max]="maxDate"
                        data-error-required="General.Required"
                        data-error-range="Error.Date_Not_In_Range"
                        data-error-invalid="Error.Invalid_Date"
                        (change)="inputChange($event)">
    </cm-datetime-picker>
    <ng-container *ngIf="!isValid">
        <div class="form-input-validation">
            <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
        </div>
    </ng-container>
</div>


