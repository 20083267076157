import {Injectable} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {LocaleService} from '../../../core/services/locale.service';
import {isLocaleSupported} from '../../../core/models/locale';

export class Identity {
    constructor(public Name: string,
                public FirstName: string,
                public LastName: string,
                public SignOutUrl: string,
                public UserData: {
                    PersonGuid: string,
                    Name: string,
                    IpAddress: string,
                    AccountGuid: string,
                    AvatarGuid: string,
                    Expiry: string
                },
                public Claims: {
                    name: string;
                    emailaddress: string;
                    authenticationmethod: string;
                    mobilephone: string;
                    windowsaccountname: string
                },
                public PersonPreferences: {
                    PreferredLanguage: string,
                    TimeZone: string,
                },
                public Expiration: string) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class WhereToService {
    private identity: Identity;
    private pollingInterval = 50;

    constructor(private localeService: LocaleService) {
    }

    public getIdentity(): Observable<Identity> {
        return interval(this.pollingInterval).pipe(
            map(() => {
                return (window as any).getCmSSO ? (window as any).getCmSSO() : undefined;
            }),
            first((val) => {
                return val !== undefined;
            })
        );
    }

    public getBaseUrl() {
        let locale = this.identity?.PersonPreferences.PreferredLanguage || window.navigator.language.toLocaleLowerCase() || 'en-gb';
        let logicalAccount = null;

        let urlLocale = null;

        const path = window.location.pathname.split('/');
        for (const item of path) {
            if (!logicalAccount && (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(item))) {
                logicalAccount = item;
            }

            if (isLocaleSupported(item)) {
                urlLocale = item;
            }
        }

        if (!urlLocale) {
            window.location.href = `/${locale}/app/seated-ticketing/${logicalAccount || ''}`;

            return;
        }

        locale = urlLocale ?? locale;

        this.localeService.setLanguage(locale);

        return `/${locale}/app/seated-ticketing/${logicalAccount || ''}`;
    }

}
