<cm-widget>
    <div slot="body">
        <div>
            <b class="fs-large">{{ title }}</b>
        </div>
        <div>
            <b class="fs-h1">{{ body }}</b>
        </div>
    </div>
</cm-widget>
