<div class="card">
    <div class="row">
        <div class="col-12">
            <div class="card-header">
                <h2 class="title">{{('Event_Detail.Sections' | trans)}}</h2>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card-content p-b-0">
                <ng-container *ngIf="venue$ | async as venue; else loading">
                    <div class="table-responsive card-fill">
                        <table class="table table-hover full-width">
                            <thead>
                            <tr>
                                <th class="fixed-column" style="width: 40%">
                                    {{('Event_Detail.Section' | trans)}}</th>
                                <th scope="col" style="width: 12%">
                                    {{('Event_Detail.Capacity' | trans)}}</th>
                                <th scope="col" style="width: 12%">
                                    {{('Event_Detail.Reserved' | trans)}}</th>
                                <th scope="col" style="width: 12%">
                                    {{('Event_Detail.Sold' | trans)}}
                                </th>
                                <th scope="col" style="width: 12%">
                                    {{('Event_Detail.Available' | trans)}}
                                </th>
                                <th scope="col" style="width: 12%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngIf="eventStatistics">
                                <ng-container *ngFor="let section of venue.sections">
                                    <tr (click)="expandSection(section.id)">
                                        <td class="fixed-column" style="width: 40%">
                                            <strong>{{section.name}}</strong>
                                        </td>
                                        <td style="width: 12%">
                                            <strong>{{getStatistics(section)?.capacity}}</strong></td>
                                        <td style="width: 12%">
                                            <strong>{{getStatistics(section)?.reserved}}</strong></td>
                                        <td style="width: 12%">
                                            <strong>{{getStatistics(section)?.issued}}</strong></td>
                                        <td style="width: 12%">
                                            <strong>{{getStatistics(section)?.available}}</strong></td>
                                        <td style="width: 12%">
                                            <ng-container *ngIf="section.sections.length > 0">
                          <span class="cm-icon cm-icon-next" [class.rotate-icon]="section.id === expandedSection"
                                aria-hidden="true"></span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="section.id === expandedSection">
                                        <tr *ngFor="let subSection of section.sections">
                                            <td class="fixed-column">{{subSection.name}}</td>
                                            <td>{{getStatistics(section, subSection)?.capacity}}</td>
                                            <td>{{getStatistics(section, subSection)?.reserved}}</td>
                                            <td>{{getStatistics(section, subSection)?.issued}}</td>
                                            <td>{{getStatistics(section, subSection)?.available}}</td>
                                            <td></td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
            <div class="card-footer border-top">
                <button (click)="exportData()" class="btn btn-secondary-icon-text">
                    <span class="cm-icon cm-icon-download" aria-hidden="true"></span>
                    <span>{{('Event_Detail.Download_Event_Report' | trans)}}</span>
                </button>
            </div>
        </div>
    </div>

</div>

<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>
