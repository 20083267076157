import {Component, ElementRef, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {MoveOrderService} from '../../services/move-order/move-order.service';
import {map, switchMap, concat, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {Router} from '@angular/router';
import {SidebarConfig, SidebarInterface} from '../../models/abstract/sidebar.interface';
import {OrderManagementService} from '../../services/order-service/order-management.service';

@Component({
    selector: 'app-move-order-sidebar',
    templateUrl: './move-order-sidebar.component.html',
    styleUrls: ['./move-order-sidebar.component.scss']
})
export class MoveOrderSidebarComponent implements OnDestroy, SidebarInterface {

    @ViewChildren('orderItemRow') orderItemElements: QueryList<ElementRef>;

    orderItems$ = this.moveOrderService.selectedOrderItems$.pipe(
        map(orderItems => {
            return orderItems.map(orderItem => this.mapOrderItem(orderItem));
        }),
        tap(orderItems => {
            this.selectedOrderItemsAmount = orderItems.length;
        })
    );

    activeOrderItems$ = this.moveOrderService.activeOrderItems$.pipe(
        map(orderItems => {
            return orderItems.map(orderItem => this.mapOrderItem(orderItem));
        }),
        tap(orderItems => {
            if (orderItems.length) {
                this.orderItemGroupId = orderItems[0].ticket?.eventId || orderItems[0].subscription?.subscriptionTypeId;
            } else {
                this.orderItemGroupId = null;
            }
        })
    );

    venue$ = this.moveOrderService.venue$.pipe(
        tap(venue => {
            if (venue) {
                this.venueId = venue.id;
                this.venueName = venue.name;
                this.venueSections = [];
                venue.sections.forEach(ring => {
                    ring.sections.forEach(section => {
                        this.venueSections.push(section);
                    });
                });
            } else {
                this.venueId = null;
                this.venueName = null;
                this.venueSections = [];
                this.venueSection = null;
                this.venueSectionId = null;
            }
        })
    );

    venueId: string = null;
    venueName: string = null;
    venueSectionId: string;
    venueSections: any[];
    venueSection: any;
    isSeatmap = false;
    selectedOrderItemsAmount = 0;
    orderItemGroupId = null;

    // Sidebar config
    sidebarConfig: SidebarConfig = {
        closeAfterRefresh: true
    };

    venueSectionFilter: string = null;

    constructor(
        private moveOrderService: MoveOrderService,
        private notificationService: NotificationService,
        private router: Router,
        private sidebarService: SidebarService,
        private orderManagementService: OrderManagementService) {
    }

    ngOnDestroy() {
        this.moveOrderService.reset(false);
    }

    setSelectedOrderItem(orderItem: any, orderItemElement: HTMLElement): void {
        this.moveOrderService.toggleActiveOrderItem(orderItem.id);
    }

    onVenueSectionSelect(venueSectionId: string): void {
        this.venueSectionId = venueSectionId;
        this.venueSection = this.venueSections.find(venueSectionI => venueSectionI.id === venueSectionId);

        this.moveOrderService.setVenueSection(this.venueSection);
    }

    onSelectSeatmap(): void {
        this.isSeatmap = true;
        this.router.navigate([{outlets: {overlay: ['move-order-seatmap', this.venueId, this.venueSection.id]}}]);
    }

    onConfirmBestSeatAvailable(): void {
        const groupedOrderItems = this.moveOrderService.getActiveOrderItemsGroupedByOrder();

        const moveOrder$: Observable<any>[] = [];
        Object.values(groupedOrderItems).forEach(orderItems => {
            moveOrder$.push(this.moveOrderService.getBestSeatAvailable(orderItems).pipe(
                switchMap(response => {
                    return this.moveOrderService.moveOrderItems(response);
                })
            ));
        });

        concat(...moveOrder$).subscribe({
            next: () => {
            },
            error: (error) => {
            },
            complete: () => {
                this.moveOrderService.reset(true, true);
                this.notificationService.showTranslatedNotification('success', 'ticket', 'moved');
                this.orderManagementService.refreshSelectedOrder();
            }
        });
    }

    onConfirmGeneralAdmission(): void {
        const groupedOrderItems = this.moveOrderService.getActiveOrderItemsGroupedByOrder();

        const moveOrder$: Observable<any>[] = [];
        Object.values(groupedOrderItems).forEach(orderItems => {
            moveOrder$.push(this.moveOrderService.getBestSeatAvailable(orderItems).pipe(
                switchMap(response => {
                    return this.moveOrderService.moveOrderItems(response);
                })
            ));
        });

        concat(...moveOrder$).subscribe({
            next: () => {
            },
            error: (error) => {
            },
            complete: () => {
                this.moveOrderService.reset(true, true);
                this.notificationService.showTranslatedNotification('success', 'ticket', 'moved');
            }
        });
    }

    onConfirmSeatmap(orderItems: any): void {
        orderItems = orderItems.filter(orderItem => orderItem.toVenueSectionSeat);
        orderItems = orderItems.map(orderItem => {
            return {
                eventTicketId: orderItem.eventTicketId,
                subscriptionId: orderItem.subscriptionId,
                venueSectionSeatId: orderItem.toVenueSectionSeat.id
            };
        });

        this.moveOrderService.moveOrderItems(orderItems).subscribe(() => {
            this.orderManagementService.refreshSelectedOrder();
            this.moveOrderService.reset(true, true);
            this.notificationService.showTranslatedNotification('success', 'ticket', 'moved');
        });
    }

    onResetToVenueSectionSeat(orderItemId: string): void {
        this.moveOrderService.resetOrderItemToVenueSectionSeat(orderItemId);
    }

    onBackToSelectVenueSection(): void {
        this.isSeatmap = false;
        this.sidebarService.closeOverlay();
        this.moveOrderService.reset(false, false);
    }

    selectAllOrderItemsInOrderItemGroup(): void {
        this.moveOrderService.selectAllOrderItemsInOrderItemGroup();
    }

    onClearSelectedOrderItems(): void {
        this.moveOrderService.reset(true, false);
    }

    onVenueSectionFilterChange(e: any): void {
        this.venueSectionFilter = e.target.value;
    }

    private mapOrderItem(orderItem: any): any {
        return {
            id: orderItem.id,
            batchId: orderItem.batchId,
            name: orderItem.ticket?.eventName || orderItem.subscription?.subscriptionType.name,
            orderItemGroupId: orderItem.ticket?.eventId || orderItem.subscription?.subscriptionTypeId,
            eventTicketId: orderItem.ticket?.id,
            subscriptionId: orderItem.subscription?.id,
            ticketType: orderItem.ticket?.ticketType.name || orderItem.subscription?.subscriptionTypePrice.name,
            customer: orderItem.ticket?.customer?.getName() || orderItem.subscription?.customer?.getName(),
            venueId: orderItem.ticket?.venueId || orderItem.subscription?.subscriptionType.venueId,
            fromVenueSection: orderItem.ticket?.seat.sectionName || orderItem.subscription?.seat.sectionName,
            fromRow: orderItem.ticket?.seat.row || orderItem.subscription?.seat.row,
            fromSeat: orderItem.ticket?.seat.seat || orderItem.subscription?.seat.seat,
            fromSectionIsSeated: orderItem.ticket?.seat.capacity === 1 || orderItem.subscription?.seat.capacity === 1,
            toVenueSection: orderItem.toVenueSection || null,
            toVenueSectionSeat: orderItem.toVenueSectionSeat || null,
            isSelected: orderItem.isSelected,
            isDisabled: orderItem.isDisabled
        };
    }
}
