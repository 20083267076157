<ng-container *ngIf="label">
    <label>{{label}}</label>
</ng-container>
<div class="form-input" [class.error]="!!errorMessage">
    <div class="row">
        <ng-container *ngFor="let option of options">
            <div class="col-12"
                 [class.col-sm-12]="columns == 1"
                 [class.col-sm-6]="columns == 2"
                 [class.col-sm-4]="columns == 3">
                <div class="form-check">
                    <label>
                        <input type="checkbox"
                               (change)="updateSelection(option[optionValue]); inputChange()"
                               [checked]="inSelection(option[optionValue])"
                               [name]="id"
                               [value]="option[optionValue]"
                               [disabled]="disabled"
                               [readonly]="readonly"
                               [class.has-error]="!!errorMessage"/>
                        <ng-container *ngIf="optionLabelTranslate">
                            <span class="label"> {{(option[optionLabel]| trans)}}</span>
                        </ng-container>
                        <ng-container *ngIf="!optionLabelTranslate">
                            <span class="label">{{option[optionLabel]}}</span>
                        </ng-container>
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="errorMessage">
        <div class="form-input-validation">
            <div class="form-input-notes-left">{{(errorMessage | trans)}}</div>
        </div>
    </ng-container>
</div>



