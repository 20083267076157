import {TranslationHelper} from './translation-helper';
import {TranslationService} from '../services/translation.service';

export class NotificationHelper {
    private static anyWindow = (window as any);

    public static showErrorNotification(translationService, translateValue: string): void {
        this.translateNotificationValues(translationService, [translateValue], (result) => {
            this.anyWindow.AddErrorNotification(result);
        });
    }

    public static showSuccessNotification(translationService, translateValue: string): void {
        this.translateNotificationValues(translationService, [translateValue], (result) => {
            this.anyWindow.AddSuccessNotification(result);
        });
    }

    /**
     * Show a custom notification
     *
     * @param translationService The service to translate with
     * @param translateTitle The title to be translated and displayed
     * @param translateDescription The description to be translated and displayed
     * @param config Configuration object with specific notification config
     */
    public static showCustomNotification(translationService, translateTitle, translateDescription, config = {}): void {
        this.translateNotificationValues(translationService, [translateTitle, translateDescription], (result => {
            this.anyWindow.AddNotification(result[0], result[1], config);
        }));
    }

    /**
     * Show a custom notification with replacing the values from a translated string
     *
     * @param translationService The service to translate with
     * @param translateTitle The title to be translated and displayed
     * @param translateDescription The description to be translated and displayed
     * @param replaceValues The values in the string to be replaced
     * @param config Configuration object with specific notification config
     */
    public static showCustomerNotificationWithReplaces(translationService, translateTitle, translateDescription, replaceValues,
                                                       config = {}): void {
        this.translateNotificationValues(translationService, [translateTitle, translateDescription], (result) => {
            let changedString = result[1];

            replaceValues.forEach(replacer => {
                changedString = changedString.replace(replacer[0], replacer[1]);
            });

            this.anyWindow.AddNotification(result[0], changedString, config);
        });
    }

    private static translateNotificationValues(
        translationService: TranslationService,
        translateValues: string[],
        resultCallback: (result: string[]) => void
    ): void {
        TranslationHelper.translateValues(translationService, translateValues).subscribe(result => {
            resultCallback(result);
        });
    }
}
