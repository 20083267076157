<cm-dialog [id]="id" data-show-top-divider data-show-bottom-divider>

    <h3 slot="header" class="dialog-title">
        {{ costItemAgreement.name }}
    </h3>

    <div slot="body" class="form-group">
        <div class="form-row">
            <div class="form-input">
                <input class="form-control" type="text" id="cost-item-agreement-amount"
                       [(ngModel)]="costItemAgreement.amount">
                <label for="cost-item-agreement-amount">{{('Cost_Item_Agreement.Agreed_Amount' | trans)}}</label>
            </div>
        </div>

        <div class="form-row">
            <div class="form-input">
                <input class="form-control" type="text" id="cost-item-agreement-percentage"
                       [(ngModel)]="costItemAgreement.percentage" [readonly]="!costItemAgreement.isPercentageEditable">
                <label for="cost-item-agreement-percentage">
                    {{('Cost_Item_Agreement.Agreed_Percentage' | trans)}}
                </label>
            </div>
        </div>

        <div class="form-row">
            <div class="form-input">
                <input class="form-control" type="text" id="cost-item-agreement-vat"
                       [(ngModel)]="costItemAgreement.vat" [readonly]="!costItemAgreement.isVatEditable">
                <label for="cost-item-agreement-vat">
                    {{('Cost_Item_Agreement.VAT_Percentage' | trans)}}
                </label>
            </div>
        </div>

        <div class="m-t-32 cl-warning-color">
            {{ 'Dialog_Cost_Item_Agreement.Warning' | trans }}
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('General.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('General.Save' | trans)}}</button>
    </div>

</cm-dialog>
