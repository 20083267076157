<cm-image data-type="noResults" data-min-height="600"></cm-image>

<div class="row">
    <div class="col-12 offset-sm-6 col-sm-6">
        <div class="card">
            <h2 class="title">{{('Status.Not_Found' | trans)}}</h2>
            <p class="message">{{('Status.Not_Found_Message' | trans)}}</p>
            <p class="details">{{('Status.Not_Found_Potential_Fix' | trans)}}</p>
            <div class="links">
                <a [routerLink]="['/events']">Home</a><br>
                <a href="https://www.cm.com/help/">Help Center</a>
            </div>
        </div>
    </div>
</div>
