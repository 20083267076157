<div class="preview-container p-16">
    <div class="row">
        <div class="col-lg-10">
            <div class="preview-row">
                <div class="preview-label">
                    <span class="cm-icon cm-icon-text"></span>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="preview-ticket-info">
                <span class="cm-icon cm-icon-qr-code"></span>
            </div>
        </div>
    </div>
</div>
