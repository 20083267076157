import {HttpParamsHelper} from '../http-params/http-params-helper';
import {ProductGroupType} from '../../enums/order/product-group-type';

export class OrderItemRequestHelper extends HttpParamsHelper {
    public static getRequestBody(
        orderItemType: ProductGroupType,
        id: string = null,
        seatId: string = null,
        itemId: string = null
    ): any {
        let requestObject;
        switch (orderItemType) {
            case ProductGroupType.EVENT_TICKET:
                requestObject = {
                    ticket: {
                        eventId: id,
                        venueSectionSeatId: seatId,
                        ticketTypeId: itemId
                    }
                };
                break;
            case ProductGroupType.SUBSCRIPTION:
                requestObject = {
                    subscription: {
                        subscriptionTypeId: id,
                        subscriptionTypePriceId: itemId,
                        venueSectionSeatId: seatId,
                    }
                };
                break;
            case ProductGroupType.PRODUCT:
                requestObject = {
                    product: {
                        productTypeId: id,
                        productTypePriceId: itemId,
                    }
                };
                break;
        }

        return requestObject;
    }
}
