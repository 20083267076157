export class FilterSetting {
    public type: string               = null;
    public nameTranslationKey: string = null;
    public isPrimary                  = false;

    constructor(type, nameTranslationKey, isPrimary) {
        this.type               = type;
        this.nameTranslationKey = nameTranslationKey;
        this.isPrimary          = isPrimary;
    }
}

