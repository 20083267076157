import {Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormField, FormManagementService} from '../../../../shared/services/form-management.service';
import {OrganisationManagementService} from '../../../../shared/services/organisation-service/organisation-management.service';
import {LocaleService} from '../../../services/locale.service';
import {NumberSymbol} from '@angular/common';
import {Subject} from 'rxjs';
import {isEmpty} from '../../../../shared/common';
import {Vat} from '../../../../shared/models/vat.class';
import {AppService} from '../../../../app.service';

@Component({
    selector: 'app-form-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit, OnDestroy, FormField {
    @Input() formId;
    @Input() id;
    @Input() label;
    @Input() hint;
    @Input() value;
    @Input() hidden = false;
    @Input() countryId;
    @Input() required;
    @Input() autocomplete;
    @Input() placeholder = '-';
    @Input() match;
    @Input() errorMessage;
    @Input() isValid = true;
    @Input() type = 'text';
    @Input() useVat = true;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedVatChange: EventEmitter<any> = new EventEmitter<any>();
    vatOptions: string[] = [];
    _selectedVat: string;
    destroy$: Subject<boolean> = new Subject<boolean>();

    // Currency format details
    currencyGroupSymbol = NumberSymbol.CurrencyGroup;
    currencyDecimalSymbol = NumberSymbol.CurrencyDecimal;
    language;

    @Input()
    set selectedVat(val: any) {
        this._selectedVat = !isEmpty(val) ? val.toString() : this.vatOptions[0]?.toString();
    }

    constructor(private formManagementService: FormManagementService,
                private organisationManagementService: OrganisationManagementService,
                private localeService: LocaleService,
                private app: AppService,
                @Inject(DEFAULT_CURRENCY_CODE) public currencyId: string
    ) {
    }

    ngOnInit() {
        this.language = this.localeService.getLanguage();

        if (!this.countryId) {
            this.organisationManagementService.item$.subscribe(organisation => {
                this.countryId = organisation.country ?? 'NL';
            });
        }

        this.formManagementService.subscribe(this.formId, this);

        const vats = this.app.config('vat');
        let vatsOptionsFromCountry = vats.filter((vat: Vat) => vat.countryId === this.countryId);

        if (vatsOptionsFromCountry.length === 0) {
            vatsOptionsFromCountry = [vats[0]];
        }

        this.vatOptions = vatsOptionsFromCountry.map((vat: Vat) => vat.percentage);

        if (!this._selectedVat) {
            this._selectedVat = this.vatOptions[0]?.toString();
        }
    }

    inputChange() {
        this.valueChange.emit(this.value);
        this.selectedVatChange.emit(this._selectedVat);
        this.validate();
    }

    validate() {
        this.isValid = true;
        this.errorMessage = '';

        if (this.required && !this.value) {
            this.errorMessage = 'General.Required';
            this.isValid = false;
        }

        return this.isValid;
    }

    ngOnDestroy(): void {
        this.formManagementService.unsubscribe(this.formId, this);
        this.destroy$.next(null);
        this.destroy$.unsubscribe();
    }
}
