import { HttpParams } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceItem} from '../../models/price-item.class';
import {PricePlan} from '../../models/price-plan.class';
import {SubscriptionTypePrice} from '../../models/subscription-type-price.class';
import {mapTicketType, TicketType} from '../../models/ticket-type.class';
import {VenueSectionGroup} from '../../models/venue-section-group.class';
import {PriceService} from '../../services/price-service/price.service';
import {SubscriptionTypePriceService} from '../../services/subscription-type-price-service/subscription-type.service';
import {SubscriptionTypeService} from '../../services/subscription-type-service/subscription-type.service';
import {TicketTypeService} from '../../services/ticket-service/ticket.service';
import {VenueService} from '../../services/venue-service/venue.service';
import {SubscriptionType} from '../../models/subscription-type.class';
import {Event} from '../../models/event.class';
import {PricePlanHelper} from '../../helpers/price-plan-helper';
import {Translation} from '../../models/translation';
import {NotificationService} from '../../services/notification-service/notification.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-price-plan-table',
    templateUrl: './price-plan-table.component.html',
    styleUrls: ['./price-plan-table.component.scss']
})
export class PricePlanTableComponent implements OnInit {
    @Input() pricePlanId: string;
    @Input() venueSectionGroups: VenueSectionGroup[] = [];
    @Input() subscriptionTypePrices: SubscriptionTypePrice[] = [];
    @Input() venueId = null;
    @Input() event: Event = null;
    @Input() subscriptionType: SubscriptionType = null;
    @Input() showEditButtons = true;
    @Output() pricePlanChange = new EventEmitter<PricePlan>();
    @Input() pricePlanTemplates: PricePlan[];

    pricePlanHelper: PricePlanHelper;
    currencyId: string;
    venueCountryId: string;
    pricePlanTemplate: PricePlan;
    pricePlan: PricePlan;
    ticketTypePriceItems = [];
    subscriptionTypePriceItems: PriceItem[] = [];
    ticketTypes: TicketType[] = [];
    showTicketTypeForm = false;
    showSubscriptionTypePriceForm = false;
    showOverridePricePlanForm = false;
    isLoading = true;
    valueChanged = false;
    subscriptionTypePrice: SubscriptionTypePrice = new SubscriptionTypePrice();
    ticketType: TicketType = new TicketType();

    constructor(private ticketTypeService: TicketTypeService,
                private subscriptionTypeService: SubscriptionTypeService,
                private subscriptionTypePriceService: SubscriptionTypePriceService,
                private venueService: VenueService,
                private priceService: PriceService,
                private notificationService: NotificationService) {
        this.pricePlanHelper = new PricePlanHelper();
    }

    ngOnInit() {
        this.isLoading = true;
        this.getPricePlan();

        if (this.venueId) {
            this.venueService.getVenue(this.venueId).subscribe(venue => this.venueCountryId = venue.country);
        }

        if (!this.ticketType.nameTranslation) {
            this.ticketType.nameTranslation = Translation.empty();
        }
    }

    inputChanged(): void {
        this.valueChanged = true;
    }

    formatDataTable(): void {
        for (const ticketType of this.ticketTypes) {
            this.ticketTypePriceItems[ticketType.id] = this.pricePlanHelper.generatePriceItems(this.venueSectionGroups, ticketType);
        }

        for (const subscriptionTypePrice of this.subscriptionTypePrices) {
            this.subscriptionTypePriceItems[subscriptionTypePrice.id] =
                this.pricePlanHelper.generatePriceItems(this.venueSectionGroups, null, subscriptionTypePrice);
        }

        this.isLoading = false;
    }

    savePricePlan(): void {
        let priceItems = [];
        for (const ticketType of this.ticketTypes) {
            ticketType.prices = [];
            for (const priceItem of Object.values(this.ticketTypePriceItems[ticketType.id])) {
                (priceItem as PriceItem).ticketTypeId = ticketType.id;
                this.ticketType.prices.push(priceItem as PriceItem);
                priceItems.push(priceItem);
            }
        }

        for (const subscriptionTypePrice of this.subscriptionTypePrices) {
            for (const priceItem of Object.values(this.subscriptionTypePriceItems[subscriptionTypePrice.id])) {
                if (!priceItem) {
                    break;
                }

                (priceItem as PriceItem).pricePlanId = this.pricePlan.id;
                priceItems.push(priceItem);
            }
        }

        priceItems = priceItems.filter(priceItem => priceItem.price !== null);

        this.priceService.patchPricePlan(this.pricePlan.id, priceItems).subscribe({
            next: (pricePlanData: PricePlan) => {
                this.pricePlan = pricePlanData;
                this.pricePlanChange.emit(this.pricePlan);
                this.refreshPricePlanTable();
            }, error: () => {
            }, complete: () => {
                this.valueChanged = false;
            }
        });
    }

    saveSubscriptionTypePrice(subscriptionTypePrice: SubscriptionTypePrice) {
        subscriptionTypePrice.order = (this.subscriptionTypePrices.length + 1);
        subscriptionTypePrice.subscriptionTypeId = this.subscriptionType.id;

        this.subscriptionTypePriceService
            .postSubscriptionTypePrice(this.subscriptionType.id, subscriptionTypePrice)
            .subscribe(subscriptionTypePriceResult => {
                this.subscriptionTypePrice = new SubscriptionTypePrice();
                this.subscriptionTypePrices.push(subscriptionTypePriceResult);
                this.formatDataTable();
            });
    }

    saveTicketType(ticketType) {
        ticketType.order = (this.ticketTypes.length + 1);
        ticketType.pricePlan = this.pricePlan;

        if (this.event) {
            ticketType.eventId = this.event.id;
        }

        this.ticketTypeService.postTicketType(this.pricePlan.id, ticketType).subscribe(ticketTypeResult => {
            this.ticketType = new TicketType();
            this.ticketType.nameTranslation = Translation.empty();
            this.ticketTypes.push(mapTicketType(ticketTypeResult));
            this.notificationService.showTranslatedNotification('success', 'ticket_type', 'saved');
            this.formatDataTable();
            this.showTicketTypeForm = false;
        });
    }

    overridePricePlan() {
        this.pricePlan = null;
        this.showOverridePricePlanForm = false;
        this.priceService.clonePricePlan(this.pricePlanTemplate.id, this.event.id).subscribe(pricePlan => {
            this.pricePlan = pricePlan;
            this.event.pricePlanId = pricePlan.id;
        });
    }

    refreshPricePlanTable() {
        if (!this.venueId) {
            this.formatDataTable();
            return;
        }

        if (this.subscriptionType && this.subscriptionType.id) {
            this.subscriptionTypeService.getSubscriptionType(this.subscriptionType.id, new HttpParams().set('depth', '3')).subscribe(subscriptionType => {
                this.subscriptionTypePrices = subscriptionType.subscriptionTypePrices;
                this.formatDataTable();
            });
        } else if (this.pricePlan) {
            this.ticketTypeService.getTicketTypesByPricePlan(this.pricePlan.id, new HttpParams().set('depth', '2'))
                .pipe(map((ticketTypes: TicketType[]) => {
                    const tickets: TicketType[] = [];
                    ticketTypes.forEach((ticketType: TicketType) => {
                        tickets.push(mapTicketType(ticketType));
                    });
                    return tickets;
                }))
                .subscribe((ticketTypes: TicketType[]) => {
                    this.ticketTypes = ticketTypes;
                    this.formatDataTable();
                });
        }
    }

    setPricePlanTemplate(event) {
        this.pricePlanTemplates.forEach(pricePlanTemplate => {
            if (pricePlanTemplate.id === event.detail.value) {
                this.pricePlanTemplate = pricePlanTemplate;
            }
        });
    }

    getPricePlan() {
        this.priceService.getPricePlan(this.pricePlanId).subscribe(pricePlan => {
            this.pricePlan = pricePlan;

            if (this.venueId) {
                this.venueService.getVenueSectionsGroups(this.pricePlan.venueId).subscribe(venueSectionGroups => {
                    this.venueSectionGroups = venueSectionGroups;
                    this.refreshPricePlanTable();
                });
            } else {
                if (this.subscriptionType && this.subscriptionType.isMembership) {
                    this.venueSectionGroups = [new VenueSectionGroup()];
                }

                this.refreshPricePlanTable();
            }
        });
    }
}
