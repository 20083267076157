<div class="venue-section-name">{{ venueSection.name }}</div>

<div class="row">
    <div class="col-12">
        <ng-container *ngIf="venueSection.seats && venueSection.seats.length > 1">
            <div class="text-center f-s-20" style="background-color: #68B126; color: #FFFFFF;">
                {{ ('Venue_Section.Field' | trans) }}
            </div>

            <svg class="image-fluid seating" #svg
                 [attr.viewBox]="getViewBox(venueSection.seats)"
                 (mousedown)="removeSelectRectElement($event); handleSVGMouseDown($event)"
                 (mousemove)="handleSVGMouseMove($event)"
                 (mouseup)="removeSelectRectElement($event); handleSVGMouseUp()">
                <ng-container *ngFor="let seat of venueSection.seats">
                    <g (click)="activeTool === 'SELECT' && dragSelectSeat([seat])"
                       (mousemove)="showTooltip($event, getSeatInfo(seat));" (mouseout)="hideTooltip();">
                        <path [attr.transform]="getTransform(seat)"
                              [attr.class]="getVenueSectionSeatStatus(seat)"
                              d="M4,83.9v4.8c0,0,0,7.4,7.4,7.4h77.3c0,0,7.4,0,7.4-7.4v-4.8c0,0,0-7.4-7.4-7.4H11.4C11.4,76.5,4,76.5,4,83.9 M4,31v13 c0,0,0,26.4,0.1,27.1h92c0-0.4,0-26.7,0-27.1V31c0,0,0-27.1-30.7-27.1H34.7C34.7,3.9,4,3.9,4,31">
                        </path>
                        <text text-anchor="middle" [attr.transform]="getTextTransform(seat)"
                              style="font-size: 45px;"
                              class="f-w-700">{{ seat.seat }}
                        </text>
                    </g>
                </ng-container>

                <ng-container *ngIf="!isMultipleSubsections">
                    <ng-container *ngFor="let row of venueSectionSeatRows">
                        <text text-anchor="start" [attr.x]="row.startX" [attr.y]="row.startY"
                              style="font-size: 45px;"
                              class="f-w-700 row-number">{{ row.row }}
                        </text>
                        <text text-anchor="end" [attr.x]="row.endX"
                              [attr.y]="row.endY"
                              style="font-size: 45px;"
                              class="f-w-700 row-number">{{ row.row }}
                        </text>
                    </ng-container>
                </ng-container>
            </svg>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="legend">
                        <div class="row">
                            <div class="col-1 text-center"><span class="cm-icon cm-icon-sending available small"
                                                                 aria-hidden="true"></span></div>
                            <div class="col-3"><span
                                class="available">{{ ('Seatmap.Status_Available' | trans) }}</span>
                            </div>
                            <div class="col-1 text-center"><span class="cm-icon cm-icon-sending unavailable small"
                                                                 aria-hidden="true"></span></div>
                            <div class="col-3"><span
                                class="unavailable">{{ ('Seatmap.Status_Unavailable' | trans) }}</span></div>
                            <div class="col-1 text-center"><span class="cm-icon cm-icon-sending reserved small"
                                                                 aria-hidden="true"></span></div>
                            <div class="col-3"><span
                                class="reserved">{{ ('Seatmap.Status_Reserved' | trans) }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1 text-center"><span class="cm-icon cm-icon-sending active-order small"
                                                                 aria-hidden="true"></span></div>
                            <div class="col-3"><span
                                class="active-order">{{ ('Seatmap.Status_Selected' | trans) }}</span></div>
                            <div class="col-1 text-center"><span class="cm-icon cm-icon-sending issued small"
                                                                 aria-hidden="true"></span></div>
                            <div class="col-3"><span class="issued">{{ ('Seatmap.Status_Issued' | trans) }}</span>
                            </div>
                            <div class="col-1 text-center"><span
                                class="cm-icon cm-icon-sending issued-subscription small"
                                aria-hidden="true"></span></div>
                            <div class="col-3"><span
                                class="issued-subscription">{{ ('Seatmap.Status_Issued_Multiday' | trans) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-right">
                    <div class="form-group tool-switch m-r-0 m-b-8 m-t-0">
                        <div class="form-row">
                            <div class="form-switch-text-icon">
                                <input type="radio" id="radio3" name="switch1" value="PAN" [(ngModel)]="activeTool"
                                       checked/>
                                <label for="radio3" class="left">
                                    <span class="cm-icon cm-icon-move" aria-hidden="true"></span>
                                    <span class="text">Move</span>
                                </label>
                                <input type="radio" id="radio4" name="switch1" value="SELECT" [(ngModel)]="activeTool"/>
                                <label for="radio4" class="right">
                                    <span class="cm-icon cm-icon-mouse-tap" aria-hidden="true"></span>
                                    <span class="text">Select</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <br>

                    <button class="btn btn-primary-icon m-r-16" (click)="zoom('IN')">
                        <span class="cm-icon cm-icon-zoom-in" aria-hidden="true"></span>
                    </button>

                    <button class="btn btn-primary-icon m-r-16" (click)="zoom('OUT')">
                        <span class="cm-icon cm-icon-zoom-out" aria-hidden="true"></span>
                    </button>

                    <button class="btn btn-primary-icon" (click)="resetViewBox()">
                        <span class="cm-icon cm-icon-reset" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="venueSection.seats && venueSection.seats.length === 1">
        <div class="row">
            <div class="col-12">
                <app-order-capacity-list [event]="event"
                                         [subscriptionType]="subscriptionType"
                                         [venueSectionId]="venueSection.id"
                                         [venueSectionSeat]="venueSection.seats[0]"
                                         [venueSectionName]="venueSection.name"
                                         [venueSectionGroupId]="venueSection.venueSectionGroupId"
                                         [venueSectionAvailable]="venueSection.available">
                </app-order-capacity-list>
            </div>
        </div>
    </ng-container>
</div>

<div id="tooltip" style="position: absolute; display: none;"></div>
