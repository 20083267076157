import {Component, OnInit} from '@angular/core';
import {DownloadManagerService} from '../../services/download-manager-service/download-manager.service';
import {Observable} from 'rxjs';
import {Download} from '../../services/download-manager-service/types/download.interface';

@Component({
    selector: 'app-download-manager',
    templateUrl: './download-manager.component.html',
    styleUrls: ['./download-manager.component.css']
})
export class DownloadManagerComponent implements OnInit {
    download$: Observable<Download>;

    constructor(private downloadManagerService: DownloadManagerService) {
    }

    ngOnInit() {
        this.download$ = this.downloadManagerService.downloadSubject.asObservable();
    }

}
