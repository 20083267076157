import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CM_TICKETING_LIBRARY_CONFIG} from '../core/cm-ticketing-library.config.token';
import {CmTicketingLibraryConfig} from '../core/cm-ticketing-library.config';

@Injectable()
export class ResourceService {
    private baseUrl = this.cmTicketingLibraryConfig.RESOURCE_API_URL;
    private accountGuid: string;
    private defaultConfig = {
        withCredentials: true,
        params: {},
        headers: {}
    };

    constructor(@Inject(CM_TICKETING_LIBRARY_CONFIG) private cmTicketingLibraryConfig: CmTicketingLibraryConfig, private http: HttpClient) {
        if (typeof (window as any).getCmSSO === 'function' && (window as any).getCmSSO()) {
        this.accountGuid = (window as any).getCmSSO().UserData.AccountGuid;
        } else {
            this.accountGuid = '92d855d3-cafe-4a1f-8418-227b43f1ba2b';
        }

        this.defaultConfig.headers = {
            'X-CM-SSO-ACCOUNTGUID': this.accountGuid
        };
    }

    public uploadImage(image): Observable<any> {
        const file: File = image;
        const postBody = new FormData();
        postBody.append('file', file);

        return this.http.post(this.baseUrl + '/v1.0/resource', postBody, this.defaultConfig);
    }

    public getResource(resourceUuid: string): Observable<string> {
        return this.http.get<string>(this.baseUrl + '/v1.0/resource/' + resourceUuid + '/content', this.defaultConfig);
    }
}
