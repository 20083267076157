<ng-container *ngIf="updatedBlockades.length">
    <div class="action-footer">
        <div>
            <ng-container *ngIf="summary">
                {{ 'Blockade_Action_Footer.Summary' | trans | tags: [
                {key: 'seats', value: summary.seats.length},
                {key: 'blockades', value: summary.blockades.length}
            ]}}
            </ng-container>
        </div>

        <div class="actions">
            <cm-button
                [attr.data-label]="('Blockade_Action_Footer.Button.Discard' | trans)"
                data-button-style="secondary"
                data-button-type="text"
                data-button-size="medium"
                class="m-r-8"
                (click)="cancelChanges()">
            </cm-button>

            <!--            <cm-button-->
            <!--                [attr.data-label]="('Blockade_Action_Footer.Button.Review' | trans)"-->
            <!--                data-button-style="secondary"-->
            <!--                data-button-type="text"-->
            <!--                data-button-size="medium"-->
            <!--                class="m-r-8"-->
            <!--                (click)="openReviewDialog()">-->
            <!--            </cm-button>-->

            <cm-button
                data-icon="save"
                [attr.data-label]="('Blockade_Action_Footer.Button.Save' | trans)"
                data-button-style="cta"
                data-button-type="icon-text"
                data-button-size="medium"
                (click)="openSaveDialog()">
            </cm-button>
        </div>
    </div>
</ng-container>

<app-dialog #cancelDialog [title]="'Blockade_Action_Footer.Cancel_Dialog.Title' | trans" [scope]="'CONFIRM'"
            (save)="onCancelDialogConfirm()">
    <div class="dialog-body">
        {{ 'Blockade_Action_Footer.Cancel_Dialog.Body' | trans }}
    </div>
</app-dialog>

<app-dialog #reviewDialog [title]="'Blockade_Action_Footer.Review_Dialog.Title' | trans" [width]="640"
            [scope]="'ACKNOWLEDGE'">
    <div class="dialog-body">
        {{ 'Blockade_Action_Footer.Review_Dialog.Body' | trans }}
    </div>

    <pre>{{ updatedBlockades | json }}</pre>
</app-dialog>

<app-dialog #saveDialog [title]="'Blockade_Action_Footer.Save_Dialog.Title' | trans" [width]="640"
            (save)="onSaveDialogConfirm($event)" [saveDisabled]="!selectedEventIds.length">
    <div class="dialog-body">
        {{ 'Blockade_Action_Footer.Save_Dialog.Body' | trans }}
    </div>

    <h4 class="m-b-0">{{ 'General.Events' | trans }}</h4>
    <div class="checkbox-list">
        <div class="form-group">
            <ng-container *ngFor="let event of events$ | async">
                <div class="form-row">
                    <div class="form-check">
                        <label>
                            <input type="checkbox" (change)="onEventChange($event, event.id)"
                                   [checked]="selectedEventIds.includes(event.id)">
                            <span class="label">{{ event.name }}</span>
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</app-dialog>
