import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {AnalyticsDashboardItem, mapAnalyticsDashboardItem} from '../../models/analytics-dashboard-item.class';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsDashboardItemService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config  = {
        withCredentials: true,
        params:          {}
    };

    constructor(private http: HttpClient) {
    }

    postAnalyticsDashboardItem(
        analyticsDashboardId: string,
        analyticsDashboardItem: AnalyticsDashboardItem,
        params?: HttpParams
    ): Observable<AnalyticsDashboardItem> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<AnalyticsDashboardItem>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item`, analyticsDashboardItem, config).pipe(
            map(item => {
                return mapAnalyticsDashboardItem(item);
            })
        );
    }

    patchAnalyticsDashboardItem(
        analyticsDashboardId: string,
        analyticsDashboardItemId: string,
        analyticsDashboardItem: AnalyticsDashboardItem,
        params?: HttpParams
    ): Observable<AnalyticsDashboardItem> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<AnalyticsDashboardItem>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item/${analyticsDashboardItemId}`, analyticsDashboardItem, config).pipe(
            map(item => {
                return mapAnalyticsDashboardItem(item);
            })
        );
    }

    deleteAnalyticsDashboardItem(
        analyticsDashboardId: string,
        analyticsDashboardItemId: string,
        params?: HttpParams
    ): Observable<any> {
        const config  = Object.assign({}, this.config);
        config.params = params;

        return this.http.delete<any>
        (this.baseUrl + `/v1.0/analytics/dashboard/${analyticsDashboardId}/item/${analyticsDashboardItemId}`, config);
    }
}
