<h3>{{ ('General.Campaign' | trans) }}</h3>
@if (campaignsLoaded) {
    <cm-select [value]="campaignId">
        <cm-option
                slot="option"
                data-display="{{ 'General.None' | trans }}"
                value="null"
                (cm-option-clicked)="campaignIdSelected.emit(null)"></cm-option>
        <cm-option *ngFor="let campaign of campaigns"
                   slot="option"
                   data-display="{{ campaign.title }}"
                   value="{{ campaign.id }}"
                   (cm-option-clicked)="campaignIdSelected.emit($event.detail.value)">
        </cm-option>
    </cm-select>
} @else {
    <cm-indicator></cm-indicator>
}
