import {ModelBase} from '../abstract/model-base';

export class OrderDetailsOrderTag extends ModelBase {
    public id: string;
    public name: string;
    public isLocked: boolean;
    public createdAt: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsOrderTag {
        const model = new OrderDetailsOrderTag();

        model.id = data.id;
        model.name = data.name;
        model.isLocked = data.isLocked;
        model.createdAt = data.createdAt;

        return model;
    }
}
