import { HttpParams } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Venue} from '../../models/venue.class';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {NotificationService} from '../../services/notification-service/notification.service';
import {VenueService} from '../../services/venue-service/venue.service';
import {GlobalizationService} from '../../services/globalization-service/globalization.service';
import {Observable} from 'rxjs';
import {Country} from '../../models/country.class';
import {FormManagementService} from '../../services/form-management.service';
import {CmTranslationService} from '../../../core/services/cm-translation.service';
import { Resource } from '../../models/event-resource.class';
import { Translation } from '../../models/translation';

@Component({
    selector: 'app-venue-form',
    templateUrl: './venue-form.component.html',
    styleUrls: ['./venue-form.component.css']
})
export class VenueFormComponent implements OnInit {
    @Input() venue: Venue;
    @Input() context: string;
    @Output() onVenueSave: EventEmitter<Venue> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    mapLink: any;
    newVenue = false;

    countries$: Observable<Country[]>;

    stageTypes: { id: string, name: string }[] = [];

    constructor(private venueService: VenueService,
                private notificationService: NotificationService,
                private router: Router,
                private sanitizer: DomSanitizer,
                private dialogService: DialogService,
                private globalizationService: GlobalizationService,
                private formManagementService: FormManagementService,
                private translationService: CmTranslationService) {
    }

    ngOnInit() {
        if (!this.venue) {
            this.venue = new Venue();
            this.newVenue = true;
        }

        if (!this.venue.nameTranslation) {
            this.venue.nameTranslation = Translation.empty();
        }

        this.countries$ = this.globalizationService.getCountries();

        this.stageTypes.push({
            id: 'FIELD',
            name: this.translationService.getPhraseForLanguage(`Venue.Stage_Type_FIELD`)
        });

        this.stageTypes.push({
            id: 'STAGE',
            name: this.translationService.getPhraseForLanguage(`Venue.Stage_Type_STAGE`)
        });

        this.stageTypes.push({
            id: 'COURSE',
            name: this.translationService.getPhraseForLanguage(`Venue.Stage_Type_COURSE`)
        });

        this.stageTypes.push({
            id: 'COURT',
            name: this.translationService.getPhraseForLanguage(`Venue.Stage_Type_COURT`)
        });
    }

    saveVenue(venue: Venue) {
        if (this.formManagementService.validate('venueForm') && venue.country) {
            if (this.newVenue) {
                this.venueService.postVenue(venue).subscribe(venueData => {
                    this.notificationService.showTranslatedNotification('success', 'venue', 'saved', {
                        label: venueData.name
                    });
                    if (this.context === 'NEW_VENUE') {
                        return this.router.navigate(['/settings/venues', venueData.id]);
                    }

                    if (this.context === 'NEW_EVENT') {
                        this.onVenueSave.emit(venueData);
                    }
                });
            } else {
                this.venueService.updateVenue(venue, new HttpParams().set('depth', '4').set('venueSection[seats]', 'true')).subscribe(venueResult => {
                    this.venue = venueResult;
                    this.notificationService.showTranslatedNotification('success', 'venue', 'saved', {
                        label: venue.name
                    });
                    this.mapLink = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.openstreetmap.org/export/embed.html?bbox=${this.venue.longitude},${this.venue.latitude}&zoom=17&layer=mapnik&marker=${this.venue.latitude},${this.venue.longitude}`);
                });
            }
        } else {
            this.notificationService.showTranslatedError('Fill_Out_All_Fields');
        }
    }

    cancel() {
        this.onCancel.emit();
    }

    setResource(resource: Resource){
        this.venue.resource = resource;
    }

}
