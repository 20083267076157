export class TicketTemplateInformation {
    public id: string;
    public name: string;
    public width_points: number;
    public height_points: number;
    public orientation: string;
    public dots_per_inch: number;

    constructor(data) {
        this.mapTicketTemplateInformation(data);
    }

    mapTicketTemplateInformation(data): TicketTemplateInformation {
        this.id = data.id;
        this.name = data.name;
        this.width_points = data.width_points;
        this.height_points = data.height_points;
        this.orientation = data.orientation;
        this.dots_per_inch = data.dots_per_inch;

        return this;
    }
}
