<ng-container *ngIf="statisticGroups; else loadingSmall">
    <ng-container *ngFor="let statisticGroup of statisticGroups">
        <h5>{{statisticGroup.name}}</h5>
        <div class="datatable m-b-64">
            <div class="datatable-header">
                <div class="datatable-header-item col">{{('General.Name' | trans)}}</div>

                <ng-container *ngFor="let statistic of statisticGroup.items[0].statistics">
                    <div class="datatable-header-item col">{{'Statistic.' + statistic.id | trans}}</div>
                </ng-container>
            </div>

            <ng-container *ngFor="let item of statisticGroup.items">
                <div class="datatable-row" [class.fade-out]="isLoading">
                    <div class="datatable-row-item col">{{item.name}}</div>

                    <ng-container *ngFor="let statistic of item.statistics">
                        <div class="datatable-row-item col">
                            <ng-container *ngIf="statistic.type === 'PERCENTAGE'">
                                {{statistic.value}}%
                            </ng-container>
                            <ng-container *ngIf="statistic.type === 'CURRENCY'">
                                {{statistic.value | currency}}
                            </ng-container>
                            <ng-container *ngIf="statistic.type === 'NUMBER'">
                                {{statistic.value | number}}
                            </ng-container>
                            <ng-container *ngIf="statistic.type === 'TEXT'">
                                {{statistic.value}}
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>
