<cm-dialog [id]="id">

    <h3 slot="header" class="dialog-title">
        {{('Dialog_Ring_Settings.Title' | trans)}}
    </h3>

    <div slot="body" class="form-group">

        <div class="form-row">
            <span class="form-input">
                <input type="text" class="form-control" [(ngModel)]="venueSection.name">
                <label>{{('Venue_Section.Name' | trans)}}</label>
            </span>
        </div>

        <div class="form-row">
            <span class="form-input">
                <input type="text" class="form-control" [(ngModel)]="venueSection.subtitle">
                <label>{{('Venue_Section.Subtitle' | trans)}}</label>
            </span>
        </div>

        <!--        <div class="form-row">-->
        <!--            <app-input-translation-->
        <!--                [(value)]="venueSection.subtitle"-->
        <!--                [required]="true"-->
        <!--                [type]="'text'"-->
        <!--                [translation]="venueSection.subtitleTranslation"-->
        <!--                [label]="('Venue_Section.Subtitle' | trans)"></app-input-translation>-->
        <!--        </div>-->

        <div class="form-row">
            <span class="form-input">
                <cm-color-picker [attr.data-label]="('Venue_Section.Color' | trans)"
                                 [attr.data-color]="venueSection.color"
                                 (change)="venueSection.color = $event.detail.hex"
                                 data-hide-alpha></cm-color-picker>
            </span>
        </div>
    </div>

    <div slot="footer">
        <button class="btn btn-secondary" (click)="cancel()">{{('General.Cancel' | trans)}}</button>
        <button class="btn btn-primary" (click)="confirm()">{{('General.Save' | trans)}}</button>
    </div>

</cm-dialog>
