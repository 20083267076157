<ng-container *ngIf="campaign; else loading">
    <div class="form-group">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="page-header">
                    <div class="row">
                        <div class="breadcrumb">
                            <a class="breadcrumb-item" [routerLink]="['/settings']">{{('Menu.Settings' | trans)}}</a>
                            <a class="breadcrumb-item"
                               [routerLink]="['/settings/campaigns']">{{('Campaign_List.Campaigns' | trans)}}</a>
                            <a class="breadcrumb-item"></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h1 class="title">{{campaign.id ? campaign.name : ('Campaign_Detail.New_Campaign' | trans)}}</h1>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="form-row">
                        <app-form-input [(value)]="campaign.name"
                                        [label]="('General.Name' | trans)">
                        </app-form-input>
                    </div>
                    <div class="form-row">
                        <div class="row">
                            <div class="col-12 col-md-10">
                                <cm-date-time-picker
                                    [attr.data-label]="('General.Start_At' | trans)"
                                    [attr.data-datetime]="campaign.startAt"
                                    data-format="dd MMMM yyyy HH:mm"
                                    (change)="startDateChanged($event)">
                                </cm-date-time-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-md-block col-md-6">
                <div class="m-l-16">
                    <cm-image data-type="mailman"></cm-image>
                </div>
            </div>
        </div>
    </div>

    <div class="page-section m-t-32 m-b-32">
        <div class="row">
            <div class="col-12 col-md-6 d-flex justify-content-between">
                <cm-button
                    data-icon="previous"
                    data-button-style="secondary"
                    data-button-type="icon-text"
                    (click)="navigateBack()"
                    [attr.data-button-size]="buttonSize"
                    [attr.data-label]="('Stepper_Step.Actions.Back' | trans)">
                </cm-button>
                <div>
                    <cm-button
                        *ngIf="!isNewCampaign"
                        data-button-style="secondary"
                        data-button-type="text"
                        class="btn-error"
                        (click)="openDeleteCampaignDialog()"
                        [attr.data-label]="('General.Delete' | trans)">
                    </cm-button>
                    <cm-button
                        data-button-style="cta"
                        data-button-type="text"
                        (click)="saveCampaign()"
                        [attr.data-button-size]="buttonSize"
                        [attr.disabled]="campaign.name && campaign.startAt ? null : 'true'"
                        [attr.data-label]="('General.Save' | trans)">
                    </cm-button>
                </div>
            </div>
            <div class="col-6">
            </div>
        </div>
    </div>

    <app-dialog #deleteCampaignDialog
                [title]="('Campaign_Detail.Delete_Campaign.Title' | trans)"
                (save)="onDeleteCampaignConfirm()">
        {{ 'Campaign_Detail.Delete_Campaign.Body' | trans}}
    </app-dialog>
</ng-container>


<ng-template #loading>
    <cm-indicator></cm-indicator>
</ng-template>

<ng-template #loadingSmall>
    <cm-indicator class="small"></cm-indicator>
</ng-template>
