import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';
import {CostItemAgreement} from '../../../models/cost-item-agreement';

@Component({
    selector: 'app-dialog-cost-item-agreement',
    templateUrl: './dialog-cost-item-agreement.component.html',
    styleUrls: ['./dialog-cost-item-agreement.component.css']
})
export class DialogCostItemAgreementComponent extends DialogBase<IInput, IOutput> {
    public costItemAgreement: CostItemAgreement;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.costItemAgreement = data.extraData;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: this.costItemAgreement
        });
    }

    public cancel() {
        super.cancel();
    }

}
