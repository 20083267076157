import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cm-ticketing-pvccard-default-1',
    templateUrl: './ticket-template-pvccard-default-1.component.html',
    styleUrls: ['./ticket-template-pvccard-default-1.component.scss', '../../shared/shared-presets.scss']
})
export class TicketTemplatePvccardDefault1Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
