import {HttpParamsHelper} from '../http-params/http-params-helper';
import {ProductGroupType} from '../../enums/order/product-group-type';

export class OrderItemReservationRequestHelper extends HttpParamsHelper {
    public static getRequestBody(
        orderItemType: ProductGroupType,
        id: string = null,
        seatId: string = null,
        itemId: string = null,
        venueSectionAccessId: string = null,
        note: string = null,
        price: number = null
    ): any {
        let requestObject;
        switch (orderItemType) {
            case ProductGroupType.EVENT_TICKET:
                requestObject = {
                    ticket: {
                        eventId: id,
                        venueSectionSeatId: seatId,
                        ticketTypeId: itemId,
                        venueSectionAccessId,
                        note,
                        price
                    }
                };
                break;
            case ProductGroupType.SUBSCRIPTION:
                requestObject = {
                    subscription: {
                        subscriptionTypeId: id,
                        subscriptionTypePriceId: itemId,
                        venueSectionSeatId: seatId,
                        venueSectionAccessId,
                        note,
                        price
                    }
                };
                break;
            case ProductGroupType.PRODUCT:
                requestObject = {
                    product: {
                        upsellItemTypeId: id,
                        upsellItemTypePriceId: itemId,
                        note,
                        price
                    }
                };
                break;
        }

        return requestObject;
    }
}
