import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EventCategory} from '../../models/event-category.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class EventCategoryService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getEventCategories(params?: HttpParams): Observable<EventCategory[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<EventCategory[]>(this.baseUrl + `/v1.0/event/category`, config);
    }

    getEventCategory(eventCategoryId: string, params?: HttpParams): Observable<EventCategory> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<EventCategory>(this.baseUrl + `/v1.0/event/category/${eventCategoryId}`, config);
    }

    postEventCategory(eventCategory: EventCategory, params?: HttpParams): Observable<EventCategory> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<EventCategory>(this.baseUrl + `/v1.0/event/category`, eventCategory, config);
    }

    updateEventCategory(eventCategoryId: string, eventCategory: EventCategory, params?: HttpParams): Observable<EventCategory> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.put<EventCategory>(this.baseUrl + `/v1.0/event/category/${eventCategoryId}`, eventCategory, config);
    }

}
