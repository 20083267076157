<ng-container *ngIf="venueSection">
    <ng-container *ngIf="options.showName">
        <div class="venue-section-name">{{ venueSection.name }}</div>
    </ng-container>

    <svg #svg [attr.viewBox]="viewBox"
         (mousedown)="svgMouseDown($event)"
         (mousemove)="svgMouseMove($event)"
         (mouseup)="svgMouseUp($event)"
         (mouseleave)="svgMouseLeave($event)">
        <ng-container *ngFor="let seat of venueSection.seats">
            <g (mousedown)="seatMouseDown($event, seat)"
               (mousemove)="seatMouseMove($event, seat)"
               (mouseenter)="seatMouseEnter($event, seat)"
               (mouseout)="seatMouseOut($event, seat)"
               [class.processing]="seat._isProcessing"
               [attr.class]="getVenueSectionSeatStatus(seat)">
                <rect [attr.transform]="seat._transform" class="seat-background"></rect>
                <path [attr.transform]="seat._transform"
                      d="M4,83.9v4.8c0,0,0,7.4,7.4,7.4h77.3c0,0,7.4,0,7.4-7.4v-4.8c0,0,0-7.4-7.4-7.4H11.4C11.4,76.5,4,76.5,4,83.9 M4,31v13 c0,0,0,26.4,0.1,27.1h92c0-0.4,0-26.7,0-27.1V31c0,0,0-27.1-30.7-27.1H34.7C34.7,3.9,4,3.9,4,31, M4,83.9v4.8c0,0,0,7.4,7.4,7.4h77.3c0,0,7.4,0,7.4-7.4v-4.8c0,0,0-7.4-7.4-7.4H11.4C11.4,76.5,4,76.5,4,83.9">
                </path>
                <text text-anchor="middle"
                      [attr.transform]="seat._textTransform">{{seat.seat}}
                </text>
                <ng-container *ngIf="seat._blockadeId">
                    <rect [attr.transform]="seat._blockadeTransform"
                          class="blockade-background"
                          [attr.fill]="seat._color"
                          rx="8" ry="8"></rect>
                    <ng-container *ngIf="seat._label">
                        <text text-anchor="middle" class="blockade-label"
                              [attr.transform]="seat._blockadeTextTransform">{{seat._label}}
                        </text>
                    </ng-container>
                </ng-container>
            </g>
        </ng-container>

        <ng-container *ngIf="!isMultipleSubsections && venueSectionSeatRows">
            <ng-container *ngFor="let row of venueSectionSeatRows">
                <text text-anchor="start" [attr.x]="row.startX" [attr.y]="row.startY"
                      style="font-size: 45px;"
                      class="f-w-700 row-number">{{row.row}}</text>
                <text text-anchor="end" [attr.x]="row.endX"
                      [attr.y]="row.endY"
                      style="font-size: 45px;"
                      class="f-w-700 row-number">{{row.row}}</text>
            </ng-container>
        </ng-container>
    </svg>
        <div class="legend">
            <ng-container *ngIf="options.showLegend || options.showBlockadesLegend">
                <ng-container *ngIf="showingLegend">
                    <ng-container *ngFor="let legendItem of legendItems">
                        <app-seat-map-legend-item
                            [color]="legendItem.color">{{ legendItem.label }}</app-seat-map-legend-item>
                    </ng-container>
                </ng-container>
                <cm-button
                    class="legend-hider"
                    *ngIf="showingLegend"
                    data-icon="hide"
                    data-button-style="cta"
                    data-button-type="icon"
                    data-button-size="small"
                    (click)="showingLegend = false">
                </cm-button>
                <cm-button
                    class="legend-hider"
                    *ngIf="!showingLegend"
                    data-icon="show"
                    data-button-style="cta"
                    data-button-type="icon"
                    data-button-size="small"
                    (click)="showingLegend = true">
                </cm-button>
            </ng-container>
        </div>

    <ng-container *ngIf="options.showOrderButton">
        <cm-button [attr.data-label]="'Add to order'"></cm-button>
    </ng-container>

    <ng-container *ngIf="tooltip$ | async as tooltip">
        <app-seat-map-tooltip [tooltipConfig]="tooltip"
                              [innerHTML]="tooltip.content | byPassSecurity"></app-seat-map-tooltip>
    </ng-container>

</ng-container>
