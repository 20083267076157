import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges
} from '@angular/core';
import {StepperStepComponent} from '../stepper-step/stepper-step.component';

@Component({
    selector: 'app-stepper-page',
    templateUrl: './stepper-page.component.html',
    styleUrls: ['./stepper-page.component.scss']
})
export class StepperPageComponent implements OnInit, AfterContentInit, OnChanges {

    @Input() config: StepperConfig;

    @ContentChildren(StepperStepComponent) steps: QueryList<StepperStepComponent>;

    public currentStep: number;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngAfterContentInit(): void {
        this.steps.forEach(step => {
            step.previous.subscribe(() => {
                this.previousStep();
            });

            step.next.subscribe(() => {
                this.nextStep();
            });

            step.advanced.subscribe(() => {
                this.advancedStep();
            });
        });

        this.steps.first.activate();

        this.currentStep = 0;

        this.steps.last.isLastStep = true;

        if (this.config.hasAdvancedStep) {
            this.steps.get(this.steps.length - 2).isLastStep = true;
            this.steps.get(this.steps.length - 2).hasAdvancedStep = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.steps) {
            return;
        }

        this.steps.get(this.steps.length - 2).hasAdvancedStep = changes.config.currentValue.hasAdvancedStep;
    }

    onStepClick(stepIndex: any): void {
        this.steps.forEach(step => step.deactivate());

        this.currentStep = stepIndex;
        this.steps.get(this.currentStep).activate();
    }

    public previousStep(): void {
        this.steps.forEach(step => step.deactivate());

        if (this.currentStep === 0) {
            this.config.onCancel();
            return;
        }

        this.currentStep > 0 ? this.currentStep-- : this.currentStep = 0;
        this.steps.get(this.currentStep).activate();
    }

    public nextStep(): void {
        if (this.steps.get(this.currentStep).nextDisabled) {
            return;
        }

        this.steps.forEach(step => step.deactivate());

        if (this.steps.get(this.currentStep).isLastStep) {
            this.config.onComplete();
            return;
        }

        this.currentStep < this.steps.length
            ? this.currentStep++
            : this.currentStep = this.steps.length;

        this.steps.get(this.currentStep).activate();
    }

    public advancedStep(): void {
        this.steps.forEach(step => step.deactivate());
        let advancedStepIndex: number;

        this.steps.forEach((step, index) => {
            if (step.hasAdvancedStep) {
                advancedStepIndex = index + 1;
            }
        });

        this.currentStep = this.steps.length - 1;

        const advancedStep = this.steps.get(advancedStepIndex);
        advancedStep?.activate();
    }

}

export class StepperConfig {
    titleTranslationKey: string;
    breadcrumbTranslationKey: string;
    illustration: 'introduction' | 'noResults' | 'empty' | 'saveCheck' | 'mobileMarketingCloud' | 'mobileShopping' | 'mailman' | 'mailman2' | 'underConstruction' | 'noMessages' | 'help' | 'ticketingGirl' | 'ticketingBoy' | 'scientist' | 'sign' | 'verification' | 'welcome' | 'success' | 'error';
    hasAdvancedStep: boolean;
    onCancel: () => any;
    onComplete: () => any;
}
