export class BulkSelect {
    method: BulkSelectMethod = BulkSelectMethod.ONLY;
    selectedIds: string[] = [];
    totalSelected: number | undefined;
    total: number | undefined;

    constructor(method: BulkSelectMethod = BulkSelectMethod.ONLY, selectedIds: string[] = []) {
        this.method = method;
        this.selectedIds = selectedIds;
        this.totalSelected = 0;
    }
}

export enum BulkSelectMethod {
    ALL = 'ALL',
    ONLY = 'ONLY',
    EXCEPT = 'EXCEPT'
}
