<div class="row">
    <ng-container *ngIf="categories">
        <ng-container *ngFor="let category of categories">
            <div class="row list-row m-b-16">
                <div class="col-12 list-row-item" [class.col-md-4]="columns > 1">
                    <span class="f-s-14">{{category[categoryName]}}</span><br>
                    <span class="header-link" (click)="onSelectAllClicked(category[optionsKey])">
                        {{('List_Picker.Select_All' | trans)}}
                    </span> /
                    <span class="header-link" (click)="onDeselectAllClicked(category[optionsKey])">
                        {{('List_Picker.Deselect_All' | trans)}}
                    </span>
                </div>
                <div class="col-12" [class.col-md-8]="columns > 1">
                    <div class="row">
                        <ng-container *ngFor="let option of category[optionsKey]">
                            <div class="col-12"
                                 [class.col-sm-12]="columns === 1"
                                 [class.col-sm-6]="columns === 2"
                                 [class.col-sm-4]="columns === 3">
                                <div class="form-check m-b-8">
                                    <label>
                                        <input type="checkbox"
                                               [checked]="inSelection(option)"
                                               (change)="updateSelection(option)">
                                        <span class="label">
                                            {{option[optionName]}}
                                            <ng-container *ngIf="optionSubtitle">
                                                <div class="subtitle">{{option[optionSubtitle]}}</div>
                                            </ng-container>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
