import {Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ModelBase} from '../../../models/abstract/model-base';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {

    protected constructor(private http: HttpClient) {
    }

    protected baseUrl = environment.CM_API_URL;
    private config = {
        withCredentials: true,
        params: {}
    };

    private static getRequestBody(body: any): any {
        const copyOfBody = {...body};
        if (copyOfBody.routes) {
            delete copyOfBody.routes;
        }

        return copyOfBody;
    }

    abstract getBaseUrl(): string;

    private getRoute(relativeRoute: string, routeParams): string {
        if (!relativeRoute) {
            return '';
        }

        const modelRoute = this.replaceRouteParams(relativeRoute, routeParams);
        return `${this.getBaseUrl()}/${modelRoute}`;
    }

    get<M>(model: ModelBase, routeParams: object, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;

        return this.http.get<M>(this.getRoute(model.routes.get, routeParams), config).pipe(
            map((response: any) => {
                if (response.body) {
                    return model.mapModel(response.body);
                }

                return model.mapModel(response);
            })
        );
    }

    getMany<M>(model: ModelBase, httpParams?: HttpParams, routeParams?: object): Observable<M[]> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;
        let route = `${this.getBaseUrl()}/${model.routes.getMany}`;

        if (!model.routes.getMany) {
            return of([]);
        }

        if (routeParams) {
            route = this.getRoute(model.routes.getMany, routeParams);
        }

        return this.http.get<M[]>(route, config).pipe(
            map(responses => {
                if ((responses as any).body) {
                    return (responses as any).body.map(response => model.mapModel(response));
                }

                return responses.map(response => model.mapModel(response));
            })
        );
    }

    put<M>(model: ModelBase, routeParams: object, body: any, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;
        const route = model.routes.put;

        body = ApiService.getRequestBody(body);

        return this.http.put<M>(this.getRoute(route, routeParams), body, config).pipe(
            map(response => model.mapModel(response))
        );
    }

    post<M>(model: ModelBase, routeParams: object, body: any, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;
        const route = model.routes.post;

        body = ApiService.getRequestBody(body);

        return this.http.post<M>(this.getRoute(route, routeParams), body, config).pipe(
            map(response => model.mapModel(response))
        );
    }

    postExtra<M>(model: ModelBase, routeName: string, routeParams: object, body: any, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign(this.config, {params: httpParams});
        const route = model.routes[routeName];

        body = ApiService.getRequestBody(body);

        return this.http.post<M>(this.getRoute(route, routeParams), body, config).pipe(
            map(response => model.mapModel(response))
        );
    }

    postBulk<M>(model: ModelBase, routeParams: object, body: any[], httpParams?: HttpParams): Observable<M[]> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;
        const route = model.routes.postBulk;

        for (let itemBody of body) {
            itemBody = ApiService.getRequestBody(itemBody);
        }

        return this.http.post<M[]>(this.getRoute(route, routeParams), body, config).pipe(
            map(response => response.map(item => model.mapModel(item)))
        );
    }

    patch<M>(model: ModelBase, routeParams: object, body: any, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;
        const route = model.routes.patch;

        return this.http.patch<M>(this.getRoute(route, routeParams), ApiService.getRequestBody(body), config).pipe(
            map(response => {
                if ((response as any).body) {
                    return model.mapModel((response as any).body);
                }

                return model.mapModel(response);
            })
        );
    }

    delete<M>(model: ModelBase, routeParams: object, httpParams?: HttpParams): Observable<M> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;

        return this.http.delete<M>(this.getRoute(model.routes.delete, routeParams), config);
    }

    postCustom<M>(route: string, body: object, httpParams?: HttpParams): Observable<M|M[]> {
        const config = Object.assign({}, this.config);
        config.params = httpParams;

        return this.http.post<M>(`${this.getBaseUrl()}/${route}`, body, config);
    }

    getManyHttpResponse<M extends ModelBase>(model: M, routeParams: object, httpParams?: HttpParams): Observable<HttpResponse<M[]>> {
        const config = Object.assign({}, {...this.config, observe: undefined});
        config.params = httpParams;
        config.observe = 'response';

        return this.http.get<M[]>(this.getRoute(model.routes.getMany, routeParams), config).pipe(
            map((response: HttpResponse<M[]>) => {
                const body = response.body.map((item: M) => {
                    return model.mapModel(item);
                });
                return new HttpResponse({body: body, headers: response.headers});
            })
        );
    }

    private replaceRouteParams(route: string, params: {}): string {
        Object.keys(params).forEach(param => {
            route = route.replace(`:${param}`, params[param]);
        });

        return route;
    }
}
