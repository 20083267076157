import {Component} from '@angular/core';
import {DialogBase, DialogService, IInput, IOutput} from '../../../services/dialog-service/dialog.service';

@Component({
    selector: 'app-dialog-export-visitors',
    templateUrl: './dialog-export-visitors.component.html',
    styleUrls: ['./dialog-export-visitors.component.scss']
})
export class DialogExportVisitorsComponent extends DialogBase<IInput, IOutput> {
    public titleText: any;
    public bodyText: any;
    public confirmText: string;
    public extraData: any;

    includeCustomFields: boolean;

    constructor(dialogService: DialogService) {
        super(dialogService);
    }

    setModal(data: IInput) {
        this.titleText = data.titleText;
        this.bodyText = data.bodyText;
        this.confirmText = data.confirmText;
    }

    public confirm() {
        super.confirm({
            successful: true,
            extraData: {
                includeCustomFields: this.includeCustomFields
            }
        });
    }

    public cancel() {
        super.cancel();
    }

    onCustomFieldsChanged(enabled: boolean) {
        this.includeCustomFields = enabled;
    }

}
