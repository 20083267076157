<ng-container *ngFor="let stat of mappedStatistics">
    <div class="stat" [class.fade-out]="isLoading">
        <div class="stat-value">
            <ng-container *ngIf="stat.type === 'PERCENTAGE'">
                {{ stat.value }}%
            </ng-container>
            <ng-container *ngIf="stat.type === 'CURRENCY'">
                {{ stat.value | currency }}
            </ng-container>
            <ng-container *ngIf="stat.type === 'NUMBER'">
                {{ stat.value | number }}
            </ng-container>
            <ng-container *ngIf="stat.type === 'TEXT'">
                {{ stat.value }}
            </ng-container>
        </div>
        <div class="row">
            <div class="stat-label col-12">
                {{ 'Statistic.' + stat.id | trans }}
            </div>
        </div>
    </div>
</ng-container>
