<cm-dialog [id]="id" data-max-width="640px">
    <h3 slot="header" class="dialog-title">
        {{('Visitors.Export' | trans)}}
    </h3>

    <div slot="body">
        <div class="form-group">
            <div class="form-check">
                <label>
                    <input type="checkbox" (change)="onCustomFieldsChanged($event.target.checked)">
                    <span class="label">{{('Customer_Export.Custom_Field' | trans)}}</span>
                </label>
            </div>
        </div>
    </div>

    <div slot="footer">
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-primary" (click)="confirm()">{{('General.Download' | trans)}}</button>
            </div>
        </div>
    </div>
</cm-dialog>
