import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ReportExport} from '../../models/report-export.class';

@Component({
    selector: 'app-report-export-list-icon',
    templateUrl: './report-export-list-icon.component.html',
    styleUrls: ['./report-export-list-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportExportListIconComponent {

    @Input()
    reportExport: ReportExport;

}
