<ng-container *ngIf="label">
    <label>{{ label }}</label>
</ng-container>

<ng-container *ngIf="!file?.link">
    <button class="btn btn-primary" (click)="onFileSelectClicked()">Select File</button>
</ng-container>

<ng-container *ngIf="file?.link">
    <div class="file-preview">
        <ng-container [ngSwitch]="file.type">
            <ng-container *ngSwitchCase="'IMAGE'">
                <div class="image-container">
                    <img [src]="file.link" [alt]="file.name">
                    <div class="edit-overlay">
                        <button class="btn btn-secondary-icon cl-selected-color bg-white" (click)="fileEditClicked()">
                            <span class="cm-icon cm-icon-edit" aria-hidden="true"></span>
                        </button>
                        <ng-container *ngIf="isRemoveAllowed">
                            <button class="btn btn-secondary-icon cl-error-color bg-white remove-button"
                                    (click)="fileRemoveClicked()">
                                <span class="cm-icon cm-icon-close" aria-hidden="true"></span>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'PDF'">
                <div class="row">
                    <div class="col-10 truncate">
                        <span class="cm-icon cm-icon-pdf" aria-hidden="true"></span>
                        <span class="file-name">
                            {{ file.name }}
                        </span>
                    </div>
                    <div class="col-2 text-right">
                        <span class="cm-icon cm-icon-close remove-file-pdf" aria-hidden="true"
                              (click)="fileRemoveClicked()"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
