import {registerLocaleData} from '@angular/common';

const supportedLocales = ['en-gb', 'en-us', 'nl', 'nl-nl', 'de', 'de-de', 'fr', 'fr-fr', 'es', 'es-es', 'ja', 'zh-sg', 'zh-tw'];

export const isLocaleSupported = (locale: string): boolean => {
    locale = locale.toLocaleLowerCase();

    return !!supportedLocales.find(supportedLocale => supportedLocale === locale);
};

export function registerLocales() {
    for (const locale of supportedLocales) {
        registerLocaleData(locale);
    }
}
