import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {Payment} from '../../models/payment.class';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentService} from '../../services/payment-service/payment.service';
import {Pagination} from '../../models/abstract/pagination.interface';
import moment from 'moment';
import {FilterSetting} from '../search-filter-container/settings.class';
import {Selected} from '../search-filter-container/selected.class';
import {ExportService} from '../../services/export-service/export.service';
import {DownloadManagerService} from '../../services/download-manager-service/download-manager.service';
import {PaymentReportDownload} from '../../services/download-manager-service/types/payment-report-download';

@Component({
    selector: 'app-payment-list',
    templateUrl: './payment-list.component.html',
    styleUrls: ['./payment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentListComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    requestPayments$: Subject<boolean> = new Subject<boolean>();
    requestStatistics$: Subject<boolean> = new Subject<boolean>();
    view = 'PAYMENT_LIST_GROUPED_BY_TYPE';

    // Filters
    httpParams: HttpParams;
    filterSettings: FilterSetting[] = [];
    selectedFilters: Selected = new Selected();
    statusOptions = [
        {label: 'Open', value: 'OPEN'},
        {label: 'Success', value: 'SUCCESS'},
        {label: 'Canceled', value: 'CANCELLED'},
        {label: 'Expired', value: 'EXPIRED'},
        {label: 'Failed', value: 'FAILURE'}
    ];

    // Data
    payments$: BehaviorSubject<any> = new BehaviorSubject([]);
    statisticGroups = [];
    statistics = [];

    // Table
    isLoading = true;
    pagination: Pagination = {
        skip: 0,
        take: 10,
        total: null
    };

    constructor(
        private paymentService: PaymentService,
        private changeDetector: ChangeDetectorRef,
        private exportService: ExportService,
        private downloadManagerService: DownloadManagerService,
    ) {}

    ngOnInit(): void {
        this.filterSettings = [
            new FilterSetting('DATE_RANGE', 'General.Date_Range', true),
            new FilterSetting('STATUS', 'General.Status', true),
            new FilterSetting('PAYMENT_METHOD', 'General.Payment_Methods', true),
            new FilterSetting('PAYOUT', 'General.Payouts', true),
            new FilterSetting('PERSON', 'General.Persons', false),
            new FilterSetting('EVENT', 'General.Events', false),
            new FilterSetting('TICKET_TYPE', 'General.Ticket_Types', true),
            new FilterSetting('SUBSCRIPTION_TYPE', 'General.Subscription_Types', false),
            new FilterSetting('SUBSCRIPTION_TYPE_PRICE', 'General.Subscription_Type_Prices', true),
            new FilterSetting('VENUE_SECTION', 'General.Venue_Sections', true),
            new FilterSetting('CUSTOMER_TAG', 'General.Customer_Tags', false),
            new FilterSetting('IMPORTED', 'General.Imported', false),
            new FilterSetting('EVENT_CATEGORIES', 'General.Event_Categories', false)
        ];

        this.selectedFilters.status.push('SUCCESS');
        this.selectedFilters.dateRange = {
            id: 'TODAY',
            startAt: moment().startOf('day').toISOString(),
            endAt: moment().endOf('day').toISOString()
        };

        this.onFilterUpdate();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onFilterUpdate() {
        // cancel current request
        this.requestStatistics$.next(null);
        this.statistics = null;
        const params = this.getHttpParams();

        this.paymentService.getPaymentStatistics(params).pipe(
            takeUntil(this.destroy$),
            takeUntil(this.requestStatistics$)
        ).subscribe(statistics => {
            this.statistics = statistics;
            this.changeDetector.markForCheck();
        });

        this.tableUpdate();
    }

    onPaginationChange(pagination: Pagination) {
        this.pagination = pagination;
        this.tableUpdate();
    }

    tableUpdate() {
        // cancel current request
        this.requestPayments$.next(null);

        this.isLoading = true;
        const params = this.getHttpParams()
            .set('payment[order]', 'true')
            .set('payment[actor]', 'true')
            .set('depth', '2')
            .set('skip', this.pagination.skip.toString())
            .set('take', this.pagination.take.toString());

        if (this.view === 'PAYMENT_LIST') {
            this.paymentService.getPayments(params).pipe(
                takeUntil(this.destroy$),
                takeUntil(this.requestPayments$)
            ).subscribe((response: HttpResponse<Payment[]>) => {
                this.isLoading = false;
                this.pagination = {
                    total: parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10),
                    skip: parseInt(response.headers.get('X-CM-PAGINATION-SKIP'), 10),
                    take: parseInt(response.headers.get('X-CM-PAGINATION-TAKE'), 10),
                };

                this.payments$.next(response.body);
            });
        } else if (this.view === 'PAYMENT_LIST_GROUPED_BY_TYPE') {
            this.paymentService.getPaymentStatisticsGrouped(params).pipe(
                takeUntil(this.destroy$),
                takeUntil(this.requestPayments$)
            ).subscribe(statisticsGroups => {
                this.statisticGroups = statisticsGroups;
                this.isLoading = false;
                this.changeDetector.markForCheck();
            });
        }
    }

    setView(view: string) {
        this.view = view;
        this.tableUpdate();
    }

    download() {
        const httpParams = this.getHttpParams();

        this.exportService.getPaymentReport(
            httpParams
                .set('skip', '0')
                .set('take', '0')
        ).subscribe(response => {
            const startAt = httpParams.has('payment[startAt]') ? (httpParams.get('payment[startAt]').substring(0, 10)).replace(/-/g, '') : 'all';
            const endAt = httpParams.has('payment[endAt]') ? (httpParams.get('payment[endAt]').substring(0, 10)).replace(/-/g, '') : 'all';
            const today =  String(new Date().getFullYear()) + String(new Date().getMonth() + 1).padStart(2, '0') + String(new Date().getDate()).padStart(2, '0');
            this.downloadManagerService.downloadData(
                new PaymentReportDownload(this.exportService)
                    .setFilters(httpParams)
                    .setTotal(parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10))
                    .setFileName(`${today}_PaymentByOrder_${startAt}_${endAt}`)
            );
        });
    }

    onPrint(): void {
        window.print();
    }


    private getHttpParams() {
        let params = new HttpParams();

        if (this.selectedFilters.status.length > 0) {
            params = params.set('payment[status]', this.selectedFilters.status.join(','));
        }

        if (this.selectedFilters.eventIds.length > 0) {
            params = params.set('payment[eventIds]', this.selectedFilters.eventIds.join(','));
        }

        if (this.selectedFilters.ticketTypeIds.length > 0) {
            params = params.set('payment[ticketTypeIds]', this.selectedFilters.ticketTypeIds.join(','));
        }

        if (this.selectedFilters.subscriptionTypeIds.length > 0) {
            params = params.set('payment[subscriptionTypeIds]', this.selectedFilters.subscriptionTypeIds.join(','));
        }

        if (this.selectedFilters.subscriptionTypePriceIds.length > 0) {
            params = params.set('payment[subscriptionTypePriceIds]', this.selectedFilters.subscriptionTypePriceIds.join(','));
        }

        if (this.selectedFilters.venueSectionIds.length > 0) {
            params = params.set('payment[venueSectionIds]', this.selectedFilters.venueSectionIds.join(','));
        }

        const importIds = this.selectedFilters.importIds;
        if (importIds.length > 0) {
            params = params.set('payment[importIds]', importIds.join(','));
        }

        if (this.selectedFilters.discountCampaignIds.length > 0) {
            params = params.set('payment[discountCampaignIds]', this.selectedFilters.discountCampaignIds.join(','));
        }

        if (this.selectedFilters.customerTagIds.length > 0) {
            params = params.set('payment[customerTagIds]', this.selectedFilters.customerTagIds.join(','));
        }

        if (this.selectedFilters.paymentMethodIds.length > 0) {
            params = params.set('payment[paymentMethodIds]', this.selectedFilters.paymentMethodIds.join(','));
        }

        if (this.selectedFilters.payoutIds.length > 0) {
            params = params.set('payment[payoutIds]', this.selectedFilters.payoutIds.join(','));
        }

        if (this.selectedFilters.personIds.length > 0) {
            params = params.set('payment[personIds]', this.selectedFilters.personIds.join(','));
        }

        if (this.selectedFilters.eventCategoryIds.length > 0) {
            params = params.set('payment[eventCategoryIds]', this.selectedFilters.eventCategoryIds.join(','));
        }

        if (this.selectedFilters.dateRange.startAt) {
            params = params.set('payment[startAt]', moment(this.selectedFilters.dateRange.startAt).toISOString());
        }

        if (this.selectedFilters.dateRange.endAt) {
            params = params.set('payment[endAt]', moment(this.selectedFilters.dateRange.endAt).toISOString());
        }

        return params;
    }
}
