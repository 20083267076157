import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {shareReplay, map} from 'rxjs/operators';
import {Observable, BehaviorSubject} from 'rxjs';
import {Phrase, Translation} from '../interfaces/translation.interface';

@Injectable()
export class TranslationService {
private translationUrl = 'https://api.ticketing.cm.com/translations/v1.0/scopes/order-distribution-package/keys/';
  private currentLang;
  public langSubject: BehaviorSubject<string>;

  private defaultConfig = {
    withCredentials: true
  };

  private translationMap: Observable<Map<string, Phrase[]>>;


  constructor(private http: HttpClient) {
    this.langSubject = new BehaviorSubject(this.currentLang);
    this.translationMap = this.http.get<Translation[]>(this.translationUrl, this.defaultConfig)
      .pipe(
        map((translations: Translation[]): Map<string, Phrase[]> => {
          return translations.reduce((result: Map<string, Phrase[]>, translation: Translation) => {
            return result.set(translation.ID, translation.Phrases);
          }, new Map<string, Phrase[]>());
        }),
        shareReplay(1)
      );
  }

  public getLanguage(): string {
    return this.currentLang;
  }

  public setLanguage(lang: string): void {
    this.currentLang = lang;
    this.langSubject.next(this.currentLang);
  }

  public getPhraseForLanguage(key: string): Observable<Phrase> {
    return this.translationMap.pipe(
      map((testPhrases: Map<string, Phrase[]>): Phrase[] => {
        return testPhrases.get(key) || [];
      }),
      map((phrases: Phrase[]) => {
        const availablePhrases = phrases
          .filter(p => p.LanguageID.toLowerCase() === this.currentLang || p.LanguageID.toLowerCase() === 'en');
        if (availablePhrases.length === 2) {
          return availablePhrases.find(p => p.LanguageID.toLowerCase() !== 'en');
        } else if (availablePhrases.length > 2) {
          throw Error('More than 2 languages are found: ' + availablePhrases.map(p => p.LanguageID).join(', '));
        }
        return phrases.find(p => p.LanguageID.toLowerCase() === 'en');
      })
    );
  }
}
