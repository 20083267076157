<div class="order-footer" [class.expanded]="expanded" [class.expanded-full]="expandedFull">
    <div class="order-footer-content">
        <ng-container *ngIf="expanded && localReservation$ | async as localReservation">
            @if (getMoveOrderItemName()) {
                <div class="row p-l-24 p-r-24 order-footer-title">
                    <div class="col-1">
                        <button class="btn btn-secondary-icon cl-selected-color m-r-16"
                                [cm-tooltip]="('Venue_Section.Move_Seat' | trans)">
                            <span class="cm-icon cm-icon-sync-true" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="col-8">
                        {{ getMoveOrderItemName() }}
                    </div>
                    <div class="col-3 text-right">
                        <button class="btn btn-secondary-icon m-r-16" (click)="closeFooter()"
                                [cm-tooltip]="('General.Close' | trans)">
                            <span class="cm-icon cm-icon-close" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            } @else {
                <div class="row order-footer-title">
                    <div class="col-9">
                        {{ orderItemAmount }} {{ ('Order.Tickets_In_Order' | trans) }}
                        @if (order) {
                            @if (order.status === 'RESERVED' && order.viewData['expireDifference'] < 60) {
                                <br>
                                @if (customer) {
                                    <a class="subtitle" [routerLink]="['/customers', customer.id]">
                                        @if (customer.externalReference1) {
                                            {{ customer.externalReference1 }} -
                                        }
                                        {{ customer.getName() }} </a> -
                                }
                            }

                            <countdown *ngIf="order?.status !== 'COMPLETE' && order.viewData['expireDifference'] < 60"
                                       class="subtitle"
                                       title="{{('Order.Extend_Expiration_Time' | trans)}}"
                                       (click)="addExpireTime()"
                                       (event)="orderExpired($event)"
                                       [config]="{stopTime: order.viewData['expireAtUnix'], format: 'm:ss'}">
                            </countdown>

                            <ng-container
                                    *ngIf="order.status === 'RESERVED' && order.viewData['expireDifference'] >= 60">
                                <div class="subtitle">{{ ('Order.Expires' | trans) }} {{ order.viewData['expireDifferenceString'] }}</div>
                            </ng-container>
                        }

                    </div>
                    <div class="col-3 text-right">
                        <cm-button
                                data-icon="close"
                                data-button-style="secondary"
                                data-button-type="icon"
                                data-button-size="medium"
                                (click)="closeFooter()">
                        </cm-button>
                        <cm-button
                                *ngIf="order?.status !== 'COMPLETE'"
                                [attr.data-icon]="expandedFull ? 'open-vertical' : 'close-vertical'"
                                data-button-style="primary"
                                data-button-type="icon"
                                data-button-size="medium"
                                (click)="expandFooter()">
                        </cm-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-order></app-order>
                    </div>
                </div>
            }
        </ng-container>
    </div>
</div>
